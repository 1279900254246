import { Routes, Route, 
  // useNavigate

 } from "react-router-dom";

import LandingPage from "./Pages/Website/LandingPage";
import Layout from "./Components/Layouts/Layout";
import Aboutus from "./Pages/Website/Aboutus";
import Services from "./Pages/Website/Services";
import Pricing from "./Pages/Website/Pricing";
import Signup from "./Pages/AuthPages/Signup";
import Login from "./Pages/AuthPages/Login";
import NoPage from "./Pages/NoPage";
import AuthLayout from "./Components/Layouts/AuthLayout";
import SignupEmail from "./Pages/AuthPages/SignupEmail";
import Onboarding from "./Pages/AuthPages/Onboarding";
import CommercialSetup from "./Pages/AuthPages/CommercialSetup";
import Protected from "./Components/layout/protected.component";
import Overview from "./Pages/overview/overview.page";
import AdvancedSearch from "./Pages/advanced-search/advanced-search.page";
import Folder from "./Pages/folder/folder.page";
import Items from "./Pages/items/items.page";
import Sales from "./Pages/sales/sales.page";
import Expenses from "./Pages/expenses/expenses.page";
import ActivityHistory from "./Pages/activity-history/activity-history.page";
import ReportAnalysis from "./Pages/reports-analysis/reports-analysis.page";
import ManageTeam from "./Pages/manage-team/manage-team.page";
import Notifications from "./Pages/notifications/notifications.page";
import TrashPage from "./Pages/trash/trash.page";
import Settings from "./Pages/settings/settings.page";
import Folderview from "./Pages/folder/folder-view/Folderview";
import HelpSupport from "./Pages/help-support/help-support.page";

import Standalone from "./Pages/stand-alone-item/Standalone";
import Groupeditems from "./Pages/grouped-items/Groupeditems";
import CompositeItems from "./Pages/composite-items/CompositeItems";
import AddNewItem from "./Pages/add-newitem/AddNewItem";
import AddCompositeItem from "./Pages/composite-new/AddCompositeItem";
// import useAuth from "./hooks/useAuth";
import { useEffect } from "react";
import MobileRedirect from "./Pages/mobile-redirect/MobileRedirect";
import { useDispatch } from "react-redux";
import {
  getDebts,
  getDrafts,
  getReceipts,
  getRecovery,
  getSales,
} from "./states/sales/sales";
import { getExpenses } from "./states/expenses/expenses";
import { 
  // getGeneral,
   getInventory } from "./states/notifications/notifications";
import ForgotPassword from "./Pages/AuthPages/ForgotPassword";
import ForgotPasswordOtp from "./Pages/AuthPages/ForgotPasswordOtp";

import "./App.css";
import "./mobile.css";
import Customers from "./Pages/customers/Customers";
import Faq from "./Pages/Website/Faq";
import TermsConditions from "./Pages/Website/TermsConditions";
import PrivacyPolicy from "./Pages/Website/PrivacyPolicy";
import Referrals from "./Pages/referals/Referrals";
import AdminLayout from "./Components/layout/AdminLayout";
import AdminOverview from "./Pages/admin-pages/AdminOverview";
import AdminUsers from "./Pages/admin-pages/AdminUsers";
import AdminSub from "./Pages/admin-pages/AdminSub";
import UserDetail from "./Pages/admin-pages/UserDetail";
import AdminMessage from "./Pages/admin-pages/AdminMessage";
function App() {
  // const { setAuth } = useAuth();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("stk-apk"));

  // const checkUserToken = () => {
  //   const userToken = JSON.parse(localStorage.getItem("stk-apk"));
  //   if (userToken) {
  //     setAuth(userToken);
  //   }
  // };
  // useEffect(() => {
  //   checkUserToken();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  useEffect(() => {
    const targetDateTime = user?.authTokenExp;
    const currentDateTime = new Date().toISOString();

    if (user?.accountType && targetDateTime > currentDateTime) {
      const auth = JSON.parse(localStorage.getItem("stk-apk"));
      const token = !auth ? "" : auth.authToken;
      if (token) {
        dispatch(
          getSales("?orderBy=Latest&dateFilter=Today&page=1&pageSize=10")
        );
        dispatch(getRecovery("?dateFilter=Today&page=1&pageSize=10"));
        dispatch(getDrafts("?dateFilter=Today&page=1&pageSize=10"));
        dispatch(getReceipts("?dateFilter=Today&page=1&pageSize=10"));
        dispatch(getDebts("?dateFilter=Today&page=1&pageSize=10"));
        dispatch(
          getExpenses("?orderBy=Latest&dateFilter=Today&page=1&pageSize=5")
        );
        // dispatch(getGeneral(""));
        dispatch(getInventory(""));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="App">
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<LandingPage />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/services" element={<Services />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/terms&conditions" element={<TermsConditions />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        </Route>

        <Route element={<AuthLayout />}>
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signupemail" element={<SignupEmail />} />
          <Route path="/signuponboarding" element={<Onboarding />} />
          <Route path="/signupcommercialsetup" element={<CommercialSetup />} />
          <Route path="/mobile-redirect" element={<MobileRedirect />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/forgot-password/verify"
            element={<ForgotPasswordOtp />}
          />
        </Route>

        <Route element={<Protected />}>
          <Route path="/overview" element={<Overview />} />
          <Route path="/advancedsearch" element={<AdvancedSearch />} />
          <Route path="/section" element={<Folder />} />
          <Route path="/items" element={<Items />} />
          {user?.permissions?.viewSales && (
            <Route path="/sales" element={<Sales />} />
          )}
          <Route path="/expenses" element={<Expenses />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/activityhistory" element={<ActivityHistory />} />
          <Route path="/section/view" element={<Folderview />} />
          <Route path="/items/stand-alone" element={<Standalone />} />
          <Route path="/items/add-new" element={<AddNewItem />} />
          <Route
            path="/items/add-new-composite"
            element={<AddCompositeItem />}
          />
          <Route path="/items/grouped-items" element={<Groupeditems />} />
          <Route path="/items/composite-items" element={<CompositeItems />} />
          <Route path="/reports&analysis" element={<ReportAnalysis />} />
          <Route path="/manageteam" element={<ManageTeam />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/trash" element={<TrashPage />} />
          <Route path="/help&support" element={<HelpSupport />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/referral" element={<Referrals />} />
        </Route>

        <Route element={<AdminLayout />}>
          <Route path="/admin-overview" element={<AdminOverview />} />
          <Route path="/admin-users" element={<AdminUsers />} />
          <Route path="/admin-message" element={<AdminMessage />} />
          <Route path="/admin-subscription" element={<AdminSub />} />
          <Route path="/admin-users/:user_id" element={<UserDetail />} />
        </Route>

        <Route path="*" element={<NoPage />} />
      </Routes>
    </div>
  );
}

export default App;
