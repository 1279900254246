import ErrorMsg from "../../alert/error-msg.component";

import "./custom-textarea.styles.css";

const CustomTextarea = ({ label, placeholder, name, errors, ...rest }) => {
    return (
        <>
            <section className="textarea-container">
                <label> {label }</label>
                <textarea 
                    placeholder={placeholder}
                    name={name}
                    className={`${errors[name] ? 'error-input': ''} `}
                    {...rest} 
                />
            </section>
            { errors[name] && <ErrorMsg msg={errors[name]} /> }
        </>
    );
}
 
export default CustomTextarea;