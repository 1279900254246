import React, { useState } from "react";
import "./filter-popup.css";
import Dashboardbtn from "../dashboard-buttons/Dashboardbtn";
import { ArrowDown2, ArrowUp2 } from "iconsax-react";

const FolderFilter = ({ display, setDisplay,selectedSort, setSelectedSort,selectedOrder, setSelectedOrder, applyFilter }) => {
  const [displayOrder, setDisplayOrder] = useState(true);
  const [displaySort, setDisplaySort] = useState(true);
  // const [selectedSort, setSelectedSort] = useState("");
  // const [selectedOrder, setSelectedOrder] = useState("");

  const openMore = (e) => {
    if (e.target.id === "order") {
      setDisplayOrder(!displayOrder);
    }
    if (e.target.id === "sort-by") {
      setDisplaySort(!displaySort);
    }
  };
  const selectFilter = (e) => {
    if (setSelectedOrder){
    setSelectedSort(e.target.id);
    }
  };
  const selectOrder = (e) => {
    if (setSelectedOrder){
    setSelectedOrder(e.target.id);
    }
  };
  const resetFilter = () => {
    if (setSelectedOrder){
      setSelectedSort("");
      setSelectedOrder("");
    }
  };
  const sortbyArray = [
    "Name",
    "Created Time",
    "Last Modified Time",
    "Quantity",
    "Value",
  ];
  const orderArray = ["Ascending", "Descending"];
  return (
    <div>
      <div className={`overlay ${display ? "w-full" : "w-0"}`} onClick={() => setDisplay(false)}> </div>
      <div className={!display ? "display-none" : "filter-pop_up"}>
        <div className="filter-pop_up-header">
          <h2>
            Filters <span onClick={resetFilter}>Reset</span>
          </h2>
        </div>
        <div className="filter-body">
          <div className="stock-level" onClick={openMore}>
            <h3 id="sort-by" style={{ cursor: "pointer" }}>
              Sort by {!displaySort ? <ArrowDown2 /> : <ArrowUp2 />}
            </h3>
          </div>
          {sortbyArray &&
            sortbyArray.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className={
                    !displaySort ? "display-none" : "stock-level_options"
                  }
                >
                  <p id={item} onClick={selectFilter} className={item === selectedSort ? "selected" : ""}>
                    {item}
                  </p>
                </div>
              );
            })}

          <div className="stock-level" onClick={openMore}>
            <h3 id="order" style={{ cursor: "pointer" }}>
              Order {!displayOrder ? <ArrowDown2 /> : <ArrowUp2 />}
            </h3>
          </div>
          {orderArray &&
            orderArray.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className={
                    !displayOrder ? "display-none" : "stock-level_options"
                  }
                >
                  <p id={item} onClick={selectOrder} className={item === selectedOrder ? "selected" : ""}>{item}</p>
                </div>
              );
            })}
        </div>

        <div className="filter-buttons">
          <Dashboardbtn
            btnClass="dashboard-variant"
            btnText="cancel"
            btnWidth="180px"
            submitForm={() => setDisplay(false)}
          />
          <Dashboardbtn
            btnClass="dashboard-btn"
            btnText="Apply"
            btnWidth="180px"
            submitForm={applyFilter}
          />
        </div>
      </div>
    </div>
  );
};

export default FolderFilter;
