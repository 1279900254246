import edit_icon from "../../../assets/dashboard/edit-icon.png";
import trash_icon2 from "../../../assets/dashboard/trash-icon2.png";

const Item = ({ item, handleEdit, handleDelete }) => {
    const { id, name, quantity, total, totalAmount } = item
    return (
        <section className="item-container">
            <h6> {name} </h6>
            <div className="quantity-edit">
                <span> Quantity: <span className="pl"> {quantity} </span> </span>
                <img
                    className="pointer"
                    src={edit_icon} 
                    onClick={() =>  handleEdit(item)} 
                    alt="edit-icon" 
                    width={24} 
                    height={24} 
                />
            </div>
            <div className="total-delete">
                <span> Total: <span className="pl" >N {totalAmount || total} </span> </span>
                <img
                    className="pointer"
                    src={trash_icon2} 
                    alt="delete-icon" 
                    width={24} 
                    onClick={() => handleDelete(id)} 
                    height={24} 
                />
            </div>
        </section>
    );
}
 
export default Item;