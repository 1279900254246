
const StockAtHand = ({ color, icon, stockQuantity, headerText }) => {
    return (
        <div
            className="stock-item"
            style={{
                background: `${color}`,
                border: `1px solid ${color}`,
                borderRadius: '8px',
                padding: '20px 16px',
                height: '116px',
            }}
        >
            <span 
                style={{
                    fontFamily: 'Nunito',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize:' 14px',
                    lineHeight: '20px',
                    color: '#FFFFFF',
                }}
            > 
                {headerText}
            </span>

            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    marginTop: '10px'
                }}
            >
                <img src={icon} alt="stock-icon" />
                <span
                    style={{
                        fontFamily: 'Nunito',
                        fontWeight: 700,
                        fontSize: '20px',
                        lineHeight: '20px',
                        color: '#FFFFFF',
                    }}
                > 
                    {stockQuantity}
                </span>
            </div>

        </div>
    );
}
 
export default StockAtHand;