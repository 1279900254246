import React from 'react';
import "./amount-input.css";
import flag from "../../assets/dashboard/Ng-flag.png";

const AmountInput = ({amountPlaceholder, amountLabel, dataChange, amountId, amountValue, errorMessage, readOnly,code, ...rest}) => {
  const handleDataChange = (e) => {
    if (dataChange){
      dataChange(e);
    }
  }
  return (
    <div className='amount-cont'>
    <div className="amount-label">
        <label style={{ color: !errorMessage ? "" : "red"}} >{amountLabel}</label>
    </div>
    <div className='amount-input'>
        <div className={!errorMessage ? "amount-input_flag" : "amount-input_flag error-input"}>
            <img src={flag} alt="flag" />
            <p>{code ? code : "NGN"}</p>
        </div>
        <input 
          className={!errorMessage ? "" : "error-input"} 
          type="text" 
          id={amountId} 
          onChange={handleDataChange} 
          value={amountValue} 
          placeholder={amountPlaceholder}  
          readOnly={readOnly}
          {...rest}
          />
    </div>
        <p style={{ color: "red" }}>{errorMessage}</p>
    </div>
  )
}

export default AmountInput