import { useState } from "react";

// import Dcheckbox from "../dashboard-checkbox/Dcheckbox";

import edit_icon from "../../assets/dashboard/edit-icon.svg";
import empty_icon from "../../assets/dashboard/empty-icon.svg";

import { createColumnHelper } from "@tanstack/react-table";

import Table from "../table/table.component";

import Empty from "../empty/empty.component";

import { useDispatch, useSelector } from "react-redux";
import { getExpenses, setExpenseModal, setSingleExpense } from "../../states/expenses/expenses";

import OnlyDate from "../excerpts/OnlyDate";
import OnlyTime from "../excerpts/OnlyTime";
import Pagination from "../pagination/pagination.component";
import useActivityHistoryData from "../../hooks/useActivityHistoryData";
import Loading from "../Loading";
import FailedMsg from "../alert/failed-msg.component";
import { setFailureModal } from "../../states/general/general";



const ExpensesTable = () => {  
    const dispatch = useDispatch();  
    const { expenses } = useSelector((state) => state?.expenses);
    const userPermissions = useSelector((state) => state?.userPermissions);

    // eslint-disable-next-line
    const [data, setData] = useState( (expenses?.result && expenses?.result?.expenseList.length > 0) ? expenses?.result?.expenseList.map(expenses => ({ ...expenses, checked: "" })) : [] );
    const { activityHistoryData } = useActivityHistoryData();


    const handleModalDisplay = () => {
        if (userPermissions?.addSales) {
          dispatch(setExpenseModal(true))
        } else {
          dispatch(setFailureModal(true));
        }
    };

    const handleEdit = (id) => {
        dispatch(setSingleExpense(id));
        dispatch(setExpenseModal(true));
    }


    const columnHelper = createColumnHelper();
    const columns = [
    columnHelper.accessor('type', {
        cell: (info) => (
                <p className=""> {info.getValue()} </p>
            ),
        header: () => <span>Expense Type</span>,
    }),
    columnHelper.accessor('description', {
        cell: (info) => (
                <p className=""> {info.getValue()} </p>
        ),
        header: () => <span>Description</span>,
    }),
    columnHelper.accessor('amount', {
        cell: (info) => (
            <p> {info.getValue()} </p>
        ),
        header: () => <span> Total Amount(N) </span>,
    }),
    columnHelper.accessor('createdAt', {
        cell: (info) => (
            <>
                <p className=""> { <OnlyDate date={info.getValue()} /> } </p>
                <p className="time"> { <OnlyTime date={info.getValue()} /> } </p>
            </>
        ),
        header: () => <span> Date & Time </span>,
    }),
    columnHelper.accessor(row => `${row.id}`, {
        id: 'edit-trash',
        cell: (info) => (
            <div style={{ display: 'flex', gap: '16px' }}>
                <img 
                    src={edit_icon} 
                    onClick={() =>  handleEdit(info.getValue())} 
                    alt="edit-icon" 
                />
            </div>
    ),
        header: () => <span> </span>,
    }),
];


    const handleUpdate = (item) => {
        dispatch(getExpenses(`?dateFilter=${activityHistoryData?.dateValue}&startDate=${activityHistoryData?.startDate}&endDate=${activityHistoryData?.endDate}&orderBy=${activityHistoryData?.orderBy}&page=${item}&pageSize=10`))
    };

    return (
        <>
        <FailedMsg
          msg={"You do not have permissions to perform this action!!!"}
        />
            { expenses?.status === 'loading' 
                ?
                <Loading /> 
                :
                <>
                    { (data && data?.length > 0) && <Table data={data} columns={columns} minWidth={"100%"} /> }
                    { ( data && data?.length === 0) && 
                        <Empty submitClick={handleModalDisplay} title={"You don't have any expense"} btnText={"Add new expense"} img={empty_icon} /> 
                    }
                </>
            }
            { (data && data?.length > 0) && 
                <Pagination 
                    currentPage={expenses?.result?.pagination?.currentPage} 
                    numberOfPages={expenses?.result?.pagination?.numberOfPages} 
                    handleUpdate={handleUpdate} />
            }
        </>
    );
}
 
export default ExpensesTable;