const ErrorMsg = ({ msg }) => {
    return (
        <span
            style={{
                display: 'block',
                fontSize: '14px',
                lineHeight: '20px',
                color: 'red',
                marginTop: '10px',
                fontFamily: 'Raleway',
                fontWeight: 600,
            }}
        > 
            {msg} 
        </span>
    );
}
 
export default ErrorMsg;