import Dashboardheader from "../../Components/dashboard-header/Dashboardheader";
import SendMessage from "../../Components/help-support/send-message/send-message.component";
import SupportOption from "../../Components/help-support/support-options/support-options.component";

const HelpSupport = () => {
    return (
        <section>
          <Dashboardheader
            headerText="Help & Support"
          />
          <SendMessage />
          <SupportOption />
        </section>
    );
}
 
export default HelpSupport;