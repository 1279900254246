import { createSlice } from "@reduxjs/toolkit";


export const itemDropdown = createSlice({
    name: "itemsDrop",
    initialState: [],
    reducers: {
        itemDd: (state,action) => {
            const dropDownItems = action.payload
            return (dropDownItems);
        }
    }
})

export const {itemDd} = itemDropdown.actions;

export default itemDropdown.reducer;