import { useEffect, useState } from "react";

import { Bar } from "react-chartjs-2";
import { 
    Chart, 
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip 
} from "chart.js";
import SalesSummaryHeading from "./sales-summary-heading.component";
import useActivityHistoryData from "../../hooks/useActivityHistoryData";
import { AxiosGet } from "../../axios/axios";

Chart.register(
    CategoryScale,
    BarElement,
    LinearScale,
    Tooltip,
);

const BarChart = () => {
    const [isLoading, setIsLoading] = useState(null);
    const [lineData, setLineData] = useState();
    const { activityHistoryData} = useActivityHistoryData();
    const url = `overview/sales-graph-summary/?dateFilter=${activityHistoryData.dateValue}&startDate=${activityHistoryData.startDate}&endDate=${activityHistoryData.endDate}`;
    useEffect(() => {
      AxiosGet(url)
        .then((res) => {
        //   console.log(res.data);
          setLineData(res.data)
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err.response);
        });
        // eslint-disable-next-line
    },[activityHistoryData]);
    const chartData =!lineData ? {} : {
        labels: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'], 
        datasets: [
          {
            data: [lineData.graphData.sales.Monday, lineData.graphData.sales.Tuesday, lineData.graphData.sales.Wednesday, lineData.graphData.sales.Thursday, lineData.graphData.sales.Friday, lineData.graphData.sales.Saturday, lineData.graphData.sales.Sunday],
            backgroundColor: "#F08227",
          },
        ]
      };
    return (
        isLoading === null ? "" :
        <section className="bar-chart-container">
            <SalesSummaryHeading text={"Sales Summary"} sales={Number(lineData.totalSales).toLocaleString()} />
            <div
                style={{
                    margin: '30px 0',
                    border: '0.744891px solid #B2B3BB',
                }}
            ></div>
            <Bar 
                data={chartData}
                options={{
                    responsive: true,
                    plugins: {
                        tooltip: {
                            backgroundColor: '#FFFFFF',
                            titleAlign: 'center',
                            titleColor: '#757575',
                            titleSpacing: 4,
                            titleFont: {
                                size: 14,
                                weight: 500,
                                family: 'Raleway',
                            },
                            bodyColor: '#282828',
                            bodyFont: {
                                size: 16,
                                weight: 500,
                                family: 'Raleway',
                            },
                            bodySpacing: 4,
                            padding: 10,
                            cornerRadius: 8,
                        },
                        legend: {
                            display: false,
                        }
                    }}}
            />
        </section>
    );
}
 
export default BarChart;