import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosAuthPut, AxiosGet } from "../../axios/axios";


export const getExpenses = createAsyncThunk(
    'getExpenses/expenses',
    async (filter, thunkAPI) => {
      try {
        const { data } = await AxiosGet(`expenses/${filter}`);
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
);


export const updateExpense = createAsyncThunk(
    'updateExpense/expenses',
    async (payload, thunkAPI) => {
      try {
        const { data } = await AxiosAuthPut(`expenses/${payload.id}/`, payload.data);
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
  );



export const expenses = createSlice({
    name: "expenses",
    initialState: {
        singleExpense: {},
        expenses: {
            status: 'idle',
            result: []
        },
        expenseModal: false

    },
    reducers: {
        setExpenseModal: (state, action) => {
            state.expenseModal = action.payload;
        },    
        setSingleExpense: (state,action) => {
            const id = action.payload;
            state.singleExpense = state.expenses.result.expenseList.find(
              (expense)=> expense.id === id
            );
        },
        clearSingleExpense: (state, action) => {
            state.singleExpense = {};
        }
        
    },
    extraReducers(builder) {
        builder
          // get all sales
          .addCase(getExpenses.pending, (state) => {
            state.expenses.status = 'loading';
          })
          .addCase(getExpenses.fulfilled, (state, action) => {
            state.expenses.status = 'idle';
            state.expenses.result = action.payload;
          })
          .addCase(getExpenses.rejected, (state) => {
            state.expenses.status = 'idle';
          })
      }
})

export const {
    setExpenseModal,
    setSingleExpense,
    clearSingleExpense
} = expenses.actions;

export default expenses.reducer;