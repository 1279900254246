import { CloseCircle, ExportCurve } from "iconsax-react";
import "./notification.css";
import pdfImg from "../../assets/dashboard/pdf.png"
import excelImg from "../../assets/dashboard/excel.png"

import React from 'react';
import Dashboardbtn from "../dashboard-buttons/Dashboardbtn";
import useSelect from "../../hooks/useSelect";

const ExportComponent = ({display, setDisplay, submitForm, isLoading}) => {
    const closeModal = () => {
        setDisplay(false);
        setSelect("");
    }
    const {select, setSelect} = useSelect()
    const selectOptions = (e) =>{
        setSelect(e.target.id);
    }

  return (
    <>
    <div
      className={`overlay ${display ? "w-full" : "w-0"}`}
    >
      {" "}
    </div>
    <div className={!display ? "display-none" : "export-cont"}>
        <div className="export-header">
            <p>Export As</p>
            <CloseCircle style={{cursor: "pointer"}} onClick={closeModal}/>
        </div>
        <div className="export-body">
            <div className={select === "pdf" ? "export-pdf-select export-pdf" : "export-pdf"}>
                <img style={{cursor: "pointer"}} onClick={(e)=>selectOptions(e)} id="pdf" src={pdfImg} alt="pdf" />
                <p>As PDF</p>
            </div>
            <div className={select === "excel" ? "export-pdf-select export-pdf" : "export-pdf"}>
                <img style={{cursor: "pointer"}} onClick={(e)=>selectOptions(e)} id="excel"  src={excelImg} alt="excel" />
                <p>As EXCEL</p>
            </div>
        </div>
        <div className="export-button">
            <Dashboardbtn submitForm={submitForm} isLoading={isLoading} btnWidth={"500px"} btnText={"Export"} btnIcon={<ExportCurve />} btnClass={"dashboard-btn new-width"} />
        </div>
    </div>
    </>
  )
}

export default ExportComponent