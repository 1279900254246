import { useEffect, useState } from "react";

import { Add } from "iconsax-react";

import Dashboardheader from "../../Components/dashboard-header/Dashboardheader";
import Tab from "../../Components/settings/tab/tab.component";
import SalesComponent from "../../Components/sales/sales-component/sales.component";
import Drafts from "../../Components/sales/drafts/drafts.component";
import Receipts from "../../Components/sales/receipts/receipts.component";
import Recovery from "../../Components/sales/recovery/recovery.component";
import Debt from "../../Components/sales/debt/debt.component";
// import AddSale from "../../Components/sales/sales-component/add-sale-modal.component";

import { getDebts, getDrafts, getReceipts, getRecovery, getSales, setAllItems,
  //  setSalesModal
 } from "../../states/sales/sales";
import { useDispatch, useSelector } from "react-redux";

import { AxiosGet } from "../../axios/axios";
import useActivityHistoryData from "../../hooks/useActivityHistoryData";
import Loading from "../../Components/Loading";
import FailedMsg from "../../Components/alert/failed-msg.component";
import { setFailureModal } from "../../states/general/general";
import AddSaleModal from "../../Components/sales/sales-component/AddSaleModal";


const Sales = () => {

  const { sales, recovery, drafts, debts, receipts } = useSelector((state) =>  state.sales)

  const dispatch = useDispatch();
  const [ activeTab, setActiveTab ] = useState("Sales");
  const { activityHistoryData } = useActivityHistoryData();
  const userPermissions = useSelector((state) => state.userPermissions);
  const [display, setDisplay] = useState(false);
  const [itemDict, setItemDict] = useState([]);

  const tabs = [ 'Sales', 'Drafts', 'Receipts', 'Returns', 'Debt' ];

  // const modalHandler = () => {
  //   if (userPermissions.addSales) {
  //     dispatch(setSalesModal(true));
  //     localStorage.removeItem("errMsg")
  //   } else {
  //     dispatch(setFailureModal(true));
  //   }
  // }

  const openSalesModal = () => {
    if (userPermissions.addSales) {
      setDisplay(true);
      setItemDict([])
    } else {
      dispatch(setFailureModal(true));
    }
  }


  const handleRefresh = () => {
    dispatch(getSales("?orderBy=Latest&dateFilter=Today"));
    dispatch(getRecovery("?dateFilter=Today"));
    dispatch(getDrafts("?dateFilter=Today"));
    dispatch(getReceipts("?dateFilter=Today"));
    dispatch(getDebts("?dateFilter=Today"));
  }

  useEffect(() => {
    dispatch(getSales(`?dateFilter=${activityHistoryData.dateValue}&startDate=${activityHistoryData.startDate}&endDate=${activityHistoryData.endDate}&orderBy=${activityHistoryData.orderBy}&page=1&pageSize=10`));
    dispatch(getDebts(`?dateFilter=${activityHistoryData.dateValue}&startDate=${activityHistoryData.startDate}&endDate=${activityHistoryData.endDate}&orderBy=${activityHistoryData.orderBy}&page=1&pageSize=10`));
    dispatch(getReceipts(`?dateFilter=${activityHistoryData.dateValue}&startDate=${activityHistoryData.startDate}&endDate=${activityHistoryData.endDate}&orderBy=${activityHistoryData.orderBy}&page=1&pageSize=10`));
    dispatch(getDrafts(`?dateFilter=${activityHistoryData.dateValue}&startDate=${activityHistoryData.startDate}&endDate=${activityHistoryData.endDate}&orderBy=${activityHistoryData.orderBy}&page=1&pageSize=10`));
    dispatch(getRecovery(`?dateFilter=${activityHistoryData.dateValue}&startDate=${activityHistoryData.startDate}&endDate=${activityHistoryData.endDate}&orderBy=${activityHistoryData.orderBy}&page=1&pageSize=10`));
      // eslint-disable-next-line
  },[activityHistoryData]);


 
  useEffect(() => {
    AxiosGet('items-all/')
    .then((res) => {
        if (res.data) {
            dispatch(setAllItems(res.data));
        }
    }).catch((err) => {
    });
}, [dispatch]);

    return (
        <section>
          <Dashboardheader
            headerText="Sales"
            refreshStyle="flex"
            refreshSubmitForm={handleRefresh}
            addStyle="flex"
            addBtnText="add"
            addBtnIcon={<Add />}
            addSubmitForm={openSalesModal}
          />
          <FailedMsg
            msg={"You do not have permissions to perform this action!!!"}
          />
          <Tab activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
          { activeTab === tabs[0] && 
            <>
              { sales.status === "loading" ? <Loading imgStyle={{ marginTop: "150px" }} /> : <SalesComponent /> } 
            </>
          }
          { activeTab === tabs[1] && 
              <>
              { drafts.status === "loading" ? <Loading imgStyle={{ marginTop: "150px" }} /> : <Drafts />  }
              </>
          }
          { activeTab === tabs[2] && 
              <>
              { receipts.status === "loading" ? <Loading imgStyle={{ marginTop: "150px" }} /> : <Receipts /> } 
              </>
          }

          { activeTab === tabs[3] && 
            <>
              { recovery.status === "loading" ? <Loading imgStyle={{ marginTop: "150px" }}  /> : <Recovery />} 
            </>
          }
          { activeTab === tabs[4] && 
            <> { debts.status === "loading" ? <Loading imgStyle={{ marginTop: "150px" }} /> : <Debt /> }  </>
          } 
          {/* <AddSale /> */}
          <AddSaleModal display={display} setDisplay={setDisplay} itemDict={itemDict} setItemDict={setItemDict} />
        </section>
    );
}
 
export default Sales;