import React from "react";
import "./selectDelete.css";
import Dcheckbox from "../dashboard-checkbox/Dcheckbox";
import { Edit2, Trash } from "iconsax-react";
import Dashboardbtn from "../dashboard-buttons/Dashboardbtn";

const SelectDelete = ({
  numberSelect,
  checkboxChange,
  checkId,
  deleteDisplay,
  editDisplay,
  checked,
  restoreDisplay,
  editItem,
  deleteItem,
  submitRestore
}) => {
  const clickEditItem = () => {
    if (editItem) {
      editItem();
    }
  };
  const clickDeleteItem = () => {
    if (deleteItem) {
      deleteItem();
    }
  };
  return (
    <div>
      <div
        className="seclect-delete display-none"
        style={{ display: deleteDisplay }}
      >
        <div className="select-delete_left">
          <h3>({numberSelect}) Selected</h3>
        </div>
        <div className="select-delete_right">
          <div className="select-delete_restore display-none" style={{display: restoreDisplay}}>
            <Dashboardbtn btnClass="dashboard-btn" btnText="Restore" submitForm={submitRestore} />
          </div>

          <Dcheckbox
            checkboxChange={checkboxChange}
            checkId={checkId}
            checked={checked}
          />
          <div className={"edit-icon-wrap"} style={{ display: editDisplay }}>
            <Edit2 onClick={clickEditItem} style={{cursor: "pointer"}} />
          </div>
          <div className={"delete-icon-wrap"}>
            <Trash onClick={clickDeleteItem} style={{cursor: "pointer"}} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectDelete;
