
import "./custom-small-checkbox.styles.css";

const CustomSmallCheckBox = ({id, dataChange, checkValue, isChecked}) => {
    const onChange = (e) => {
        if (dataChange){
            dataChange(e);
        }
    }
    return (
        <>
            <input type="checkbox" id={`myCheckbox${id}`} value={checkValue} checked={isChecked}  onChange={onChange}/>
            <label className="checkbox-label" htmlFor={`myCheckbox${id}`} ></label>
        </>

    );
}
 
export default CustomSmallCheckBox;