import React, { useState } from "react";
import "./add-composite.css";
import { InputForm, SelectInput } from "../../Components/AuthHead/AuthHead";
import { Edit, Edit2, More, Trash } from "iconsax-react";
import AddNew from "../../Components/add&new/Add&New";
import DiscardChanges from "../../Components/discard-changes/DiscardChanges";
import Goback from "../../Components/Dashboard-goback/Goback";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  AxiosAuthPost,
  AxiosAuthPut,
  AxiosDelete,
  AxiosGet,
} from "../../axios/axios";
import { itemDd } from "../../states/items/itemDropdown";
import singlePic from "../../assets/dashboard/single-item.png";
import { useNavigate } from "react-router-dom";
import UploadImg from "../../Components/upload-img/UploadImg";
import useCoverPicture from "../../hooks/useCoverPicture";
import useAddContent from "../../hooks/useAddContent";
import DeletePopup from "../../Components/filter-popups/DeletePopup";
import SuccessPopup from "../../Components/filter-popups/SuccessPopup";
import Loading from "../../Components/Loading";
import useCoverImage from "../../hooks/useCoverImage";

const AddCompositeItem = () => {
  const url = "composite-items/";
  const compositeId = localStorage.getItem("compositeId");
  const getCompositeUrl = `composite-items/${compositeId}/`;
  const itemDropdown = useSelector((state) => state.itemsDrop);
  const businessDropdown = useSelector((state) => state.businessDrop);
  const dispatch = useDispatch();
  const [displayMore, setDisplayMore] = useState(false);
  const [displayPic, setDisplayPic] = useState(false);
  const [displayDelete, setDisplayDelete] = useState(false);
  const [editDisplay, setEditDisplay] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(null);
  const [isDLoading, setIsDLoading] = useState(false);
  const [nameError, setNameError] = useState("");
  const [itemError, setItemError] = useState("");
  const [picError, setPicError] = useState("");
  const [itemData, setItemData] = useState();
  const [compositeData, setCompositeData] = useState();
  const [displayCancel, setDisplayCancel] = useState(false);
  const [tableArray, setTableArray] = useState([]);
  const { coverPicture } = useCoverPicture();
  const {setCoverImage} = useCoverImage();
  const { addContent } = useAddContent();
  const itemDropId = localStorage.getItem("itemDropId");
  const tableIds = tableArray.map((item) => {
    return item.id;
  });
  const amountArray = tableArray.map((item) => {
    return item.sellingPrice;
  });
  const totalSum = amountArray.reduce(
    (accumulator, current) => accumulator + current,
    0
  );
  const compositeArrayId = !compositeData
    ? []
    : compositeData.itemsList.map((item) => {
        return item.id;
      });
  const navigate = useNavigate();
  const allItemsUrl = "items-all/?itemType=Stand Alone";
  useEffect(() => {
    AxiosGet(allItemsUrl)
      .then((res) => {
        // console.log(res);
        dispatch(itemDd(res.data));
      })
      .catch((err) => {
        // console.log(err.response);
      });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!compositeId) {
      setIsLoading(false);
    }
    AxiosGet(getCompositeUrl)
      .then((res) => {
        // console.log(res.data);
        setIsLoading(false);
        setCompositeData(res.data);
      })
      .catch((err) => {
        // console.log(err.response);
      });
    // eslint-disable-next-line
  }, []);
  const [data, setData] = useState({
    name: "",
    business_id: "",
  });
  const dataObject = !compositeData
    ? {
        name: data.name,
        business_id: data.business_id,
        picture: coverPicture,
        itemIds: tableIds,
      }
    : {
        name: !compositeData ? data.name : compositeData.name,
        business_id: !compositeData
          ? data.business_id
          : compositeData.businessId,
        picture: !coverPicture ? "" : coverPicture,
        itemIds: !compositeData ? tableIds : compositeArrayId.concat(tableIds),
      };
  const addComposite = () => {
    // console.log(dataObject);
    setIsLoading(true);
    AxiosAuthPost(url, dataObject)
      .then((res) => {
        setIsLoading(false);
        // console.log(res);
        if (addContent === "Add") {
          navigate("/items");
          setData({
            name: "",
            business_id: "",
          });
          setTableArray([]);
          setCoverImage("");
        }
        if (addContent === "Add & New") {
          setData({
            name: "",
            business_id: "",
          });
          setTableArray([]);
          setCoverImage("");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err.response);
        if (
          err.message !== "Request failed with status code 500" &&
          err.message !== "Network Error"
        ) {
          for (let i = 0; i < err.response.data.errors.length; i++) {
            if (err.response.data.errors[i].fieldName === "name") {
              setNameError(err.response.data.errors[i].error);
            }
            if (err.response.data.errors[i].fieldName === "itemIds") {
              setItemError(err.response.data.errors[i].error);
            }
            if (err.response.data.errors[i].fieldName === "picture") {
              setPicError(err.response.data.errors[i].error);
            }
          }
        }
      });
  };
  const editComposite = () => {
    setIsLoading(true);
    // console.log(dataObject);
    AxiosAuthPut(getCompositeUrl, dataObject)
      .then((res) => {
        // console.log(res);
        if (res.success=== true ){
          navigate("/items");
          setDisplaySuccess(true);
          setEditDisplay(true);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err.response);
        setIsLoading(false);
      });
  };
  const deleteComposite = () => {
    setIsDLoading(true);
    AxiosDelete(getCompositeUrl)
      .then((res) => {
        // console.log(res);
        setIsDLoading(false);
        if (res.success === true) {
          setDisplayDelete(false);
          localStorage.removeItem("compositeId")
          setDisplaySuccess(true);
        }
      })
      .catch((err) => {
        // console.log(err.response);
        setIsDLoading(false);
      });
  };

  const handleDataChange = (e) => {
    if (e.target.id === "items_dropdown") {
      setItemError("");
    }
    if (e.target.id === "name") {
      setNameError("");
    }
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    for (let i = 0; i < itemDropdown.length; i++) {
      if (e.target.value === itemDropdown[i].id) {
        setItemData(itemDropdown[i]);
        setTableArray([...tableArray, itemDropdown[i]]);
        // console.log(itemDropdown[i]);
      }
    }
    // console.log(newData);
  };
  const openMore = (item) => {
    setDisplayMore(!displayMore);
    localStorage.setItem("itemDropId", item.id);
  };
  const removeItem = (item) => {
    // console.log(item.id);
    setTableArray(tableArray.filter((item) => item.id !== itemDropId));
  };
  const editItem = (item) => {
    localStorage.setItem("itemId", item.id);
    navigate("/items/stand-alone");
  };
  const handleCancel = () => {
    setDisplayCancel(!displayCancel);
  };
  const popDelete = () => {
    setDisplayDelete(!displayDelete);
  };
  const cancelDelete = () => {
    setDisplayDelete(!displayDelete);
  };

  return isLoading === null ? (
    <Loading />
  ) : (
    <div className="new-composite_item">
      <SuccessPopup
        display={displaySuccess}
        setDisplay={setDisplaySuccess}
        popupMessage={!editDisplay ? "You have successfully deleted selected entities" : "You have successfully edited your composite"}
        cancelClick={() => navigate("/items")}
      />
      <DeletePopup
        display={displayDelete}
        deleteItem={deleteComposite}
        popupName={!compositeData ? "" : compositeData.name}
        isLoading={isDLoading}
        cancelDelete={cancelDelete}
        setDispay={setDisplayDelete}
      />
      <Goback />
      {!compositeData ? (
        <input
          type="text"
          placeholder="Composite Item Name*"
          id="name"
          onChange={handleDataChange}
          value={data.name}
        />
      ) : (
        <input
          type="text"
          placeholder={compositeData.name}
          className="composite-name"
          id="name"
          onChange={handleDataChange}
          value={data.name}
          style={{ color: "black", textTransform: "capitalize" }}
        />
      )}
      {nameError ? <p style={{ color: "red" }}>{nameError}</p> : ""}
      <div className="new-composite_item-body">
        <div className="new-composite_item-body-left">
          <h2>Associate Items</h2>
          <div className="new-composite_item-body_table">
            <table className="grid-table">
              <thead>
                <tr>
                  <th className="column1">Item Details</th>
                  <th className="column2">Quantity</th>
                  <th className="column3">Amount</th>
                </tr>
              </thead>
              <tbody>
                {!compositeData
                  ? ""
                  : compositeData.itemsList.map((item, idx) => {
                      return (
                        <tr
                          key={idx}
                          id={item.id}
                          className="select-comp_items-row"
                        >
                          <td className="item-more">
                            <div className="select-comp_items">
                              <p>
                                <img
                                  src={!item.picture ? singlePic : item.picture}
                                  alt="item"
                                />
                                {item.name}
                              </p>
                              <More
                                style={{ rotate: "90deg", cursor: "pointer" }}
                                onClick={() => openMore(item)}
                              />
                              <div
                                className={
                                  displayMore && itemDropId === item.id
                                    ? "composite-more_list"
                                    : "display-none"
                                }
                              >
                                <p onClick={() => editItem(item)}>
                                  <Edit /> Edit Item
                                </p>
                                <p onClick={() => removeItem(item)}>
                                  <Trash /> Remove
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <InputForm
                              labelStyle={{ display: "none" }}
                              placeholder={item.quantity}
                              // inputStyle={{ width: "120px" }}
                            />
                          </td>
                          <td>
                            <InputForm
                              labelStyle={{ display: "none" }}
                              placeholder={"₦ " + Number(item.totalValue).toLocaleString() + ".00"}
                              // inputStyle={{ width: "120px" }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                {tableArray.length === 0
                  ? ""
                  : tableArray.map((item, idx) => {
                      return (
                        <tr
                          key={idx}
                          id={item.id}
                          className="select-comp_items-row"
                        >
                          <td className="item-more">
                            <div className="select-comp_items">
                              <p>
                                <img
                                  src={!item.picture ? singlePic : item.picture}
                                  alt="item"
                                />
                                {item.name}
                              </p>
                              <More
                                style={{ rotate: "90deg", cursor: "pointer" }}
                                onClick={() => openMore(item)}
                              />
                              <div
                                className={
                                  displayMore && itemDropId === item.id
                                    ? "composite-more_list"
                                    : "display-none"
                                }
                              >
                                <p onClick={() => editItem(item)}>
                                  <Edit /> Edit Item
                                </p>
                                <p onClick={() => removeItem(item)}>
                                  <Trash /> Remove
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <InputForm
                              labelStyle={{ display: "none" }}
                              placeholder={item.quantity}
                              inputStyle={{ width: "120px" }}
                            />
                          </td>
                          <td>
                            <InputForm
                              labelStyle={{ display: "none" }}
                              placeholder={Number(item.sellingPrice).toLocaleString()}
                              inputStyle={{ width: "120px" }}
                            />
                          </td>
                        </tr>
                      );
                    })}

                <tr>
                  <td>
                    <SelectInput
                      labelStyle={{ display: "none" }}
                      optionLabel="Choose Item"
                      style={{ marginBottom: "10px", width: "320px" }}
                      optionsArray={itemDropdown}
                      formId="items_dropdown"
                      formValue={data.items_dropdown}
                      dataChange={handleDataChange}
                    />
                    {itemError ? (
                      <p style={{ color: "red" }}>{itemError}</p>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>
                    <InputForm
                      labelStyle={{ display: "none" }}
                      placeholder={!itemData ? "0.00" : itemData.quantity}
                      inputStyle={{ width: "120px" }}
                    />
                  </td>
                  <td>
                    <InputForm
                      labelStyle={{ display: "none" }}
                      placeholder={!itemData ? "0.00" : itemData.sellingPrice}
                      inputStyle={{ width: "120px" }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="total-value">
              <p>Total(NGN) :</p>{" "}
              <InputForm
                labelStyle={{ display: "none" }}
                // inputStyle={{ width: "100px" }}
                placeholder={
                  compositeData
                    ? "₦ " + Number(compositeData.totalValue + totalSum).toLocaleString() + ".00"
                    : !itemData
                    ? "0.00"
                    : totalSum + ".00"
                }
              />
            </div>
          </div>
        </div>
        <div className="new-composite_item-body-right">
          <h3>Photos</h3>
          <div className="new-item-body-right_img">
            {!compositeData ? (
              <UploadImg
                style={{ height: "326px", width: "100%" }}
                iconSize="44"
              />
            ) : displayPic ? (
              <UploadImg
                style={{ height: "326px", width: "100%" }}
                iconSize="44"
              />
            ) : (
              <img src={compositeData.picture} alt="pic" />
            )}
            {picError && <p style={{color: "red"}}>{picError}</p>}
          </div>
          {!compositeData ? (
            ""
          ) : (
            <div
              className="standalone-form_right-icon_cont"
              style={{ justifyContent: "flex-end" }}
            >
              <div className="standalone-form_right-edit_icon">
                <Edit2
                  style={{ cursor: "pointer" }}
                  onClick={() => setDisplayPic(!displayPic)}
                />
              </div>
              <div className="standalone-form_right-delete_icon">
                <Trash style={{ cursor: "pointer" }} onClick={popDelete} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="new-item_bottom">
        <div className="new-item_bottom-left">
          <SelectInput
            formLabel="Add to Business"
            optionLabel="Select Business"
            optionsArray={businessDropdown}
            formId="business_id"
            dataChange={handleDataChange}
            formValue={data.business_id}
          />
        </div>
        <div className="new-item_bottom-right">
          <p onClick={handleCancel}>Cancel</p>
          <AddNew
            btnWidth="180px"
            submitForm={!compositeData ? addComposite : editComposite}
            isLoading={isLoading}
          />
        </div>
      </div>
      <DiscardChanges
        displayCancel={displayCancel}
        cancelForm={() => setDisplayCancel(false)}
      />
    </div>
  );
};

export default AddCompositeItem;
