import React from "react";
import "./landing-page.css";

const PrivacyPolicy = () => {
  const infoCollect = [
    {
      text: "a. Personal Information:",
      span: "When you register for an account, we collect certain personal information such as your name, email address, phone number, and business information. This information is used to create and manage your account and to provide customer support.",
    },
    {
      text: "b. Inventory Data:",
      span: "StockKeeper collects and stores information related to your inventory, including product names, descriptions, quantities, prices, and supplier information. This data is used to facilitate inventory management and stock-keeping.",
    },
    {
      text: "c. Usage Data:",
      span: "We may collect information about how you use the App, including your interactions, preferences, and activities. This data helps us improve the functionality and user experience of the App.",
    },
    {
      text: "d. Device Information:",
      span: "We may collect device-specific information, such as your device type, operating system, and unique device identifiers. This information is used for troubleshooting, analytics, and app optimization.",
    },
  ];
  const useInfo = [
    {
      text: "a. Providing Services:",
      span: "We use your personal information and inventory data to provide you with the requested services, including inventory management and stockkeeping functionalities.",
    },
    {
      text: "b. Communication:",
      span: "We may use your email address to send you notifications, updates, and important information about the App.",
    },
    {
      text: "c. Improvement:",
      span: "We use usage and device information to analyze user behavior, understand user preferences, and enhance the App's features, performance, and security.",
    },
    {
      text: "d. Legal Compliance:",
      span: "We may process your information to comply with applicable laws, regulations, or legal obligations.",
    },
  ];
  const shareInfo = [
    {
      text: "a. Service Providers:",
      span: "We may share your information with third-party service providers who help us with App development, maintenance, and customer support.",
    },
    {
      text: "b. Business Transfers:",
      span: "In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the acquiring entity.",
    },
    {
      text: "c. Legal Requirements:",
      span: "We may process your information to comply with applicable laws, regulations, or legal obligations.",
    },
  ];
  const dataSecurity = [
    "request for and access your Personal Data collected and stored by the App",
    "withdraw consent at any time. For example, you can withdraw your consent to receipt of our marketing or promotional materials or unsubscribe to our newsletters",
    "object to automated decision making",
    "request rectification and modification of Personal Data kept by the app",
    "request for deletion of your Personal Data",
    "be informed of and entitled to provide consent prior to the processing of Personal Data for purposes other than that for which the Personal Data were collected",
    "request that the App restricts processing of your Personal Data.",
  ];

  return (
    <section className="privacy-policy">
      <div className="privacy-policy_cont">
        <div className="privacy-policy_head">
          <h2>
            Privacy <span>Policy</span>
          </h2>
          <p>
            This Privacy Policy describes how StockKeeper ("we," "us," or "our")
            collects, uses, and shares personal information when you ("user,"
            "you," or "your") access and use the StockKeeper web application and
            mobile application (collectively, the "App"). By using the App, you
            agree to the practices described in this Privacy Policy.
          </p>
        </div>

        <div className="privacy-policy_body">
          <div className="info-collect">
            <h3>1. Information We Collect</h3>
            {infoCollect &&
              infoCollect.map((item, idx) => {
                return (
                  <p key={idx}>
                    {item.text} <span>{item.span}</span>
                  </p>
                );
              })}
          </div>
          <div className="when-collect">
            <h3>2. When do we collect your Personal Data? </h3>
            <p>
              We collect Personal Data that you give to us, for example, where
              you create an account with us, request for further information
              about our product, fill a form, apply for a job through our
              website or you subscribe to newsletters on our website. We may
              also automatically collect some technical information when you
              visit our website such as your IP address, and information about
              your visit, such as the pages that you viewed. This information
              helps us understand customer interests and helps us improve our
              website.
            </p>
          </div>
          <div className="info-collect">
            <h3>3. How We Use Your Information</h3>
            {useInfo &&
              useInfo.map((item, idx) => {
                return (
                  <p key={idx}>
                    {item.text} <span>{item.span}</span>
                  </p>
                );
              })}
          </div>
          <div className="when-collect">
            <h3>4. What Constitutes Your Consent? </h3>
            <p>
              Where processing of Personal Data is based on consent, We shall
              obtain the requisite consent at the time of collection of the
              Personal Data. In this regard, you consent to the processing of
              your Personal Data when you access our platforms, or use our
              services, content, features, technologies or functions offered on
              our website or other digital platforms. You can withdraw your
              consent at any time but such withdrawal will not affect the
              lawfulness of processing based on consent given before its
              withdrawal.
            </p>
          </div>
          <div className="info-collect">
            <h3>5. Who Do We Share Your Information With?</h3>
            {shareInfo &&
              shareInfo.map((item, idx) => {
                return (
                  <p key={idx}>
                    {item.text} <span>{item.span}</span>
                  </p>
                );
              })}
          </div>
          <div className="when-collect">
            <h3>6. Security of Your Personal Data? </h3>
            <p>
              We take security of your Personal Data seriously and We make every
              effort to keep your Personal Data secure. We have put in place
              technological and organizational procedures, including requiring
              our staff and any third parties who carry out any work on our
              behalf to comply with appropriate security standards in order to
              protect your Personal Data.
            </p>
          </div>
          <div className="data-security">
            <h3>7. Security of Your Personal Data?</h3>
            <p>
              Stock Keeper collects Personal Data only for the purposes
              identified in this Policy and such information cannot be reused
              for another purpose that is incompatible with the original
              purpose.
            </p>
            <ul>
              <h4>
                You can exercise the following rights with respect to your
                Personal Data with Stock Keeper:
              </h4>
              {dataSecurity &&
                dataSecurity.map((item, idx) => {
                  return <li key={idx}>{item}</li>;
                })}
            </ul>
          </div>
          <div className="when-collect">
            <h3>8. Update of Your Personal Data </h3>
            <p>
              We will only use your personal data as set out in this Privacy
              Policy which is in force at the time, we collect your information.
              If any revision or update is done that affects the processing of
              your personal data, we will inform you.
            </p>
          </div>
          <div className="when-collect">
            <h3>9. Contact Us </h3>
            <p>
              If you have any questions, concerns, or requests regarding this
              Privacy Policy or your personal information, please contact us at
              [email]. <br /> By using the StockKeeper App, you acknowledge that you
              have read and understood this Privacy Policy and agree to its
              terms. .
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
