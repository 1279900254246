import { useEffect, useState } from "react";

import { ArrowLeft, ArrowRight } from "iconsax-react";

import pagination_arrow_down from "../../assets/dashboard/pagination-arrow-down.svg";

import "./pagination.styles.css";

const Pagination = ({ currentPage, numberOfPages, handleUpdate }) => {
    
    const dropdownItems = [];
    for (let i = 1; i <= numberOfPages; i++) {
        dropdownItems.push(i);
    }
    const [dropdown, setDropdown] = useState(false);
    const [dropdownOption, setDropdownOption] = useState(currentPage);
    const [start, setStart] = useState(false);
    const [end, setEnd] = useState(false);


    const toggleDropdown = () => setDropdown((state) => !state);

    const dropdownHandler = (item) => {
        setDropdown(false);
        setDropdownOption(item);
        handleUpdate(item);
    };

    const nextPage = () => {
        if (!end) {
            const position = dropdownItems.indexOf(dropdownOption);
            setDropdownOption(dropdownItems[position + 1]);
            handleUpdate(dropdownItems[position + 1]);
        }
    };
    const prevPage = () => {
        if (!start) {
            const position = dropdownItems.indexOf(dropdownOption);
            setDropdownOption(dropdownItems[position - 1]);
            handleUpdate(dropdownItems[position - 1]);
        }
    };

    useEffect(() => {
        if ( dropdownOption === numberOfPages ) {
            setEnd(true)
        } else {
            setEnd(false);
        }
        // eslint-disable-next-line 
    }, [dropdownOption]);

    useEffect(() => {
        if (dropdownOption === 1) {
            setStart(true)
        } else {
            setStart(false);
        }
    }, [dropdownOption]);

    return (
        <section className="pagination-container">
            <span className="total-pages"> 1 - 10 of {numberOfPages} page(s) </span>
            <div
                style={{
                    display: 'flex',
                    gap: '20px',
                    alignItems: 'center'
                }}
            >
                <span className="text"> You are on page </span>
                <div
                    style={{
                        display: 'flex',
                        gap: '5px',
                        alignItems: 'center'
                    }}
                >
                    <div className="page-numbers">
                        <span 
                            onClick={toggleDropdown}
                            className="number"
                        > 
                            {dropdownOption} <img src={pagination_arrow_down} alt="pagination-arrow-down" width={12} height={12} />  
                        </span>
                        { dropdown && 
                            <div className="dropdown">
                                {dropdownItems.map((item, id) => (
                                    <p key={id} onClick={() => dropdownHandler(item)}>
                                        {item}
                                    </p>
                                ))}
                            </div>
                            }
                    </div>
                    <div
                    style={{
                        height: '20.5px',
                        width: '0px',
                        border: '1px solid #B2B3BB'
                    }}
                    ></div>
                    <div
                        style={{
                            display: 'flex',
                            gap: '10px',
                        }}
                    >
                        <ArrowLeft 
                            onClick={prevPage}
                            size={"18"}
                            color={`${start ? "#A1A1A1" :  '#393c50'}`} 
                            className="pointer"
                        />
                        <ArrowRight
                            onClick={nextPage}
                            size={"18"}
                            color={`${end ? "#A1A1A1" :  '#393c50'}`}
                            className="pointer" 
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default Pagination;