import React from 'react';
import spinner from "../assets/dashboard/load-spinner.gif"

const Loading = ({imgStyle}) => {
  return (
    <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
    }}>
        <img style={imgStyle} src={spinner} alt="Loading..." />
    </div>
  )
}

export default Loading