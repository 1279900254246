import { Chart, ArcElement, Tooltip } from "chart.js";
import { useEffect, useState } from "react";

import { Pie } from "react-chartjs-2";

import SalesSummaryHeading from "./sales-summary-heading.component";
import LegendComponent from "./legend.component";
import useActivityHistoryData from "../../hooks/useActivityHistoryData";
import { AxiosGet } from "../../axios/axios";

Chart.register(ArcElement, Tooltip);

const PieChart = () => {
  const [isLoading, setIsLoading] = useState(null);
  const [pieData, setPieData] = useState({});
  const [pieChartData, setPieChartData] = useState({});
  const { activityHistoryData } = useActivityHistoryData();
  const url = `overview/expense-statistics/?dateFilter=${activityHistoryData.dateValue}&startDate=${activityHistoryData.startDate}&endDate=${activityHistoryData.endDate}`;
  useEffect(() => {
    AxiosGet(url)
      .then((res) => {
        // console.log(res.data);
        setPieData(res.data);
        // setPieChartData(res.data.pieChartData)
        setPieChartData(res.data.pieChartData);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err.response);
      });
    // eslint-disable-next-line
  }, [activityHistoryData]);
  // eslint-disable-next-line
  const chartData =
    isLoading === null
      ? {
          labels: ["Salary", "Utility", "Maintenance", "Others"],
          datasets: [
            {
              data: [0, 0, 0, 0],
              backgroundColor: ["#080B24", " #F08227", "#4CAF50", "#2266B0"],
            },
          ],
        }
      : {
          labels: ["Salary", "Utility", "Maintenance", "Others"],
          datasets: [
            {
              data: [
                !pieData.pieChartData.Salary
                  ? 0
                  : parseInt(pieData.pieChartData.Salary, 10),
                !pieData.pieChartData.Utility
                  ? 0
                  : parseInt(pieData.pieChartData.Utility, 10),
                !pieData.pieChartData.Maintenance
                  ? 0
                  : parseInt(pieData.pieChartData.Maintenance, 10),
                !pieData.pieChartData.Others ? 0 : parseInt(pieData.pieChartData.Others, 10),
              ],
              backgroundColor: ["#080B24", " #F08227", "#4CAF50", "#2266B0"],
            },
          ],
        };

  return (
    <section className="pie-chart-container">
      <SalesSummaryHeading text={"Expense Statistics"} />
      <section className="total-expenses">
        <span> Total Expenses </span>
        <p> ₦ {Number(pieData.totalExpense).toLocaleString()} </p>
      </section>
      <section className="pie-chart-legend-container">
        <section
          style={{
            marginTop: "30px",
            width: "300px",
            height: "300px",
          }}
        >
          {!pieChartData ? (
            "No data"
          ) : (
            <Pie
              options={{
                responsive: true,
                plugins: {
                  tooltip: {
                    backgroundColor: "#FFFFFF",
                    titleAlign: "center",
                    titleColor: "#757575",
                    titleSpacing: 4,
                    titleFont: {
                      size: 14,
                      weight: 500,
                      family: "Raleway",
                    },
                    bodyColor: "#282828",
                    bodyFont: {
                      size: 16,
                      weight: 500,
                      family: "Raleway",
                    },
                    bodySpacing: 4,
                    padding: 10,
                    cornerRadius: 8,
                  },
                  legend: {
                    display: false,
                  },
                },
              }}
              data={chartData}
            />
          )}
        </section>
        {!pieData.expenseData ? (
          ""
        ) : (
          <section className="pie-chart-legends">
            <LegendComponent
              color={"#080B24"}
              label={"Salary"}
              price={
                !pieData.expenseData.Salary
                  ? "₦ 0"
                  : "₦ " + Number(pieData.expenseData.Salary).toLocaleString()
              }
            />
            <LegendComponent
              color={"#F08227"}
              label={"Utility"}
              price={
                !pieData.expenseData.Utility
                  ? "₦ 0"
                  : "₦ " + Number(pieData.expenseData.Utility).toLocaleString()
              }
            />
            <LegendComponent
              color={"#4CAF50"}
              label={"Maintenance"}
              price={
                !pieData.expenseData.Maintenance
                  ? "₦ 0"
                  : "₦ " +
                    Number(pieData.expenseData.Maintenance).toLocaleString()
              }
            />
            <LegendComponent
              color={"#2266B0"}
              label={"Others"}
              price={
                !pieData.expenseData.Others
                  ? "₦ 0"
                  : "₦ " + Number(pieData.expenseData.Others).toLocaleString()
              }
            />
          </section>
        )}
      </section>
    </section>
  );
};

export default PieChart;
