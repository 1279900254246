import React, { useState } from "react";
import "./quantitybar.css";
import Dcheckbox from "../dashboard-checkbox/Dcheckbox";
import { Clock, FolderOpen, Grid2, More, Task, Trash } from "iconsax-react";
import SelectDelete from "../select-delete-bar/SelectDelete";
import { useNavigate } from "react-router-dom";
import itemImg from "../../assets/dashboard/single-item.svg";
import { useDispatch, useSelector } from "react-redux";
import { newId } from "../../states/items/itemIdSlice";

const Quantitybar = ({
  quantityArray,
  folderArray,
  quantityDisplay,
  editDisplay,
  folderHDisplay,
  itemHDisplay,
  restoreDisplay,
  itemsArray,
  folderDisplay,
  itemDisplay,
  quantityBarDisplay,
  checkboxDisplay,
  deleteItem,
  editItem,
  clickHistoryItem,
  dontOpenItem,
}) => {
  const [display, setDisplay] = useState("grid");
  const dispatch = useDispatch();
  const itemId = useSelector((state) => state.itemId);
  const [itemsId, setItemsId] = useState([]);
  const [displayMore, setDisplayMore] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [folderId, setFolderId] = useState([]);
  const handleCheckbox = (e) => {
    if (folderArray) {
      if (e.target.checked === true) {
        setFolderId([...folderId, e.target.id]);
      }
      if (e.target.checked === false) {
        setFolderId(folderId.filter((item) => item !== e.target.id));
        setIsChecked(false);
      }
    }
    if (itemsArray) {
      if (e.target.checked === true) {
        setItemsId([...itemsId, e.target.id]);
        localStorage.setItem("itemType", e.target.value);
      }
      if (e.target.checked === false) {
        setItemsId(itemsId.filter((item) => item !== e.target.id));
        setIsChecked(false);
      }
    }
  };
  const handleGeneralCheck = (e) => {
    if (folderArray) {
      if (e.target.checked === true) {
        setIsChecked(true);
        const newArray = e.target.id;
        setFolderId(newArray.split(","));
        //   console.log(Array.from(e.target.id));
      }
      if (e.target.checked === false) {
        setIsChecked(false);
        setFolderId([]);
      }
    }
    if (itemsArray) {
      if (e.target.checked === true) {
        setIsChecked(true);
        const newArray = e.target.id;
        setItemsId(newArray.split(","));
      }
      if (e.target.checked === false) {
        setIsChecked(false);
        setItemsId([]);
      }
    }
  };
  const openMore = (item) => {
    dispatch(newId(item.id));
    setDisplayMore(!displayMore);
    localStorage.setItem("itemType", item.itemType);
    localStorage.setItem("itemId", item.id);
  };
  const navigate = useNavigate();
  const openItem = (item) => {
    if (item.itemType === "Stand Alone") {
      dispatch(newId(item.id));
      localStorage.setItem("itemId", item.id);
      navigate("/items/stand-alone");
    }
    if (item.itemType === "Grouped") {
      localStorage.setItem("grpId", item.id);
      navigate("/items/grouped-items");
    }
    if (item.itemType === "Composite") {
      localStorage.setItem("compositeId", item.id);
      navigate("/items/add-new-composite");
    }
  };
  const clickDeleteItem = () => {
    localStorage.setItem("itemId", itemId);
    if (deleteItem) {
      setDisplayMore(false);
      deleteItem();
      if (folderArray) {
        if (folderId.length === 1) {
          localStorage.setItem("folderId", folderId[0]);
          deleteItem();
          setFolderId([]);
        }
        if (folderId.length > 1) {
          dispatch(newId(folderId));
          localStorage.setItem("multiDelete", true);
          deleteItem();
          setFolderId([]);
        }
      }
      if (itemsArray) {
        if (itemsId.length === 1) {
          localStorage.setItem("itemId", itemsId[0]);
          deleteItem();
          setItemsId([]);
        }
        if (itemsId.length > 1) {
          dispatch(newId(itemsId));
          localStorage.setItem("multiDelete", true);
          // console.log(itemsId);
          deleteItem();
          setItemsId([]);
          setIsChecked(false);
        }
      }
    }
  };
  const clickEditItem = () => {
    if (folderArray) {
      localStorage.setItem("folderId", folderId[0]);
      if (editItem) {
        editItem();
      }
      // navigate("/items/stand-alone");
    }
  };
  const openBusiness = (item) => {
    localStorage.setItem("businessId", item.id);
    navigate("/section/view");
  };

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();
  
    return `${year}-${month}-${day}`;
  }
  const today = new Date();
  const formattedDate = formatDate(today);
  
  function is90DaysBefore(targetDate, givenDate) {
    const target = new Date(targetDate);
    const given = new Date(givenDate);
    const differenceMs = target.getTime() - given.getTime();
    const differenceDays = differenceMs / (1000 * 3600 * 24);
  
    return differenceDays <= 90;
  }

  return (
    <div className="quantity-bar_cont">
      <div
        className={
          folderId.length === 0 && itemsId.length === 0
            ? "quantity-bar"
            : "display-none"
        }
      >
        <div className="quantity-bar_left">
          {!quantityArray
            ? ""
            : quantityArray.map((item, idx) => {
                return (
                  <h2 key={idx}>
                    {item.value}:<span>{item.quantity}</span>
                  </h2>
                );
              })}
        </div>
        <div
          className="quantity-bar_right display-none"
          style={{ display: quantityDisplay }}
        >
          <Dcheckbox
            checkboxDisplay={checkboxDisplay}
            checkboxChange={handleGeneralCheck}
            checkId={
              !folderArray && !itemsArray
                ? ""
                : folderArray
                ? folderArray.map((item) => {
                    return item.id;
                  })
                : itemsArray
                ? itemsArray.map((item) => {
                    return item.id;
                  })
                : ""
            }
            checked={isChecked ? "true" : ""}
          />
          <div
            onClick={() => {
              setDisplay("grid");
            }}
            className={
              display === "grid"
                ? "icon-wrap active-wrap"
                : "icon-wrap inactive-wrap"
            }
          >
            <Grid2 />
          </div>
          <div
            onClick={() => {
              setDisplay("block");
            }}
            className={
              display === "block"
                ? "icon-wrap active-wrap"
                : "icon-wrap inactive-wrap"
            }
          >
            <Task />
          </div>
        </div>
      </div>

      <SelectDelete
        deleteDisplay={
          folderId.length !== 0
            ? "flex"
            : itemsId.length !== 0
            ? "flex"
            : "none"
        }
        numberSelect={folderId.length || itemsId.length}
        editDisplay={
          editDisplay === "" && folderId.length === 1
            ? "flex"
            : editDisplay === "" && itemsId.length === 1
            ? "flex"
            : "none"
        }
        checkboxChange={handleGeneralCheck}
        checkId={
          !folderArray && !itemsArray
            ? ""
            : folderArray
            ? folderArray.map((item) => {
                return item.id;
              })
            : itemsArray
            ? itemsArray.map((item) => {
                return item.id;
              })
            : ""
        }
        checked={isChecked ? true : ""}
        restoreDisplay={restoreDisplay}
        editItem={clickEditItem}
        deleteItem={clickDeleteItem}
      />
      <div
        className="quantity-bar_display display-none"
        style={{ display: quantityBarDisplay }}
      >
        <div
          className="quantity-bar_display-folder display-none"
          style={{ display: folderDisplay }}
        >
          <div
            className="quantity-bar_header display-none"
            style={{ display: folderHDisplay }}
          >
            <h2>Folders</h2>
          </div>
          <div
            className={
              display === "block" ? "display-none" : "quantity-grid_display"
            }
          >
            {!folderArray
              ? ""
              : display === "block"
              ? ""
              : folderArray.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      id={item.id}
                      className={
                        folderId.includes(item.id)
                          ? "quantity-grid_item quantity-grid_item-focus"
                          : "quantity-grid_item"
                      }
                    >
                      <div className="quantity-grid_item-top">
                        <div className="folder-icon_wrap">
                          <FolderOpen style={{ fontSize: "32px" }} />
                        </div>
                        <h6>
                          {Number(item.totalItems).toLocaleString()} Items
                        </h6>
                        {isChecked ? (
                          <Dcheckbox
                            checkId={item.id}
                            checkboxChange={handleCheckbox}
                            checked={isChecked ? true : ""}
                          />
                        ) : (
                          <Dcheckbox
                            checkId={item.id}
                            checkboxChange={handleCheckbox}
                            checked={folderId.includes(item.id) ? true : ""}
                          />
                        )}
                      </div>
                      <div className="quantity-grid_item-middle">
                        <h3
                          style={{ cursor: "pointer" }}
                          onClick={() => openBusiness(item)}
                        >
                          {item.name}
                        </h3>
                      </div>
                      <div className="quantity-grid_item-bottom">
                        <h5>Section value:</h5>
                        <h4>
                          {"₦" +
                            Number(item.totalValue).toLocaleString() +
                            ".00"}{" "}
                        </h4>
                      </div>
                    </div>
                  );
                })}
          </div>
          <div
            className={
              display === "grid" ? "display-none" : "quantity-block_display"
            }
          >
            {!folderArray
              ? ""
              : display === "grid"
              ? ""
              : folderArray.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      id={item.id}
                      className={
                        folderId.includes(item.id)
                          ? "quantity-block_items quantity-block_item-focus"
                          : "quantity-block_items"
                      }
                    >
                      <div className="quantity-block_items-left">
                        <div className="folder-icon_wrap">
                          <FolderOpen style={{ fontSize: "32px" }} />
                        </div>
                        <h3
                          style={{ cursor: "pointer" }}
                          onClick={() => openBusiness(item)}
                        >
                          {item.name}
                        </h3>
                      </div>
                      <div className="quantity-block_items-right">
                        <div className="quantity-block_items-right_top">
                          <h6>
                            {Number(item.totalItems).toLocaleString()} Items
                          </h6>
                          {isChecked ? (
                            <Dcheckbox
                              checkId={item.id}
                              checkboxChange={handleCheckbox}
                              checked={isChecked ? "true" : ""}
                            />
                          ) : (
                            <Dcheckbox
                              checkId={item.id}
                              checkboxChange={handleCheckbox}
                              checked={folderId.includes(item.id) ? "true" : ""}
                            />
                          )}
                        </div>
                        <div className="quantity-block_items-right_bottom">
                          <h5>Section value:</h5>
                          <h4>
                            {"₦" +
                              Number(item.totalValue).toLocaleString() +
                              ".00"}
                          </h4>
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>

        <div
          className="quantity-bar_display-item display-none"
          style={{ display: itemDisplay }}
        >
          <div
            className="quantity-bar_header display-none"
            style={{ display: itemHDisplay }}
          >
            <h2>Items</h2>
          </div>
          <div className="item-grid_display">
            {!itemsArray
              ? ""
              : display === "block"
              ? ""
              : itemsArray.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      id={item.id}
                      className={
                        itemsId.includes(item.id)
                          ? "item-grid_item quantity-block_item-focus"
                          : "item-grid_item"
                      }
                    >
                      <div className="item-image_header">
                        {!item.picture ? (
                          <img src={itemImg} alt="item" />
                        ) : (
                          <img src={item.picture} alt="item" />
                        )}
                        <div className="item-image_header-span">
                          {item.itemType === "Stand Alone" ? (
                            <p style={{ background: "transparent" }}></p>
                          ) : (
                            <p>{item.itemType}</p>
                          )}
                          {isChecked ? (
                            <Dcheckbox
                              checkboxChange={handleCheckbox}
                              checkId={item.id}
                              containerBg="white"
                              checkValue={item.itemType}
                              checked={isChecked ? true : ""}
                            />
                          ) : (
                            <Dcheckbox
                              checkboxChange={handleCheckbox}
                              checkId={item.id}
                              checkValue={item.itemType}
                              containerBg="white"
                              checked={itemsId.includes(item.id) ? true : ""}
                            />
                          )}
                        </div>
                      </div>
                      <div className="item-grid_middle">
                        <div className="item-grid_middle-top" style={{alignItems: "center"}}>
                          <h3
                            onClick={() => openItem(item)}
                            style={{ cursor: "pointer" }}
                          >
                            {item.name}
                          </h3>
                          <More
                            onClick={() =>
                              dontOpenItem ? undefined : openMore(item)
                            }
                            style={{
                              rotate: "90deg",
                              color: "#111111",
                              cursor: "pointer",
                            }}
                          />

                          <div
                            className={
                              displayMore && itemId === item.id
                                ? "items-more_list"
                                : "display-none"
                            }
                          >
                            <p
                              onClick={() => {
                                if (clickHistoryItem) {
                                  clickHistoryItem();
                                }
                                setDisplayMore(false);
                              }}
                            >
                              <Clock /> History
                            </p>
                            {/* <p onClick={()=>{clickCloneItem(); setDisplayMore(false)}}>
                              <Copy /> Clone Item
                            </p> */}
                            <p onClick={clickDeleteItem}>
                              <Trash /> Delete
                            </p>
                          </div>
                        </div>
                        <div
                          className="stock-status"
                          onClick={() => openItem(item)}
                        >
                          {!item.stockStatus ? (
                            <span
                              style={{
                                background: "transparent",
                                color: "transparent",
                                width: "100px",
                              }}
                            >
                              stock
                            </span>
                          ) : item.stockStatus === "In Stock" ? (
                            <span
                              style={{
                                background: "#EDF7EE",
                                color: "#4CAF50",
                                width: "70px",
                              }}
                            >
                              {item.stockStatus}
                            </span>
                          ) : item.stockStatus === "Out of Stock" ? (
                            <span
                              style={{
                                background: "#FFE6E6",
                                color: "#FF0000",
                                width: "100px",
                              }}
                            >
                              {item.stockStatus}
                            </span>
                          ) : item.stockStatus === "Low Stock" ? (
                            <span
                              style={{
                                background: "#FFF9E6",
                                color: "#FFC107",
                                width: "100px",
                              }}
                            >
                              {item.stockStatus}
                            </span>
                          ) : (
                            ""
                          )}
                          {item.expiringDate && <div className="is-expired">
                            <p 
                              style={{
                                background: item.expiringDate <= formattedDate ? "#FFE6E6" : is90DaysBefore(item.expiringDate, today) ? "#FFF9E6" : "",
                                color: item.expiringDate <= formattedDate ? "#FF0000" : is90DaysBefore(item.expiringDate, today) ? "#FFC107" : "#616161",
                                textAlign: "center",
                                padding: "5px",
                                borderRadius: "8px",
                                fontSize: item.expiringDate > formattedDate && "12px"
                              }}>{item.expiringDate <= formattedDate ? "Expired" : `Exp. Date: ${item.expiringDate}`}</p>
                          </div>}
                        </div>
                      </div>
                      <div className="item-grid_bottom" onClick={() => openItem(item)}>
                        <h5>{Number(item.quantity).toLocaleString()} units</h5>
                        <h6>₦{Number(item.totalValue).toLocaleString()}.00</h6>
                      </div>
                    </div>
                  );
                })}
          </div>
          <div className="item-block_display">
            {!itemsArray
              ? ""
              : display === "grid"
              ? ""
              : itemsArray.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      id={item.id}
                      className={
                        itemsId.includes(item.id)
                          ? "item-block_item quantity-block_item-focus"
                          : "item-block_item"
                      }
                    >
                      <div className="item-block_display-left">
                        <div className="item-block_variant ">
                          {!item.picture ? (
                            <img src={itemImg} alt="item" />
                          ) : (
                            <img src={item.picture} alt="item" />
                          )}
                          {item.itemType === "Stand Alone" ? (
                            <p style={{ background: "transparent" }}></p>
                          ) : (
                            <p>{item.itemType}</p>
                          )}
                        </div>
                        <div className="item-block_display-name">
                          <h3
                            onClick={() => openItem(item)}
                            style={{ cursor: "pointer" }}
                          >
                            {item.name}
                          </h3>
                          {item.stockStatus === "In Stock" ? (
                            <span
                              style={{
                                background: "#EDF7EE",
                                color: "#4CAF50",
                                width: "70px",
                              }}
                            >
                              {item.stockStatus}
                            </span>
                          ) : item.stockStatus === "Out of Stock" ? (
                            <span
                              style={{
                                background: "#FFE6E6",
                                color: "#FF0000",
                                width: "100px",
                              }}
                            >
                              {item.stockStatus}
                            </span>
                          ) : item.stockStatus === "Low Stock" ? (
                            <span
                              style={{
                                background: "#FFF9E6",
                                color: "#FFC107",
                                width: "100px",
                              }}
                            >
                              {item.stockStatus}
                            </span>
                          ) : (
                            ""
                          )}
                          {item.expiringDate && <div className="is-expired">
                            <p 
                              style={{
                                background: item.expiringDate <= formattedDate ? "#FFE6E6" : is90DaysBefore(item.expiringDate, today) ? "#FFF9E6" : "",
                                color: item.expiringDate <= formattedDate ? "#FF0000" : is90DaysBefore(item.expiringDate, today) ? "#FFC107" : "#616161",
                                textAlign: "center",
                                padding: "5px",
                                borderRadius: "8px",
                                fontSize: item.expiringDate > formattedDate && "12px"
                              }}>{item.expiringDate <= formattedDate ? "Expired" : `Exp. Date: ${item.expiringDate}`}</p>
                          </div>}
                        </div>
                      </div>
                      <div className="items-block_display-right">
                        <div className="items-block_display-right-top">
                          <More
                            onClick={() =>
                              dontOpenItem ? undefined : openMore(item)
                            }
                            style={{
                              rotate: "90deg",
                              color: "#616161",
                              cursor: "pointer",
                            }}
                          />
                          <div
                            className={
                              displayMore && itemId === item.id
                                ? "items-more_list"
                                : "display-none"
                            }
                          >
                            <p
                              onClick={() => {
                                if (clickHistoryItem) {
                                  clickHistoryItem();
                                }
                                setDisplayMore(false);
                              }}
                            >
                              <Clock /> History
                            </p>
                            {/* <p  onClick={()=>{clickCloneItem(); setDisplayMore(false)}}>
                              <Copy /> Clone Item
                            </p> */}
                            <p onClick={clickDeleteItem}>
                              <Trash /> Delete
                            </p>
                          </div>
                          {isChecked ? (
                            <Dcheckbox
                              checkboxChange={handleCheckbox}
                              checkId={item.id}
                              containerBg="white"
                              checked={isChecked ? "true" : ""}
                            />
                          ) : (
                            <Dcheckbox
                              checkboxChange={handleCheckbox}
                              checkId={item.id}
                              containerBg="white"
                              checked={itemsId.includes(item.id) ? "true" : ""}
                            />
                          )}
                        </div>
                        <div className="item-display_block-right_bottom">
                          <h5>
                            {Number(item.quantity).toLocaleString()} units
                          </h5>
                          <h6>
                            ₦{Number(item.totalValue).toLocaleString()}.00
                          </h6>
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quantitybar;
