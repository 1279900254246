import React, { useState, useEffect } from "react";
import "./Navbar.css";
import "../../App.css";
import largeLogo from "../../assets/landingpage/largeLogo.png";
import mobileLogo from "../../assets/landingpage/mobileLogo.png";
import { Link, useLocation } from "react-router-dom";
import { CloseCircle, HambergerMenu } from "iconsax-react";

const Navbar = () => {
  const { pathname } = useLocation();
  const activeLink = {
    color: "#080B24",
    borderBottom: "4px solid #F08227",
    paddingLeft: "5px",
    paddingRight: "5px",
  };
  const [active, setActive] = useState("");
  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  const [mobileMenuDisplay, setMobileMenuDisplay] = useState(false);

  const clickMenu = () => {
    if (!mobileMenuDisplay) {
      setMobileMenuDisplay(true);
    } else {
      setMobileMenuDisplay(false);
    }
  };

  return (
    <nav className="nav-bar">
      <div className="nav-bar_content desktop-nav">
        <div className="nav-logo">
          <img src={largeLogo} alt="logo" />
        </div>
        <div className="nav-ul">
          <ul>
            <Link to="/" style={{ textDecoration: "none" }}>
              <li style={active === "" ? activeLink : undefined}>home</li>
            </Link>
            <Link to="/aboutus" style={{ textDecoration: "none" }}>
              <li style={active === "aboutus" ? activeLink : undefined}>
                about us
              </li>
            </Link>
            <Link to="/services" style={{ textDecoration: "none" }}>
              <li style={active === "services" ? activeLink : undefined}>
                services
              </li>
            </Link>
            <Link to="/faq" style={{ textDecoration: "none" }}>
              <li style={active === "faq" ? activeLink : undefined}>FAQs</li>
            </Link>
            <Link to="/pricing" style={{ textDecoration: "none" }}>
              <li style={active === "pricing" ? activeLink : undefined}>
                Pricing plans
              </li>
            </Link>
          </ul>
        </div>
        <div className="nav-buttons">
          <Link to="/login" style={{ textDecoration: "none" }}>
            <button className="btn-trans">login</button>
          </Link>
          <Link to="/signupemail" style={{ textDecoration: "none" }}>
            <button className="btn">get started</button>
          </Link>
        </div>
      </div>

      <div className="nav-bar_content mobile-nav">
        <div className="nav-logo">
          <img src={mobileLogo} alt="logo" />
        </div>
        <div className="mobile-menu">
          <HambergerMenu onClick={clickMenu} />
          <div className={`overlay ${mobileMenuDisplay ? "w-full" : "w-0"}`}>
            {" "}
          </div>
          <div className={!mobileMenuDisplay ? "display-none" : `mobile-ul`}>
            <div className="mobile-ul_menu">
              <CloseCircle onClick={clickMenu} />
            </div>
            <ul>
              <Link
                to="/"
                style={{ textDecoration: "none" }}
                onClick={() => setMobileMenuDisplay(false)}
              >
                <li style={active === "" ? activeLink : undefined}>home</li>
              </Link>
              <Link
                to="/aboutus"
                style={{ textDecoration: "none" }}
                onClick={() => setMobileMenuDisplay(false)}
              >
                <li style={active === "aboutus" ? activeLink : undefined}>
                  about us
                </li>
              </Link>
              <Link
                to="/services"
                style={{ textDecoration: "none" }}
                onClick={() => setMobileMenuDisplay(false)}
              >
                <li style={active === "services" ? activeLink : undefined}>
                  services
                </li>
              </Link>
              <Link
                to="/faq"
                style={{ textDecoration: "none" }}
                onClick={() => setMobileMenuDisplay(false)}
              >
                <li style={active === "faq" ? activeLink : undefined}>FAQs</li>
              </Link>
              <Link
                to="/pricing"
                style={{ textDecoration: "none" }}
                onClick={() => setMobileMenuDisplay(false)}
              >
                <li style={active === "pricing" ? activeLink : undefined}>
                  Pricing plans
                </li>
              </Link>
            </ul>
            <div className="nav-buttons">
              <Link to="/login" style={{ textDecoration: "none" }}>
                <button className="btn-trans" style={{ color: "#F08227" }}>
                  login
                </button>
              </Link>
              <Link to="/signupemail" style={{ textDecoration: "none" }}>
                <button className="btn">get started</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
