import React, {useState} from "react";
import "./folderview.css";
import Dashboardheader from "../../../Components/dashboard-header/Dashboardheader";
import { Add } from "iconsax-react";
import Quantitybar from "../../../Components/quantity-bar/Quantitybar";
import Goback from "../../../Components/Dashboard-goback/Goback";
import AddItemsPopup from "../../../Components/add-items-popup/AddItemsPopup";
import FolderFilter from "../../../Components/filter-popups/FolderFilter";
import { useEffect } from "react";
import { AxiosGet } from "../../../axios/axios";
import Loading from "../../../Components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { setFailureModal } from "../../../states/general/general";
import FailedMsg from "../../../Components/alert/failed-msg.component";

const Folderview = () => {
  const businessId = localStorage.getItem("businessId");
  const url = `business/${businessId}/`
  const [display, setDisplay] = useState(false);
  const [isLoading, setIsLoading] = useState(null);
  const [singleBusiness, setSingleBusiness] = useState();
  const dispatch = useDispatch();
  const userPermissions = useSelector((state) => state.userPermissions);
  useEffect(() => {
    AxiosGet(url)
      .then((res) => {
        // console.log(res.data);
        setSingleBusiness(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err.response);
      });
    // eslint-disable-next-line
  }, [display]);
  const quantityArray = !singleBusiness ? [] : [
    {
      value: "Quantity",
      quantity: Number(singleBusiness.itemsQuantity).toLocaleString(),
    },
    {
      value: "Total Value",
      quantity: "₦ " + Number(singleBusiness.totalValue).toLocaleString() + ".00"
    },
  ];
  const itemsArray = !singleBusiness ? [] : singleBusiness.itemsList;
  const showItemAdd = () => {
    if (userPermissions.addItem){
      setDisplay(!display);
      localStorage.setItem('sectionId', singleBusiness?.id)
    } else{
      dispatch(setFailureModal(true));
    }
  };
  const [displayFilter, setDisplayFilter] = useState(false);
  const openFilter = () => {
    setDisplayFilter(!display);
  };
  return (
    isLoading === null ? <Loading /> :
    <section>
      <Goback />
      <FailedMsg msg={"You do not have permissions to perform this action!!!"} />
      <div className="folderview-page_header">
      <Dashboardheader
        headerText={!singleBusiness ? "" : singleBusiness.name}
        filterStyle="flex"
        addStyle="flex"
        addBtnText="add"
        addBtnIcon={<Add />}
        addSubmitForm={showItemAdd}
        filterSubmitForm={openFilter}
      />
      <FolderFilter display={displayFilter} setDisplay={setDisplayFilter} />
      <AddItemsPopup display={display} setDisplay={setDisplay} />
      </div>
      <Quantitybar
        quantityDisplay="flex"
        quantityArray={quantityArray}
        // editDisplay=""
        itemsArray={itemsArray}
        itemDisplay="block"
        quantityBarDisplay="block"
        dontOpenItem={true}
      />
    </section>
  );
};

export default Folderview;
