import { Add } from "iconsax-react";
import Dashboardheader from "../../Components/dashboard-header/Dashboardheader";
import ExpensesComponent from "../../Components/expenses/expenses.component";
import ExpenseModal from "../../Components/expenses/expenses-modal.component";
import { useDispatch, 
  // useSelector
 } from "react-redux";
import { getExpenses, setExpenseModal } from "../../states/expenses/expenses";
import useActivityHistoryData from "../../hooks/useActivityHistoryData";
import { useEffect } from "react";
// import FailedMsg from "../../Components/alert/failed-msg.component";
// import { setFailureModal } from "../../states/general/general";

const Expenses = () => {
  const dispatch = useDispatch();
  // const userPermissions = useSelector((state) => state.userPermissions);

  const modalHandler = () => {
    // if (userPermissions.addSales) {
      dispatch(setExpenseModal(true))
    // } else {
    //   dispatch(setFailureModal(true));
    // }
  };
  const { activityHistoryData } = useActivityHistoryData();

  useEffect(() => {
    dispatch(
      getExpenses(
        `?dateFilter=${activityHistoryData.dateValue}&startDate=${activityHistoryData.startDate}&endDate=${activityHistoryData.endDate}&orderBy=${activityHistoryData.orderBy}&page=1&pageSize=10`
      )
    );
    // eslint-disable-next-line
  }, [activityHistoryData]);

  return (
    <section>
      {/* <FailedMsg
        msg={"You do not have permissions to perform this action!!!"}
      /> */}
      <Dashboardheader
        headerText="Expenses"
        addStyle="flex"
        addBtnText="add"
        addBtnIcon={<Add />}
        addSubmitForm={modalHandler}
      />
      <ExpensesComponent />
      <ExpenseModal />
    </section>
  );
};

export default Expenses;
