import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosGet } from "../../axios/axios";



export const getGeneral = createAsyncThunk(
    'getGeneral/notifications',
    async (filter, thunkAPI) => {
      try {
        const { data } = await AxiosGet(`notifications/general/${filter}`);
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
);

export const getInventory = createAsyncThunk(
    'getInventory/notifications',
    async (filter, thunkAPI) => {
      try {
        const { data } = await AxiosGet(`notifications/inventory/${filter}`);
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
);


export const notifications = createSlice({
    name: "notifications",
    initialState: {
        general: {
            status: 'idle',
            result: []
        },
        inventory: {
            status: 'idle',
            result: []
        },
    },
    reducers: {},
    extraReducers(builder) {
        builder
          // get inventory notification
          .addCase(getGeneral.pending, (state) => {
            state.general.status = 'loading';
          })
          .addCase(getGeneral.fulfilled, (state, action) => {
            state.general.status = 'idle';
            state.general.result = action.payload;
          })
          .addCase(getGeneral.rejected, (state) => {
            state.general.status = 'idle';
          })

          // get inventory notification
          .addCase(getInventory.pending, (state) => {
            state.inventory.status = 'loading';
          })
          .addCase(getInventory.fulfilled, (state, action) => {
            state.inventory.status = 'idle';
            state.inventory.result = action.payload;
          })
          .addCase(getInventory.rejected, (state) => {
            state.inventory.status = 'idle';
          })  
    }
})

// export const {} = notifications.actions;

export default notifications.reducer;