import Notification from "../notification/Notification";

const SalesSummaryHeading = ({ text, sales, revenue }) => {
    return (
        
        <section className="sales-summary bar-header" style={{flexWrap: "wrap"}}>
            <section className="summary-text-with-sales-and-revenues bar-header_sub-heading">
                <h3> {text} </h3>
                <section className="total-sales-revenue">
                    { sales &&
                        <section>
                            <span>Total Sales</span>
                            <p> ₦{sales} </p>
                        </section>
                    }
                    { revenue &&
                        <section>
                            <span>Total Expenses </span>
                            <p> ₦{revenue} </p>
                        </section>
                    }
                </section>
            </section>
            <Notification notificationStyle="flex" />
    </section>
    );
}
 
export default SalesSummaryHeading;