import React from 'react';
import "./billing.styles.css";


const CurrentPlanCard = ({currentPlan}) => {
  return (
    <div className='current-plan'>
        <div className="plan-left">
            <h3>{currentPlan?.type} Plan</h3>
            <p><span>Current</span></p>
        </div>
        <div className="plan-right">
            <h3>N{currentPlan?.amount}</h3>
            <p>{currentPlan?.period}</p>
        </div>
        
    </div>
  )
}

export default CurrentPlanCard