import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./admin.css";
import { AxiosGet } from "../../axios/axios";
import Loading from "../../Components/Loading";
import Dashboardheader from "../../Components/dashboard-header/Dashboardheader";
import stock_icon_three from "../../assets/dashboard/stock-icon-1.svg";

import { createColumnHelper } from "@tanstack/react-table";

import empty_icon from "../../assets/dashboard/empty-icon.svg";
import Table from "../../Components/table/table.component";
import Empty from "../../Components/empty/empty.component";
import Pagination from "../../Components/pagination/pagination.component";
import OnlyDate from "../../Components/excerpts/OnlyDate";
import OnlyTime from "../../Components/excerpts/OnlyTime";
import StockAtHand from "../../Components/overview/stock-at-hand.component";

const AdminUsers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [viewUsers, setViewUsers] = useState([]);
  const [pagItem, setPagItem] = useState(1);
  // const [userDetailsList, setUserDetailsList] = useState([]);
  const url = `super-admin/users/?page=${pagItem}&pageSize=10`;
  const navigate = useNavigate();

  const getUsers = () => {
    setIsLoading(true);
    AxiosGet(url)
      .then((res) => {
        // console.log(res);
        setViewUsers(res);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err.response);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, [pagItem]);

  const stocks = [
    {
      color: "#040614",
      icon: stock_icon_three,
      headerText: "Total Users",
      stockQuantity: Number(viewUsers?.data?.totalUsers).toLocaleString(),
    },
  ];

  const handleClick = (event, data) => {
    // console.log(data);
    navigate(`/admin-users/${data.id}`);
  };
  // console.log("users",userDetailsList)
  // console.log("users", userData.data.usersList);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("firstName", {
      cell: (info) => <p>{info.getValue() || "NIL"}</p>,
      header: () => <span>First Name</span>,
    }),
    columnHelper.accessor("lastName", {
      cell: (info) => <p>{info.getValue() || "NIL"}</p>,
      header: () => <span>Last Name</span>,
    }),
    columnHelper.accessor("phoneNumber", {
      cell: (info) => <p className="">{info.getValue() || "NIL"}</p>,
      header: () => <span> Phone Number </span>,
    }),
    columnHelper.accessor("organizationName", {
      cell: (info) => <p>{info.getValue() || "NIL"}</p>,
      header: () => <span> Organization Name </span>,
    }),
    columnHelper.accessor("email", {
      cell: (info) => <p>{info.getValue() || "NIL"}</p>,
      header: () => <span> Email </span>,
    }),
    columnHelper.accessor("accountType", {
      cell: (info) => <p>{info.getValue() || "NIL"}</p>,
      header: () => <span> Account Type </span>,
    }),
    columnHelper.accessor("accountStatus", {
      cell: (info) => (
        <p
          className={
            info.getValue() === "Active" ? "active-cell" : "inactive-cell"
          }
        >
          {" "}
          {info.getValue() || "NIL"}{" "}
        </p>
      ),
      header: () => <span> Account Status </span>,
    }),
    columnHelper.accessor("role", {
      cell: (info) => <p>{info.getValue() || "NIL"}</p>,
      header: () => <span> Role </span>,
    }),
    columnHelper.accessor("createdAt", {
      cell: (info) => (
        <>
          <p className=""> {<OnlyDate date={info.getValue() || "NIL"} />} </p>
          <p className="time">
            {<OnlyTime date={info.getValue() || "NIL"} />}{" "}
          </p>
        </>
      ),
      header: () => <span> Date/Time </span>,
    }),
  ];

  const handleUpdate = (item) => {
    setPagItem(item);
    // dispatch(getSales(`?page=${item}&pageSize=10`));
  };

  return (
    <div>
      {isLoading && <Loading imgStyle={{ marginTop: "200px" }} />}
      {!isLoading && (
        <>
          <Dashboardheader
            headerText="users"
            // addStyle="flex"
            // addBtnText="add"
            // addBtnIcon={<Add />}
          />
          <section className="stock-at-hand-container">
            {stocks.map(({ color, icon, stockQuantity, headerText }, index) => (
              <StockAtHand
                key={index}
                color={color}
                icon={icon}
                stockQuantity={stockQuantity}
                headerText={headerText}
              />
            ))}
          </section>
          {viewUsers?.data?.usersList &&
            viewUsers?.data?.usersList?.length > 0 && (
              <Table
                data={viewUsers?.data?.usersList}
                columns={columns}
                width={"250%"}
                click={handleClick}
              />
            )}
          {viewUsers?.data?.usersList &&
            viewUsers?.data?.usersList?.length === 0 && (
              <Empty title={"You don't have any user"} img={empty_icon} />
            )}{" "}
          {viewUsers?.data?.usersList &&
            viewUsers?.data?.usersList?.length > 0 && (
              <Pagination
                currentPage={viewUsers?.pagination?.currentPage}
                numberOfPages={viewUsers?.pagination?.numberOfPages}
                handleUpdate={handleUpdate}
              />
            )}
        </>
      )}
    </div>
  );
};

export default AdminUsers;
