import { useEffect, useState } from "react";
import search_icon from "../../assets/dashboard/search-icon.svg";

import "./search-bar.styles.css";
import { AxiosGet } from "../../axios/axios";
import useActive from "../../hooks/useActive";
import useSearchParam from "../../hooks/useSearchParam";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { search } from "../../states/search/searchSlice";

const SearchBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {searchParam, setSearchParam} = useSearchParam("");  
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };
  const {setActive } = useActive();
  const handleDataChange = (e) => {
    if(isFocused){
      setSearchParam(e.target.value);
      setActive("advancedsearch");
      navigate("/advancedsearch");
    }
    // console.log(active);
  };
  const url = `advance-search/?searchQuery=${searchParam}`;
  useEffect(() => {
    AxiosGet(url)
      .then((res) => {
        // console.log(res);
        dispatch(search(res.data));
      })
      .catch((err) => {
        // console.log(err.response);
      });
    // eslint-disable-next-line
  }, [searchParam]);
  return (
    <section className="search-container">
      <img src={search_icon} width={20} height={20} alt="search-icon" />
      <input type="search" autoComplete="off" placeholder="Search" onChange={handleDataChange} value={searchParam} onFocus={handleFocus} onBlur={handleBlur} />
    </section>
  );
};

export default SearchBar;
