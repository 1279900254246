import React, { useState } from "react";
import "./admin.css";
import Dashboardheader from "../../Components/dashboard-header/Dashboardheader";
import { FormButton, InputForm, SelectInput } from "../../Components/AuthHead/AuthHead";
import { AxiosAuthPost } from "../../axios/axios";
import SuccessPopup from "../../Components/filter-popups/SuccessPopup";

const AdminMessage = () => {

  const url = "super-admin/messages/";
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const [data, setData] = useState({
    title: "",
    body: "",
    recipientCondition: ""
  });

  const handleChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    // console.log(newData);
  }

  const submitBtn = () => {
    setIsLoading(true);
    AxiosAuthPost(url, data).then((res) => {
      // console.log(res);
      setIsLoading(false);
      setIsSuccess(true);
      setSuccessMsg(res.message);
      setTimeout(() => {
        setData({
          title: "",
          body: "",
          recipientCondition: ""
        })
      }, 1000);
  }).catch((err) => {
      // console.log(err.response);
      setIsLoading(false);
  })
  }

    const userDd = [
        {
            name: "All Users",
            id: "All Users"
        },
        {
            name: "Active Users",
            id: "Active Users"
        },
        {
            name: "Inactive Users",
            id: "Inactive Users"
        },
        {
            name: "Expired Plan Users",
            id: "Expired Plan Users"
        },
    ]

  return (
    <div>
    <SuccessPopup
      display={isSuccess}
      setDisplay={setIsSuccess}
      popupMessage={successMsg}
    />
      <Dashboardheader
        headerText="Send a message"
        // addStyle="flex"
        // addBtnText="add"
        // addBtnIcon={<Add />}
      />
      <div className="admin-msg_body">
            <SelectInput
              formLabel="User Category"
              optionsArray={userDd}
              formValue={data?.recipientCondition}
              formId={"recipientCondition"}
              dataChange={handleChange}
            //   errorMessage={grpErrorList}
              dropHeight="200px"
            />
            <InputForm
              formLabel="Message Title"
              // placeholder="Enter Message Title"
              dataChange={handleChange}
              formValue={data?.title}
              formId={"title"}
            //   errorMessage={grpErrorName}
            />
            <div className="admin-text_area">
            <label>Message Body</label> <br />
            <textarea name="" id="body" value={data?.body} onChange={(e) => handleChange(e)} cols="30" ></textarea>
            </div>
            <FormButton isLoading={isLoading} submitForm={submitBtn} buttonText={"Send Message"} btnStyle={{width: "20%"}} dataValues={data.body && data.recipientCondition && data.title} />
      </div>
    </div>
  );
};

export default AdminMessage;
