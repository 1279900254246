import { useState } from "react";

import { ArrowSwapVertical } from "iconsax-react";

import "./dropdown-sort.styles.css";
import useActivityHistoryData from "../../hooks/useActivityHistoryData";

const DropdownSort = ({activityDrop}) => {
  const {activityHistoryData, setActivityHistoryData} = useActivityHistoryData();
  const [dropdownOption, setDropdownOption] = useState("Latest");
  const [dropdown, setDropdown] = useState(false);
  const dropdownItems = activityDrop ? ["Oldest", "Latest"] :  ["Oldest", "Latest", "Highest Sale", "Lowest Sale"];
  
  const toggleDropdown = () => setDropdown((state) => !state);
  
  const dropdownHandler = (item) => {
    setDropdownOption(item);
    setActivityHistoryData({...activityHistoryData, orderBy: item});
  };

  return (
    <section onClick={toggleDropdown} className="latest">
      <ArrowSwapVertical color="#616161" />
      <span> {dropdownOption} </span>
      {dropdown && (
        <div className="dropdown">
          {dropdownItems.map((item, id) => (
            <p key={id} onClick={() => dropdownHandler(item)}>
              {" "}
              {item}{" "}
            </p>
          ))}
        </div>
      )}
    </section>
  );
};

export default DropdownSort;
