import React from "react";
import "./Dashboardbtn.css";
import { Link } from "react-router-dom";
import spin from "../../assets/landingpage/spinner.gif";

const Dashboardbtn = ({
  btnClass,
  btnIcon,
  btnText,
  btnLink,
  submitForm,
  rightBtnIcon,
  btnWidth,
  btnType,
  isLoading,
  btnBgColor,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    if (submitForm) {
      submitForm(e);
    }
  };
  return (
    <>
      <button type={btnType} onClick={handleSubmit} style={{cursor: isLoading ? "not-allowed" : ""}}>
        <Link
          to={btnLink}
          style={{
            textDecoration: "none",
            width: btnWidth,
            background: btnBgColor,
          }}
          className={btnClass}
        >
          {!isLoading ? (
            <>
              {btnIcon} {btnText} {rightBtnIcon}
            </>
          ) : (
            <img style={{ width: "20px", height: "20px" }} src={spin} alt="" />
          )}
        </Link>
      </button>
    </>
  );
};

export default Dashboardbtn;
