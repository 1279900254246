import React from "react";

import "./billing.styles.css";
// import { useState } from "react";
import "../../Pricingplan/Pricingplan.css";
import { CloseCircle } from "iconsax-react";
import CurrentPlanCard from "./CurrentPlanCard";
import PriceToggleCard from "./PriceToggleCard";

const PriceCard = ({ display, setDisplay, currentPlan, lastSub }) => {
  const closeModal = () => {
    setDisplay(false);
  };


  return (
    <div>
      {display && (
        <>
          <div
            className={`overlay ${display ? "w-full" : "w-0"}`}
            onClick={closeModal}
          >
            {" "}
          </div>
          <section className="price-card">
            <div className="price-card_header">
              <h2>Upgrade Plan</h2>
              <CloseCircle style={{cursor: "pointer"}} onClick={closeModal} />
            </div>
            {currentPlan && <CurrentPlanCard currentPlan={currentPlan} />}
            <PriceToggleCard currentPlan={currentPlan} lastSub={lastSub} />
          </section>
        </>
      )}
    </div>
  );
};

export default PriceCard;
