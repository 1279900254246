import React from "react";

import increase_icon from "../../../assets/dashboard/increase-icon.svg";

import "./recovery.styles.css";
import TableHeading from "../../table-heading/table-heading.component";
import RecoveryTable from "./recovery-table.component";
import { useSelector } from "react-redux";

const Recovery = () => {
    const { recovery } = useSelector((state) => state.sales);

    const info = [
        { heading: "TOTAL QTY RETURNED", number: recovery?.result?.totalQuantityReturned, img: increase_icon, text: "10% increase compared to Last Month" }, 
        { heading: "TOTAL SALES RETURN AMOUNT ", number: "₦" + Number(recovery?.result?.totalSalesRecoveryAmount).toLocaleString() + ".00", img: increase_icon, text: "10% increase compared to Last Month" }, 
        { heading: "RETURNS  RATE", number: recovery?.result?.recoveryRate, img: increase_icon, text: "10% decrease compared to Last Month" }, 
    ];

    return (
        <section className="recovery-container">                
            <section className="returned-recovery">
                { info.map(({heading, number, img, text}, id) => (
                    <React.Fragment key={id}>
                        <section className="returned">
                            <h5> {heading} </h5>
                            <p className={`numbers ${heading === "RETURN  RATE" ? 'green' : '' }`}> {number} </p>
                            <span className="comparison"> <img src={img} alt={'arrow-icon'} /> {text}  </span>
                        </section>
                        { heading !== "RETURNS  RATE" &&
                            <div style={{
                                border: '1px solid #D1D1D1',
                            }}></div>
                        }
                    </React.Fragment>
                ))
                }
            </section>
            <TableHeading title={"Sales Returns"} dropdown={true} />
            <RecoveryTable />
        </section>
    );
}
 
export default Recovery;