import React from 'react';
import "./discard-changes.css"
import Dashboardbtn from '../dashboard-buttons/Dashboardbtn';

const DiscardChanges = ({displayCancel, cancelForm, discardForm}) => {
  return (
    <>
    <div className={`overlay ${displayCancel ? 'w-full' : 'w-0'}`}> </div>
    <div className={displayCancel ? "discard-changes" : "display-none"}>
        <h3>Discard unsaved changes?</h3>
        <div className="discard-changes_btn">
            <Dashboardbtn btnClass="dashboard-variant" btnText="cancel" btnWidth="180px" submitForm={cancelForm} />
            <Dashboardbtn btnClass="dashboard-btn" btnText="discard" btnWidth="180px" submitForm={discardForm} />
        </div>
    </div>
    </>
  )
}

export default DiscardChanges