import "./activity-history.styles.css";
import useActivityHistoryData from "../../hooks/useActivityHistoryData";
import useActivityDetails from "../../hooks/useActivityDetails";
import { AxiosGet } from "../../axios/axios";
import { useEffect, useState } from "react";
import Loading from "../Loading";
import Empty from "../empty/empty.component";
import eActivity from "../../assets/dashboard/e-activity.svg";
import useActive from "../../hooks/useActive";
import Pagination from "../pagination/pagination.component";

const ActivityHistoryComponent = () => {
  const { activityHistoryData } = useActivityHistoryData();
  const { activityDetails } = useActivityDetails();
  const [isLoading, setIsLoading] = useState(null);
  const [activityData, setActivityData] = useState(null);
  const [pagData, setPagData] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [activityClick, setActivityClick] = useState(null);
  const { active } = useActive();
  useEffect(() => {
    if (active === "activityhistory") {
      setActivityClick(true);
    }
  }, [active]);

  const url = `activity-history/?orderBy=${activityHistoryData?.orderBy}&dateFilter=${activityHistoryData?.dateValue}&startDate=${activityHistoryData?.startDate}&endDate=${activityHistoryData?.endDate}&page=${pageNum}&pageSize=10`;
  useEffect(() => {
    setIsLoading(null);
    AxiosGet(url)
      .then((res) => {
        // console.log(res);
        setPagData(res);
        setActivityData(res?.data?.activityData);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err.response);
      });
    // eslint-disable-next-line
  }, [activityHistoryData, activityDetails, activityClick, pageNum]);

  const handleUpdate = (item) => {
    setPageNum(item);
  };

  const transactionHistory =
    isLoading === null
      ? []
      : activityDetails.length === 0
      ? activityData
      : activityDetails;
  const options = { year: "numeric", month: "long", day: "numeric" };
  const timeOptions = { hour: "numeric", minute: "numeric", hour12: true };

  return isLoading === null ? (
    <Loading imgStyle={{ marginTop: "150px" }} />
  ) : (
    <section className="activity-history-component">
      {transactionHistory.length === 0 ? (
        <Empty img={eActivity} title="You don’t have any Activity" />
      ) : (
        transactionHistory.map((item, index) => (
          <section key={index} className="activity-history">
            <section className="date-time">
              <p className="activity-history-date">
                {" "}
                {new Date(item.createdAt).toLocaleDateString(
                  "en-US",
                  options
                )}{" "}
              </p>
              <p className="activity-history-time">
                {" "}
                {new Date(item.createdAt).toLocaleTimeString(
                  "en-US",
                  timeOptions
                )}{" "}
              </p>
            </section>
            <section className={`circle-with-line-container`}>
              <div
                className={` ${index === 0 ? "circle-first" : "circle"}`}
              ></div>
              <div className={`${index === 0 ? "line-first" : "line"}`}></div>
            </section>
            <section className="transaction-performer-container">
              <p className="transaction"> {item.name} </p>
              <p className="performer">
                {" "}
                by {item.createdBy ? item.createdBy : "Staff"}{" "}
              </p>
            </section>
          </section>
        ))
      )}
      {transactionHistory?.length > 0 && (
        <Pagination
          currentPage={pagData?.pagination?.currentPage}
          numberOfPages={pagData?.pagination?.numberOfPages}
          handleUpdate={handleUpdate}
        />
      )}
    </section>
  );
};

export default ActivityHistoryComponent;
