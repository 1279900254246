import { createSlice } from "@reduxjs/toolkit";


export const itemIdSlice = createSlice({
    name: "itemId",
    initialState: "",
    reducers: {
        newId: (state,action) => {
            const itemId = action.payload
            return (itemId);
        }
    }
})

export const {newId} = itemIdSlice.actions;

export default itemIdSlice.reducer;