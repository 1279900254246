import React, { useState } from "react";
import Back from "../../Components/Back";
import {
  AuthHead,
  FormButton,
  InputForm,
  PasswordInput,
} from "../../Components/AuthHead/AuthHead";
import { AxiosPost } from "../../axios/axios";
import SuccessPopup from "../../Components/filter-popups/SuccessPopup";
import { useNavigate } from "react-router-dom";

const ForgotPasswordOtp = () => {
  const otpUrl = "password-reset/verify/";
  const confirmUrl = "password-reset/complete/";
  const [isLoading, setIsLoading] = useState(false);
  const [isPass, setIsPass] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [success, setSuccess] = useState("");
  const [data, setData] = useState({
    email: "",
    otp: "",
    newPassword: "",
  });
  const [otpErr, setOtpErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);
  const navigate = useNavigate();
  const handleDataChange = (e) => {
    if (e.target.id === "email") {
      setEmailErr("");
    }
    if (e.target.id === "otp") {
      setOtpErr("");
    }
    if (e.target.id === "newPassword") {
      setPasswordErr("");
    }
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    // console.log(newData);
  };
  const dataObject = {
    credential: data.email,
    otp: data.otp,
  };
  const confirmObject = {
    credential: data.email,
    otp: data.otp,
    newPassword: data.newPassword,
  };
  const handleSubmit = () => {
    setIsLoading(true);
    AxiosPost(otpUrl, dataObject)
      .then((res) => {
        // console.log(res);
        setIsLoading(false);
        setIsPass(true);
      })
      .catch((err) => {
        // console.log(err.response);
        setIsLoading(false);
        if (
          err?.message !== "Request failed with status code 500" &&
          err?.message !== "Network Error"
        ) {
          for (let i = 0; i < err?.response?.data?.errors?.length; i++) {
            if (err?.response?.data?.errors[i]?.fieldName === "email") {
              setEmailErr(err?.response?.data?.errors[i]?.error);
            }
            if (err?.response?.data?.errors[i]?.fieldName === "otp") {
              setOtpErr(err?.response?.data?.errors[i]?.error);
            }
          }
        }
      });
  };
  const confirmChange = () => {
    setIsLoading(true);
    AxiosPost(confirmUrl, confirmObject)
      .then((res) => {
        // console.log(res);
        setIsLoading(false);
        setSuccess(res.message);
        setDisplaySuccess(res.message + " ...redirecting to login");
        setTimeout(()=>{
          navigate("/login");
        }, 3000);
      })
      .catch((err) => {
        // console.log(err.response);
        setIsLoading(false);
        if (
          err?.message !== "Request failed with status code 500" &&
          err?.message !== "Network Error"
        ) {
          for (let i = 0; i < err?.response?.data?.errors?.length; i++) {
            if (err?.response?.data?.errors[i]?.fieldName === "email") {
              setEmailErr(err?.response?.data?.errors[i]?.error);
            }
            if (err?.response?.data?.errors[i]?.fieldName === "otp") {
              setOtpErr(err?.response?.data?.errors[i]?.error);
            }
            if (err?.response?.data?.errors[i]?.fieldName === "newPassword") {
              setPasswordErr(true);
            }
          }
        }
      });
  };


  return (
    <div>
      <Back />
      <AuthHead
        headerText="Forgot Password?"
        pText="No worries! It happens to the best of us. But don't worry, we're here to help you get back on track."
      />
      <SuccessPopup
        display={displaySuccess}
        setDisplay={setDisplaySuccess}
        popupMessage={success}
      />
      <InputForm
        formLabel="Email Address/Phone Number"
        placeholder="Enter your email address/phone number"
        dataChange={handleDataChange}
        formId="email"
        formValue={data.email}
        errorMessage={emailErr}
      />
      <InputForm
        formLabel="Otp"
        placeholder="Enter Otp"
        dataChange={handleDataChange}
        formId="otp"
        formValue={data.otp}
        errorMessage={otpErr}
      />
      {isPass && (
        <PasswordInput
          formLabel="New Password"
          placeholder="Enter New password"
          dataChange={handleDataChange}
          formId="newPassword"
          formValue={data.newPassword}
          errorMessage={
            !passwordErr ? (
              ""
            ) : (
              <p style={{ color: "#A3A3A3" }}>
                Password must be at least{" "}
                <span style={{ color: "#4CAF50" }}>8 Characters</span> and
                must contain at least a{" "}
                <span style={{ color: "#4CAF50" }}>Capital Letter</span> , a{" "}
                <span style={{ color: "#FF0000" }}>Number</span> and a{" "}
                <span style={{ color: "#FF0000" }}>Special Character.</span>{" "}
              </p>
            )
          }
        />
      )}
      {!isPass && (
        <FormButton
          submitForm={handleSubmit}
          isLoading={isLoading}
          dataValues={data.email && data.otp}
          buttonText={"Verify"}
        />
      )}
      {isPass && (
        <FormButton
          submitForm={confirmChange}
          isLoading={isLoading}
          dataValues={data.email && data.otp && data.newPassword}
          buttonText={"Confirm"}
        />
      )}
    </div>
  );
};

export default ForgotPasswordOtp;
