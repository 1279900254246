import React, { useState } from "react";
import "./add-items-popup.css";
import { CloseCircle, NotificationStatus } from "iconsax-react";
import { InputForm, SelectInput } from "../AuthHead/AuthHead";
import AmountInput from "../amount-input/AmountInput";
import Tooltip from "../tooltip/Tooltip";
import UploadImg from "../upload-img/UploadImg";
import ToggleBar from "../toggle-component/ToggleBar";
import info from "../../assets/dashboard/info.png";
import { Link } from "react-router-dom";
import ToolTipInput from "../tooltip/ToolTipInput";
import AddNew from "../add&new/Add&New";
import useCoverPicture from "../../hooks/useCoverPicture";
import useAddContent from "../../hooks/useAddContent";
import useData from "../../hooks/useData";
import { AxiosFormData } from "../../axios/axios";
import SuccessPopup from "../filter-popups/SuccessPopup";
import { useDispatch, useSelector } from "react-redux";
import useCoverImage from "../../hooks/useCoverImage";
import FailedMsg from "../alert/failed-msg.component";
import { setFailureModal } from "../../states/general/general";
import DateInput from "../amount-input/DateInput";

const AddItemsPopup = ({ display, setDisplay, setAddOne }) => {
  const dispatch = useDispatch();
  const sectionId = localStorage.getItem('sectionId');
  const [failedMsg, setFailedMsg] = useState("");
  const { coverPicture, setCoverPicture } = useCoverPicture();
  const { addContent } = useAddContent();
  const { setCoverImage } = useCoverImage();
  const [showToggle, setShowToggle] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [nameError, setNameError] = useState("");
  const [success, setSuccess] = useState("");
  const [quantityError, setQuantityError] = useState("");
  const [cpError, setCpError] = useState("");
  const [spError, setSpError] = useState("");
  const [dateValue, setDateValue] = useState();
  const [alertError, setAlertError] = useState("");
  const businessDropdown = useSelector((state) => state.businessDrop);
  const url = "items/";
  const { data, setData } = useData();
  const dataObject = {
    name: data.name,
    cost_price: data.cost_price,
    selling_price: data.selling_price,
    quantity: data.quantity,
    picture: coverPicture,
    alert_threshold: data.alert_threshold,
    notes: data.notes,
    has_variants: showToggle,
    variant_attributes: data.variant_attributes,
    expiringDate: dateValue,
    variant_options: data.variant_options,
    business_id: data.business_id === "Select Section" && !sectionId ? "" : data.business_id === "" && sectionId ? sectionId : data.business_id,
  };
  const closeModal = () => {
    setData({
      name: "",
      cost_price: "",
      selling_price: "",
      quantity: "",
      picture: "",
      alert_threshold: "",
      notes: "",
      has_variants: "",
      variant_attributes: "",
      variant_options: "",
      expiringDate: "",
      business_id: "",
    });
    setDisplay(false);
    setDateValue("");
    setCoverImage("");
    setToggle(false);
    localStorage.removeItem("sectionId");
  };
  const addItem = () => {
    // console.log(dataObject);
    setIsLoading(true);
    AxiosFormData(url, dataObject)
      .then((res) => {
        setIsLoading(false);
        // console.log(res);
        if (addContent === "Add") {
          // console.log(dataObject);
          setDisplay(false);
          setData({
            name: "",
            cost_price: "",
            selling_price: "",
            quantity: "",
            picture: "",
            alert_threshold: "",
            notes: "",
            has_variants: "",
            variant_attributes: "",
            variant_options: "",
            expiringDate: "",
            business_id: "",
          });
          if (setAddOne) {
            setAddOne(true);
          }
          setCoverImage("");
          setCoverPicture("");
          setDateValue("");
          setToggle(false);
        }
        if (addContent === "Add & New") {
          setDisplaySuccess(true);
          setData({
            ...data,
            name: "",
            cost_price: "",
            selling_price: "",
            quantity: "",
            picture: "",
            alert_threshold: "",
            notes: "",
            has_variants: "",
            variant_attributes: "",
            expiringDate: "",
            variant_options: "",
          });
          if (setAddOne) {
            setAddOne(true);
          }
          setCoverImage("");
          setCoverPicture("");
          setDateValue("");
          setToggle(false);
          setSuccess(res.message + ". You can add another Item");
        }
        // console.log(res);
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err.response);
        if (err.response.status === 403) {
          setFailedMsg(err.response.data.message);
          dispatch(setFailureModal(true));
        } else if (
          err.message !== "Request failed with status code 500" &&
          err.message !== "Network Error"
        ) {
          for (let i = 0; i < err.response.data.errors.length; i++) {
            if (err.response.data.errors[i].fieldName === "name") {
              setNameError(err.response.data.errors[i].error);
            }
            if (err.response.data.errors[i].fieldName === "quantity") {
              setQuantityError(err.response.data.errors[i].error);
            }
            if (err.response.data.errors[i].fieldName === "costPrice") {
              setCpError(err.response.data.errors[i].error);
            }
            if (err.response.data.errors[i].fieldName === "sellingPrice") {
              setSpError(err.response.data.errors[i].error);
            }
            if (err.response.data.errors[i].fieldName === "alertThreshold") {
              setAlertError(err.response.data.errors[i].error);
            }
          }
        }
      });
  };
  const handleDataChange = (e) => {
    if (e.target.id === "name") {
      setNameError("");
    }
    if (e.target.id === "quantity") {
      setQuantityError("");
    }
    if (e.target.id === "cost_price") {
      setCpError("");
    }
    if (e.target.id === "selling_price") {
      setSpError("");
    }
    if (e.target.id === "alert_threshold") {
      setAlertError("");
    }
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    // console.log(newData);
  };
  const [showVariantTip, setShowVariantTip] = useState(false);
  const clickPopup = () => {
    setShowVariantTip(!showVariantTip);
  };

  return (
    <div>
      {display && <FailedMsg msg={failedMsg} />}
      <SuccessPopup
        display={displaySuccess}
        setDisplay={setDisplaySuccess}
        popupMessage={success}
      />
      <div
        className={`overlay ${display ? "w-full" : "w-0"}`}
        onClick={closeModal}
      >
        {" "}
      </div>
      <div className={!display ? "display-none" : "add-items_popup"}>
        <div className="add-items_popup-head">
          <h3>Add New Item</h3>
          <CloseCircle style={{ cursor: "pointer" }} onClick={closeModal} />
        </div>
        <div className="add-item_form">
          <InputForm
            formLabel="Name"
            placeholder="Enter Item Name"
            formId="name"
            dataChange={handleDataChange}
            errorMessage={nameError}
            formValue={data.name}
          />
          <InputForm
            formLabel="Quantity"
            placeholder="Enter Quantity"
            formId="quantity"
            dataChange={handleDataChange}
            errorMessage={quantityError}
            formValue={data.quantity}
          />
          <AmountInput
            amountLabel="Cost Price"
            amountPlaceholder="0.00"
            amountId="cost_price"
            dataChange={handleDataChange}
            errorMessage={cpError}
            amountValue={data.cost_price}
          />
          <AmountInput
            amountLabel="Selling Price"
            amountPlaceholder="0.00"
            amountId="selling_price"
            dataChange={handleDataChange}
            errorMessage={spError}
            amountValue={data.selling_price}
          />
          <ToolTipInput
            formLabel="Low Stock Level"
            placeholder="Add Low Stock Level"
            tooltipText="Set alert threshold to determine the level at which you will be notified about low stock. When the threshold is reached, an alert will be triggered to ensure you stay informed and can take appropriate action."
            tipWidth="320px"
            formId="alert_threshold"
            dataChange={handleDataChange}
            errorMessage={alertError}
            formValue={data.alert_threshold}
          />
          <div
            className="expiry-date"
            style={{ display: "flex", gap: "10px", alignItems: "center" }}
          >
            <p>Add Expiry Date</p>
            <ToggleBar
              toggleWidth="40px"
              toggleHeight="20px"
              toggleBallHeight="16px"
              toggleBallWidth="17px"
              toggleState={toggle}
              setToggleState={setToggle}
            />
          </div>
          {toggle && (
            <DateInput
              dateLabel="Expiry Date"
              dateValue={dateValue}
              setDateValue={setDateValue}
              newDate={true}
            />
          )}
          <UploadImg />
          <div className="item-variant_cont">
            <div
              className="item-variant_option display-none"
              style={{ display: "none" }}
            >
              <ToggleBar
                toggleWidth="40px"
                toggleHeight="20px"
                toggleBallHeight="16px"
                toggleBallWidth="17px"
                toggleState={showToggle}
                setToggleState={setShowToggle}
              />
              <h4>This item has variants</h4>
              <img src={info} alt="info" onClick={clickPopup} />
              <Tooltip
                tooltipText="This item has multiple options, like different colors or sizes."
                tipWidth="320px"
                showTip={!showVariantTip ? "" : "flex"}
                showTipRight="22px"
                showTipBottom="-70px"
              />
            </div>
            <div className={showToggle ? "item-variant_show" : "display-none"}>
              <InputForm
                formLabel="Attributes"
                placeholder="Enter Attributes"
                formId="variant_attributes"
                dataChange={handleDataChange}
                formValue={data.variant_attributes}
              />
              <InputForm
                formLabel="Options"
                placeholder="Enter Options"
                formId="variant_options"
                dataChange={handleDataChange}
                formValue={data.variant_options}
              />
            </div>
            <div className="item-show_allfields">
              <Link to="/items/add-new" style={{ textDecoration: "none" }}>
                <p>
                  <NotificationStatus /> Show all fields
                </p>
              </Link>
            </div>
            <div className="item-add_business">
              <SelectInput
                formLabel="Add to Section"
                optionLabel="Select Section"
                optionsArray={businessDropdown}
                style={{ width: "250px" }}
                formId="business_id"
                dataChange={handleDataChange}
                formValue={data.business_id}
              />
              <AddNew
                btnWidth="120px"
                submitForm={addItem}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddItemsPopup;
