import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosGet } from "../../axios/axios";


export const getSales = createAsyncThunk(
    'getSales/sales',
    async (filter, thunkAPI) => {
      try {
        const { data } = await AxiosGet(`sales/${filter}`);
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
);

export const getRecovery = createAsyncThunk(
    'getRecovery/sales',
    async (dateFilter, thunkAPI) => {
      try {
        const { data } = await AxiosGet(`sales-recovery/${dateFilter}`);
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
);

export const getDrafts = createAsyncThunk(
    'getDrafts/sales',
    async (dateFilter, thunkAPI) => {
      try {
        const { data } = await AxiosGet(`drafts/${dateFilter}`);
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
);

export const getDebts = createAsyncThunk(
    'getDebts/sales',
    async (dateFilter, thunkAPI) => {
      try {
        const { data } = await AxiosGet(`debts/${dateFilter}`);
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
);

export const getReceipts = createAsyncThunk(
    'getReceipts/sales',
    async (dateFilter, thunkAPI) => {
      try {
        const { data } = await AxiosGet(`receipts/${dateFilter}`);
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response);
      }
    }
);


export const sales = createSlice({
    name: "sales",
    initialState: {
        allItems: [],
        singleSale: {},
        singleSaleId: {},
        singleRecovery: {},
        singleDraft: {},
        singleDraftId: {},
        singleDebt: {},
        singleReceipt: {},
        singleReceiptId: {},
        sales: {
            status: 'idle',
            result: []
        },
        salesModal: false,
        recovery: {
            status: 'idle',
            result: []
        },
        drafts: {
            status: 'idle',
            result: []
        },
        debts: {
            status: 'idle',
            result: []
        },
        receipts: {
            status: 'idle',
            result: []
        },
    },
    reducers: {
        setAllItems: (state, action) => {
            state.allItems = action.payload;
        },
        setSalesModal: (state, action) => {
            state.salesModal = action.payload;
        },    
        setSingleSale: (state,action) => {
            const id = action.payload;
            state.singleSale = state.sales.result.salesList.find(
              (sale)=> sale.id === id
            );
        },
        setSingleSaleId: (state,action) => {
            const sale = action.payload;
            state.singleSaleId = sale;
        },
        setSingleRecovery: (state,action) => {
            const id = action.payload;
            state.singleRecovery = state.recovery.result.salesRecoveryList.find(
              (recovery)=> recovery.id === id
            );
        },
        setSingleDraft: (state,action) => {
            const id = action.payload;
            state.singleDraft = state.drafts.result.draftList.find(
              (draft)=> draft.id === id
            );
        },
        setSingleDraftId: (state,action) => {
            const draft = action.payload;
            state.singleDraftId = draft;
        },
        setSingleDebt: (state,action) => {
            const id = action.payload;
            state.singleDebt = state.debts.result.debtList.find(
              (debt)=> debt.id === id
            );
        },
        setSingleReceipt: (state,action) => {
            const id = action.payload;
            state.singleReceipt = state.receipts.result.salesList.find(
              (receipt)=> receipt.id === id
            );
        },
        setSingleReceiptId: (state,action) => {
            const receipt = action.payload;
            state.singleReceiptId = receipt;
        },
        clearSingleSale: (state, action) => {
            state.singleSale = {};
        },
        clearSingleSaleId: (state, action) => {
            state.singleSaleId = {};
        },
        clearSingleRecovery: (state, action) => {
            state.singleRecovery = {};
        },
        clearSingleDraft: (state, action) => {
            state.singleDraft = {};
        },
        clearSingleDraftId: (state, action) => {
            state.singleDraftId = {};
        },
        clearSingleDebt: (state, action) => {
            state.singleDebt = {};
        },
        clearSingleReceipt: (state, action) => {
            state.singleReceipt = {};
        },
        clearSingleReceiptId: (state, action) => {
            state.singleReceiptId = {};
        },
    },
    extraReducers(builder) {
        builder
          // get all sales
          .addCase(getSales.pending, (state) => {
            state.sales.status = 'loading';
          })
          .addCase(getSales.fulfilled, (state, action) => {
            state.sales.status = 'idle';
            state.sales.result = action.payload;
          })
          .addCase(getSales.rejected, (state) => {
            state.sales.status = 'idle';
          })

          // get recovery
          .addCase(getRecovery.pending, (state) => {
            state.recovery.status = 'loading';
          })
          .addCase(getRecovery.fulfilled, (state, action) => {
            state.recovery.status = 'idle';
            state.recovery.result = action.payload;
          })
          .addCase(getRecovery.rejected, (state) => {
            state.recovery.status = 'idle';
          })
          
          // get drafts
          .addCase(getDrafts.pending, (state) => {
            state.drafts.status = 'loading';
          })
          .addCase(getDrafts.fulfilled, (state, action) => {
            state.drafts.status = 'idle';
            state.drafts.result = action.payload;
          })
          .addCase(getDrafts.rejected, (state) => {
            state.drafts.status = 'idle';
          })

          // get debts
          .addCase(getDebts.pending, (state) => {
            state.debts.status = 'loading';
          })
          .addCase(getDebts.fulfilled, (state, action) => {
            state.debts.status = 'idle';
            state.debts.result = action.payload;
          })
          .addCase(getDebts.rejected, (state) => {
            state.debts.status = 'idle';
          })

          // get receipts
          .addCase(getReceipts.pending, (state) => {
            state.receipts.status = 'loading';
          })
          .addCase(getReceipts.fulfilled, (state, action) => {
            state.receipts.status = 'idle';
            state.receipts.result = action.payload;
          })
          .addCase(getReceipts.rejected, (state) => {
            state.receipts.status = 'idle';
          })
      }
})

export const { 
    setAllItems,
    setSalesModal,
    setSingleSale,
    setSingleSaleId,
    setSingleRecovery,
    setSingleDebt,
    setSingleDraft,
    setSingleDraftId,
    setSingleReceipt,
    setSingleReceiptId,
    clearSingleSale,
    clearSingleSaleId,
    clearSingleRecovery,
    clearSingleDebt,
    clearSingleDraft,
    clearSingleDraftId,
    clearSingleReceipt,
    clearSingleReceiptId
} = sales.actions;

export default sales.reducer;