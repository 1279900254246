import { Call, Sms } from "iconsax-react";

import SubHeading from "../sub-heading";

import "./support-options.styles.css";

const SupportOption = () => {
    return (
        <section className="support-options-container">
        <SubHeading text={"Support Options"} />
        
        <section className="flex-items">
            <Sms size="32" color="#292D32"/> 
            <p> support@stockkeeper.ng </p>   
        </section>

        <section className="flex-items">
            <Call size="32" color="#292D32"/>
            <p> 08168206494 </p>   
        </section>
        </section>
    );
}
 
export default SupportOption;