import React from 'react'
import Pricingplan from '../../Components/Pricingplan/Pricingplan'

const Pricing = () => {
  const advancedFeatures = [
    "Add more than 10 staff","More than 100 item entries","Inventory Tracking","Stock Optimization","Stock Level Monitoring", "Section Management ", "Sales Management", "Reports and Analytics", "Manage Team and Roles",  "Debt Management", "Mobile App Integration","Priority Support"
  ]
  const basicFeatures = [
    "Add at most 10 staff members","Maximum of 100 Item Entries","Inventory Tracking","Stock Optimization","Stock Level Monitoring", "Section Management ", "Sales Management", "Reports and Analytics", "Manage Team and Roles",  "Debt Management", "Mobile App Integration"
  ]
  const soloFeatures = [
    "Inventory Tracking","Stock Optimization","Stock Level Monitoring", "Section Management ", "Sales Management", "Reports and Analytics", "Manage Team and Roles",  "Debt Management", "Mobile App Integration", "Single User", "Add up to 20 products"
  ]
  return (
    <div>
      <Pricingplan advancedFeatures={advancedFeatures} basicFeatures={basicFeatures} freeFeatures={basicFeatures} displayStyle={{display: "none"}} soloFeatures={soloFeatures} />
    </div>
  )
}

export default Pricing