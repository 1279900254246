import React from "react";
import "./single-fields.css";
import { ArrowLeft, Calendar1, CloseCircle } from "iconsax-react";
import ToolTipInput from "../tooltip/ToolTipInput";
import Dashboardbtn from "../dashboard-buttons/Dashboardbtn";

const SingleCustomFields = ({
  displaySingleField,
  setDisplaySingleField,
  fieldName,
  setFieldName,
  setClickedId,
  submitSaveForm
}) => {
  const clearField = (item) => {
    setClickedId("");
    setDisplaySingleField(false);
    setFieldName("");
  };
  return (
    <>
      <div className={`overlay ${displaySingleField ? "w-full" : "w-0"}`}>
        {" "}
      </div>
      <div
        className={displaySingleField ? "single-custom_fields" : "display-none"}
      >
        <div className="single-field_head">
          <h6>{fieldName} Field</h6>
          <CloseCircle style={{ cursor: "pointer" }} onClick={clearField} />
        </div>
        <div className="single-fields_form">
          <ToolTipInput
            formLabel={fieldName}
            placeholder={`Enter ${fieldName}`}
            tooltipText="The field name property is used to set the name of your custom field. This name will be displayed in the input field, so make sure it is descriptive and easy to understand."
            tipWidth="320px"
          />
          {fieldName.includes("Date") ? (
            <ToolTipInput
              formLabel="Default Value"
              placeholder="Enter Default Value"
              tooltipText="Specify the default information that you want to be shown in this field. You can edit this information at any time."
              tipWidth="320px"
              dateIcon={<Calendar1 />}
            />
          ) : (
            <ToolTipInput
              formLabel="Default Text"
              placeholder="Enter Default Text"
              tooltipText="Specify the default information that you want to be shown in this field. You can edit this information at any time."
              tipWidth="320px"
            />
          )}

          <ToolTipInput
            formLabel="Placeholder Text"
            placeholder="Enter Placeholder Text"
            tooltipText="The placeholder is a short, helpful message that tells users what they should enter in an input field. It can be a sample value or a description of the expected format."
            tipWidth="320px"
          />
        </div>
        <div className="single-fields_btn">
          <Dashboardbtn
            btnClass="dashboard-variant"
            btnText="back"
            btnIcon={<ArrowLeft />}
            submitForm={clearField}
            btnWidth="180px"
          />
          <Dashboardbtn
            btnClass="dashboard-btn"
            btnText="save"
            btnWidth="180px"
            submitForm={submitSaveForm}
          />
        </div>
      </div>
    </>
  );
};

export default SingleCustomFields;
