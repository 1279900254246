const LegendComponent = ({label, price, color}) => {
    return (
        <section className="legend">
            <section className="legend-content">
                <div 
                className="round"
                style={{
                    background: color,
                }}
                ></div>
                <p className="label"> {label} </p>
            </section>
           { price && <p className="price"> {price} </p>}
        </section>
    );
}
 
export default LegendComponent;