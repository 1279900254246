import React, { useEffect, useState } from "react";
import "./admin.css";
import Dashboardheader from "../../Components/dashboard-header/Dashboardheader";
import Loading from "../../Components/Loading";
import { AxiosGet } from "../../axios/axios";
import Table from "../../Components/table/table.component";
import Empty from "../../Components/empty/empty.component";
import empty_icon from "../../assets/dashboard/empty-icon.svg";
import { createColumnHelper } from "@tanstack/table-core";
import OnlyDate from "../../Components/excerpts/OnlyDate";
import OnlyTime from "../../Components/excerpts/OnlyTime";
import Pagination from "../../Components/pagination/pagination.component";
import StockAtHand from "../../Components/overview/stock-at-hand.component";
import stock_icon_one from "../../assets/dashboard/stock-icon-1.svg";

const AdminSub = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [viewSubs, setViewSubs] = useState();
  const [pagItem, setPagItem] = useState(1);
  const url = `super-admin/subscriptions/?page=${pagItem}&pageSize=10`;

  const getSubs = () => {
    setIsLoading(true);
    AxiosGet(url)
      .then((res) => {
        // console.log(res);
        setViewSubs(res);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err.response);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getSubs();
    // eslint-disable-next-line
  }, [pagItem]);

  const stocks = [
    {
      color: "#040614",
      icon: stock_icon_one,
      headerText: "Total Subscription Amount",
      stockQuantity: "₦" + Number(viewSubs?.data?.amountFromSubscription).toLocaleString() + ".00",
    },
]

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("owner", {
      cell: (info) => <p className="capital"> {info.getValue() || "NIL"} </p>,
      header: () => <span> Owner </span>,
    }),
    columnHelper.accessor("amount", {
      cell: (info) => <p className=""> {info.getValue() || "NIL"} </p>,
      header: () => <span> Amount Paid </span>,
    }),
    columnHelper.accessor("period", {
      cell: (info) => <p> {info.getValue() || "NIL"} </p>,
      header: () => <span> Period </span>,
    }),
    columnHelper.accessor("itemLimit", {
      cell: (info) => <p> {info.getValue() || "NIL"} </p>,
      header: () => <span> Item Limit </span>,
    }),
    columnHelper.accessor("type", {
      cell: (info) => <p> {info.getValue() || "NIL"} </p>,
      header: () => <span> Type </span>,
    }),
    columnHelper.accessor("status", {
      cell: (info) => <p className={info.getValue() === "Active" ? "active-cell" : "inactive-cell"}> {info.getValue() || "NIL"} </p>,
      header: () => <span> Status </span>,
    }),
    columnHelper.accessor("staffLimit", {
      cell: (info) => <p> {info.getValue() || "NIL"} </p>,
      header: () => <span> Staff Limit </span>,
    }),
    columnHelper.accessor("createdAt", {
      cell: (info) => (
        <>
          <p className=""> {<OnlyDate date={info.getValue()} />} </p>
          <p className="time"> {<OnlyTime date={info.getValue()} />} </p>
        </>
      ),
      header: () => <span> Created At </span>,
    }),
    columnHelper.accessor("expireAt", {
      cell: (info) => (
        <>
          <p className=""> {<OnlyDate date={info.getValue()} />} </p>
          <p className="time"> {<OnlyTime date={info.getValue()} />} </p>
        </>
      ),
      header: () => <span> Expire At </span>,
    }),
  ];

  const handleUpdate = (item) => {
    setPagItem(item);
    // dispatch(getSales(`?page=${item}&pageSize=10`));
  };

  return (
    <div>
      {isLoading && <Loading imgStyle={{ marginTop: "200px" }} />}
      {!isLoading && (
        <>
          <Dashboardheader
            headerText="subscriptions"
            // addStyle="flex"
            // addBtnText="add"
            // addBtnIcon={<Add />}
          />
          <section className="stock-at-hand-container">
            {stocks.map(({ color, icon, stockQuantity, headerText }, index) => (
              <StockAtHand
                key={index}
                color={color}
                icon={icon}
                stockQuantity={stockQuantity}
                headerText={headerText}
              />
            ))}
          </section>
          {viewSubs?.data?.subscriptionList &&
            viewSubs?.data?.subscriptionList?.length > 0 && (
              <Table
                data={viewSubs?.data?.subscriptionList}
                columns={columns}
                width={"250%"}
              />
            )}
          {viewSubs?.data?.subscriptionList &&
            viewSubs?.data?.subscriptionList?.length === 0 && (
              <Empty
                title={"You don't have any Subscribers"}
                img={empty_icon}
              />
            )}{" "}
          {viewSubs?.data?.subscriptionList &&
            viewSubs?.data?.subscriptionList?.length > 0 && (
              <Pagination
                currentPage={viewSubs?.pagination?.currentPage}
                numberOfPages={viewSubs?.pagination?.numberOfPages}
                handleUpdate={handleUpdate}
              />
            )}
        </>
      )}
    </div>
  );
};

export default AdminSub;
