import React from 'react';
import noPage from "../assets/dashboard/noPage.gif"

const NoPage = () => {
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "60vh"
    }}>
      <h2 style={{ fontSize: "65px"}}>404</h2>
      <p style={{ fontSize: "25px"}}>Sorry!!! The page you're looking for does not exist. Check back again later...</p>
      <img src={noPage} alt="sad face" style={{width: "30%", height: "70%", marginTop: "20px"}} />
    </div>
  )
}

export default NoPage