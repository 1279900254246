import { Add } from "iconsax-react";

import "./empty.styles.css";
import { Link } from "react-router-dom";

const Empty = ({ title, btnText, img, btnLink, emptyStyle, submitClick }) => {
    const clickBtn = () =>{
        if (submitClick){
            submitClick();
        }
    }

    return (
        <section className="empty-container" style={emptyStyle}>
            <div className="img-container">
                <img src={img} alt="empty-icon" width={120} height={120} />
            </div>
            { title && <p> {title} </p> }
            { btnText && 
                <section className="btn-hold">
                    <Link to={btnLink} style={{textDecoration: "none"}}><button className="" onClick={clickBtn} > <Add color="#080b24"/> {btnText} </button> </Link>
                </section>
            }
        </section>
    );
}
 
export default Empty;