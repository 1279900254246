import React, { useEffect, useState } from "react";
import "./admin.css";
import { AxiosGet } from "../../axios/axios";
import Loading from "../../Components/Loading";
import Dashboardheader from "../../Components/dashboard-header/Dashboardheader";
import stock_icon_one from "../../assets/dashboard/stock-icon-1.svg";
import stock_icon_two from "../../assets/dashboard/stock-icon-2.svg";
import stock_icon_three from "../../assets/dashboard/stock-icon-3.svg";
import StockAtHand from "../../Components/overview/stock-at-hand.component";

const AdminOverview = () => {
  const url = "super-admin/overview/statistics/";
  const [isLoading, setIsLoading] = useState(false);
  const [viewData, setViewData] = useState();

  const getStats = () => {
    setIsLoading(true);
    AxiosGet(url)
      .then((res) => {
        // console.log(res);
        setViewData(res.data)
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err.response);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getStats();
    // eslint-disable-next-line
  }, []);

  const stocks = [
    {
      color: "#040614",
      icon: stock_icon_one,
      headerText: "Total Subscription Amount",
      stockQuantity: "₦" + Number(viewData?.amountFromSubscription).toLocaleString() + ".00" || 0,
    },
    {
      color: "#F08227",
      icon: stock_icon_two,
      headerText: "Subscribed Users",
      stockQuantity:Number(viewData?.numberOfSubscribedUser).toLocaleString() || 0,
    },
    {
      color: "#133861",
      icon: stock_icon_three,
      headerText: "Unsubscribed Users",
      stockQuantity:
      Number(viewData?.numberOfUnsubscribedUser).toLocaleString() || 0,
    },
    {
      color: "#6B2D00",
      icon: stock_icon_three,
      headerText: "Total Users",
      stockQuantity:
      Number(viewData?.numberOfUser).toLocaleString() || 0,
    },
  ];

  return (
    <div>
      {isLoading && <Loading imgStyle={{marginTop: "200px"}} />}
      {!isLoading && (
        <>
          <Dashboardheader
            headerText="overview"
            // addStyle="flex"
            // addBtnText="add"
            // addBtnIcon={<Add />}
          />
          <section className="stock-at-hand-container">
            {stocks.map(({ color, icon, stockQuantity, headerText }, index) => (
              <StockAtHand
                key={index}
                color={color}
                icon={icon}
                stockQuantity={stockQuantity}
                headerText={headerText}
              />
            ))}
          </section>
        </>
      )}
    </div>
  );
};

export default AdminOverview;
