import React, { useEffect, useState } from "react";
import "./groupeditems.css";
import { Add } from "iconsax-react";
import Dashboardheader from "../../Components/dashboard-header/Dashboardheader";
import Goback from "../../Components/Dashboard-goback/Goback";
import Quantitybar from "../../Components/quantity-bar/Quantitybar";
import AddItemsPopup from "../../Components/add-items-popup/AddItemsPopup";
import FolderFilter from "../../Components/filter-popups/FolderFilter";
import { AxiosGet } from "../../axios/axios";
import Loading from "../../Components/Loading";
import { useDispatch, useSelector } from "react-redux";
import FailedMsg from "../../Components/alert/failed-msg.component";
import { setFailureModal } from "../../states/general/general";

const Groupeditems = () => {
  const groupId = localStorage.getItem("grpId");
  const url =`group-items/${groupId}/`;
  const [grpData, setGrpData] = useState();
  const [isLoading, setIsLoading] = useState(null);
  const dispatch = useDispatch();
  const userPermissions = useSelector((state) => state.userPermissions);
  useEffect(()=>{
    AxiosGet(url).then(res => {
      // console.log(res);
      setGrpData(res.data);
      setIsLoading(false);
    }).catch(err => {
      // console.log(err.response);
    })
    // eslint-disable-next-line
  },[])
  const [display, setDisplay] = useState(false);
  const showItemAdd = () => {
    if (userPermissions.addItem){
      setDisplay(!display);
    } else{
      dispatch(setFailureModal(true));
    }
  };
  const quantityArray = !grpData ? [] : [
    {
      value: "Quantity",
      quantity: Number(grpData.totalQuantity).toLocaleString(),
    },
    {
      value: "Total Value",
      quantity:"₦ " +  Number(grpData.totalValue).toLocaleString()  + ".00",
    },
  ];
  const itemsArray = !grpData ? [] : grpData.itemsList;
  const [displayFilter, setDisplayFilter] = useState(false);
  const openFilter = () => {
    setDisplayFilter(!display);
  };
  return (
    isLoading === null ? <Loading /> :
    <div>
      <Goback />
      <FailedMsg msg={"You do not have permissions to perform this action!!!"} />
      <div className="grouped-items_header">
        <Dashboardheader
          headerText= {!grpData ? "" : grpData.name}
          filterStyle="flex"
          addStyle="flex"
          addBtnText="add"
          addBtnIcon={<Add />}
          addSubmitForm={showItemAdd}
          filterSubmitForm={openFilter}
        />
        <FolderFilter display={displayFilter} setDisplay={setDisplayFilter} />
        <AddItemsPopup display={display} setDisplay={setDisplay} />
      </div>
      <Quantitybar
        quantityArray={quantityArray}
        itemsArray={itemsArray}
        itemDisplay="block"
        quantityDisplay="flex"
        quantityBarDisplay="block"
        dontOpenItem={true}
      />
    </div>
  );
};

export default Groupeditems;
