import { useEffect, useState } from "react";
import useActivityHistoryData from "../../hooks/useActivityHistoryData";
import { AxiosGet } from "../../axios/axios";
import Empty from "../empty/empty.component";
import eActivity from "../../assets/dashboard/e-activity.svg";

const RecentActivities = () => {
    const {activityHistoryData} = useActivityHistoryData();
    const [activityDetails, setActivityDetails] = useState([]);
    const [isLoading, setIsLoading] = useState(null);
    // console.log(activityHistoryData);
    
    const url = `overview/recent-activities/`;
    useEffect(() => {
        AxiosGet(url)
          .then((res) => {
            // console.log(res);
            setActivityDetails(res.data);
            setIsLoading(false);
          })
          .catch((err) => {
            // console.log(err.response);
          });
          // eslint-disable-next-line
      },[activityHistoryData]);


    const transactionHistory = isLoading === null ? [] : activityDetails;
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true }
    return (
        isLoading === null ? "" :
        <section className="recent-activities">
            <h4> Recent Activities </h4>
            { transactionHistory.length ===0 ? <Empty img={eActivity} title="You don’t have any Activity" /> :  transactionHistory.map((item, index) => (
                <section key={index} className="recent-activities-history">
                    <section className="date-time">
                        <p className="recent-activities-history-date"> {new Date(item.createdAt).toLocaleDateString('en-US', options)} </p>
                        <p className="recent-activities-history-time"> {new Date(item.createdAt).toLocaleTimeString('en-US', timeOptions)} </p>
                    </section>
                    <section className={`circle-with-line-container`}>
                        <div className={` ${index === 0 ? 'circle-first' :  'circle'}`}></div>
                        <div className={`${index === 0 ? 'line-first' :  'line'}`}></div>
                    </section>
                    <section className="transaction-performer-container">
                        <p className="transaction"> {item.name} </p>
                        <p className="performer"> by {item.createdBy ? item.createdBy : "Staff"} </p>
                    </section>
                </section>
            ))}
        </section>
    );
}
 
export default RecentActivities;