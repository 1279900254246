import { useState } from "react";

import { createColumnHelper } from "@tanstack/react-table";

import Table from "../../table/table.component";
import Empty from "../../empty/empty.component";

import empty_icon from "../../../assets/dashboard/empty-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import OnlyDate from "../../excerpts/OnlyDate";
import OnlyTime from "../../excerpts/OnlyTime";
import { getReceipts, setSalesModal, setSingleReceipt, setSingleReceiptId } from "../../../states/sales/sales";
import Pagination from "../../pagination/pagination.component";
import useActivityHistoryData from "../../../hooks/useActivityHistoryData";
import Receipt from "../../modals/receipt.component";
import Loading from "../../Loading";
import { AxiosGet } from "../../../axios/axios";

const Receipts = () => {
    const dispatch = useDispatch(); 
    const { receipts } = useSelector((state) => state.sales);

    
    // eslint-disable-next-line
    const [data, setData] = useState( (receipts?.result && receipts?.result?.salesList.length > 0) ?  receipts?.result?.salesList.map(sale => ({ ...sale, checked: "" })) : [] );
    const { activityHistoryData } = useActivityHistoryData();
    const [ showModal, setShowModal ] = useState(false);



    const columnHelper = createColumnHelper();
    const columns = [
    columnHelper.accessor('name', {
        cell: (info) => (
                <p className=""> {info.getValue()} </p>
            ),
        header: () => <span>Item</span>,
    }),
    columnHelper.accessor('unitPrice', {
        cell: (info) => (
                <p className=""> {info.getValue()} </p>
        ),
        header: () => <span> Unit Price(N) </span>,
    }),
    columnHelper.accessor('quantity', {
        cell: (info) => (
            <p> {info.getValue()} </p>
        ),
        header: () => <span> QTY </span>,
    }),
    columnHelper.accessor('subTotal', {
        cell: (info) => (
            <p> {info.getValue()} </p>
        ),
        header: () => <span> Sub Total(N) </span>,
    }),
    columnHelper.accessor('total', {
        cell: (info) => (
            <p> {info.getValue()} </p>
        ),
        header: () => <span> Total Amount(N) </span>,
    }),
    columnHelper.accessor('customerName', {
        cell: (info) => (
            <p> {info.getValue()} </p>
        ),
        header: () => <span> Name </span>,
    }),
    columnHelper.accessor('customerPhoneNumber', {
        cell: (info) => (
            <p> {info.getValue()} </p>
        ),
        header: () => <span> Phone </span>,
    }),
    columnHelper.accessor('createdAt', {
        cell: (info) => (
            <>
                <p className=""> { <OnlyDate date={info.getValue()} /> } </p>
                <p className="time"> { <OnlyTime date={info.getValue()} /> } </p>
            </>
        ),
        header: () => <span> Date/Time </span>,
    }),
];


    const handleUpdate = (item) => {
        dispatch(getReceipts(`?dateFilter=${activityHistoryData.dateValue}&startDate=${activityHistoryData.startDate}&endDate=${activityHistoryData.endDate}&orderBy=${activityHistoryData.orderBy}&page=${item}&pageSize=10`))
    };

    const handleAddSaleModal = () => dispatch(setSalesModal(true));

    const handleClick = (event, data) => {
        const target = event.target;
        if ( 
            target.parentNode.classList.contains('checkbox-container') || 
            target.classList.contains('checkbox-container') || 
            target.parentNode.classList.contains('edit-trash-container') || 
            target.classList.contains('edit-trash-container')) 
        {
            return;
        } else {
            dispatch(setSingleReceipt(data.id));
            setShowModal(true);
            AxiosGet(`receipts/${data.id}/`).then(res => {
                // console.log(res);
                dispatch(setSingleReceiptId(res.data));
            }).catch( err => {
                // console.log(err.response);
            })
        }     
    };


    return (
        <>
            { receipts.status === 'loading' 
                ?
                <Loading />
                : 
                <>
                    { (data && data.length > 0) && <Table minWidth={"100%"} data={data} columns={columns} click={handleClick} /> }
                    { ( data && data.length === 0) && 
                        <Empty submitClick={handleAddSaleModal} title={"You don't have any receipts"} img={empty_icon} /> 
                    }
                    <Receipt showReceipt={showModal} setShowReceipt={setShowModal} />
                </>
            }
            { (data && data.length > 0) && 
                <Pagination
                    currentPage={receipts?.result?.pagination?.currentPage} 
                    numberOfPages={receipts?.result?.pagination?.numberOfPages} 
                    handleUpdate={handleUpdate} />
            }
        </>
    );
}
 
export default Receipts;