import { CloseCircle } from "iconsax-react";

import { forwardRef } from "react";

import "./connect-to-printer.styles.css";
import ReactToPrint from "react-to-print";

const ConnectToPrinter = forwardRef(({ showModal, setShowModal, setShowReceipt}, ref ) => {

    const hanldeReceiptSlideOut = () => {
        setShowModal(false);
        setShowReceipt(true);
    };

    return (
        <>
            <div className={`overlay ${showModal ? 'w-full' : 'w-0'} `}></div>
            <aside className={`connect-to-printer ${showModal ? '' : 'slide-out'} `}>
                <section className="connect-to-printer-heading-container">
                    <h3> Connect to Thermal Printer </h3>
                    <CloseCircle 
                        size="32" 
                        color="#111111" 
                        className="close-receipt"
                        onClick={hanldeReceiptSlideOut} 
                    />
                </section>
                <p>
                    Before you can print the receipt, please ensure that your device is 
                    connected to a compatible thermal printer. 
                    Once your device is connected to the thermal printer, you can proceed 
                    to print the receipt by selecting the <span style={{ color: '#F08227' }}> "Print Receipt" </span> option.
                </p>

                <section className="connect-to-printer-buttons">
                    {/* <button className="connect"> Connect to Thermal Printer </button> */}
                    <ReactToPrint 
                        trigger={() => <button className="print-receipt"> Print Receipt </button> }
                        content={() => ref.current}
                    />
                </section>
            </aside>
        </>
    );
});
 
export default ConnectToPrinter;