import React, { useState, useRef } from "react";
import Back from "../../Components/Back";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  AuthHead,
  FormButton,
  InputForm,
  PasswordInput,
} from "../../Components/AuthHead/AuthHead";
import { AxiosPost } from "../../axios/axios";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const SignupEmail = () => {
  const inputNRef = useRef(null);
  const { setAuth } = useAuth();
  const [data, setData] = useState({
    email: "",
    password: "",
    referredBy: "",
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessageEmail, setErrorMessageEmail] = useState("");
  const [errorMessagePhone, setErrorMessagePhone] = useState("");
  const [errorMessagePassword, setErrorMessagePassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleDataChange = (e) => {
    if (e.target.id === "email") {
      setErrorMessageEmail("");
    }
    if (e.target.id === "password") {
      setErrorMessagePassword("");
    }
    setIsNCursorOnInput(document.activeElement === inputNRef.current);
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    // console.log(newData);
  };
  const [isNCursorOnInput, setIsNCursorOnInput] = useState(false);

  const handleFocus = () => {
    setIsNCursorOnInput(true);
  };

  const handleBlur = () => {
    setIsNCursorOnInput(false);
  };
  const url = "auth/normal-signup/";
  const dataObject = {
    emailAddress: data.email,
    phoneNumber: phoneNumber,
    password: data.password,
    referredBy: data.referredBy,
  };
  const navigate = useNavigate();
  const handleSubmit = () => {
    if (data.email && data.password && phoneNumber) {
      setIsLoading(true);
      AxiosPost(url, dataObject)
        .then((res) => {
          // console.log(res.data);
          setAuth(res.data);
          navigate("/signuponboarding");
          setIsLoading(false);
        })
        .catch((err) => {
          // console.error(err.response);
          for (let i = 0; i < err.response.data.errors.length; i++) {
            if (err.response.data.errors[i].fieldName === "emailAddress") {
              setErrorMessageEmail(err.response.data.errors[i].error);
            }
            if (err.response.data.errors[i].fieldName === "phoneNumber") {
              setErrorMessagePhone(err.response.data.errors[i].error);
            }
            if (err.response.data.errors[i].fieldName === "password") {
              setErrorMessagePassword(true);
            }
          }
          setIsLoading(false);
        });
    }
  };
  const handleCodeChange = (value) => {
    setErrorMessagePhone("");
    setPhoneNumber(value);
  };
  return (
    <div>
      <Back />
      <AuthHead
        headerText="Get Started"
        pText="Keep your inventory in check. Sign up to streamline your inventory
            processes."
      />
      <div className="loginpage-form">
        <form method="post">
          <InputForm
            formLabel="Email Address (optional)"
            placeholder="Enter your email address"
            dataChange={handleDataChange}
            formId="email"
            formValue={data.email}
            errorMessage={errorMessageEmail}
          />
          <div className="login-form_input">
            <label
              className={`${
                !phoneNumber
                  ? ""
                  : `${
                      !isNCursorOnInput
                        ? "focus-label"
                        : phoneNumber
                        ? "active-label"
                        : "neutral-label"
                    }`
              } ${errorMessagePhone ? "error-label" : ""}`}
              htmlFor="phoneNumber"
            >
              Phone Number
            </label>
            <PhoneInput
              id="phoneNumber"
              placeholder="Enter phone number"
              defaultCountry="NG"
              onChange={handleCodeChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              ref={inputNRef}
            />
            <p style={{ color: "red" }}>{errorMessagePhone}</p>
          </div>
          <PasswordInput
            formLabel="Password"
            placeholder="Enter your password"
            dataChange={handleDataChange}
            formId="password"
            formValue={data.password}
            errorMessage={
              !errorMessagePassword ? (
                ""
              ) : (
                <p style={{ color: "#A3A3A3" }}>
                  Password must be at least{" "}
                  <span style={{ color: "#4CAF50" }}>8 Characters</span> and
                  must contain at least a{" "}
                  <span style={{ color: "#4CAF50" }}>Capital Letter</span> , a{" "}
                  <span style={{ color: "#FF0000" }}>Number</span> and a{" "}
                  <span style={{ color: "#FF0000" }}>Special Character.</span>{" "}
                </p>
              )
            }
          />
          <InputForm
            formLabel="Referral Code (optional)"
            placeholder="Enter your Referral Code"
            dataChange={handleDataChange}
            formId="referredBy"
            formValue={data.referredBy}
            errorMessage={errorMessageEmail}
          />
          <FormButton
            submitForm={handleSubmit}
            isLoading={isLoading}
            dataValues={data.email && data.password && phoneNumber}
            buttonText="sign up"
          />
        </form>
      </div>
    </div>
  );
};

export default SignupEmail;
