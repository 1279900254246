import React, {useState} from 'react';
import "./Faq.css";
import "../../App.css";
import { ArrowDown2, ArrowUp2 } from 'iconsax-react';

const Faq = () => {
    const [dropDown, setDropDown] = useState("display-none");
    const [selectedId, setSelectedId] = useState(null);
    const faq = [
        {
            id: 1,
            question: "How does Stockkeeper benefit my business?",
            answer: "Stockkeeper helps to minimize manual labor costs, monitor sales, expenses, profit, and loss in real-time, tracking salesperson activities, and managing customers. All these help to produce maximum profit for your business."
        },
        {
            id: 2,
            question: "What features does Stockkeeper have?",
            answer: "- General overview of sales and expenses ",
            answer2: "- Management of salespeople and permissions ",
            answer3: "- Reports and Analytics ",
            answer4: "- Customer Support (advanced plan) ",
            answer5: "- Information on customers ",
            answer6: "- Display of items to be sold ",
        },
        {
            id: 3,
            question: "Can I upgrade from basic plan to advanced plan?",
            answer: "Yes! For a subscription fee."
        },
        {
            id: 4,
            question: "Is there a limit to the number of users who can access the system?",
            answer: "No, there's no limit. But, users can be granted permission to perform some activities on the system."
        },
        {
            id: 5,
            question: "How do I cancel my plan if needed?",
            answer: "You can cancel your plan by canceling your subscription from Settings or change your billing preferences if need be."
        },
        {
            id: 6,
            question: "Can I access the Stockkeeper app from multiple devices or locations?",
            answer: "Absolutely! In as much as you have your login credentials, you can access the app across many devices and from any location."
        },
    ]
    const openDropDown = (item) =>{
        if (selectedId === item.id) {
            setSelectedId(null);
            setDropDown("display-none");
          } else {
            setSelectedId(item.id);
            setDropDown("display-flex");
          }
    }

  return (
    <div>
        <div className="faq">
            <h2>Frequently <span>Asked Questions</span> </h2>
            <p>Do you still have questions or are you unsure about something? We got you</p>
            <div className="faq-questions">
                {faq.map((item,idx)=>{
                    return (
                        <div id={item.id} className="each-question" key={idx}>
                            <h5 onClick={() => openDropDown(item)}>{item.question} {dropDown === "display-flex" && selectedId === item.id ? <ArrowUp2 /> : <ArrowDown2 />}</h5>
                            {selectedId === item.id && <h6 className={`${dropDown}`}>{item.answer}</h6>}
                            {selectedId === item.id && item.id === 2 && <h6 className={`${dropDown}`}>{item.answer2}</h6>}
                            {selectedId === item.id && item.id === 2 && <h6 className={`${dropDown}`}>{item.answer3}</h6>}
                            {selectedId === item.id && item.id === 2 && <h6 className={`${dropDown}`}>{item.answer4}</h6>}
                            {selectedId === item.id && item.id === 2 && <h6 className={`${dropDown}`}>{item.answer5}</h6>}
                            {selectedId === item.id && item.id === 2 && <h6 className={`${dropDown}`}>{item.answer6}</h6>}
                        </div>
                    )
                })}
            </div>
        </div>
    </div>
  )
}

export default Faq