import React, { useState } from "react";
import "./add-newitem.css";
import { InputForm, SelectInput } from "../../Components/AuthHead/AuthHead";
import AmountInput from "../../Components/amount-input/AmountInput";
import Tooltip from "../../Components/tooltip/Tooltip";
import info from "../../assets/dashboard/info.png";
import TextAreaInput from "../../Components/textarea/TextAreaInput";
import ToggleBar from "../../Components/toggle-component/ToggleBar";
import Goback from "../../Components/Dashboard-goback/Goback";
import DiscardChanges from "../../Components/discard-changes/DiscardChanges";
import CustomFields from "../../Components/custom-fields/CustomFields";
import ToolTipInput from "../../Components/tooltip/ToolTipInput";
import AddNew from "../../Components/add&new/Add&New";
import UploadImg from "../../Components/upload-img/UploadImg";
import useCoverPicture from "../../hooks/useCoverPicture";
import useAddContent from "../../hooks/useAddContent";
import useData from "../../hooks/useData";
import { AxiosFormData } from "../../axios/axios";
import { useNavigate } from "react-router-dom";
import SuccessPopup from "../../Components/filter-popups/SuccessPopup";
import { useSelector } from "react-redux";
import useCoverImage from "../../hooks/useCoverImage";
import DateInput from "../../Components/amount-input/DateInput";

const AddNewItem = () => {
  const url = "items/";
  const { coverPicture, setCoverPicture} = useCoverPicture();
  const { setCoverImage } = useCoverImage();
  const { addContent } = useAddContent();
  const [showToggle, setShowToggle] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [nameError, setNameError] = useState("");
  const businessDropdown = useSelector((state) => state.businessDrop);
  const [quantityError, setQuantityError] = useState("");
  const [cpError, setCpError] = useState("");
  const [dateValue, setDateValue] = useState();
  const [spError, setSpError] = useState("");
  const [alertError, setAlertError] = useState("");
  const { data, setData } = useData();
  const dataObject = {
    name: data.name,
    cost_price: data.cost_price,
    selling_price: data.selling_price,
    quantity: data.quantity,
    picture: coverPicture,
    alert_threshold: data.alert_threshold,
    notes: data.notes,
    has_variants: showToggle,
    variant_attributes: data.variant_attributes,
    expiringDate: dateValue,
    variant_options: data.variant_options,
    business_id: data.business_id === "Select Business" ? "" : data.business_id,
  };
  const navigate = useNavigate();
  const addItem = () => {
    setIsLoading(true);
    AxiosFormData(url, dataObject)
      .then((res) => {
        setIsLoading(false);
        if (addContent === "Add") {
          navigate("/items");
          setData({
            name: "",
            cost_price: "",
            selling_price: "",
            quantity: "",
            picture: "",
            alert_threshold: "",
            notes: "",
            expiringDate: "",
            has_variants: "",
            variant_attributes: "",
            variant_options: "",
            business_id: "",
          });
          setCoverImage("");
          setDateValue("");
          setCoverPicture("");
        }
        if (addContent === "Add & New") {
          setDisplaySuccess(true);
          setData({
            ...data,
            name: "",
            cost_price: "",
            selling_price: "",
            quantity: "",
            picture: "",
            alert_threshold: "",
            expiringDate: "",
            notes: "",
            has_variants: "",
            variant_attributes: "",
            variant_options: "",
          });
          setCoverImage("");
          setDateValue("");
          setCoverPicture("");
          setSuccess(res.message + ". You can add another Item");
        }
        // console.log(res);
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err);
        if (
          err.message !== "Request failed with status code 500" &&
          err.message !== "Network Error"
        ) {
          for (let i = 0; i < err.response.data.errors.length; i++) {
            if (err.response.data.errors[i].fieldName === "name") {
              setNameError(err.response.data.errors[i].error);
            }
            if (err.response.data.errors[i].fieldName === "quantity") {
              setQuantityError(err.response.data.errors[i].error);
            }
            if (err.response.data.errors[i].fieldName === "costPrice") {
              setCpError(err.response.data.errors[i].error);
            }
            if (err.response.data.errors[i].fieldName === "sellingPrice") {
              setSpError(err.response.data.errors[i].error);
            }
            if (err.response.data.errors[i].fieldName === "alertThreshold") {
              setAlertError(err.response.data.errors[i].error);
            }
          }
        }
      });
  };
  const handleDataChange = (e) => {
    if (e.target.id === "name") {
      setNameError("");
    }
    if (e.target.id === "quantity") {
      setQuantityError("");
    }
    if (e.target.id === "cost_price") {
      setCpError("");
    }
    if (e.target.id === "selling_price") {
      setSpError("");
    }
    if (e.target.id === "alert_threshold") {
      setAlertError("");
    }
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    // console.log(newData);
  };
  const [showVariantTip, setShowVariantTip] = useState(false);
  const [displayCancel, setDisplayCancel] = useState(false);
  const clickPopup = () => {
    setShowVariantTip(!showVariantTip);
  };
  const handleCancel = () => {
    setDisplayCancel(!displayCancel);
  };

  return (
    <div className="new-item">
      <SuccessPopup
        display={displaySuccess}
        setDisplay={setDisplaySuccess}
        popupMessage={success}
      />
      <Goback />
      <input
        className="new-item_input"
        type="text"
        placeholder="Item Name*"
        id="name"
        onChange={handleDataChange}
        value={data.name}
      />
      {nameError ? <p style={{ color: "red" }}>{nameError}</p> : ""}
      <div className="new-item-body">
        <div className="new-item_body-form">
          <div className="new-item-body-left">
            <h3>Item Details</h3>
            <div className="new-item-left_form">
              <div className="new-item-left_form-inputs">
                <InputForm
                  formLabel="Quantity"
                  placeholder="Enter Quantity"
                  formId="quantity"
                  dataChange={handleDataChange}
                  errorMessage={quantityError}
                  formValue={data.quantity}
                />
                <AmountInput
                  amountLabel="Cost Price"
                  amountPlaceholder="0.00"
                  amountId="cost_price"
                  dataChange={handleDataChange}
                  errorMessage={cpError}
                  amountValue={data.cost_price}
                />
                <AmountInput
                  amountLabel="Selling Price"
                  amountPlaceholder="0.00"
                  amountId="selling_price"
                  dataChange={handleDataChange}
                  errorMessage={spError}
                  amountValue={data.selling_price}
                />
                <AmountInput
                  amountLabel="Total Value"
                  amountPlaceholder="0.00"
                  amountValue={data.selling_price * data.quantity + ".00"}
                  readOnly={"readOnly"}
                />
                <ToolTipInput
                  formLabel="Low Stock Level"
                  placeholder="Add Low Stock Level"
                  tooltipText="Set alert threshold to determine the level at which you will be notified about low stock. When the threshold is reached, an alert will be triggered to ensure you stay informed and can take appropriate action."
                  tipWidth="320px"
                  formId="alert_threshold"
                  dataChange={handleDataChange}
                  errorMessage={alertError}
                  formValue={data.alert_threshold}
                />
                <div className="expiry-date" style={{display: "flex", gap: "10px", alignItems: "center"}}>
                  <p>Add Expiry Date</p>
                  <ToggleBar
                    toggleWidth="40px"
                    toggleHeight="20px"
                    toggleBallHeight="16px"
                    toggleBallWidth="17px"
                    toggleState={toggle}
                    setToggleState={setToggle}
                  />
                </div>
          {toggle && (
            <DateInput
              dateLabel="Expiry Date"
              dateValue={dateValue}
              setDateValue={setDateValue}
              newDate={true}
            />
          )}
              </div>
              <TextAreaInput
                textAreaLabel="Notes"
                textAreaPlaceholder="Add Notes"
                textAreaId="notes"
                dataChange={handleDataChange}
                formValue={data.notes}
              />
            </div>
          </div>
          <div className="new-item-body-right">
            <h3>Photos</h3>
            <UploadImg
              style={{ height: "326px", width: "100%" }}
              iconSize="44"
            />
          </div>
        </div>

        <div className="item-variant_container">
          <div className="item-variant_option " style={{display: "none"}}>
            <ToggleBar
              toggleWidth="40px"
              toggleHeight="20px"
              toggleBallHeight="16px"
              toggleBallWidth="17px"
              toggleState={showToggle}
              setToggleState={setShowToggle}
            />
            <h4>This item has variants</h4>
            <img src={info} alt="info" onClick={clickPopup} />
            <Tooltip
              tooltipText="This item has multiple options, like different colors or sizes."
              tipWidth="320px"
              showTip={!showVariantTip ? "" : "flex"}
              showTipRight="22px"
              showTipBottom="-65px"
            />
          </div>
        </div>
        <div className={showToggle ? "new-item-variant_show" : "display-none"}>
          <InputForm
            formLabel="Attributes"
            placeholder="Enter Attributes"
            inputStyle={{ width: "320px" }}
            formId="variant_attributes"
            dataChange={handleDataChange}
            formValue={data.variant_attributes}
          />
          <InputForm
            formLabel="Options"
            placeholder="Enter Options"
            inputStyle={{ width: "320px" }}
            formId="variant_options"
            dataChange={handleDataChange}
            formValue={data.variant_options}
          />
        </div>

        <CustomFields />

        <div className="new-item_bottom">
          <div className="new-item_bottom-left">
            <SelectInput
              formLabel="Add to Section"
              optionLabel="Select Section"
              optionsArray={businessDropdown}
              formId="business_id"
              dataChange={handleDataChange}
              formValue={data.business_id}
            />
          </div>
          <div className="new-item_bottom-right">
            <p onClick={handleCancel}>Cancel</p>
            <AddNew
              btnWidth="180px"
              submitForm={addItem}
              isLoading={isLoading}
            />
          </div>
        </div>
        <DiscardChanges
          displayCancel={displayCancel}
          cancelForm={() => setDisplayCancel(false)}
          discardForm={() => navigate("/items")}
        />
      </div>
    </div>
  );
};

export default AddNewItem;
