import { createSlice } from "@reduxjs/toolkit";


export const searchSlice = createSlice({
    name: "searchDetails",
    initialState: null,
    reducers: {
        search: (state,action) => {
            const searchResults = action.payload
            return (searchResults);
        }
    }
})

export const {search} = searchSlice.actions;

export default searchSlice.reducer;