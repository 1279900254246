import { createSlice } from "@reduxjs/toolkit";



export const general = createSlice({
    name: "general",
    initialState: {
        successModal: false,
        failureModal: false

    },
    reducers: {
        setSuccessModal: (state, action) => {
            state.successModal = action.payload;
        },    
        setFailureModal: (state, action) => {
            state.failureModal = action.payload;
        },
        
    },
    extraReducers(builder) {
      }
})

export const {
    setSuccessModal,
    setFailureModal
} = general.actions;

export default general.reducer;