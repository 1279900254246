import React from 'react';
import "./protected.styles.css";
import { useEffect, useState } from "react";

import { Outlet, useLocation, useNavigate } from "react-router-dom";

import AdminSidenav from '../sidenav/AdminSidenav';

const AdminLayout = () => {

    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, [pathname]);
  
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
  
    // setAuth(userToken)
    const checkUserToken = () => {
      const userToken = JSON.parse(localStorage.getItem("stk-apk"));
      const targetDateTime = userToken?.authTokenExp;
      const currentDateTime = new Date().toISOString();
      if (!userToken || userToken === "undefined") {
        setIsLoggedIn(false);
        return navigate("/login");
      }
      if (targetDateTime <= currentDateTime) {
        setIsLoggedIn(false);
        return navigate("/login");
      }
      setIsLoggedIn(true);
    };
    useEffect(() => {
      checkUserToken();
      // eslint-disable-next-line 
    }, [isLoggedIn]);
  
  

  return isLoggedIn ? (
    <section className="layout-container">
      {/* {isLoading === null && <Loading /> } */}
      {/* {isLoading && <> */}
          <AdminSidenav />
          <section className="content">
            {/* <TopBar /> */}
            <Outlet />
          </section>
        {/* </>} */}
        
    </section>
  ) : null;
}

export default AdminLayout