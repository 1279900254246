import { useDispatch, useSelector } from "react-redux";
import Dashboardbtn from "../../dashboard-buttons/Dashboardbtn";
import SubHeading from "../../help-support/sub-heading";
import { InputForm } from "../../AuthHead/AuthHead";
import { useEffect, useState } from "react";
import { AxiosAuthPut, AxiosGet } from "../../../axios/axios";
import DiscardChanges from "../../discard-changes/DiscardChanges";
import SuccessPopup from "../../filter-popups/SuccessPopup";
import { profile } from "../../../states/profile/profileSlice";
import UploadImg from "../../upload-img/UploadImg";
import useCoverPicture from "../../../hooks/useCoverPicture";
import useCoverImage from "../../../hooks/useCoverImage";

const PersonalInformation = () => {
  const url = "profile/";
  const dispatch = useDispatch();
  const myProfile = useSelector((state) => state.profileDetails);
  const [isLoading, setIsLoading] = useState(false);
  const [displayDiscard, setDisplayDiscard] = useState(false);
  const { setCoverImage } = useCoverImage();
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [errFirstName, setErrFirstName] = useState("");
  const [errLastName, setErrLastName] = useState("");
  const { coverPicture } = useCoverPicture();
  const [errEmail, setErrEmail] = useState("");
  const [errPhoneNumber, setErrPhoneNumber] = useState("");
  const [errJd, setErrJd] = useState("");
  useEffect(() => {
    AxiosGet(url)
      .then((res) => {
        // console.log(res);
        dispatch(profile(res.data));
        setIsLoading(false);
      })
      .catch((err) => {});
    // eslint-disable-next-line
  }, [displaySuccess]);
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    job_description: "",
  });
  const dataObject = {
    firstName: !data.first_name ? myProfile.firstName : data.first_name,
    lastName: !data.last_name ? myProfile.lastName : data.last_name,
    email: !data.email ? myProfile.email : data.email,
    phoneNumber: !data.phone_number ? myProfile.phoneNumber : data.phone_number,
    jobDescription: !data.job_description
      ? myProfile.jobDescription
      : data.job_description,
    picture: !coverPicture ? "" : coverPicture,
  };
  const submitProfile = () => {
    setIsLoading(true);
    AxiosAuthPut(url, dataObject)
      .then((res) => {
        // console.log(res);
        setIsLoading(false);
        if (res.success === true) {
          setData({
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            job_description: "",
          });
          setDisplaySuccess(true);
          setCoverImage("");
        }
      })
      .catch((err) => {
        // console.log(err.response);
        setIsLoading(false);
        if (
          err.message !== "Request failed with status code 500" &&
          err.message !== "Network Error"
        ) {
          for (let i = 0; i < err.response.data.errors.length; i++) {
            if (err.response.data.errors[i].fieldName === "firstName") {
              setErrFirstName(err.response.data.errors[i].error);
            }
            if (err.response.data.errors[i].fieldName === "lastName") {
              setErrLastName(err.response.data.errors[i].error);
            }
            if (err.response.data.errors[i].fieldName === "email") {
              setErrEmail(err.response.data.errors[i].error);
            }
            if (err.response.data.errors[i].fieldName === "phoneNumber") {
              setErrPhoneNumber(err.response.data.errors[i].error);
            }
            if (err.response.data.errors[i].fieldName === "jobDescription") {
              setErrJd(err.response.data.errors[i].error);
            }
          }
        }
      });
  };
  const handleDataChange = (e) => {
    if (e.target.id === "first_name") {
      setErrFirstName("");
    }
    if (e.target.id === "last_name") {
      setErrLastName("");
    }
    if (e.target.id === "email") {
      setErrEmail("");
    }
    if (e.target.id === "phone_number") {
      setErrPhoneNumber("");
    }
    if (e.target.id === "job_description") {
      setErrJd("");
    }
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    // console.log(newData);
  };
  const discardChanges = () => {
    setData({
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      job_description: "",
    });
    setCoverImage("")
    setDisplayDiscard(!displayDiscard);
  };
  const cancelSuccess = () => {
    setDisplaySuccess(!displaySuccess);
  };

  return (
    <section className="personal-information-container">
      <DiscardChanges
        displayCancel={displayDiscard}
        discardForm={discardChanges}
        cancelForm={() => setDisplayDiscard(!displayDiscard)}
      />
      <SuccessPopup
        display={displaySuccess}
        setDisplay={setDisplaySuccess}
        popupMessage={"Your Profile has been updated successfully"}
        cancelClick={cancelSuccess}
      />
      <SubHeading text={"Personal Information"} />
      <div className="prof-mob" style={{ width: "35%" }}>
        <UploadImg
          pic={!myProfile.picture ? undefined : myProfile.picture}
          picDisplay={!myProfile.picture ? undefined : "flex"}
          emptyPic={!myProfile.picture ? "flex" : "none"}
          style={{ display: "none" }}
          userPic="flex"
        />
      </div>
      <section className="flex">
        <div className="input-form">
          <InputForm
            formLabel="First Name"
            placeholder={
              !myProfile.firstName ? "Enter First Name" : myProfile.firstName
            }
            formId="first_name"
            dataChange={handleDataChange}
            formValue={data.first_name}
            errorMessage={errFirstName}
          />
        </div>
        <div className="input-form">
          <InputForm
            formLabel="Last Name"
            placeholder={
              !myProfile.lastName ? "Enter Last Name" : myProfile.lastName
            }
            formId="last_name"
            dataChange={handleDataChange}
            formValue={data.last_name}
            errorMessage={errLastName}
          />
        </div>
      </section>
      <section className="flex">
        <div className="input-form">
          <InputForm
            formLabel="Email Address"
            placeholder={
              !myProfile.email ? "Enter Email Address" : myProfile.email
            }
            formId="email"
            dataChange={handleDataChange}
            formValue={data.email}
            errorMessage={errEmail}
          />
        </div>
        <div className="input-form">
          <InputForm
            formLabel="Phone Number"
            placeholder={
              !myProfile.phoneNumber
                ? "Enter Phone Number"
                : myProfile.phoneNumber
            }
            formId="phone_number"
            dataChange={handleDataChange}
            formValue={data.phone_number}
            errorMessage={errPhoneNumber}
          />
        </div>
      </section>
      <section className="flex">
        <div className="input-form">
          <InputForm
            formLabel="Team Role"
            placeholder={!myProfile.role ? "Enter Team Role" : myProfile.role}
            formId="team_role"
            isReadonly={"readOnly"}
          />
        </div>
        <div className="input-form">
          <InputForm
            formLabel="Job Description"
            placeholder={
              !myProfile.jobDescription
                ? "Enter Job Description"
                : myProfile.jobDescription
            }
            formId="job_description"
            dataChange={handleDataChange}
            formValue={data.job_description}
            errorMessage={errJd}
          />
        </div>
      </section>
      <section className="btn-hold">
        <Dashboardbtn
          btnClass={"dashboard-btn"}
          btnText={"Save Changes"}
          btnWidth="200px"
          submitForm={submitProfile}
          isLoading={isLoading}
        />
        <Dashboardbtn
          btnClass={"dashboard-variant"}
          btnLink={""}
          btnText={"Discard"}
          submitForm={() => setDisplayDiscard(!displayDiscard)}
          btnWidth="200px"
        />
      </section>
    </section>
  );
};

export default PersonalInformation;
