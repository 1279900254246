import React, { useEffect, useState } from "react";

import itemImg from "../../assets/dashboard/single-item.svg";

import Notification from "../notification/Notification";
import useActivityHistoryData from "../../hooks/useActivityHistoryData";
import { AxiosGet } from "../../axios/axios";
import Empty from "../empty/empty.component";
import nothing from "../../assets/dashboard/nothing.png";

const TopSellers = () => {
  const [isLoading, setIsLoading] = useState(null);
  const [tSellers, setTSellers] = useState([]);
  const { activityHistoryData } = useActivityHistoryData();
  const url = `overview/top-sellers/?dateFilter=${activityHistoryData.dateValue}&startDate=${activityHistoryData.startDate}&endDate=${activityHistoryData.endDate}`;
  useEffect(() => {
    AxiosGet(url)
      .then((res) => {
        // console.log(res.data);
        setTSellers(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err.response);
      });
    // eslint-disable-next-line
  }, [activityHistoryData]);
  const items = isLoading === null ? [] : tSellers;

  return (
    <section className="top-sellers">
      <section className="top">
        <h4> Top Sellers </h4>
        <Notification notificationStyle={"flex"} />
      </section>

      {items.length === 0 ? (
        <Empty title="Nothing to show here" img={nothing} />
      ) : (
        <>
          <div className="tab-cont">
            <div className="tab-content-scroll">
              <section className="items-heading">
                <p> Item name </p>
                <p> QTY </p>
                <p> Amount </p>
              </section>
              {items.map((item, index) => (
                <section className="items" key={index}>
                  <div className="img-text-hold">
                    <img
                      src={!item.picture ? itemImg : item.picture}
                      alt="coca-cola"
                    />
                    <p className="name"> {item.name} </p>
                  </div>
                  <p className="qty"> {item.quantity} </p>
                  <p className="amount"> N {item.totalAmount} </p>
                </section>
              ))}
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default TopSellers;
