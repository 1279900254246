import { useState } from "react";

const useFormValidation = (initialValues, validationSchema, onSubmit) => {
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setSubmitting] = useState(false);
  
    const handleChange = (event) => {
      const { name, value } = event.target;
      setValues((prevValues) => ({ ...prevValues, [name]: value }));
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      setSubmitting(true);
  
      validationSchema
        .validate(values, { abortEarly: false })
        .then(() => {
          setSubmitting(false);
          onSubmit(values);
          setValues(initialValues);
          setErrors({});
        })
        .catch((error) => {
          // console.log(error);
          const validationErrors = {};
          error.inner.forEach((err) => {
            validationErrors[err.path] = err.message;
          });
          setErrors(validationErrors);
          setSubmitting(false);
        });
    };
  
    return { values, errors, isSubmitting, handleChange, handleSubmit, setValues, setErrors };
  };

  export default useFormValidation;