import { useNavigate } from "react-router-dom";

import { CloseCircle, LogoutCurve, Setting2 } from "iconsax-react";
import profile_img from "../../assets/dashboard/profile_img.png";
import { useSelector } from "react-redux";

const ProfileModal = ({ modal, setModal }) => {
  const navigate = useNavigate();
  const myProfile = useSelector((state) => state.profileDetails);
  //   console.log(myProfile);

  const handleModal = () => {
    setModal(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  const handleSettingsNavigation = () => {
    navigate("/settings");
    setModal(false);
  };

  return (
    <section className="profile-modal">
      <section className="profile-modal-info-close">
        <section className="profile-modal-info">
          <section className="profile-modal-img-hold">
          {!myProfile.picture ? <img src={profile_img} height={60} width={60} alt="profile-img" /> : <img src={myProfile.picture} height={60} width={60} alt="profile-img" />}
          </section>
          <section className="name-email-container">
            <p id="name">
              {!myProfile.firstName && !myProfile.lastName
                ? ""
                : myProfile.firstName + " " + myProfile.lastName}{" "}
            </p>
            <p id="email"> {myProfile.email} </p>
          </section>
        </section>
        <CloseCircle
          size="32"
          className="close"
          color=" #111111"
          onClick={handleModal}
        />
      </section>

      <p id="settings" onClick={handleSettingsNavigation}>
        <Setting2 color="#2266b0" /> Account Settings
      </p>

      <div
        style={{
          border: "1px solid #D1D1D1",
        }}
      ></div>

      <section className="plan">
        {!myProfile.organizationDetails.name &&
        !myProfile.firstName &&
        !myProfile.lastName &&
          ""
        }
        <p id="plan-name">
          {!myProfile.organizationDetails.name
            ? myProfile.firstName + " " + myProfile.lastName
            : myProfile.organizationDetails.name}
        </p>
        <p id="plan-type"> Basic Plan </p>
      </section>

      <div
        style={{
          border: "1px solid #D1D1D1",
        }}
      ></div>

      <p id="logout" onClick={handleLogout}>
        <LogoutCurve color="#b50000" /> Logout
      </p>
    </section>
  );
};

export default ProfileModal;
