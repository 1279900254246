import { createSlice } from "@reduxjs/toolkit";


export const businessDropdown = createSlice({
    name: "businessDrop",
    initialState: [],
    reducers: {
        bDropdown: (state,action) => {
            const dropdown = action.payload
            return (dropdown);
        }
    }
})

export const {bDropdown} = businessDropdown.actions;

export default businessDropdown.reducer;