import React from "react";

import SubHeading from "../../help-support/sub-heading";

const PaymentHistory = ({ paymentHistoryDetail }) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
  return (
    <section className="payment-history-container">
      <SubHeading text={"Payment History"} />
      <section className="payment-history">
        <section className="heading">
          <p> Billing Date </p>
          <p> Amount </p>
          <p> Plan </p>
          <p> Status </p>
        </section>
        {paymentHistoryDetail &&
          paymentHistoryDetail.map(
            ({ billingDate, amount, type, status }, index) => (
              <React.Fragment key={index}>
                <section className="capitalize payment-history-detail">
                  <p>
                    {new Date(billingDate).toLocaleTimeString(
                      "en-US",
                      options
                    )}
                  </p>
                  <p> {amount} </p>
                  <p> {type} </p>
                  {status === "Expired" && <button className="status-btn" style={{background: "red"}}> {status} </button>}
                  {status === "Active" && <button className="status-btn"> {status} </button>}
                  {status === "Future" && <button className="status-btn"> {status} </button>}
                </section>
                <div
                  style={{
                    border: "1px solid #D1D1D1",
                  }}
                ></div>
              </React.Fragment>
            )
          )}
      </section>
    </section>
  );
};

export default PaymentHistory;
