import { ExportCurve } from "iconsax-react";
import Dashboardheader from "../../Components/dashboard-header/Dashboardheader";
import TableHeading from "../../Components/table-heading/table-heading.component";
import ActivityHistoryComponent from "../../Components/activity-history/activity-history.component";
import ExportComponent from "../../Components/notification/ExportComponent";
import { useState } from "react";
import useActivityHistoryData from "../../hooks/useActivityHistoryData";
import useSelect from "../../hooks/useSelect";
import { AxiosGet } from "../../axios/axios";

const ActivityHistory = ({nots}) => {
  const [display, setDisplay] = useState(false);
  const { activityHistoryData } = useActivityHistoryData();
  const { select, setSelect } = useSelect();
  const [isLoading, setIsLoading] = useState(null);
  const openExport = () => {
    setDisplay(true);
  };
  const url = `activity-history/?orderBy=${activityHistoryData?.orderBy}&dateFilter=${activityHistoryData?.dateValue}&startDate=${activityHistoryData?.startDate}&endDate=${activityHistoryData?.endDate}&export=true&exportType=${select.toUpperCase()}`;

  function downloadExcelFromBase64(base64String, fileName) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/vnd.ms-excel" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  const handlePdfDownload = (base64String, fileName) => {
    // Convert Base64 to Blob
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Create URL for the Blob
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // Cleanup
    URL.revokeObjectURL(url);
  };

  const exportHistory = () => {
    if (select === "excel") {
      setIsLoading(true);
      AxiosGet(url)
        .then((res) => {
          // console.log(res);
          downloadExcelFromBase64(
            res.data.download.file,
            res.data.download.fileName
          );
          setIsLoading(false);
          setDisplay(false);
          setSelect("");
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
    }
    if (select === "pdf") {
      setIsLoading(true);
      AxiosGet(url)
        .then((res) => {
          // console.log(res);
          handlePdfDownload(
            res.data.download.file,
            res.data.download.fileName
          );
          setIsLoading(false);
          setDisplay(false);
          setSelect("");
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
    }
  };

  return (
    <section>
      {!nots && <Dashboardheader
        headerText="Activity History"
        addStyle="flex"
        addBtnText="export"
        addBtnIcon={<ExportCurve />}
        addSubmitForm={openExport}
      />}
      <ExportComponent
        display={display}
        setDisplay={setDisplay}
        submitForm={exportHistory}
        isLoading={isLoading}
      />
      <TableHeading dropdown={true} activityDrop={true} />
      <ActivityHistoryComponent />
    </section>
  );
};

export default ActivityHistory;
