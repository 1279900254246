import React, { useState } from "react";
import { ArrowLeft } from "iconsax-react";
import { Link, useNavigate } from "react-router-dom";
import { AuthHead } from "../../Components/AuthHead/AuthHead";
import useActiveClick from "../../hooks/useActiveClick";
import useAuth from "../../hooks/useAuth";
import axios from "axios";

const Onboarding = () => {
  const { activeClick, setActiveClick } = useActiveClick();
  const [isLoading, setIsLoading] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { auth } = useAuth();
  // const windowWidth = window.innerWidth;

  const handleSelect = (e) => {
    e.preventDefault();
    if (e.target.id === "Individual") {
      setActiveClick("Individual");
    }
    if (e.target.id === "Organization") {
      setActiveClick("Organization");
    }
  };
  const postUrl = "auth/account-setup/";
  const navigate = useNavigate();
  const dataObject = {
    accountType: "Individual",
  };
  const token = !auth ? "" : auth.authToken;
  const authConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const handleSubmit = () => {
    if (activeClick === "Individual") {
      setIsLoading(true);
      axios
        .post(`${baseUrl}${postUrl}`, dataObject, authConfig)
        .then((res) => {
          // console.log(res);
          setIsLoading(false);
          // if (windowWidth < 1000) {
          //   navigate("/mobile-redirect");
          // }
          // if (windowWidth > 1000) {
            localStorage.setItem("stk-apk", JSON.stringify(res.data.data));
              // setTimeout(function () {
              //   window.location.reload();
              // }, 100);
            navigate("/overview");
          // }
          setActiveClick();
        })
        .catch((err) => {
          // console.log(err.response);
          setIsLoading(false);
        });
    }
  };

  return (
    <div>
      <AuthHead
        headerText="What do you plan to achieve by using Stock Keeper?"
        pText="To customize your experience, kindly provide us with some
            information regarding your needs."
      />
      <div className="onboarding-card_cont">
        <div
          onClick={handleSelect}
          id="Individual"
          className={
            activeClick === ""
              ? "onboarding-card"
              : activeClick === "Individual"
              ? " onboarding-card selected-border"
              : "onboarding-card selected-opacity"
          }
        >
          <h4 className={activeClick === "Individual" ? "selected-text" : ""}>
            Individual
          </h4>
          <h5>
            I need organize and categorize the various items in my household.
          </h5>
        </div>
        <div
          onClick={handleSelect}
          id="Organization"
          className={
            activeClick === ""
              ? "onboarding-card"
              : activeClick === "Organization"
              ? "onboarding-card selected-border"
              : "onboarding-card selected-opacity"
          }
        >
          <h4 className={activeClick === "Organization" ? "selected-text" : ""}>
            Organization
          </h4>
          <h5>I need to monitor and keep tabs on items within my workplace.</h5>
        </div>
        <div className="onboarding-button">
          <Link
            to={
              activeClick === "Organization"
                ? "/signupcommercialsetup"
                : activeClick === "Individual"
                ? ""
                : ""
            }
            style={{ textDecoration: "none" }}
          >
            <button
              className={activeClick === "" ? "selected-opacity btn" : "btn"}
              onClick={handleSubmit}
              style={{ cursor: isLoading ? "progress" : "pointer" }}
            >
              Continue
            </button>
          </Link>
          <button
            onClick={() => setActiveClick("")}
            className={activeClick === "" ? "display-none" : "btn-trans"}
          >
            <ArrowLeft />
            Go back
          </button>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
