
import { useState } from "react";
import SubHeading from "../../help-support/sub-heading";
import ToggleBar from "../../toggle-component/ToggleBar";

const EmailPreference = () => {
    const [toggle, setToggle] = useState(true);
    return (
        <section className="email-container">
            <SubHeading text={"Email Preferences"} />
            <section className="flex-items">
                <div>
                    <p className="small-heading"> Alert Notifications </p>
                    <p className="text"> Email alerts will be sent to the email address associated with your account </p>
                </div>
                <ToggleBar toggleState={toggle} setToggleState={setToggle} />
            </section>
        </section>
    );
}
 
export default EmailPreference;