import React, { useEffect, useState } from "react";
import Dashboardheader from "../../Components/dashboard-header/Dashboardheader";
import nothing from "../../assets/dashboard/nothing.png";
import { AxiosGet } from "../../axios/axios";
import Empty from "../../Components/empty/empty.component";
import Loading from "../../Components/Loading";

const Referrals = () => {
  const url = "referral/";
  const [isLoading, setIsLoading] = useState(false);
  const [referData, setReferData] = useState();

  const getRefer = () => {
    setIsLoading(true);
    AxiosGet(url)
      .then((res) => {
        // console.log(res);
        setReferData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err.response);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getRefer();
    // eslint-disable-next-line
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <div>
      <Dashboardheader
        headerText={"Referral"}
        // addStyle="flex"
        // addBtnText="Refer Friends"
        // addBtnIcon={<Share />}
        // addSubmitForm={}
      />
      {!referData && <Empty img={nothing} title={"Nothing to show here"} />}
      {referData && (
        <div
          className="unverified-referrals"
          style={{ display: "flex", flexDirection: "column", gap: "40px" }}
        >
          <div className="ref-table">
            <h2 style={{ paddingBottom: "10px" }}>Referrals</h2>
            <div className="ref-tab_cont">
              <div className="ref-tab_content">
                <section className="report-table_head">
                  <p> Business Name </p>
                  <p> Individual Name </p>
                  <p> Phone Number </p>
                </section>
                {referData?.unverifiedReferrals?.length === 0 && (
                  <p
                    style={{
                      paddingTop: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    There are no referral records to display, refer a business.
                  </p>
                )}
                {referData?.unverifiedReferrals?.map((item, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <section className="report-table_body">
                        <p>{item.referredOrganization}</p>
                        <p>{item.referredName} </p>
                        <p>{item.referredNumber}</p>
                      </section>
                      <div
                        style={{
                          border: "1px solid #D1D1D1",
                        }}
                      ></div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="ref-table">
            <h2 style={{ paddingBottom: "10px" }}>Verified Referrals</h2>
            <div className="ref-tab_cont">
              <div className="ref-tab_content"> 
              <section className="report-table_head">
              <p> Business Name </p>
              <p> Individual Name </p>
              <p> Phone Number </p>
            </section>
            {referData?.verifiedReferrals?.length === 0 && (
              <p
                style={{
                  paddingTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                There are no referral records to display, refer a business.
              </p>
            )}
            {referData?.verifiedReferrals?.map((item, idx) => {
              return (
                <React.Fragment key={idx}>
                  <section className="report-table_body">
                    <p>{item.referredOrganization}</p>
                    <p>{item.referredName} </p>
                    <p>{item.referredNumber}</p>
                  </section>
                  <div
                    style={{
                      border: "1px solid #D1D1D1",
                    }}
                  ></div>
                </React.Fragment>
              );
            })}
              </div>
            </div>
            
          </div>
        </div>
      )}
    </div>
  );
};

export default Referrals;
