import React, { useState } from "react";
import "./tooltip-input.css";
import { InputForm } from "../AuthHead/AuthHead";
import info from "../../assets/dashboard/info.png";
import Tooltip from "./Tooltip";

const ToolTipInput = ({
  formLabel,
  placeholder,
  tooltipText,
  tipWidth,
  dateIcon,
  formId,
  dataChange,
  errorMessage,
  inputStyle,
  formValue,
  isReadonly
}) => {
  const [showTip, setShowTip] = useState(false);
  const clickAlert = () => {
    if (!showTip) {
      setShowTip(true);
    } else {
      setShowTip(false);
    }
  };
  return (
    <div className="alert-threshold">
      <InputForm
        formLabel={formLabel}
        placeholder={placeholder}
        formId={formId}
        dataChange={dataChange}
        errorMessage={errorMessage}
        inputStyle={inputStyle}
        formValue={formValue}
        isReadonly={isReadonly}
      />

      {dateIcon ? (
        <div className="alert-threshold_icon" onClick={clickAlert}>
          {dateIcon}
        </div>
      ) : (
        <img src={info} alt="info" onClick={clickAlert} />
      )}
      <Tooltip
        tooltipText={tooltipText}
        tipWidth={tipWidth}
        showTip={!showTip ? "" : "flex"}
        showTipRight="0"
        showTipBottom="-60px"
      />
    </div>
  );
};

export default ToolTipInput;
