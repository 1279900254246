import React from "react";
import "./tooltip.css";
// import arrow from "../../assets/dashboard/Arrow.png"

const Tooltip = ({tooltipText, showTip, tipWidth, showTipBottom, showTipLeft, showTipRight, showTipTop, tooltipTop}) => {
  return (
    <div className="tool-tip display-none" style={{width: tipWidth, display: showTip, left: showTipLeft, right: showTipRight, top: showTipTop, bottom: showTipBottom}}>
      {/* <div className={`tool-tip-arrow`} style={{top: tooltipTop}}>
        <img src={arrow} alt="up" />
      </div> */}
      <p>{tooltipText}</p>
    </div>
  );
};

export default Tooltip;
