import React, { useEffect, useState } from "react";
import {
  AuthHead,
  FormButton,
  InputForm,
  SelectInput,
} from "../../Components/AuthHead/AuthHead";
import Back from "../../Components/Back";
import useActiveClick from "../../hooks/useActiveClick";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import axios from "axios";

const CommercialSetup = () => {
  const [optionsArray, setOptionsArray] = useState([]);
  const { activeClick, setActiveClick } = useActiveClick();
  const url = "lookup/business-industry/";
  const postUrl = "auth/account-setup/";
  const baseUrl = process.env.REACT_APP_BASE_URL;
  // const windowWidth = window.innerWidth;
  const { auth } = useAuth();
  const token = !auth ? "" : auth.authToken;
  const authConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    axios
      .get(`${baseUrl}${url}`, authConfig)
      .then((res) => {
        // console.log(res);
        setOptionsArray(res.data.data);
      })
      .catch((err) => {
        // console.log(err.response);
      });
    // eslint-disable-next-line
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    business_name: "",
    business_address: "",
  });
  const [selectValue, setSelectValue] = useState("");
  const handleDataChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    // console.log(newData);
    if (e.target.tagName === "SELECT") {
      setSelectValue(e.target.value);
      // console.log(e.target.value);
    }
  };
  const navigate = useNavigate();
  const dataObject = {
    accountType: activeClick,
    name: data.business_name,
    industryId: selectValue,
    address: data.business_address,
  };
  // console.log(activeClick);
  const handleSubmit = () => {
    if (data.business_name && data.business_address && selectValue) {
      setIsLoading(true);
      axios
        .post(`${baseUrl}${postUrl}`, dataObject, authConfig)
        .then((res) => {
          // console.log(res.data.data);
          setIsLoading(false);
          // if (windowWidth < 1000  ){
          //   navigate("/mobile-redirect");
          // }
          // if (windowWidth > 1000  ){
            localStorage.setItem("stk-apk", JSON.stringify(res.data.data));
            // setTimeout(function() {
            //   window.location.reload();
            // }, 100);
            navigate("/overview");
          // }
          setActiveClick();
        })
        .catch((err) => {
          // console.log(err.response);
          setIsLoading(false);
        });
    }
  };
  return (
    <div>
      <Back />
      <AuthHead
        headerText="Provide us with a brief overview of your business."
        pText="To customize your experience, kindly provide us with some information regarding your needs."
      />
      <form method="post">
        <InputForm
          formLabel="Organization Name"
          placeholder="Enter Name"
          formId="business_name"
          dataChange={handleDataChange}
          formValue={data.business_name}
        />
        <SelectInput
          formLabel="Organization Industry"
          dataChange={handleDataChange}
          formValue={selectValue}
          optionLabel="Industry"
          optionsArray={optionsArray}
        />
        <InputForm
          formLabel="Organization Address"
          placeholder="Enter Address"
          formId="business_address"
          dataChange={handleDataChange}
          formValue={data.business_address}
        />
        <FormButton
          submitForm={handleSubmit}
          isLoading={isLoading}
          dataValues={
            data.business_name && data.business_address && selectValue
          }
          buttonText="continue"
        />
      </form>
    </div>
  );
};

export default CommercialSetup;
