import { useState } from "react";

import { flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import { rankItem } from "@tanstack/match-sorter-utils";

import "./table.styles.css";

const Table = ({ data, columns, click, minWidth }) => {
    
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    
    const fuzzyFilter = (row, columnId, value, addMeta) => {
        // Rank the item
        const itemRank = rankItem(row.getValue(columnId), value);
    
        // Store the itemRank info
        addMeta({
        itemRank,
        });
    
        // Return if the item should be filtered in/out
        return itemRank.passed;
  };

    
    const table = useReactTable({
        data,
        columns,
        filterFns: {
        fuzzy: fuzzyFilter,
        },
        state: {
        globalFilter,
        columnFilters,
        },
        onGlobalFilterChange: setGlobalFilter,
        onColumnFiltersChange: setColumnFilters,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
    });

    return (
        <section className="table-container">
            <table className="" style={{minWidth: minWidth}}>
                <thead className="">
                    { table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id} className="table-head-row">
                            { headerGroup.headers.map((header) => (
                                <th key={header.id} className="">
                                    {
                                        flexRender(header.column.columnDef.header,header.getContext())
                                    }
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                { click ? (
                    <tbody>
                        { table.getRowModel().rows.map((row) =>
                            (
                                <tr 
                                    key={row.id}
                                    onClick={(event) => click(event, row.original)}
                                >
                                    { row.getVisibleCells().map((cell) => (
                                        <td key={cell.id} className=""> 
                                            { flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            )
                        )}
                    </tbody>

                ) : (
                    <tbody>
                        { table.getRowModel().rows.map((row) =>
                            (
                                <tr key={row.id}>
                                    { row.getVisibleCells().map((cell) => (
                                        <td key={cell.id} className=""> 
                                            { flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            )
                        )}
                    </tbody>
                )

                }
            </table>
        </section>
    );
}
 
export default Table;