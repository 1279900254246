import { useState } from "react";

import { createColumnHelper } from "@tanstack/react-table";

import Table from "../../table/table.component";
import Empty from "../../empty/empty.component";
import SalesDetailsModal from "../../modals/sales-details-modal.component";

import empty_icon from "../../../assets/dashboard/empty-icon.svg";

import { useDispatch, useSelector } from "react-redux";
import {
  getSales,
  setSingleSale,
  setSingleSaleId,
} from "../../../states/sales/sales";

import OnlyDate from "../../excerpts/OnlyDate";
import OnlyTime from "../../excerpts/OnlyTime";
import Pagination from "../../pagination/pagination.component";
import Loading from "../../Loading";
import { AxiosGet } from "../../../axios/axios";
import FailedMsg from "../../alert/failed-msg.component";
import { setFailureModal } from "../../../states/general/general";
import AddSaleModal from "./AddSaleModal";

const SalesHistoryTable = () => {
  const dispatch = useDispatch();
  const { sales } = useSelector((state) => state.sales);
  const userPermissions = useSelector((state) => state.userPermissions);
  const [display, setDisplay] = useState(false);
  const [itemDict, setItemDict] = useState([]);

  // eslint-disable-next-line
  const [data, setData] = useState(
    sales?.result && sales?.result?.salesList !== undefined
      ? sales?.result?.salesList.map((sale) => ({ ...sale, checked: "" }))
      : []
  );

  const [showModal, setShowModal] = useState(false);

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => <p className=""> {info.getValue()} </p>,
      header: () => <span>Item</span>,
    }),
    columnHelper.accessor("unitPrice", {
      cell: (info) => <p className=""> {info.getValue()} </p>,
      header: () => <span> Unit Price(N) </span>,
    }),
    columnHelper.accessor("quantity", {
      cell: (info) => <p> {info.getValue()} </p>,
      header: () => <span> QTY </span>,
    }),
    columnHelper.accessor("subTotal", {
      cell: (info) => <p> {info.getValue()} </p>,
      header: () => <span> Sub Total(N) </span>,
    }),
    columnHelper.accessor("total", {
      cell: (info) => <p> {info.getValue()} </p>,
      header: () => <span> Total Amount(N) </span>,
    }),
    columnHelper.accessor("customerName", {
      cell: (info) => <p> {info.getValue()} </p>,
      header: () => <span> Name </span>,
    }),
    columnHelper.accessor("customerPhoneNumber", {
      cell: (info) => <p> {info.getValue()} </p>,
      header: () => <span> Phone</span>,
    }),
    columnHelper.accessor("createdAt", {
      cell: (info) => (
        <>
          <p className=""> {<OnlyDate date={info.getValue()} />} </p>
          <p className="time"> {<OnlyTime date={info.getValue()} />} </p>
        </>
      ),
      header: () => <span> Date/Time </span>,
    }),
  ];

  const handleClick = (event, data) => {
    const target = event.target;
    if (
      target.parentNode.classList.contains("checkbox-container") ||
      target.classList.contains("checkbox-container") ||
      target.parentNode.classList.contains("edit-trash-container") ||
      target.classList.contains("edit-trash-container")
    ) {
      return;
    } else {
      dispatch(setSingleSale(data.id));
      setShowModal(true);
      AxiosGet(`sales/${data.id}/`)
        .then((res) => {
          // console.log(res);
          dispatch(setSingleSaleId(res.data));
        })
        .catch((err) => {
          // console.log(err.response);
        });
    }
  };
  // console.log(singleSale);

  const openSalesModal = () => {
    if (userPermissions.addSales) {
      setDisplay(true);
      setItemDict([])
    } else {
      dispatch(setFailureModal(true));
    }
  }

  const handleUpdate = (item) => {
    dispatch(getSales(`?page=${item}&pageSize=10`));
  };

  return (
    <>
      <FailedMsg
        msg={"You do not have permissions to perform this action!!!"}
      />
      {sales.status === "loading" ? (
        <Loading />
      ) : (
        <>
          {data && data.length > 0 && (
            <Table data={data} columns={columns} click={handleClick} minWidth={"100%"} />
          )}
          {data && data.length === 0 && (
            <Empty
              submitClick={openSalesModal}
              title={"You don't have any sales"}
              btnText={"Add Sale"}
              img={empty_icon}
            />
          )}
          <SalesDetailsModal
            showModal={showModal}
            setShowModal={setShowModal}
          />
        </>
      )}
      {data && data.length > 0 && (
        <Pagination
          currentPage={sales?.result?.pagination?.currentPage}
          numberOfPages={sales?.result?.pagination?.numberOfPages}
          handleUpdate={handleUpdate}
        />
      )}
      <AddSaleModal display={display} setDisplay={setDisplay} itemDict={itemDict} setItemDict={setItemDict} />
    </>
  );
};

export default SalesHistoryTable;
