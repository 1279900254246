import { ArrowLeft, CloseCircle } from "iconsax-react";

import CustomSmallCheckBox from "../../inputs/custom-small-checkbox/custom-small-checkbox.component";
import Dashboardbtn from "../../dashboard-buttons/Dashboardbtn";

import "./modals.styles.css";
import { useEffect, useState } from "react";
import { InputForm, PasswordInput, SelectInput } from "../../AuthHead/AuthHead";
import { AxiosAuthPost, AxiosAuthPut, AxiosGet } from "../../../axios/axios";
import FailedMsg from "../../alert/failed-msg.component";
import { setFailureModal } from "../../../states/general/general";
import { useDispatch } from "react-redux";

const AddTeamMember = ({ modal, setModal }) => {
  const dispatch = useDispatch();
  const [failedMsg, setFailedMsg] = useState("");
  const url = "roles/";
  const teamUrl = "team-members/";
  const memberId = localStorage.getItem("memberId");
  const isOpen = localStorage.getItem("openMem");
  const isEdit = localStorage.getItem("editMem");
  const singleMemberUrl = `team-members/${memberId}/`;
  const [roleData, setRoleData] = useState([]);
  const [singleMember, setSingleMember] = useState();
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passError, setPassError] = useState("");
  const [roleError, setRoleError] = useState("");
  const modalHandler = () => {
    setModal(false);
    localStorage.removeItem("memberId");
    setSingleMember();
    setCheckedValues([]);
    localStorage.removeItem("openMem");
    localStorage.removeItem("editMem");
    setData({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      role: "",
    });
    setEmailError("");
    setFirstNameError("");
    setLastNameError("");
    setRoleError("");
  };

  useEffect(() => {
    localStorage.removeItem("memberId");
    setSingleMember();
    setCheckedValues([]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    AxiosGet(url)
      .then((res) => {
        // console.log(res.data);
        setRoleData(res.data.rolesList);
      })
      .catch((err) => {
        // console.log(err.response);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (memberId){
      AxiosGet(singleMemberUrl)
        .then((res) => {
          // console.log(res);
          setSingleMember(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err.response);
        });
    }
    // eslint-disable-next-line
  }, [memberId]);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    role: "",
  });
  const [checkedValues, setCheckedValues] = useState([]);
  const permit = {
    addSales: checkedValues.includes("Add Sales") ? true : false,
    viewSales: checkedValues.includes("View Sales") ? true : false,
    viewReceipt: checkedValues.includes("View Receipt") ? true : false,
    addItem: checkedValues.includes("Add") ? true : false,
    editItem: checkedValues.includes("Edit") ? true : false,
    deleteItem: checkedValues.includes("Delete") ? true : false,
    deleteTrash: checkedValues.includes("Delete Trash") ? true : false,
    giveDiscount: checkedValues.includes("Give Discount") ? true : false,
    addTeamMember: checkedValues.includes("Add Team Member") ? true : false,
    createRole: checkedValues.includes("Add Role") ? true : false,
    createReport: checkedValues.includes("Create Report") ? true : false,
    viewReport: checkedValues.includes("View Report") ? true : false,
  };
  const handleCheckChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      // Add the checked value to the array
      setCheckedValues((prevValues) => [...prevValues, value]);
    } else {
      // Remove the unchecked value from the array
      setCheckedValues((prevValues) =>
        prevValues.filter((item) => item !== value)
      );
    }
  };
  const handleDataChange = (e) => {
    if (e.target.id === "firstName") {
      setFirstNameError("");
    }
    if (e.target.id === "lastName") {
      setLastNameError("");
    }
    if (e.target.id === "email") {
      setEmailError("");
    }
    if (e.target.id === "password") {
      setPassError("");
    }
    if (e.target.id === "role") {
      setRoleError("");
    }
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    // console.log(newData);
  };
  const dataObject = {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    password: data.password,
    roleId: data.role,
    permissions: permit,
  };
  const editObject = {
    accountStatus: ["Active"],
    firstName: data.firstName ? data.firstName : singleMember?.firstName,
    lastName: data.lastName ? data.lastName : singleMember?.lastName,
    email: data.email ? data.email : singleMember?.email,
    roleId: data.role  ? data.role : singleMember?.role?.id,
    permissions: permit,
  };
  const permissions = !isOpen ? [
    {
      heading: "Sales",
      content: [
        {
          text: "Add Sales",
          id: 1,
        },
        {
          text: "View Sales",
          id: 2,
        },
      ],
    },
    {
      heading: "Receipt",
      content: [
        {
          text: "View Receipt",
          id: 3,
        },
      ],
    },
    {
      heading: "Business & Items",
      content: [
        {
          text: "Add",
          id: 4,
        },
        {
          text: "Edit",
          id: 5,
        },
        {
          text: "Delete",
          id: 6,
        },
      ],
    },
    {
      heading: "Trash",
      content: [
        {
          text: "Delete Trash",
          id: 7,
        },
      ],
    },
    {
      heading: "Discount",
      content: [
        {
          text: "Give Discount",
          id: 9,
        },
      ],
    },
    {
      heading: "Team",
      content: [
        {
          text: "Add Team Member",
          id: 12,
        },
        {
          text: "Add Role",
          id: 13,
        },
      ],
    },
    {
      heading: "Report & Analytics",
      content: [
        {
          text: "Create Report",
          id: 10,
        },
        {
          text: "View Report",
          id: 11,
        },
      ],
    },
  ] :  [
    {
      heading: "Sales",
      content: [
        {
          text: "Add Sales",
          id: 1,
          check: !singleMember
            ? false
            : singleMember?.permissions?.addSales
            ? true
            : false,
        },
        {
          text: "View Sales",
          id: 2,
          check: !singleMember
            ? false
            : singleMember?.permissions?.viewSales
            ? true
            : false,
        },
      ],
    },
    {
      heading: "Receipt",
      content: [
        {
          text: "View Receipt",
          id: 3,
          check: !singleMember
            ? false
            : singleMember?.permissions?.viewReceipt
            ? true
            : false,
        },
      ],
    },
    {
      heading: "Business & Items",
      content: [
        {
          text: "Add",
          id: 4,
          check: !singleMember
            ? false
            : singleMember?.permissions?.addItem
            ? true
            : false,
        },
        {
          text: "Edit",
          id: 5,
          check: !singleMember
            ? false
            : singleMember?.permissions?.editItem
            ? true
            : false,
        },
        {
          text: "Delete",
          id: 6,
          check: !singleMember
            ? false
            : singleMember?.permissions?.deleteItem
            ? true
            : false,
        },
      ],
    },
    {
      heading: "Trash",
      content: [
        {
          text: "Delete Trash",
          id: 7,
          check: !singleMember
            ? false
            : singleMember?.permissions?.deleteTrash
            ? true
            : false,
        },
      ],
    },
    {
      heading: "Discount",
      content: [
        {
          text: "Give Discount",
          id: 9,
          check: !singleMember
            ? false
            : singleMember?.permissions?.giveDiscount
            ? true
            : false,
        },
      ],
    },
    {
      heading: "Team",
      content: [
        {
          text: "Add Team Member",
          id: 12,
          check: !singleMember
            ? false
            : singleMember?.permissions?.addTeamMember
            ? true
            : false,
        },
        {
          text: "Add Role",
          id: 13,
          check: !singleMember
            ? false
            : singleMember?.permissions?.createRole
            ? true
            : false,
        },
      ],
    },
    {
      heading: "Report & Analytics",
      content: [
        {
          text: "Create Report",
          id: 10,
          check: !singleMember
            ? false
            : singleMember?.permissions?.createReport
            ? true
            : false,
        },
        {
          text: "View Report",
          id: 11,
          check: !singleMember
            ? false
            : singleMember?.permissions?.viewReport
            ? true
            : false,
        },
      ],
    },
  ];

  const editMember = () => {
    setIsLoading(true);
    // console.log(editObject);
    AxiosAuthPut(singleMemberUrl, editObject)
      .then((res) => {
        // console.log(res);
        setIsLoading(false);
        if (res.success === true) {
          setModal(false);
          setData({
            firstName: "",
            lastName: "",
            email: "",
            role: "",
          });
          setCheckedValues([]);
          localStorage.removeItem("editMem");
        }
      })
      .catch((err) => {
        // console.log(err.response);
        setIsLoading(false);
        if (err.response.status === 403){
          setFailedMsg(err.response.data.message);
          dispatch(setFailureModal(true));
        } else
        if (
          err?.message !== "Request failed with status code 500" &&
          err?.message !== "Network Error"
        ) {
          for (let i = 0; i < err?.response?.data?.errors?.length; i++) {
            if (err?.response?.data?.errors[i]?.fieldName === "firstName") {
              setFirstNameError(err?.response?.data?.errors[i]?.error);
            }
            if (err?.response?.data?.errors[i]?.fieldName === "lastName") {
              setLastNameError(err?.response?.data?.errors[i]?.error);
            }
            if (err?.response?.data?.errors[i]?.fieldName === "email") {
              setEmailError(err?.response?.data?.errors[i]?.error);
            }
            if (err?.response?.data?.errors[i]?.fieldName === "roleId") {
              setRoleError(err?.response?.data?.errors[i]?.error);
            }
          }
        }
      });
  };
  
  const submitMember = () => {
    setIsLoading(true);
    // console.log(dataObject);
    AxiosAuthPost(teamUrl, dataObject)
      .then((res) => {
        // console.log(res);
        setIsLoading(false);
        if (res.success === true) {
          setModal(false);
          setData({
            firstName: "",
            lastName: "",
            email: "",
            role: "",
          });
          setCheckedValues([]);
        }
      })
      .catch((err) => {
        // console.log(err.response);
        setIsLoading(false);
        if (err.response.status === 403){
          setFailedMsg(err.response.data.message);
          dispatch(setFailureModal(true));
        } else
        if (
          err.message !== "Request failed with status code 500" &&
          err.message !== "Network Error"
        ) {
          for (let i = 0; i < err.response.data.errors.length; i++) {
            if (err?.response?.data?.errors[i]?.fieldName === "firstName") {
              setFirstNameError(err?.response?.data?.errors[i]?.error);
            }
            if (err?.response?.data?.errors[i]?.fieldName === "lastName") {
              setLastNameError(err?.response?.data?.errors[i]?.error);
            }
            if (err.response.data.errors[i].fieldName === "email") {
              setEmailError(err.response.data.errors[i].error);
            }
            if (err.response.data.errors[i].fieldName === "roleId") {
              setRoleError(err.response.data.errors[i].error);
            }
          }
        }
      });
  };

  return (
    <>
      <div
        className={`overlay ${modal ? "w-full" : "w-0"}`}
        onClick={modalHandler}
      ></div>
      {modal && <FailedMsg msg={failedMsg} />}
      <section
        className={`add-team-member-modal ${modal ? "opacity-1" : "opacity-0"}`}
      >
        <section className="add-team-member-modal-heading">
          <h3>{isEdit ? "Edit Team Member" : " Add Team Member "}</h3>
          <CloseCircle
            size="32"
            className="close"
            color=" #111111"
            onClick={modalHandler}
          />
        </section>

        <section className="add-team_content">
          <section className="inputs-hold">
            <InputForm
              formLabel={"First Name"}
              placeholder={!singleMember ? "Enter First Name" : singleMember?.firstName}
              formId="firstName"
              formValue={isOpen ? singleMember?.firstName : data?.firstName}
              dataChange={handleDataChange}
              errorMessage={firstNameError}
              isReadonly={isOpen ? true : ""}
            />
            <InputForm
              formLabel={"Last Name"}
              placeholder={!singleMember ? "Enter Last Name" : singleMember?.lastName}
              formId="lastName"
              formValue={isOpen ? singleMember?.lastName : data?.lastName}
              dataChange={handleDataChange}
              errorMessage={lastNameError}
              isReadonly={isOpen ? true : ""}
            />
            <InputForm
              formLabel={"Email Address"}
              placeholder={
                !singleMember ? "Enter Email Address" : singleMember?.email
              }
              formId="email"
              formValue={isOpen ? singleMember?.email : data?.email}
              dataChange={handleDataChange}
              errorMessage={emailError}
              isReadonly={isOpen ? true : ""}
            />
            <SelectInput
              formLabel={"Role"}
              optionLabel={!singleMember ? "Assign Role" : singleMember?.role?.name}
              optionsArray={isOpen ? [] : roleData}
              formId="role"
              formValue={isOpen ? singleMember?.role : data?.role}
              dataChange={handleDataChange}
              errorMessage={roleError}
            />
            {!isEdit && !isOpen && <PasswordInput
              formLabel="Password"
              placeholder="Enter your password"
              dataChange={handleDataChange}
              formId="password"
              formValue={data.password}
              errorMessage={passError}
            />}
          </section>
          <section className="permissions-container">
            <h4> Permissions </h4>
            {permissions.map(({ heading, content }, id) => (
              <section key={id}>
                <span> {heading} </span>
                {content.map(({ text, id, check }) => (
                  <section key={id} className="option">
                    <p> {text} </p>
                    {isOpen ? (
                      <CustomSmallCheckBox
                        id={id}
                        checkValue={text}
                        isChecked={check}
                        dataChange={handleCheckChange}
                      />
                    ) : (
                      <CustomSmallCheckBox
                        id={id}
                        checkValue={text}
                        dataChange={handleCheckChange}
                      />
                    )}
                  </section>
                ))}
              </section>
            ))}
          </section>
        </section>
        <section className="add-team_btn-cont">
          <Dashboardbtn
            btnClass={"dashboard-variant"}
            btnText="back"
            btnIcon={<ArrowLeft />}
            btnWidth="164px"
            submitForm={modalHandler}
          />
          <Dashboardbtn
            btnClass={isOpen ? "low-opacity dashboard-btn" : "dashboard-btn"}
            btnText={isEdit ? "save" : "invite"}
            btnWidth="164px"
            submitForm={isOpen ? undefined : isEdit ? editMember : submitMember}
            isLoading={isLoading}
          />
        </section>
      </section>
    </>
  );
};

export default AddTeamMember;
