import { Add, CloseCircle } from "iconsax-react";
import Dashboardheader from "../../Components/dashboard-header/Dashboardheader";
import Quantitybar from "../../Components/quantity-bar/Quantitybar";
import { useEffect, useState } from "react";

import "./items-page.css";
import { InputForm } from "../../Components/AuthHead/AuthHead";
import UploadImg from "../../Components/upload-img/UploadImg";
import AddItemsPopup from "../../Components/add-items-popup/AddItemsPopup";
import AddNew from "../../Components/add&new/Add&New";
import FolderFilter from "../../Components/filter-popups/FolderFilter";
import { AxiosAuthPost, AxiosDelete, AxiosGet } from "../../axios/axios";
import Empty from "../../Components/empty/empty.component";
import emptyIcon from "../../assets/dashboard/empty-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { newItem } from "../../states/items/itemSlice";
import DeletePopup from "../../Components/filter-popups/DeletePopup";
import useAddContent from "../../hooks/useAddContent";
import SuccessPopup from "../../Components/filter-popups/SuccessPopup";
import useCoverPicture from "../../hooks/useCoverPicture";
import MultipleSelect from "../../Components/AuthHead/MultipleSelect";
import { useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading";
import useCoverImage from "../../hooks/useCoverImage";
import useActivityDetails from "../../hooks/useActivityDetails";
import FailedMsg from "../../Components/alert/failed-msg.component";
import { setFailureModal } from "../../states/general/general";
// import Pagination from "../../Components/pagination/pagination.component";

const Items = () => {
  const [selectedSort, setSelectedSort] = useState("");
  const [selectedOrder, setSelectedOrder] = useState("");
  const url = `items/?sortBy=${selectedSort}&orderBy=${selectedOrder}`;
  const groupUrl = `group-items/?sortBy=${selectedSort}&orderBy=${selectedOrder}`;
  const standaloneUrl = `items/stand-alone/?sortBy=${selectedSort}&orderBy=${selectedOrder}`;
  const compositeUrl = `composite-items/?sortBy=${selectedSort}&orderBy=${selectedOrder}`;
  const itId = localStorage.getItem("itemId");
  const deleteUrl = `items/${itId}/`;
  const historyUrl = `items/${itId}/history`;
  // const cloneUrl = `items/${itId}/clone`;
  const navigate = useNavigate();
  // const groupId = localStorage.getItem("grpId");
  const itemType = localStorage.getItem("itemType");
  const deleteGrp = `group-items/${itId}/`;
  const deleteComposite = `composite-items/${itId}/`;
  const deleteAllUrl = `items/delete-multiple/`;
  const [quantityData, setQuantityData] = useState();
  const multiId = localStorage.getItem("multiDelete");
  const [display, setDisplay] = useState(false);
  const [applyFilter, setApplyFilter] = useState(false);
  const [displayDelete, setDisplayDelete] = useState(false);
  const dispatch = useDispatch();
  const myItems = useSelector((state) => state.itemsArray);
  const userPermissions = useSelector((state) => state.userPermissions);
  const myItemsId = useSelector((state) => state.itemId);
  const itemDropdown = useSelector((state) => state.itemsDrop);
  const [isDLoading, setIsDLoading] = useState(false);
  const [itemsFilterArray, setItemsFilterArray] = useState(myItems);
  const [targetContent, setTargetContent] = useState("All items");
  const [standaloneItems, setStandaloneItems] = useState();
  const [compositeItems, setCompositeItems] = useState();
  const [displayGroup, setDisplayGroup] = useState(false);
  const [displayFilter, setDisplayFilter] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(null);
  const [addOne, setAddOne] = useState(false);
  const [selected, setSelected] = useState([]);
  const [groupItems, setGroupItems] = useState([]);
  const [grpName, setGrpName] = useState("");
  const [subFail, setSubFail] = useState("");
  const [grpErrorName, setGrpErrorName] = useState("");
  const { addContent } = useAddContent();
  const [grpErrorList, setGrpErrorList] = useState("");
  const { coverPicture, setCoverPicture } = useCoverPicture();
  const { setCoverImage } = useCoverImage();
  const { setActivityDetails } = useActivityDetails();
  const selectedValue = selected.map((item) => {
    return item.id;
  });
  const dataObject = {
    itemIds: myItemsId,
  };
  const groupData = {
    name: grpName,
    itemIds: selectedValue,
    picture: coverPicture,
  };
  useEffect(() => {
    setTargetContent("All items");
    AxiosGet(url)
      .then((res) => {
        // console.log(res.data);
        dispatch(newItem(res.data.itemsList));
        setItemsFilterArray(res.data.itemsList);
        setQuantityData(res.data.totalDetails);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err.response);
      });
    // eslint-disable-next-line
  }, [addOne, displayDelete, display, displaySuccess, applyFilter]);

  useEffect(() => {
    AxiosGet(standaloneUrl)
      .then((res) => {
        // console.log(res.data);
        setStandaloneItems(res.data);
      })
      .catch((err) => {
        // console.log(err.response);
      });
    // eslint-disable-next-line
  }, [display, displayDelete, applyFilter]);

  useEffect(() => {
    AxiosGet(compositeUrl)
      .then((res) => {
        // console.log(res.data);
        setCompositeItems(res.data);
      })
      .catch((err) => {
        // console.log(err.response);
      });
    // eslint-disable-next-line
  }, [display, displayDelete, applyFilter]);

  useEffect(() => {
    AxiosGet(groupUrl)
      .then((res) => {
        // console.log(res.data);
        setGroupItems(res.data);
      })
      .catch((err) => {
        // console.log(err.response);
      });
    // eslint-disable-next-line
  }, [displayGroup, displayDelete, applyFilter]);

  const deleteItem = () => {
    setIsDLoading(true);
    if (!multiId) {
      AxiosDelete(
        itemType === "Grouped"
          ? deleteGrp
          : itemType === "Composite"
          ? deleteComposite
          : deleteUrl
      )
        .then((res) => {
          // console.log(res);
          if (res.success === true) {
            setIsDLoading(false);
            setDisplayDelete(false);
            setDisplaySuccess(true);
            setSuccess("You have successfully deleted selected entities");
            setAddOne(true);
            // navigate("/items");
          }
        })
        .catch((err) => {
          // console.log(err.response);
        if (err.response.status === 403){
          setSubFail(err.response.data.message);
          dispatch(setFailureModal(true));
        }
          setIsDLoading(false);
        });
    }
    if (multiId) {
      // console.log(dataObject);
      AxiosAuthPost(deleteAllUrl, dataObject)
        .then((res) => {
          // console.log(res);
          if (res.success === true) {
            setIsDLoading(false);
            localStorage.removeItem("multiDelete");
            setDisplayDelete(false);
            setDisplaySuccess(true);
            setSuccess("You have successfully deleted selected entities");
            setAddOne(true);
            // navigate("/items");
          }
        })
        .catch((err) => {
          // console.log(err.response);
        if (err.response.status === 403){
          setSubFail(err.response.data.message);
          dispatch(setFailureModal(true));
        }
          setIsDLoading(false);
        });
    }
  };
  // console.log(selected);
  const addGroup = () => {
    setIsLoading(true);
    AxiosAuthPost(groupUrl, groupData)
      .then((res) => {
        setIsLoading(false);
        // console.log(res);
        if (addContent === "Add") {
          setDisplayGroup(false);
          setAddOne(true);
          setGrpName("");
          setSelected([]);
          setCoverImage("");
          setCoverPicture("");
        }
        if (addContent === "Add & New") {
          setGrpName("");
          setSelected([]);
          setCoverImage("");
          setCoverPicture("");
          setDisplaySuccess(true);
          setAddOne(true);
          setSuccess(res.message + ". You can add another Item");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err.response);
        if (
          err.message !== "Request failed with status code 500" &&
          err.message !== "Network Error"
        ) {
          for (let i = 0; i < err.response.data.errors.length; i++) {
            if (err.response.data.errors[i].fieldName === "name") {
              setGrpErrorName(err.response.data.errors[i].error);
            }
            if (err.response.data.errors[i].fieldName === "itemIds") {
              setGrpErrorList(err.response.data.errors[i].error);
            }
          }
        }
      });
  };
  const closeGroupModal = () => {
    setDisplayGroup(false);
    setGrpName("");
    setSelected([]);
    setCoverImage("");
  }
  const handleChange = (e) => {
    setGrpName(e.target.value);
    // console.log(grpName);
  };
  // const cloneItem = () => {
  //   AxiosAuthPost(cloneUrl)
  //     .then((res) => {
  //       // console.log(res);
  //       if (res.success === true) {
  //         setDisplaySuccess(true);
  //         setSuccess(res.message);
  //       }
  //     })
  //     .catch((err) => {
  //       // console.log(err.response);
  //     });
  // };

  const itemHistory = () => {
    AxiosGet(historyUrl)
      .then((res) => {
        // console.log(res);
        navigate("/activityhistory");
        setActivityDetails(res.data);
      })
      .catch((err) => {
        // console.log(err.response);
      });
  };

  const quantityArray = !quantityData
    ? [
        {
          value: "Quantity",
          quantity: "0",
        },
      ]
    : targetContent === "All items"
    ? [
        {
          value: "Quantity",
          quantity: Number(quantityData.totalQuantity).toLocaleString(),
        },
        {
          value: "Total Value",
          quantity:
            "₦" + Number(quantityData.totalValue).toLocaleString() + ".00",
        },
      ]
    : targetContent === "Grouped items"
    ? [
        {
          value: "Quantity",
          quantity: Number(
            groupItems.totalDetails.totalQuantity
          ).toLocaleString(),
        },
        {
          value: "Total Value",
          quantity:
            "₦" +
            Number(groupItems.totalDetails.totalValue).toLocaleString() +
            ".00",
        },
      ]
    : targetContent === "Standalone items"
    ? [
        {
          value: "Quantity",
          quantity: Number(
            standaloneItems.totalDetails.totalQuantity
          ).toLocaleString(),
        },
        {
          value: "Total Value",
          quantity:
            "₦" +
            Number(standaloneItems.totalDetails.totalValue).toLocaleString() +
            ".00",
        },
      ]
    : targetContent === "Composite items"
    ? [
        {
          value: "Quantity",
          quantity: Number(
            compositeItems.totalDetails.totalQuantity
          ).toLocaleString(),
        },
        {
          value: "Total Value",
          quantity:
            "₦" +
            Number(compositeItems.totalDetails.totalValue).toLocaleString() +
            ".00",
        },
      ]
    : [];
  const itemsArray = myItems;
  // eslint-disable-next-line
  const itemName = itemsArray.map((item) => {
    if (item.id === itId) {
      return item.name;
    }
  });
  const headerType = (e) => {
    if (e.target.textContent === "All items") {
      setItemsFilterArray(itemsArray);
      setTargetContent("All items");
      setDisplayGroup(false);
      setDisplay(false);
    }
    if (e.target.textContent === "Grouped items") {
      setItemsFilterArray(
        itemsArray.filter((item) => item.itemType === "Grouped")
      );
      setTargetContent("Grouped items");
      setDisplay(false);
      setDisplayGroup(false);
    }
    if (e.target.textContent === "Composite items") {
      setItemsFilterArray(
        itemsArray.filter((item) => item.itemType === "Composite")
      );
      setTargetContent("Composite items");
      setDisplay(false);
      setDisplayGroup(false);
    }
    if (e.target.textContent === "Standalone items") {
      setItemsFilterArray(
        itemsArray.filter((item) => item.itemType === "Stand Alone")
      );
      setTargetContent("Standalone items");
      setDisplayGroup(false);
      setDisplay(false);
    }
  };
  const popDelete = () => {
    if (userPermissions.deleteItem){
      setDisplayDelete(!displayDelete);
    } else{
      dispatch(setFailureModal(true));
    }
  };
  const filterSubmit = () => {
    setApplyFilter(!applyFilter);
    setDisplayFilter(false);
  };
  const cancelDelete = () => {
    setDisplayDelete(!displayDelete);
    localStorage.removeItem("multiDelete");
  };
  const showItemAdd = () => {
    if (userPermissions.addItem) {
      setDisplay(!display);
    } else{
      dispatch(setFailureModal(true));
    }
  };
  const showItemGroup = () => {
    if (userPermissions.addItem) {
      setDisplayGroup(!displayGroup);
    } else{
      dispatch(setFailureModal(true));
    }
  };
  const showComposite = () => {
    if (userPermissions.addItem) {
      localStorage.removeItem("compositeId");
      navigate("/items/add-new-composite");
    } else{
      dispatch(setFailureModal(true));
    }
  };
  const openFilter = () => {
    setDisplayFilter(!display);
  };

  return isLoading === null ? (
    <Loading />
  ) : (
    <section>
      <SuccessPopup
        display={displaySuccess}
        setDisplay={setDisplaySuccess}
        popupMessage={success}
      />
      <FailedMsg msg={subFail ? subFail : "You do not have permissions to perform this action!!!"} />
        <div className="items-page_header">
        <Dashboardheader
          headerTextVariationStyle="flex"
          filterStyle="flex"
          addStyle="flex"
          addBtnText="add"
          addBtnIcon={<Add />}
          headerVariation={(e) => headerType(e)}
          headerVText={targetContent}
          setHeaderVText={setTargetContent}
          addSubmitForm={
            targetContent === "All items" ||
            targetContent === "Standalone items"
              ? showItemAdd
              : targetContent === "Grouped items"
              ? showItemGroup
              : targetContent === "Composite items"
              ? showComposite
              : undefined
          }
          addLink={
            targetContent === "Composite items"
              ? "/items/add-new-composite"
              : ""
          }
          filterSubmitForm={openFilter}
        />
        <FolderFilter
          display={displayFilter}
          setDisplay={setDisplayFilter}
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          selectedSort={selectedSort}
          setSelectedSort={setSelectedSort}
          applyFilter={filterSubmit}
        />
        <AddItemsPopup
          display={display}
          setDisplay={setDisplay}
          setAddOne={setAddOne}
        />
        <div className={`overlay ${displayGroup ? "w-full" : "w-0"}`}> </div>
        <div className={!displayGroup ? "display-none" : "grouped-popup"}>
          <div className="grouped-popup_header">
            <h3>Add New Group</h3>
            <CloseCircle
              style={{ cursor: "pointer" }}
              onClick={closeGroupModal}
            />
          </div>
          <div className="grouped-item_form">
            <InputForm
              formLabel="Name"
              placeholder="Enter Group Name"
              dataChange={handleChange}
              formValue={grpName}
              errorMessage={grpErrorName}
            />
            <MultipleSelect
              formLabel="Choose Items"
              optionsArray={itemDropdown}
              selected={selected}
              setSelected={setSelected}
              selectPlaceholder="Select Options..."
              errorMessage={grpErrorList}
              dropHeight="200px"
            />
            <UploadImg style={{ margin: "20px 0" }} />
            <div className="grouped-items_btn">
              <AddNew
                btnWidth="120px"
                isLoading={isLoading}
                submitForm={addGroup}
              />
            </div>
          </div>
        </div>
      </div>
      <DeletePopup
        display={displayDelete}
        deleteItem={deleteItem}
        popupName={!multiId ? itemName : myItemsId.length + " selected"}
        isLoading={isDLoading}
        cancelDelete={cancelDelete}
        setDispay={setDisplayDelete}
      />
      {itemsFilterArray.length === 0 ? (
        <Empty
          img={emptyIcon}
          title={
            targetContent === "All items"
              ? "You don’t have any Item"
              : targetContent === "Composite items"
              ? "You don’t have any Composite Item"
              : targetContent === "Grouped items"
              ? "You don’t have any Grouped Item"
              : targetContent === "Standalone items"
              ? "You don’t have any Standalone Item"
              : ""
          }
          btnText={
            targetContent === "All items"
              ? "Add new Item"
              : targetContent === "Composite items"
              ? "Add new Composite Item"
              : targetContent === "Grouped items"
              ? "Add new Grouped Item"
              : targetContent === "Standalone items"
              ? "Add new Standalone Item"
              : ""
          }
          btnLink={
            targetContent === "All items" && userPermissions.addItem 
              ? "/items/add-new"
              : targetContent === "Composite items"
              ? "/items/add-new-composite"
              : targetContent === "Standalone items" && userPermissions.addItem 
              ? "/items/add-new"
              : ""
          }
          submitClick={
            targetContent === "Grouped items"
              ? showItemGroup
              : undefined
          }
          // emptyStyle={{ marginTop: "20px" }}
        />
      ) : (
        <Quantitybar
          quantityDisplay="flex"
          quantityArray={quantityArray}
          // editDisplay=""
          itemsArray={itemsFilterArray}
          itemDisplay="block"
          quantityBarDisplay="block"
          checkboxDisplay={itemsArray.length === 0 ? "none" : "block"}
          deleteItem={popDelete}
          clickHistoryItem={itemHistory}
          // clickCloneItem={cloneItem}
        />
      )}
      {/* <Pagination /> */}
    </section>
  );
};

export default Items;
