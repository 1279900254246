import React, { useState } from "react";
import "./filter-popup.css";
import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import { CheckBox, InputForm } from "../AuthHead/AuthHead";
import Dashboardbtn from "../dashboard-buttons/Dashboardbtn";

const AdvancedFilter = ({
  display,
  setDisplay,
  handleStock,
  handleSortby,
  dataChange,
  minPrice,
  maxPrice,
  submitForm,
  isLoading,
  reset,
}) => {
  const [displayStock, setDisplayStock] = useState(true);
  const [displaySort, setDisplaySort] = useState(true);
  const [displayRole, setDisplayRole] = useState(true);
  const [displayItem, setDisplayItem] = useState(true);
  const [checkState, setCheckState] = useState(new Set());
  const [selectedCheck, setSelectedCheck] = useState("");
  const resetFilter = () => {
    if (reset) {
      reset();
    }
    setSelectedCheck("");
    setCheckState(new Set());
  };
  const handleStockChange = (e) => {
    if (handleStock) {
      handleStock(e);
    }
    const updatedCheckboxes = new Set(checkState);
    if (updatedCheckboxes.has(e.target.value)) {
      updatedCheckboxes.delete(e.target.value);
    } else {
      updatedCheckboxes.add(e.target.value);
    }

    setCheckState(updatedCheckboxes);
  };
  const handleSortbyChange = (e) => {
    if (handleSortby) {
      handleSortby(e);
    }
    setSelectedCheck(e.target.value);
  };
  const openMore = (e) => {
    if (e.target.id === "stock-level") {
      setDisplayStock(!displayStock);
    }
    if (e.target.id === "sort-by") {
      setDisplaySort(!displaySort);
    }
    if (e.target.id === "team-role") {
      setDisplayRole(!displayRole);
    }
    if (e.target.id === "item-price") {
      setDisplayItem(!displayItem);
    }
  };
  const stockLevel = ["In Stock", "Low Stock", "Out of Stock"];
  const sortbyArray = [
    "Newest",
    "Oldest",
    "Highest Cost",
    "Lowest Cost",
    "Highest Quantity",
    "Lowest Quantity",
  ];
  // const roleArray = ["Admin", "Manager", "Staff"];
  return (
    <div>
      <div className={`overlay ${display ? "w-full" : "w-0"}`} onClick={()=> setDisplay(false)}> </div>
      <div className={!display ? "display-none" : "filter-pop_up"}>
        <div className="filter-pop_up-header">
          <h2>
            Filters <span onClick={resetFilter}>Reset</span>
          </h2>
        </div>
        <div className="filter-body">
          <div className="stock-level">
            <h3
              id="stock-level"
              onClick={openMore}
              style={{ cursor: "pointer" }}
            >
              Stock Level{" "}
              {!displayStock ? (
                <ArrowDown2 onClick={openMore} />
              ) : (
                <ArrowUp2 onClick={openMore} />
              )}
            </h3>
          </div>
          {stockLevel &&
            stockLevel.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className={
                    !displayStock ? "display-none" : "stock-level_options"
                  }
                >
                  <div className="filter-para">
                    {item}{" "}
                    <CheckBox
                      id={item}
                      checkValue={item}
                      checked={checkState.has(item)}
                      checkboxChange={handleStockChange}
                    />
                  </div>
                </div>
              );
            })}

          <div className="stock-level">
            <h3 id="sort-by" onClick={openMore} style={{ cursor: "pointer" }}>
              Sort by{" "}
              {!displaySort ? (
                <ArrowDown2 onClick={openMore} />
              ) : (
                <ArrowUp2 onClick={openMore} />
              )}
            </h3>
          </div>
          {sortbyArray &&
            sortbyArray.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className={
                    !displaySort ? "display-none" : "stock-level_options"
                  }
                >
                  <div className="filter-para">
                    {item}{" "}
                    <CheckBox
                      id={item}
                      checkValue={item}
                      checked={selectedCheck === item}
                      checkboxChange={handleSortbyChange}
                    />
                  </div>
                </div>
              );
            })}

          <div className="stock-level">
            <h3
              id="item-price"
              onClick={openMore}
              style={{ cursor: "pointer" }}
            >
              Item Price{" "}
              {!displayItem ? (
                <ArrowDown2 onClick={openMore} />
              ) : (
                <ArrowUp2 onClick={openMore} />
              )}
            </h3>
          </div>
          <div className={!displayItem ? "display-none" : "item-price"}>
            <InputForm
              formLabel="From"
              placeholder="Min"
              formId={"minPrice"}
              dataChange={dataChange}
              formValue={minPrice}
            />
            <InputForm
              formLabel="To"
              placeholder="Max"
              formId={"maxPrice"}
              dataChange={dataChange}
              formValue={maxPrice}
            />
          </div>

          {/* <div className="stock-level">
            <h3 id="team-role" onClick={openMore} style={{ cursor: "pointer" }}>
              Team Role{" "}
              {!displayRole ? (
                <ArrowDown2 onClick={openMore} />
              ) : (
                <ArrowUp2 onClick={openMore} />
              )}
            </h3>
          </div>
          {roleArray &&
            roleArray.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className={
                    !displayRole ? "display-none" : "stock-level_options"
                  }
                >
                  <p>
                    {item} <CheckBox />
                  </p>
                </div>
              );
            })} */}
          <div className="filter-buttons">
            <Dashboardbtn
              btnClass="dashboard-variant"
              btnText="cancel"
              btnWidth="180px"
              submitForm={() => setDisplay(false)}
            />
            <Dashboardbtn
              btnClass="dashboard-btn"
              btnText="Apply"
              btnWidth="180px"
              submitForm={submitForm}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvancedFilter;
