import React, { useState } from "react";
import { InputForm } from "./AuthHead";
import "./AuthHead.css";

const SearchSelect = ({
  formLabel,
  placeholder,
  dataChange,
  searching,
  setSearching,
  optionsArray,
  optionClick,
  formValue
}) => {
  const [isSelect, setIsSelect] = useState("");
  const handleOptionClick = (item) => {
      setIsSelect(item.id);
      setSearching(false);
      if (optionClick){
        optionClick(item);
      }
  };
  return (
    <div>
      <InputForm
        formLabel={formLabel}
        placeholder={placeholder}
        dataChange={dataChange}
        formValue={formValue}
      />
      <div className={searching ? "dropdown-plate" : undefined}>
        {searching && optionsArray?.length === 0 && <p>nothing to show here</p>}
        {searching &&
          optionsArray.map((item) => {
            return (
              <p
                className={isSelect === item.id ? "selected-item" : undefined}
                onClick={() => handleOptionClick(item)}
                key={item.id}
                id={item.id}
                style={{cursor: "pointer"}}
              >
                {item.name}
              </p>
            );
          })}
      </div>
    </div>
  );
};

export default SearchSelect;
