import React, { useState } from "react";

import "./overview.styles.css";
import { Information } from "iconsax-react";
import { FormButton } from "../AuthHead/AuthHead";
import PriceCard from "../settings/billing/PriceCard";

const FreeTrial = ({ subscription, subPlan }) => {
  const btnStyle = {
    padding: "4px 7px",
    background: "red",
    opacity: "1",
    cursor: "pointer",
  };

  const [display, setDisplay] = useState(false);

  const changePlan = () => {
    setDisplay(true);
  };

  return (
    <>
      <PriceCard
        display={display}
        setDisplay={setDisplay}
        currentPlan={subPlan}
      />
      {subscription === 0 && (
        <div className="expired-sub">
          <p>Subscription Expired</p>
          <FormButton
            buttonText={"Subscribe"}
            btnStyle={btnStyle}
            submitForm={changePlan}
            dataValues={true}
          />
        </div>
      )}
      {subscription > 0 && (
        <div className="info-cont">
          <div className="info-content">
            <Information />
            <h4>{subscription} Days remaining on </h4>
            <p>{subPlan}</p>
            {subPlan === "Free" && (
              <FormButton
                buttonText={"Subscribe"}
                btnStyle={btnStyle}
                submitForm={changePlan}
                dataValues={true}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default FreeTrial;
