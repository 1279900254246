import { createSlice } from "@reduxjs/toolkit";


export const itemSlice = createSlice({
    name: "itemsArray",
    initialState: [],
    reducers: {
        newItem: (state,action) => {
            const item = action.payload
            return (item);
        }
    }
})

export const {newItem} = itemSlice.actions;

export default itemSlice.reducer;