import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AxiosGet } from "../../axios/axios";
import Loading from "../../Components/Loading";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "../../Components/table/table.component";
import Pagination from "../../Components/pagination/pagination.component";
import OnlyDate from "../../Components/excerpts/OnlyDate";
import OnlyTime from "../../Components/excerpts/OnlyTime";
import Dashboardheader from "../../Components/dashboard-header/Dashboardheader";
import Goback from "../../Components/Dashboard-goback/Goback";

const UserDetail = () => {
  const { user_id } = useParams();
  const [user, setUser] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [subs, setSubs] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const url = `super-admin/users/${user_id}/?page=${currentPage}&pageSize=${itemsPerPage}`;

  useEffect(() => {
    AxiosGet(url)
      .then((res) => {
        // console.log(res.data);
        setSubs(res.data.subscriptions);
        setPagination(res.pagination);
        setUser(res.data.userData);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.error(err);
        setIsLoading(false);
      });
  }, [url]);

  const columnHelper = createColumnHelper();
  const userColumns = [
    columnHelper.accessor("firstName", {
      cell: (info) => <p>{info.getValue() || "NIL"}</p>,
      header: () => <span>First Name</span>,
    }),
    columnHelper.accessor("lastName", {
      cell: (info) => <p>{info.getValue() || "NIL"}</p>,
      header: () => <span>Last Name</span>,
    }),
    columnHelper.accessor("phoneNumber", {
      cell: (info) => <p>{info.getValue() || "NIL"}</p>,
      header: () => <span>Phone Number</span>,
    }),
    columnHelper.accessor("organizationName", {
      cell: (info) => <p>{info.getValue() || "NIL"}</p>,
      header: () => <span>Organization Name</span>,
    }),
    columnHelper.accessor("email", {
      cell: (info) => <p>{info.getValue() || "NIL"}</p>,
      header: () => <span>Email</span>,
    }),
    columnHelper.accessor("accountType", {
      cell: (info) => <p>{info.getValue() || "NIL"}</p>,
      header: () => <span>Account Type</span>,
    }),
    columnHelper.accessor("accountStatus", {
      cell: (info) => <p className={info.getValue() === "Active" ? "active-cell" : "inactive-cell"}>{info.getValue() || "NIL"}</p>,
      header: () => <span>Account Status</span>,
    }),
    columnHelper.accessor("role", {
      cell: (info) => <p>{info.getValue() || "NIL"}</p>,
      header: () => <span>Role</span>,
    }),
    columnHelper.accessor("createdAt", {
      cell: (info) => (
        <>
          <p>{<OnlyDate date={info.getValue() || "NIL"} />}</p>
          <p>{<OnlyTime date={info.getValue() || "NIL"} />}</p>
        </>
      ),
      header: () => <span>Date/Time</span>,
    }),
  ];

  const subscriptionColumns = [
    columnHelper.accessor("amount", {
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>Amount Paid</span>,
    }),
    columnHelper.accessor("period", {
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>Period</span>,
    }),
    columnHelper.accessor("itemLimit", {
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>Item Limit</span>,
    }),
    columnHelper.accessor("type", {
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>Type</span>,
    }),
    columnHelper.accessor("status", {
      cell: (info) => <p className={info.getValue() === "Active" ? "active-cell" : "inactive-cell"}>{info.getValue()}</p>,
      header: () => <span>Status</span>,
    }),
    columnHelper.accessor("staffLimit", {
      cell: (info) => <p>{info.getValue()}</p>,
      header: () => <span>Staff Limit</span>,
    }),
    columnHelper.accessor("createdAt", {
      cell: (info) => (
        <>
          <p>{<OnlyDate date={info.getValue() || "NIL"} />}</p>
          <p>{<OnlyTime date={info.getValue() || "NIL"} />}</p>
        </>
      ),
      header: () => <span>Created At</span>,
    }),
    columnHelper.accessor("expireAt", {
      cell: (info) => (
        <>
          <p>{<OnlyDate date={info.getValue() || "NIL"} />}</p>
          <p>{<OnlyTime date={info.getValue() || "NIL"} />}</p>
        </>
      ),
      header: () => <span>Expire At</span>,
    }),
  ];

  const handlePrevious = () => {
    if (pagination.previousPage !== null) {
      setCurrentPage(pagination.previousPage);
    }
  };

  const handleNext = () => {
    if (pagination.nextPage !== null) {
      setCurrentPage(pagination.nextPage);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!user) {
    return <div>User not found</div>;
  }

  return (
    <div>
      <Goback />
      <Dashboardheader headerText="User Details" />
      <section className="user-details-container">
        <Table data={[user]} columns={userColumns} width={"100%"} />
        <Dashboardheader headerText="Subcription History" />
        <Table
          data={subs}
          columns={subscriptionColumns}
          width={"100%"}
          title="Subscription History"
        />
        <Pagination
          currentPage={pagination.currentPage}
          numberOfPages={pagination.numberOfPages}
          handleUpdate={setCurrentPage}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
        />
      </section>
    </div>
  );
};

export default UserDetail;
