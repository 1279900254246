import NotificationsEmpty from "./empty-notifications.component";
import { useEffect, useState } from "react";
import notificationsCircle from "../../assets/dashboard/notifications-circle.svg";
import { AxiosGet } from "../../axios/axios";
import "./notifications-component.styles.css";
import Loading from "../Loading";
import useActivityHistoryData from "../../hooks/useActivityHistoryData";

const InventoryComponent = () => {
  const {activityHistoryData} = useActivityHistoryData();
  const url = `notifications/inventory/?dateFilter=${activityHistoryData.dateValue}&startDate=${activityHistoryData.startDate}&endDate=${activityHistoryData.endDate}`;
  const [isLoading, setIsLoading] = useState(null);
  const [stockData, setStockData] = useState([]);
  useEffect(() => {
    AxiosGet(url)
      .then((res) => {
        // console.log(res);
        setStockData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err.response);
      });
    // eslint-disable-next-line
  }, []);


  return (
    isLoading === null ? <Loading imgStyle={{marginTop: "150px"}} /> :
    <>
      {stockData.length === 0 ? <NotificationsEmpty /> :  stockData.map((content, idx) => (
        <section key={idx} className="inventory-component">
          <section className="inventory-circle-hold">
            <img src={notificationsCircle} alt="inventory-circle" />
          </section>
          <section>
            <h3 className={content.itemStatus === "Low Stock" ? "low-stock" : "out-of-stock" }> {content.itemName} is {content.itemStatus === "Low Stock" ? " on" : "" } <span style={{marginLeft: "5px"}}> {content.itemStatus}</span>  </h3>
            <p> The {content.itemName} item is {content.itemStatus === "Low Stock" ? " low on Stock" : "out of Stock" }. {content.itemQuantity} units left. </p>
          </section>
        </section>
      ))}
    </>
  );
};
export default InventoryComponent;
