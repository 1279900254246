import { ArrowRight } from "iconsax-react";
import React from "react";
import Faq from "../../Components/faq/Faq";
import { Link } from "react-router-dom";

const Aboutus = () => {
  return (
    <div>
      <div className="about-us">
        <h2>
          About <span>us</span>{" "}
        </h2>
        <p>
          Welcome to Stock Keeper, a leading provider of innovative inventory
          management solutions. We are proud to be affiliated with LAH (Legacy
          Access Hub) Company, based in Akure, Ondo State, Nigeria, and
          together, we are dedicated to helping businesses streamline their
          inventory processes and maximize operational efficiency. <br /> <br /> At
          Stock Keeper, we understand the challenges businesses face when it
          comes to managing inventory effectively. Our mission is to empower
          organizations of all sizes to take control of their inventory, reduce
          costs, and optimize their supply chain. <br /> <br /> With our user-friendly
          web app and mobile app, businesses can effortlessly track stock
          levels, monitor inventory movements, and gain real-time insights into
          their inventory performance. Our advanced features, including demand
          forecasting and warehouse optimization, enable businesses to make
          data-driven decisions, ensuring that the right products are available
          at the right time. <br /> <br /> As an affiliated company of Legacy Access
          Hub in Akure, we bring together expertise, resources, and a shared
          commitment to delivering top-notch inventory management solutions. Our
          collaboration allows us to leverage the industry knowledge and
          experience of LAH Company, ensuring that our customers receive
          cutting-edge technology and exceptional service. <br /> <br /> We are
          passionate about helping businesses thrive by providing them with the
          tools they need to succeed in today's competitive market. Our
          dedicated team of professionals is always available to support our
          customers, answer inquiries, and provide guidance every step of the
          way. <br /> <br /> Thank you for choosing Stock Keeper. We look forward to
          serving you and being your trusted partner in inventory management.
        </p>
        <div className="about-us_button">
          <Link to="/signupemail" style={{ textDecoration: "none" }}>
            <button className="btn">Get Started</button>
          </Link>
          <Link to="/pricing" style={{ textDecoration: "none" }}>
            <button className="btn-trans" style={{ color: "#f08227" }}>
              view plans <ArrowRight />
            </button>
          </Link>
        </div>
      </div>
      <Faq />
    </div>
  );
};

export default Aboutus;
