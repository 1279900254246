import React from "react";
import "./checkbox.css";

const Dcheckbox = ({ checkboxChange, checkId, checked, containerBg, checkTickBg, checkboxDisplay, checkValue }) => {
  const handleCheckboxChange = (e) => {
    if (checkboxChange) {
      checkboxChange(e);
    }
  };
  return (
    <div style={{display: checkboxDisplay}}>
      <label className="checkbox-container" style={{background: containerBg}}>
        <input
          className="checkbox-input"
          type="checkbox"
          checked={checked}
          id={checkId}
          onChange={handleCheckboxChange}
          value={checkValue}
        />
        <span className="checktick" style={{background: checkTickBg}}></span>
      </label>
    </div>
  );
};

export default Dcheckbox;
