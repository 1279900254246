import arrow_down from "../../assets/dashboard/arrow-down.svg";
import profile_img from "../../assets/dashboard/profile_img.png";

import "./profile.styles.css";
import ProfileModal from "./profile-modal.component";
import { useState } from "react";
import { useSelector } from "react-redux";

const Profile = () => {
    const [modal, setModal] = useState(false);
    const myProfile = useSelector((state) => state.profileDetails);
    // console.log(myProfile);
    const modalHandler = () => {
        setModal(status => !status);
    }

    return (
        <section className="profile">
            <section className="profile-text-arrow-hold" onClick={modalHandler}>
                {!myProfile.organizationDetails.name && !myProfile.firstName && !myProfile.lastName && ""}
                <p className="profile-text">{!myProfile.organizationDetails.name ?  myProfile.firstName + " " + myProfile.lastName : myProfile.organizationDetails.name }</p>
                <img src={arrow_down} alt="arrow_down" width={16} className="arrow_down" />
            </section>
            <section className="profile-img-hold">
                {!myProfile.picture ? <img src={profile_img} height={40} width={40} alt="profile-img" /> : <img src={myProfile.picture} height={40} width={40} alt="profile-img" />}
            </section>
            { modal && <ProfileModal modal={modal} setModal={setModal} /> } 
        </section>
    );
}
 
export default Profile;