import React, { useState } from "react";
import "./Pricingplan.css";
import "../../App.css";
import selected from "../../assets/landingpage/Selected.png";
import tick from "../../assets/landingpage/tick-circle.png";
import tick2 from "../../assets/landingpage/tick-circle2.png";
import { ArrowRight, MedalStar } from "iconsax-react";
import { Link } from "react-router-dom";

const Pricingplan = ({ advancedFeatures, basicFeatures, freeFeatures, displayStyle, displayPriority, soloFeatures }) => {
  const [monthly, setMonthly] = useState("active-toggle");
  const [annually, setAnnually] = useState("inactive-toggle");
  const [toggleClick, settoggleClick] = useState("year-toggle");

  const onToggleClick = () => {
    if (monthly === "active-toggle") {
      setAnnually("active-toggle");
      setMonthly("inactive-toggle");
      settoggleClick("month-toggle");
    }
    if (annually === "active-toggle") {
      setAnnually("inactive-toggle");
      setMonthly("active-toggle");
      settoggleClick("year-toggle");
    }
  };

  return (
    <div>
      <div className="pricing-plan">
        <h2>
          Simple and <span>Flexible Pricing Plans</span>{" "}
        </h2>
        <p>
          Choose the Perfect Plan for Your Inventory Management Needs - Flexible
          and Affordable Pricing Options to Fit Every Business Size.
        </p>
        <div className="pricing-plan_toggle">
          <h4 className={`${monthly}`}>Monthly</h4>
          <div onClick={onToggleClick} className="toggle-bg">
            <div className={`toggle-click ${toggleClick}`}></div>
          </div>
          <h4 className={`${annually}`}>Annually</h4>
        </div>

        <div className="pricing-cards">

          
        <div className="basic-pricing advanced-pricing">
            <h3>Free</h3>
            <h5>Popular</h5>
            <div className="pricing-line"></div>
            <div className="pricing-button basic-pricing_button">
              {toggleClick === "year-toggle" ? (
                <h4>
                  ₦0 <span>/ 14 days free trial</span>
                </h4>
              ) : (
                <h4>
                  ₦0 <span>/ 14 days free trial</span>
                </h4>
              )}
            </div>
            <div className="pricing-features basic-pricing_features">
              <h6>Features:</h6>
              {!freeFeatures
                ? ""
                : freeFeatures.map((item) => {
                    return (
                      <div
                        className="features-list basic-features_list"
                        key={item}
                      >
                        <h4>
                          <img
                            src={tick2}
                            alt="tick"
                            style={{ paddingRight: "20px" }}
                          />
                          {item}
                        </h4>
                      </div>
                    );
                  })}
            </div>
            <div className="pricing-line"></div>
            <Link to="/signupemail" style={{textDecoration: "none"}}><button className="btn-variant basic_pricing-getstarted">
              get started
            </button></Link>
            <Link to="/pricing" style={{ textDecoration: "none" }}>
              <button
                style={displayStyle}
                className="btn-trans basic_pricing-learnmore"
              >
                learn more <ArrowRight />
              </button>
            </Link>
          </div>

          <div className="basic-pricing advanced-pricing">
            <h3>Solo</h3>
            <h5>Popular</h5>
            <div className="pricing-line"></div>
            <div className="pricing-button basic-pricing_button">
              {toggleClick === "year-toggle" ? (
                <h4>
                  ₦2,000 <span>/ per month</span>
                </h4>
              ) : (
                <h4>
                  ₦20,000 <span>/ per year</span>
                </h4>
              )}
            </div>
            <div className="pricing-features basic-pricing_features">
              <h6>Features:</h6>
              {!soloFeatures
                ? ""
                : soloFeatures.map((item) => {
                    return (
                      <div
                        className="features-list basic-features_list"
                        key={item}
                      >
                        <h4>
                          <img
                            src={tick2}
                            alt="tick"
                            style={{ paddingRight: "20px" }}
                          />
                          {item}
                        </h4>
                      </div>
                    );
                  })}
            </div>
            <div className="pricing-line"></div>
            <Link to="/signupemail" style={{textDecoration: "none"}}><button className="btn-variant basic_pricing-getstarted">
              get started
            </button></Link>
            <Link to="/pricing" style={{ textDecoration: "none" }}>
              <button
                style={displayStyle}
                className="btn-trans basic_pricing-learnmore"
              >
                learn more <ArrowRight />
              </button>
            </Link>
          </div>

          
          <div className="advanced-pricing">
            <img
              className="advanced-pricing_img"
              src={selected}
              alt="selected"
            />
            <h3>Advanced</h3>
            <h5>Get the best out of inventory</h5>
            <div className="pricing-line"></div>
            <div className="pricing-button">
              {toggleClick === "year-toggle" ? (
                <h4>
                  ₦20,000 <span>/ per month</span>
                </h4>
              ) : (
                <h4>
                  ₦200,000 <span>/ per year</span>
                </h4>
              )}
            </div>
            <div className="pricing-features">
              <div className="priority-logo" style={displayPriority}>
                <MedalStar />
              </div>
              <h6>Features:</h6>
              {!advancedFeatures
                ? ""
                : advancedFeatures.map((item) => {
                    return (
                      <div className="features-list" key={item}>
                        <h4>
                          <img
                            src={tick}
                            alt="tick"
                            style={{ paddingRight: "20px" }}
                          />
                          {item}
                        </h4>
                      </div>
                    );
                  })}
            </div>
            <div className="pricing-line"></div>
            <Link to="/signupemail" style={{textDecoration: "none"}}><button className="btn-variant pricing-getstarted">
              get started
            </button></Link>
            <Link to="/pricing" style={{ textDecoration: "none" }}>
              <button
                style={displayStyle}
                className="btn-trans pricing-learnmore"
              >
                learn more <ArrowRight />
              </button>
            </Link>
          </div>
          
          <div className="basic-pricing advanced-pricing">
            <h3>Basic</h3>
            <h5>Popular</h5>
            <div className="pricing-line"></div>
            <div className="pricing-button basic-pricing_button">
              {toggleClick === "year-toggle" ? (
                <h4>
                  ₦5,000 <span>/ per month</span>
                </h4>
              ) : (
                <h4>
                  ₦50,000 <span>/ per year</span>
                </h4>
              )}
            </div>
            <div className="pricing-features basic-pricing_features">
              <h6>Features:</h6>
              {!basicFeatures
                ? ""
                : basicFeatures.map((item) => {
                    return (
                      <div
                        className="features-list basic-features_list"
                        key={item}
                      >
                        <h4>
                          <img
                            src={tick2}
                            alt="tick"
                            style={{ paddingRight: "20px" }}
                          />
                          {item}
                        </h4>
                      </div>
                    );
                  })}
            </div>
            <div className="pricing-line"></div>
            <Link to="/signupemail" style={{textDecoration: "none"}}><button className="btn-variant basic_pricing-getstarted">
              get started
            </button></Link>
            <Link to="/pricing" style={{ textDecoration: "none" }}>
              <button
                style={displayStyle}
                className="btn-trans basic_pricing-learnmore"
              >
                learn more <ArrowRight />
              </button>
            </Link>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Pricingplan;
