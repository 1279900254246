import { useState } from "react";
import { PasswordInput } from "../../AuthHead/AuthHead";
import Dashboardbtn from "../../dashboard-buttons/Dashboardbtn";
import SubHeading from "../../help-support/sub-heading";

import "./change-password.styles.css";
import { AxiosAuthPut } from "../../../axios/axios";
import SuccessPopup from "../../filter-popups/SuccessPopup";
import DiscardChanges from "../../discard-changes/DiscardChanges";

const ChangePassword = () => {
  const url = "change-password/";
  const [isLoading, setIsLoading] = useState(false);
  const [displayDiscard, setDisplayDiscard] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [errNewP, setErrNewP] = useState("");
  const [errConfirmP, setErrConfirmP] = useState("");
  const [data, setData] = useState({
    new_password: "",
    confirm_password: "",
  });
  const dataObject = {
    newPassword: data.new_password,
    confirmPassword: data.confirm_password,
  };
  const changePassword = () => {
    setIsLoading(true);
    AxiosAuthPut(url, dataObject)
      .then((res) => {
        // console.log(res);
        if (res.success === true) {
          setDisplaySuccess(true);
          setData({
            new_password: "",
            confirm_password: "",
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err.response);
        setIsLoading(false);
        if (
          err.message !== "Request failed with status code 500" &&
          err.message !== "Network Error"
        ) {
          for (let i = 0; i < err.response.data.errors.length; i++) {
            if (err.response.data.errors[i].fieldName === "newPassword") {
              setErrNewP(err.response.data.errors[i].error);
            }
            if (err.response.data.errors[i].fieldName === "confirmPassword") {
              setErrConfirmP(err.response.data.errors[i].error);
            }
          }
        }
      });
  };
  const handleDataChange = (e) => {
    if (e.target.id === "new_password") {
      setErrNewP("");
    }
    if (e.target.id === "confirm_password") {
      setErrConfirmP("");
    }
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    // console.log(newData);
  };
  const discardChanges = () => {
    setData({
        new_password: "",
        confirm_password: "",
    });
    setDisplayDiscard(!displayDiscard)
  }

  return (
    <section className="change-password-container">
      <SuccessPopup
        display={displaySuccess}
        setDisplay={setDisplaySuccess}
        popupMessage={"Password changed successfully"}
        cancelClick={() => setDisplaySuccess(false)}
      />
      <DiscardChanges displayCancel={displayDiscard} discardForm={discardChanges} cancelForm={()=> setDisplayDiscard(!displayDiscard)} />
      <SubHeading text={"Change Password"} />
      <section className="flex">
        <div className="input-form">
          <PasswordInput
            formLabel={"New Password"}
            formId="new_password"
            placeholder={"Enter New Password"}
            formValue={data.new_password}
            dataChange={handleDataChange}
            errorMessage={errNewP}
            autoComplete={"off"}
          />
        </div>
        <div className="input-form">
          <PasswordInput
            formLabel={"Confirm Password"}
            formId="confirm_password"
            placeholder={"Enter Confirm Password"}
            formValue={data.confirm_password}
            dataChange={handleDataChange}
            errorMessage={errConfirmP}
            autoComplete={"off"}
          />
        </div>
      </section>
      <section className="btn-hold">
        <Dashboardbtn
          btnClass={"dashboard-btn"}
          btnText={"Save Changes"}
          btnWidth="200px"
          isLoading={isLoading}
          submitForm={changePassword}
        />
        <Dashboardbtn
          btnClass={"dashboard-variant"}
          btnText={"Discard"}
          btnWidth="200px"
          submitForm={()=>setDisplayDiscard(!displayDiscard)}
        />
      </section>
    </section>
  );
};

export default ChangePassword;
