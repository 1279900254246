import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [activeClick, setActiveClick] = useState("");
  const [coverPicture, setCoverPicture] = useState("");
  const [addContent, setAddContent] = useState("Add");
  const [searchParam, setSearchParam] = useState("");
  const [active, setActive] = useState();
  const [coverImage, setCoverImage] = useState("");
  const [activityDetails, setActivityDetails] = useState([]);
  const [select, setSelect] = useState("");
  const [data, setData] = useState({
    name: "",
    cost_price: "",
    selling_price: "",
    quantity: "",
    picture: "",
    alert_threshold: "",
    notes: "",
    has_variants: "",
    variant_attributes: "",
    expiringDate: "",
    variant_options: "",
    business_id: "",
  });
  const [activityHistoryData, setActivityHistoryData] = useState({
    orderBy: "Latest",
    dateValue: "Today",
    startDate: "",
    endDate: "",
  });

  return (
    <AuthContext.Provider
      value={{
        activeClick,
        setActiveClick,
        auth,
        setAuth,
        coverPicture,
        setCoverPicture,
        addContent,
        setAddContent,
        data,
        setData,
        active,
        setActive,
        searchParam,
        setSearchParam,
        activityHistoryData,
        setActivityHistoryData,
        coverImage,
        setCoverImage,
        activityDetails,
        setActivityDetails,
        select,
        setSelect
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
