import React from "react";
import "./Footer.css";
import "../../App.css";
import googlePlay from "../../assets/landingpage/googlePlay.png";
// import appStore from "../../assets/landingpage/appStore.png";
import largeLogo from "../../assets/landingpage/largeLogo.png";
import Icon from "@mdi/react";
import { mdiFacebook, mdiInstagram, 
  // mdiTwitter
 } from "@mdi/js";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        <div className="footer-top">
          <h2>
            Mobile App
          </h2>
          <div className="footer-top_links">
          {/* <h3>
            Coming Soon...
          </h3> */}
            {/* <img src={appStore} alt="Apple Store" /> */}
            <a href="https://play.google.com/store/apps/details?id=ng.stockkeeper" target="_blank" rel="noopener noreferrer"><img src={googlePlay} alt="Play Store" /></a>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="footer-bottom_right">
            <Link to="/" style={{ textDecoration: "none" }}>
              <img src={largeLogo} alt="logo" />
            </Link>
            <p>
              Streamline, track, and optimize your inventory like never before.
              Say goodbye to manual spreadsheets and outdated systems today.
            </p>
            <div className="footer-bottom_right-icons">
              {/* <a href="https://" target="_blank" rel="noopener noreferrer">
                <Icon path={mdiTwitter} size={1} />
              </a> */}
              <a
                href="https://instagram.com/lahstockkeeper?igshid=NjIwNzIyMDk2Mg=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon path={mdiInstagram} size={1} />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100092611159426"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon path={mdiFacebook} size={1} />
              </a>
            </div>
          </div>
          <div className="footer-bottom_left">
            <div className="footer-bottom_left-links">
              <p>company</p>
              <ul>
                <Link to="/pricing" style={{ textDecoration: "none" }}>
                  <li>pricing Plans</li>
                </Link>
                <Link to="/services" style={{ textDecoration: "none" }}>
                  <li>services</li>
                </Link>
              </ul>
            </div>
            <div className="footer-bottom_left-links">
              <p>about us</p>
              <ul>
                <Link to="/terms&conditions" style={{ textDecoration: "none" }}>
                  <li>terms and conditions</li>
                </Link>
                <Link to="/privacypolicy" style={{ textDecoration: "none" }}>
                  <li>privacy policy</li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
