import React, { useEffect, useState } from "react";
import "./reports.css";
import Dashboardbtn from "../dashboard-buttons/Dashboardbtn";
import { CloseCircle, ExportCurve } from "iconsax-react";
import { AxiosGet } from "../../axios/axios";
import Notification from "../notification/Notification";
import useActivityHistoryData from "../../hooks/useActivityHistoryData";
import Empty from "../empty/empty.component";
import nothing from "../../assets/dashboard/nothing.png";
import { InputForm, SelectInput } from "../AuthHead/AuthHead";
import ExportComponent from "../notification/ExportComponent";
import useSelect from "../../hooks/useSelect";

const ReportsModal = ({
  display,
  setDisplay,
  lowInventoryDisplay,
  expiredItemsDisplay,
  totalInventoryDisplay,
  stockBalanceDisplay,
  headerText,
  staffDisplay,
}) => {
  const [isLoading, setIsLoading] = useState(null);
  const [displayExport, setDisplayExport] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [expiredData, setExpiredData] = useState([]);
  const { activityHistoryData } = useActivityHistoryData();
  const [stockData, setStockData] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [stockChange, setStockChange] = useState("");
  const { select, setSelect } = useSelect();
  const [searchBalance, setSearchBalance] = useState("");
  const [searchStaff, setSearchStaff] = useState("");
  const [stockBalanceData, setStockBalanceData] = useState([]);
  const [stockBalanceSum, setStockBalanceSum] = useState();
  const [staffData, setStaffData] = useState([]);
  const inventoryUrl = `reports/inventory/?dateFilter=${activityHistoryData.dateValue}&startDate=${activityHistoryData.startDate}&endDate=${activityHistoryData.endDate}&search=${searchParam}`;
  const exportInventory = `reports/inventory/?dateFilter=${
    activityHistoryData.dateValue
  }&startDate=${activityHistoryData.startDate}&endDate=${
    activityHistoryData.endDate
  }&search=${searchParam}&export=true&exportType=${select.toUpperCase()}`;
  const stockUrl = `reports/stock-level/?stockLevel=${stockChange}`;
  const exportStock = `reports/stock-level/?stockLevel=${stockChange}&export=true&exportType=${select.toUpperCase()}`;
  const stockBalanceUrl = `reports/stock-balance/?search=${searchBalance}`;
  const exportBalance = `reports/stock-balance/?export=true&search=${searchBalance}&exportType=${select.toUpperCase()}`;
  const staffUrl = `reports/staff-report/?search=${searchStaff}`;
  const expiredUrl = `reports/expiring-date/`;
  const exportExpired = `reports/expiring-date/?export=true&exportType=${select.toUpperCase()}`;
  const exportStaff = `reports/staff-report/?export=true&search=${searchStaff}&exportType=${select.toUpperCase()}`;
  const stockLevelArray = [
    { name: "Low stock", id: "Low Stock" },
    { name: "Out of stock", id: "Out of Stock" },
  ];
  const handleDataChange = (e) => {
    if (e.target.id === "searchParam") {
      setSearchParam(e.target.value);
    }
    if (e.target.id === "searchBalance") {
      setSearchBalance(e.target.value);
    }
    if (e.target.id === "searchStaff") {
      setSearchStaff(e.target.value);
    }
    // console.log(e.target.value);
  };
  const handleSelectChange = (e) => {
    setStockChange(e.target.value);
    // console.log(e.target.value);
  };
  const openExport = () => {
    setDisplayExport(true);
  };

  useEffect(() => {
    if (stockBalanceDisplay) {
      AxiosGet(stockBalanceUrl)
        .then((res) => {
          // console.log(res);
          setStockBalanceData(res.data.reportData);
          setStockBalanceSum(res.data.totalSum);
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err.response);
        });
    }
    // eslint-disable-next-line
  }, [headerText, searchBalance]);

  useEffect(() => {
    if (expiredItemsDisplay) {
      AxiosGet(expiredUrl)
        .then((res) => {
          // console.log(res);
          setExpiredData(res.data.reportData);
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err.response);
        });
    }
    // eslint-disable-next-line
  }, [headerText]);

  useEffect(() => {
    if (staffDisplay) {
      AxiosGet(staffUrl)
        .then((res) => {
          // console.log(res);
          setStaffData(res.data.reportData);
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err.response);
        });
    }
    // eslint-disable-next-line
  }, [headerText, searchStaff]);

  useEffect(() => {
    if (totalInventoryDisplay || lowInventoryDisplay) {
      AxiosGet(
        headerText === "Total Inventory Report"
          ? inventoryUrl
          : headerText === "Low Level Out of Stock Report"
          ? stockUrl
          : ""
      )
        .then((res) => {
          if (headerText === "Total Inventory Report") {
            // console.log(res);
            setInventoryData(res.data.reportData);
          }
          if (headerText === "Low Level Out of Stock Report") {
            setStockData(res.data.reportData);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err.response);
        });
    }
    // eslint-disable-next-line
  }, [headerText, activityHistoryData, searchParam, stockChange]);

  function downloadExcelFromBase64(base64String, fileName) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/vnd.ms-excel" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  const handlePdfDownload = (base64String, fileName) => {
    // Convert Base64 to Blob
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    // Create URL for the Blob
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // Cleanup
    URL.revokeObjectURL(url);
  };

  const exportReport = () => {
    if (select === "excel") {
      setIsLoading(true);
      AxiosGet(
        headerText === "Total Inventory Report"
          ? exportInventory
          : headerText === "Low Level Out of Stock Report"
          ? exportStock
          : headerText === "Stock Balance Report"
          ? exportBalance
          : headerText === "Staff Report"
          ? exportStaff
          : headerText === "Expired Items"
          ? exportExpired
          : ""
      )
        .then((res) => {
          // console.log(res);
          downloadExcelFromBase64(
            res.data.download.file,
            res.data.download.fileName
          );
          setIsLoading(false);
          setDisplayExport(false);
          setSelect("");
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
    }
    if (select === "pdf") {
      setIsLoading(true);
      AxiosGet(
        headerText === "Total Inventory Report"
          ? exportInventory
          : headerText === "Low Level Out of Stock Report"
          ? exportStock
          : headerText === "Stock Balance Report"
          ? exportBalance
          : headerText === "Staff Report"
          ? exportStaff
          : ""
      )
        .then((res) => {
          // console.log(res);
          handlePdfDownload(res.data.download.file, res.data.download.fileName);
          setIsLoading(false);
          setDisplayExport(false);
          setSelect("");
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
    }
  };

  const lowInventory = isLoading === null ? [] : stockData;
  const expiredItems = isLoading === null ? [] : expiredData;
  const totalInventory = isLoading === null ? [] : inventoryData;
  const stockBalance = isLoading === null ? [] : stockBalanceData;
  const staff = isLoading === null ? [] : staffData;
  return (
    <div>
      <div
        className={`overlay ${display ? "w-full" : "w-0"}`}
        onClick={() => setDisplay(false)}
      ></div>
      <ExportComponent
        display={displayExport}
        setDisplay={setDisplayExport}
        submitForm={exportReport}
        isLoading={isLoading}
      />
      <div className={!display ? "display-none" : "reports-modal"}>
        <div className="modal-head">
          <h3>{headerText}</h3>
          <div className="close-modal">
            <Dashboardbtn
              btnText={"Export"}
              btnClass={"dashboard-btn"}
              btnIcon={<ExportCurve />}
              submitForm={openExport}
              btnWidth={"128px"}
            />
            <CloseCircle
              size={32}
              onClick={() => setDisplay(false)}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>

        <section
          className="report-table display-none"
          style={{ display: expiredItemsDisplay }}
        >
          {/* <SelectInput
            optionLabel={"Stock-level"}
            optionsArray={stockLevelArray}
            dataChange={handleSelectChange}
          /> */}
          <div className="tab-cont">
            <div className="tab-cont_content">
              <section className="report-table_head">
                <p> Item Name </p>
                <p> Quantity </p>
                <p> Expired Date </p>
              </section>
              {expiredItems.length === 0 ? (
                <Empty img={nothing} title={"Nothing to show here"} />
              ) : (
                expiredItems.map((item, index) => (
                  <React.Fragment key={index}>
                    <section className="report-table_body">
                      <p> {item.name} </p>
                      <p> {Number(item.quantity).toLocaleString()} </p>
                      <p>{item.expiringDate}</p>
                    </section>
                    <div
                      style={{
                        border: "1px solid #D1D1D1",
                      }}
                    ></div>
                  </React.Fragment>
                ))
              )}
            </div>
          </div>
        </section>

        <section
          className="report-table display-none"
          style={{ display: lowInventoryDisplay }}
        >
          <SelectInput
            optionLabel={"Stock-level"}
            optionsArray={stockLevelArray}
            dataChange={handleSelectChange}
          />
          <div className="tab-cont">
            <div className="tab-cont_content">
              <section className="report-table_head">
                <p> Item Name </p>
                <p> Quantity </p>
                <p> Stock Level </p>
              </section>
              {lowInventory.length === 0 ? (
                <Empty img={nothing} title={"Nothing to show here"} />
              ) : (
                lowInventory.map((item, index) => (
                  <React.Fragment key={index}>
                    <section className="report-table_body">
                      <p> {item.name} </p>
                      <p> {Number(item.quantity).toLocaleString()} </p>
                      <p
                        className={
                          item.stockStatus === "Low Stock"
                            ? "low-stock"
                            : item.stockStatus === "Out of Stock"
                            ? "out-of-stock"
                            : ""
                        }
                      >
                        {" "}
                        <span>{item.stockStatus}</span>{" "}
                      </p>
                    </section>
                    <div
                      style={{
                        border: "1px solid #D1D1D1",
                      }}
                    ></div>
                  </React.Fragment>
                ))
              )}
            </div>
          </div>
        </section>

        <section
          className="report-table display-none"
          style={{ display: totalInventoryDisplay }}
        >
          <div className="filter-cont">
            <Notification notificationStyle="flex" />
            <InputForm
              placeholder={"Search"}
              formId="searchParam"
              dataChange={handleDataChange}
              formValue={searchParam}
              inputStyle={{ height: "41px" }}
            />
          </div>
          <div className="tab-cont">
            <div className="tab-cont_content">
              <section className="report-table_head">
                <p> Item Name </p>
                <p> Quantity </p>
                <p> Total Amount Sold(N) </p>
                <p> Profit Margin </p>
              </section>
              {totalInventory.length === 0 ? (
                <Empty img={nothing} title={"Nothing to show here"} />
              ) : (
                totalInventory.map((item, index) => (
                  <React.Fragment key={index}>
                    <section className="report-table_body">
                      <p> {item.name} </p>
                      <p> {item.quantity} </p>
                      <p>
                        {" "}
                        <span>
                          {"₦" +
                            Number(item.totalAmountSold).toLocaleString() +
                            ".00"}
                        </span>{" "}
                      </p>
                      <p>
                        {" "}
                        <span>
                          {"₦" +
                            Number(item.profitMargin).toLocaleString() +
                            ".00"}
                        </span>{" "}
                      </p>
                    </section>
                    <div
                      style={{
                        border: "1px solid #D1D1D1",
                      }}
                    ></div>
                  </React.Fragment>
                ))
              )}
            </div>
          </div>
        </section>

        <section
          className="report-table display-none"
          style={{ display: stockBalanceDisplay }}
        >
          <InputForm
            placeholder={"Search"}
            formId="searchBalance"
            dataChange={handleDataChange}
            formValue={searchBalance}
          />

          <div className="tab-cont">
            <div className="tab-cont_content">
              <section className="report-table_head">
                <p> Item Name </p>
                <p> Quantity </p>
                <p> Unit Cost Price (₦) </p>
                <p> Unit Selling Price (₦) </p>
                <p> Total Cost Price (₦) </p>
                <p> Total Selling Price (₦) </p>
              </section>
              {stockBalance.length === 0 ? (
                <Empty img={nothing} title={"Nothing to show here"} />
              ) : (
                stockBalance.map((item, index) => (
                  <React.Fragment key={index}>
                    <section className="report-table_body">
                      <p> {item.name} </p>
                      <p> {Number(item.quantity).toLocaleString()} </p>
                      <p>
                        {" "}
                        <span>{item.unitCostPrice}</span>{" "}
                      </p>
                      <p>
                        {" "}
                        <span>{item.unitSellingPrice}</span>{" "}
                      </p>
                      <p>
                        {" "}
                        <span>{item.totalCostPrice}</span>{" "}
                      </p>
                      <p>
                        {" "}
                        <span>{item.totalSellingPrice}</span>{" "}
                      </p>
                    </section>
                    <div
                      style={{
                        border: "1px solid #D1D1D1",
                      }}
                    ></div>
                  </React.Fragment>
                ))
              )}
            </div>
          </div>

          {stockBalanceSum && (
            <div className="total-balance">
              <p>
                Total Selling Price:{" "}
                <span>
                  ₦
                  {Number(
                    stockBalanceSum?.sumTotalSellingPrice
                  ).toLocaleString()}
                  .00
                </span>{" "}
              </p>
              <p>
                Total Cost Price:{" "}
                <span>
                  ₦{Number(stockBalanceSum?.sumTotalCostPrice).toLocaleString()}
                  .00
                </span>
              </p>
              <p>
                Total Profit:{" "}
                <span>
                  ₦{Number(stockBalanceSum?.profitMargin).toLocaleString()}.00
                </span>
              </p>
            </div>
          )}
        </section>

        <section
          className="report-table display-none"
          style={{ display: staffDisplay }}
        >
          <InputForm
            placeholder={"Search"}
            formId="searchStaff"
            dataChange={handleDataChange}
            formValue={searchStaff}
          />

          <div className="tab-cont">
            <div className="tab-cont_content">
              <section className="report-table_head">
                <p> Staff Name</p>
                <p> Staff Role</p>
                <p> Number of sales </p>
                <p> Debt Amount (N) </p>
              </section>
              {staff.length === 0 ? (
                <Empty img={nothing} title={"Nothing to show here"} />
              ) : (
                staff.map((item, index) => (
                  <React.Fragment key={index}>
                    <section className="report-table_body">
                      <p> {item.name} </p>
                      <p style={{ textTransform: "capitalize" }}>
                        {" "}
                        {item.role}{" "}
                      </p>
                      <p> {Number(item.numberOfSales).toLocaleString()} </p>
                      <p>
                        {" "}
                        <span>{item.debtAmount}.00</span>{" "}
                      </p>
                    </section>
                    <div
                      style={{
                        border: "1px solid #D1D1D1",
                      }}
                    ></div>
                  </React.Fragment>
                ))
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ReportsModal;
