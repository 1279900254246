import React from "react";
import "./billing.styles.css";
import { CloseCircle } from "iconsax-react";
import Dashboardbtn from "../../dashboard-buttons/Dashboardbtn";
import { AxiosAuthPost } from "../../../axios/axios";
import { useState } from "react";

const SwitchPlan = ({ display, setDisplay, billingObject }) => {
    const url = "billing/"
    const [isLoading, setIsLoading] = useState(false);

  const closeModal = () => {
    setDisplay(false);
  };


  const upgradePlan = () => {
    setIsLoading(true);
    AxiosAuthPost(url, billingObject).then(res => {
        // console.log(res);
        setIsLoading(false);
        window.open(res.data.paymentLink, '_blank');
        // window.location.reload();
    }).catch(err => {
        // console.log(err.response);
        setIsLoading(false);
    })
  };


  return (
    <div>
      {display && (
        <>
          <div
            className={`overlay ${display ? "w-full" : "w-0"}`}
            onClick={closeModal}
          >
            {" "}
          </div>
          <section className="price-card">
            <div className="price-card_header">
              <CloseCircle style={{ cursor: "pointer", marginLeft: "auto" }} onClick={closeModal} />
            </div>
            <div className="switch-plan_cont">
              <h2>Switch Plan</h2>
              {billingObject?.planType === "Basic" &&
                billingObject?.period === "Monthly" && (
                  <p>
                    Note that you will be charged an amount of N5,000 to switch
                    to this plan and changes would be effected immediately
                  </p>
                )}
              {billingObject?.planType === "Basic" &&
                billingObject?.period === "Yearly" && (
                  <p>
                    Note that you will be charged an amount of N50,000 to switch
                    to this plan and changes would be effected immediately
                  </p>
                )}
              {billingObject?.planType === "Solo" &&
                billingObject?.period === "Monthly" && (
                  <p>
                    Note that you will be charged an amount of N2,000 to switch
                    to this plan and changes would be effected immediately
                  </p>
                )}
              {billingObject?.planType === "Solo" &&
                billingObject?.period === "Yearly" && (
                  <p>
                    Note that you will be charged an amount of N20,000 to switch
                    to this plan and changes would be effected immediately
                  </p>
                )}
              {billingObject?.planType === "Advanced" &&
                billingObject?.period === "Monthly" && (
                  <p>
                    Note that you will be charged an amount of N20,000 to switch
                    to this plan and changes would be effected immediately
                  </p>
                )}
              {billingObject?.planType === "Advanced" &&
                billingObject?.period === "Yearly" && (
                  <p>
                    Note that you will be charged an amount of N200,000 to switch
                    to this plan and changes would be effected immediately
                  </p>
                )}
            </div>
            <div className="switch-plan_btn">
              <Dashboardbtn
                btnClass={"dashboard-variant"}
                btnText={"Go back"}
                btnWidth={"180px"}
                submitForm={closeModal}
              />
              <Dashboardbtn
                btnClass={"dashboard-btn"}
                btnText={"Upgrade Plan"}
                btnWidth={"180px"}
                submitForm={upgradePlan}
                isLoading={isLoading}
              />
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default SwitchPlan;
