import React from 'react';
import "./toggle-bar.css";

const ToggleBar = ({toggleState, setToggleState, toggleWidth, toggleHeight, toggleBallWidth, toggleBallHeight}) => {

    const toggleClick = () =>{
        if (setToggleState){
            if (!toggleState) {
                setToggleState(true);
            } else {
                setToggleState(false);
            }
        }
    }
  return (
    <div className='toggle-bar_cont' onClick={toggleClick} style={{width: toggleWidth, height: toggleHeight}}>
        <div style={{width: toggleBallWidth, height: toggleBallHeight}} className={toggleState ? "toggle-ball" : !toggleState ? "off-toggle" : ""}></div>
    </div>
  )
}

export default ToggleBar