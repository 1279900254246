import { useSelector } from "react-redux";
import { InputForm } from "../../AuthHead/AuthHead";
import SubHeading from "../../help-support/sub-heading";

const CompanyInformation = () => {
    const myProfile = useSelector((state) => state.profileDetails);
    // console.log(myProfile);
  return (
    <section className="company-information-container">
      <SubHeading text={"Company Information"} />
      <section className="flex">
        <div className="input-form">
          <InputForm
            formLabel="Company Name"
            placeholder={
              !myProfile.organizationDetails.name ? "Company Name" : myProfile.organizationDetails.name
            }
            formId="company_name"
            isReadonly={"readOnly"}
          />
        </div>
        <div className="input-form">
          <InputForm
            formLabel="Industry"
            placeholder={
              !myProfile.organizationDetails.industry ? "Industry" : myProfile.organizationDetails.industry
            }
            formId="company_industry"
            isReadonly={"readOnly"}
          />
        </div>
      </section>
      <section className="flex">
        <div className="input-form">
          <InputForm
            formLabel="Company Address"
            placeholder={
              !myProfile.organizationDetails.address ? "Company Address" : myProfile.organizationDetails.address
            }
            formId="company_address"
            isReadonly={"readOnly"}
          />
        </div>
      </section>
    </section>
  );
};

export default CompanyInformation;
