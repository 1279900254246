import React, { useEffect } from "react";

import "./billing.styles.css";
import "../../Pricingplan/Pricingplan.css";
import { useState } from "react";
import Dashboardbtn from "../../dashboard-buttons/Dashboardbtn";
import SwitchPlan from "./SwitchPlan";
import { AxiosGet } from "../../../axios/axios";

const PriceToggleCard = ({currentPlan, lastSub}) => {
  const [monthly, setMonthly] = useState("active-toggle");
  const [annually, setAnnually] = useState("inactive-toggle");
  const [planType, setPlanType] = useState("");
  const [display, setDisplay] = useState(false);
  const [toggleClick, settoggleClick] = useState("year-toggle");
  // console.log(lastSub);
  const planDetails = [
    {
      plan: "Solo",
      price: annually === "active-toggle" ? "N20000" : "N2000",
    },
    {
      plan: "Basic",
      price: annually === "active-toggle" ? "N50000" : "N5000",
    },
    {
      plan: "Advanced",
      price: annually === "active-toggle" ? "N200000" : "N20000",
    },
  ];

  const onToggleClick = () => {
    if (monthly === "active-toggle") {
      setAnnually("active-toggle");
      setMonthly("inactive-toggle");
      settoggleClick("month-toggle");
    }
    if (annually === "active-toggle") {
      setAnnually("inactive-toggle");
      setMonthly("active-toggle");
      settoggleClick("year-toggle");
    }
  };

  const billingObject = {
    planType: planType,
    period: annually === "active-toggle" ? "Yearly" : "Monthly",
  };

  const clickPlan = (item) => {
    setPlanType(item.plan);
    setDisplay(true)
  };

  const url = `billing/`;
  const [billData, setBillData] = useState([]);
  useEffect(() => {
    AxiosGet(url)
      .then((res) => {
        // console.log(res);
        setBillData(res.data);
      })
      .catch((err) => {
        // console.log(err.response);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div>
        <SwitchPlan display={display} setDisplay={setDisplay} billingObject={billingObject} />
      <div className="pricing-plan_toggle toggle-btn">
        <h4 className={`${monthly}`}>Monthly</h4>
        <div onClick={onToggleClick} className="toggle-bg">
          <div className={`toggle-click ${toggleClick}`}></div>
        </div>
        <h4 className={`${annually}`}>Annually</h4>
      </div>
      <div className="plans-card_cont">
        {planDetails &&
          planDetails.map((item, idx) => {
            return (
              <div key={idx} className="plans-card_content">
                <div className="plans-card_content-left">
                  <h3>{item.plan}</h3>
                  <p>
                    {item.price}
                    <span>
                      {annually === "active-toggle" ? "per Year" : "per Month"}
                    </span>
                  </p>
                </div>
                <div className="plans-card_content-right">
                  <Dashboardbtn
                    btnText={currentPlan?.type === item.plan ? "Renew" : currentPlan?.type === "" && billData?.latestExpiredSubscription?.type === item.plan ? "Renew" : "Switch plan"}
                    btnClass={currentPlan?.type === item.plan ? "btn-green" : currentPlan?.type === "" && billData?.latestExpiredSubscription?.type === item.plan ? "btn-green" : "dashboard-btn"}
                    btnWidth={"180px"}
                    submitForm={()=> clickPlan(item)}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default PriceToggleCard;
