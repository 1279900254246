import React, {useState} from "react";
import "./add-new.css";
import Dashboardbtn from "../dashboard-buttons/Dashboardbtn";
import arrowDown from "../../assets/dashboard/arrow-down.png";
import { Add, Additem } from "iconsax-react";
import useAddContent from "../../hooks/useAddContent";

const AddNew = ({btnWidth, submitForm, btnType, isLoading, addBtnStyle}) => {
    const {addContent, setAddContent} = useAddContent();
    const [displayAdd, setDisplayAdd] = useState(false);
    const handleAddSelect = (e) => {
      setAddContent(e.target.id);
      setDisplayAdd(false);
    };
    const handleAddDropdown = () => {
      setDisplayAdd(!displayAdd);
    };
  return (
    <div className="add-new_btn">
      <div className="add-folder_button" style={addBtnStyle}>
        <Dashboardbtn
          btnWidth={btnWidth}
          btnText={addContent}
          btnClass="dashboard-btn"
          submitForm={submitForm}
          btnType={btnType}
          isLoading={isLoading}
        />
        <Dashboardbtn
          btnText={<img src={arrowDown} alt="down" />}
          btnClass="dashboard-btn"
          submitForm={handleAddDropdown}
        />
      </div>
      <div className={displayAdd ? `add-folder_btn-dropdown` : "display-none"}>
        <p id="Add" onClick={(e) => handleAddSelect(e)}>
          <Add /> Add
        </p>
        <p id="Add & New" onClick={(e) => handleAddSelect(e)}>
          <Additem /> Add & New
        </p>
      </div>
    </div>
  );
};

export default AddNew;
