import React from "react";

import increase_icon from "../../../assets/dashboard/increase-icon.svg";
import decrease_icon from "../../../assets/dashboard/decrease-icon.svg";

import "./debt.styles.css";
import TableHeading from "../../table-heading/table-heading.component";
import DebtTable from "./debt-history-table.component";
import { useSelector } from "react-redux";

const Debt = () => {
    const { debts } = useSelector((state) => state.sales);
    
    const info = [
        { heading: "TOTAL NUMBER OF  DEBTS", number: debts?.result?.totalNumber, img: increase_icon, text: "10% increase compared to Last Month" }, 
        { heading: "TOTAL DEBT AMOUNT", number: "₦" + Number(debts?.result?.totalDebtAmount).toLocaleString() + ".00" , img: increase_icon, text: "10% increase compared to Last Month" }, 
        { heading: "DEBT RATE", number: debts?.result?.debtRate, img: decrease_icon, text: "10% decrease compared to Last Month" }, 
    ];

    return (
        <section>
            <section className="debt">
                { info.map(({heading, number, img, text}, id) => (
                    <React.Fragment key={id}>
                        <section className="total-debts">
                            <h5> {heading} </h5>
                            <p className={`numbers ${heading === "DEBT RATE" ? 'red' : '' }`}> {number} </p>
                            <span className="comparison"> <img src={img} alt={'arrow-icon'} /> {text}  </span>
                        </section>
                        { heading !== "DEBT RATE" &&
                            <div style={{
                                border: '1px solid #D1D1D1',
                            }}></div>
                        }
                    </React.Fragment>
                ))
                }
            </section>
            <TableHeading title={"Debt History"} dropdown={true} />
            <DebtTable />

        </section>
    );
}
 
export default Debt;