import { Add } from "iconsax-react";
import Dashboardheader from "../../Components/dashboard-header/Dashboardheader";
import { useState } from "react";
import Tab from "../../Components/settings/tab/tab.component";
import Members from "../../Components/manage-team/members.component";
import Roles from "../../Components/manage-team/roles.component";
import { useDispatch, useSelector } from "react-redux";
import { setFailureModal } from "../../states/general/general";
import FailedMsg from "../../Components/alert/failed-msg.component";

const ManageTeam = () => {
  const [ activeTab, setActiveTab ] = useState("roles");
  const [ modal, setModal ] = useState(false);
  const userPermissions = useSelector((state) => state.userPermissions);
  const dispatch = useDispatch();

  const tabs = ["roles", "members"];

  const addHandler = () => {
    if (userPermissions.createRole && activeTab === tabs[0]) {
      setModal(true);
    } else if (userPermissions.createRole && activeTab === tabs[1]){
      setModal(true);
    } else {
      dispatch(setFailureModal(true));
    }
  }

    return (
        <section>
          <Dashboardheader
            headerText="Manage Team"
            addStyle="flex"
            addBtnText="add"
            addSubmitForm={addHandler}
            addBtnIcon={<Add />}
          />
          <FailedMsg
            msg={"You do not have permissions to perform this action!!!"}
          />
          <Tab activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} /> 
          { activeTab === tabs[0] && <Roles modal={modal} setModal={setModal} />  }
          { activeTab === tabs[1] &&  <Members modal={modal} setModal={setModal} /> }
        </section>
    );
}
 
export default ManageTeam;