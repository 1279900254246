import { useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { CloseCircle } from "iconsax-react";

import { clearSingleSale} from "../../states/sales/sales";

import OnlyDate from "../excerpts/OnlyDate";
import OnlyTime from "../excerpts/OnlyTime";


import "./sales-details-modal.styles.css";
import Receipt from "./receipt.component";
import AddSaleModal from "../sales/sales-component/AddSaleModal";

const SalesDetailsModal = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch();
  const { singleSale, singleSaleId } = useSelector((state) => state.sales);

  const receiptRef = useRef(null);
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [display, setDisplay] = useState(false);
  const [itemDict, setItemDict] = useState([]);
  // console.log(singleSaleId);

  const handleModalSlideOut = () => {
    setShowModal(false);
    dispatch(clearSingleSale());
  };

  const handleConnectToPrinterModalShow = () => {
    setShowModal(false);
    setShowConnectModal(true);
  };

  const salesRecovery = () => {
    localStorage.setItem("recovery", true);
    setDisplay(true);
    setShowModal(false);
    setItemDict(singleSaleId.itemsDict);
  }

  return (
    <>
      <div className={`overlay ${showModal ? "w-full" : "w-0"}`}></div>
      <aside
        className={`sales-details-modal ${
          showModal ? "slide-in" : "slide-out"
        } `}
      >
        <section className="heading">
          <h4> Sales Details </h4>
          <CloseCircle
            size="32"
            className="close-sales-modal"
            color="#111111"
            onClick={handleModalSlideOut}
          />
        </section>

        <section ref={receiptRef}>
          <section className="user-info">
            <div className="info">
              <p> Total Amount </p>
              <span> ₦ {singleSale.subTotal} </span>
            </div>
            <div className="info">
              <p> Customer Name </p>
              <span style={{ textTransform: "capitalize" }}>
                {!singleSale.customerName && "NA"}
                {singleSale.customerName}{" "}
              </span>
            </div>
            <div className="info">
              <p> Salesperson Name </p>
              <span>{!singleSaleId.sellerName && "NA"} {singleSaleId.sellerName} </span>
            </div>
          </section>

          <section className="time">
            <div className="info">
              <p> Date </p>
              <span>
                {" "}
                <OnlyDate date={singleSale.createdAt} />{" "}
              </span>
            </div>
            <div className="info">
              <p> Time </p>
              <span>
                {" "}
                <OnlyTime date={singleSale.createdAt} />{" "}
              </span>
            </div>
          </section>

          <section className="sales-modal-item-qty-amount-container">
            {singleSaleId?.itemsDict?.length  &&
              singleSaleId.itemsDict.map((item, idx) => {
                return (
                  <section key={idx} className="sales-modal-item-qty-amount">
                    <p> {item.name} </p>
                    <section className="qty-amount">
                      <div className="qty">
                        <span className="first"> Quantity: </span>
                        <span className="second"> {item.quantity} </span>
                      </div>

                      <div className="amount">
                        <span className="first"> Total Amount: </span>
                        <span className="second"> ₦ {item.totalAmount} </span>
                      </div>
                    </section>
                  </section>
                );
              })}
          </section>
        </section>

        <button
          onClick={handleConnectToPrinterModalShow}
          className="view-receipt pointer"
        >
          View Receipt
        </button>
        <div className="recover-sale">
          <p>Want to add a recovery to this sale? <span onClick={salesRecovery}>Click here</span> </p>
        </div>
      </aside>
      
      <AddSaleModal display={display} setDisplay={setDisplay} itemDict={itemDict} setItemDict={setItemDict} />

      
      <Receipt showReceipt={showConnectModal} setShowReceipt={setShowConnectModal} singleSaleId={singleSaleId} />
    </>
  );
};

export default SalesDetailsModal;
