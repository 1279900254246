import { useEffect, useState } from "react";

import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LineElement,
  LinearScale,
  PointElement,
  Legend,
  Tooltip,
  Filler,
} from "chart.js";
// import Notification from "../notification/Notification";
import SalesSummaryHeading from "./sales-summary-heading.component";
import LegendComponent from "./legend.component";
import useActivityHistoryData from "../../hooks/useActivityHistoryData";
import { AxiosGet } from "../../axios/axios";

Chart.register(
  CategoryScale,
  LineElement,
  LinearScale,
  PointElement,
  Legend,
  Tooltip,
  Filler
);

const LineChart = () => {
    const [isLoading, setIsLoading] = useState(null);
    const [lineData, setLineData] = useState();
    // console.log(lineData);
  // eslint-disable-next-line
  const chartData= !lineData ? {} : {
    labels: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
    datasets: [
      {
        label: "Expense",
        data: [lineData.graphData.expense.Monday, lineData.graphData.expense.Tuesday, lineData.graphData.expense.Wednesday, lineData.graphData.expense.Thursday, lineData.graphData.expense.Friday, lineData.graphData.expense.Saturday, lineData.graphData.expense.Sunday],
        backgroundColor: "rgba(240, 130, 39, 0.2)",
        borderColor: "#F08227",
        borderWidth: 2,
        tension: 0.4,
        fill: true,
      },
      {
        label: "Sales",
        data: [lineData.graphData.sales.Monday, lineData.graphData.sales.Tuesday, lineData.graphData.sales.Wednesday, lineData.graphData.sales.Thursday, lineData.graphData.sales.Friday, lineData.graphData.sales.Saturday, lineData.graphData.sales.Sunday],
        backgroundColor: "rgba(34, 102,176, 0.2)",
        borderColor: "#2266B0",
        borderWidth: 2,
        tension: 0.4,
        fill: true,
      },
    ],
  };
  const { activityHistoryData} = useActivityHistoryData();
  const url = `overview/sales-graph-summary/?dateFilter=${activityHistoryData.dateValue}&startDate=${activityHistoryData.startDate}&endDate=${activityHistoryData.endDate}`;
  useEffect(() => {
    AxiosGet(url)
      .then((res) => {
        // console.log(res.data.graphData);
        setLineData(res.data)
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err.response);
      });
      // eslint-disable-next-line
  },[activityHistoryData]);


  return ( isLoading === null ? "" :
    <section className="line-chart-container">
      <SalesSummaryHeading
        text={"Sales Summary"}
        sales={Number(lineData.totalSales).toLocaleString()}
        revenue={Number(lineData.totalExpense).toLocaleString()}
      />
      <div
        style={{
          margin: "30px 0",
          width: "100%",
          border: "0.744891px solid #B2B3BB",
        }}
      ></div>
      <Line
        className="line-chart"
        data={chartData}
        options={{
          responsive: true,
          plugins: {
            tooltip: {
              backgroundColor: "#FFFFFF",
              titleAlign: "center",
              titleColor: "#757575",
              titleSpacing: 4,
              titleFont: {
                size: 14,
                weight: 500,
                family: "Raleway",
              },
              bodyColor: "#282828",
              bodyFont: {
                size: 16,
                weight: 500,
                family: "Raleway",
              },
              bodySpacing: 4,
              padding: 10,
              cornerRadius: 8,
            },
            title: {
              display: true,
              text: "",
            },
            legend: {
              display: false,
            },
          },
        }}
      />
      <section className="line-chart-legend">
        <LegendComponent color={"#2164AC"} label={"Sales"} />
        <LegendComponent color={"#EB8026"} label={"Expense"} />
      </section>
    </section>
  );
};

export default LineChart;
