
import { ArrowLeft, CloseCircle } from "iconsax-react";
import { InputForm } from "../../AuthHead/AuthHead";
import { useEffect, useState } from "react";
import TextAreaInput from "../../textarea/TextAreaInput";
import Dashboardbtn from "../../dashboard-buttons/Dashboardbtn";
import { AxiosAuthPost, AxiosAuthPut, AxiosGet } from "../../../axios/axios";

import "./modals.styles.css";
import { setFailureModal } from "../../../states/general/general";
import { useDispatch } from "react-redux";
import FailedMsg from "../../alert/failed-msg.component";

const AddRole = ({ modal, setModal }) => {
  const dispatch = useDispatch();
  const [failedMsg, setFailedMsg] = useState("");
  const roleId = localStorage.getItem("roleId");
  const [isLoading, setIsLoading] = useState(false);
  const [nameError, setNameError] = useState("");
  const [singleRole, setSingleRole] = useState();
  const [desError, setDesError] = useState("");
  const isOpen = localStorage.getItem("openRole");
  const isEdit = localStorage.getItem("isEdit");
  const url = "roles/";
  const singleRoleUrl = `roles/${roleId}/`;
  const modalHandler = () => {
    setModal(false);
    localStorage.removeItem("roleId");
    localStorage.removeItem("openRole");
    localStorage.removeItem("isEdit");
    setSingleRole();
    setDesError("")
    setNameError("")
  };
  useEffect(() => {
    if (roleId){
      AxiosGet(singleRoleUrl)
        .then((res) => {
          // console.log(res);
          setSingleRole(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          // console.log(err.response);
        });
    }
    // eslint-disable-next-line
  }, [roleId]);
  const [data, setData] = useState({
    name: "",
    description: "",
  });
  const dataObject = !singleRole
    ? {
        name: data?.name,
        description: data?.description,
      }
    : {
        name: data?.name || singleRole?.name,
        description: data?.description || singleRole?.description,
      };
  const submitRole = () => {
    setIsLoading(true);
    if (!singleRole) {
      AxiosAuthPost(url, dataObject)
        .then((res) => {
          setIsLoading(false);
          // console.log(res);
          if (res.success === true) {
            setModal(false);
            localStorage.removeItem("roleId");
            setSingleRole();
            setData({
              name: "",
              description: "",
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          // console.log(err.response);
        if (err.response.status === 403){
          setFailedMsg(err.response.data.message);
          dispatch(setFailureModal(true));
        } else
          if (
            err.message !== "Request failed with status code 500" &&
            err.message !== "Network Error"
          ) {
            for (let i = 0; i < err.response.data.errors.length; i++) {
              if (err.response.data.errors[i].fieldName === "name") {
                setNameError(err.response.data.errors[i].error);
              }
              if (err.response.data.errors[i].fieldName === "description") {
                setDesError(err.response.data.errors[i].error);
              }
            }
          }
        });
    }
    if (singleRole) {
      AxiosAuthPut(singleRoleUrl, dataObject)
        .then((res) => {
          setIsLoading(false);
          // console.log(res);
          if (res.success === true) {
            setModal(false);
            localStorage.removeItem("roleId");
            localStorage.removeItem("isEdit");
            setSingleRole();
            setData({
              name: "",
              description: "",
            });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          // console.log(err.response);
        if (err.response.status === 403){
          setFailedMsg(err.response.data.message);
          dispatch(setFailureModal(true));
        } else
          if (
            err.message !== "Request failed with status code 500" &&
            err.message !== "Network Error"
          ) {
            for (let i = 0; i < err.response.data.errors.length; i++) {
              if (err.response.data.errors[i].fieldName === "name") {
                setNameError(err.response.data.errors[i].error);
              }
              if (err.response.data.errors[i].fieldName === "description") {
                setDesError(err.response.data.errors[i].error);
              }
            }
          }
        });
    }
  };

  const handleDataChange = (e) => {
    if (e.target.id === "description") {
      setDesError("");
    }
    if (e.target.id === "name") {
      setNameError("");
    }
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    // console.log(newData);
  };

  return (
    <>
      <div
        className={`overlay ${modal ? "w-full" : "w-0"}`}
        onClick={() => setModal(false)}
      ></div>
      {modal && <FailedMsg msg={failedMsg} />}
      <section
        className={`add-role-modal ${modal ? "opacity-1" : "opacity-0"}`}
      >
        <section className="add-role-modal-heading">
          <h3> {isEdit ? "Edit Role" : "Add Role"} </h3>
          <CloseCircle
            size="32"
            className="close"
            color=" #111111"
            onClick={modalHandler}
          />
        </section>
        <section className="content">
          <InputForm
            formLabel={"Role Name"}
            placeholder={!singleRole ? "Enter Name" : singleRole?.name}
            formId="name"
            formValue={isOpen ? singleRole?.name : data?.name}
            dataChange={handleDataChange}
            errorMessage={nameError}
            isReadonly={isOpen ? true : false}
          />
          <TextAreaInput
            textAreaLabel={"Description"}
            textAreaPlaceholder={
              !singleRole ? "Enter Description" : singleRole?.description
            }
            textAreaId="description"
            dataChange={handleDataChange}
            formValue={isOpen ? singleRole?.description : data?.description}
            errorMessage={desError}
            isReadonly={isOpen ? true : false}
          />
          <section className="btn-container">
            <Dashboardbtn
              btnClass="dashboard-variant"
              btnText="back"
              btnIcon={<ArrowLeft />}
              // btnWidth="164px"
              submitForm={modalHandler}
            />
            <Dashboardbtn
              btnClass={isOpen ? "low-opacity dashboard-btn" : "dashboard-btn"}
              btnText={isEdit ? "Save" : "add"}
              // btnWidth="164px"
              submitForm={isOpen ? undefined : submitRole}
              isLoading={isLoading}
            />
          </section>
        </section>
      </section>
    </>
  );
};

export default AddRole;
