import React, { useState, useEffect } from "react";
import Dashboardheader from "../../Components/dashboard-header/Dashboardheader";
import HeadingThree from "../../Components/expenses/heading-three.component";

import "../advanced-search/advanced-search.css";
import Dcheckbox from "../../Components/dashboard-checkbox/Dcheckbox";
import { FolderOpen } from "iconsax-react";
import itemImg from "../../assets/dashboard/single-item.svg";
import SelectDelete from "../../Components/select-delete-bar/SelectDelete";
import { AxiosAuthPost, AxiosAuthPut, AxiosGet } from "../../axios/axios";
import DeletePopup from "../../Components/filter-popups/DeletePopup";
import SuccessPopup from "../../Components/filter-popups/SuccessPopup";
import Empty from "../../Components/empty/empty.component";
import nothing from "../../assets/dashboard/nothing.png";
import Loading from "../../Components/Loading";
import { useDispatch, useSelector } from "react-redux";
import FailedMsg from "../../Components/alert/failed-msg.component";
import { setFailureModal } from "../../states/general/general";

const TrashPage = () => {
  const [failedMsg, setFailedMsg] = useState("");
  const url = "trash/";
  const trashUrl = "trash/delete/";
  const restoreUrl = "trash/recover/";
  const [trashData, setTrashData] = useState();
  const [displayDelete, setDisplayDelete] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [displayEnd, setDisplayEnd] = useState(false);
  const [displaySuccessDelete, setDisplaySuccessDelete] = useState(false);
  const [displaySuccessRestore, setDisplaySuccessRestore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDLoading, setIsDLoading] = useState(false);
  const dispatch = useDispatch();
  const userPermissions = useSelector((state) => state.userPermissions);
  useEffect(() => {
    AxiosGet(url)
      .then((res) => {
        // console.log(res.data);
        setTrashData(res.data);
        setIsLoading(true);
      })
      .catch((err) => {
        // console.log(err.response);
      });
    // eslint-disable-next-line
  }, [displaySuccess]);

  const headingArray = !trashData
    ? []
    : [
        {
          text: "Sections",
          amount: Number(trashData.totalDetails.businessCount).toLocaleString(),
        },
        {
          text: "Items",
          amount: Number(trashData.totalDetails.itemsCount).toLocaleString(),
        },
        {
          text: "Quantity",
          amount: Number(trashData.totalDetails.totalQuantity).toLocaleString(),
        },
      ];
  const folderArray = !trashData ? [] : trashData.business;
  const itemsArray = !trashData ? [] : trashData.items;
  const [folderId, setFolderId] = useState([]);
  const [generalId, setGeneralId] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [itemId, setItemId] = useState([]);
  const [displayRestore, setDisplayRestore] = useState(false);
  // const [selectedItemId, setSelectedItemId] = useState(null);
  const deleteObject = {
    itemIds: itemId,
    businessIds: folderId,
  };
  const handleCheckbox = (e) => {
    setDisplayEnd(false);
    if (folderArray) {
      if (e.target.checked === true) {
        setFolderId([...folderId, e.target.id]);
        setGeneralId([...generalId, e.target.id]);
      }
      if (e.target.checked === false) {
        setFolderId(folderId.filter((item) => item !== e.target.id));
        setGeneralId(generalId.filter((item) => item !== e.target.id));
        setIsChecked(false);
      }
    }
  };
  const handleItemCheck = (e) => {
    setDisplayEnd(false);
    if (itemsArray) {
      if (e.target.checked === true) {
        setItemId([...itemId, e.target.id]);
        setGeneralId([...generalId, e.target.id]);
      }
      if (e.target.checked === false) {
        setItemId(itemId.filter((item) => item !== e.target.id));
        setGeneralId(generalId.filter((item) => item !== e.target.id));
        setIsChecked(false);
      }
    }
  };
  const handleGeneralCheck = (e) => {
    if (e.target.checked === true) {
      setIsChecked(true);
      const newArray = e.target.id;
      setGeneralId(newArray.split(","));
      const businessId = folderArray.map((item) => {
        return item.id;
      });
      const itemId = itemsArray.map((item) => {
        return item.id;
      });
      setFolderId(businessId);
      setItemId(itemId);
    }
    if (e.target.checked === false) {
      setIsChecked(false);
      setGeneralId([]);
      setFolderId([]);
      setItemId([]);
    }
  };
  const deleteCancel = () => {
    setDisplayDelete(false);
    setDisplayRestore(false);
  };
  const deleteItem = () => {
    setIsDLoading(true);
    // console.log(deleteObject);
    if (!displayRestore) {
      AxiosAuthPost(trashUrl, deleteObject)
        .then((res) => {
          setIsDLoading(false);
          // console.log(res);
          if (res.success === true) {
            setDisplaySuccessDelete(true);
            setDisplaySuccess(true);
            setGeneralId([]);
            setFolderId([]);
            setItemId([]);
            setDisplayDelete(false);
            setDisplayEnd(true);
          }
        })
        .catch((err) => {
          // console.log(err.response);
          setIsDLoading(false);
          if (err.response.status === 403){
            setFailedMsg(err.response.data.message);
            dispatch(setFailureModal(true));
          }
        });
    }
    if (displayRestore) {
      AxiosAuthPut(restoreUrl, deleteObject)
        .then((res) => {
          setIsDLoading(false);
          // console.log(res);
          if (res.success === true) {
            setDisplaySuccess(true);
            setDisplaySuccessRestore(true);
            setGeneralId([]);
            setFolderId([]);
            setItemId([]);
            setDisplayDelete(false);
            setDisplayEnd(true);
          }
        })
        .catch((err) => {
          // console.log(err.response);
          setIsDLoading(false);
          if (err.response.status === 403){
            setFailedMsg(err.response.data.message);
            dispatch(setFailureModal(true));
          }
        });
    }
  };
  const popDelete = () => {
    if (userPermissions.deleteTrash) {
      setDisplayDelete(!displayDelete);
      setDisplayRestore(false);
    } else {
      dispatch(setFailureModal(true));
    }
  };
  const popRestore = () => {
    if (userPermissions.deleteTrash) {
      setDisplayDelete(!displayDelete);
      setDisplayRestore(true);
    } else {
      dispatch(setFailureModal(true));
    }
  };
  // const openMore = (item) => {
  //   setSelectedItemId(item.id);
  //   setDisplayMore(!displayMore);
  // };

  return (
    <section>
      <SuccessPopup
        display={displaySuccess}
        setDisplay={setDisplaySuccess}
        popupMessage={
          displaySuccessRestore
            ? "You have successfully restored selected entities"
            : displaySuccessDelete
            ? "You have successfully deleted selected entities"
            : ""
        }
      />
      {/* <FailedMsg
        msg={"You do not have permissions to perform this action!!!"}
      /> */}
      {<FailedMsg msg={failedMsg ? failedMsg : "You do not have permissions to perform this action!!!"} />}
      <DeletePopup
        display={displayDelete}
        setDispay={setDisplayDelete}
        deleteItem={deleteItem}
        popupName={generalId.length + " selected"}
        isLoading={isDLoading}
        cancelDelete={deleteCancel}
        restore={!displayRestore ? "" : "restore"}
      />
      <Dashboardheader headerText="Trash" />
      <HeadingThree
        array={headingArray}
        headingDisplay={
          folderId.length === 0 && itemId.length === 0 ? "flex" : "none"
        }
      />
      <SelectDelete
        deleteDisplay={
          displayEnd
            ? "none"
            : folderId.length !== 0 || itemId.length !== 0
            ? "flex"
            : "none"
        }
        deleteItem={popDelete}
        submitRestore={popRestore}
        editDisplay="none"
        restoreDisplay="flex"
        checkboxChange={handleGeneralCheck}
        numberSelect={generalId.length}
        checkId={
          !folderArray && !itemsArray
            ? ""
            : folderArray.map((item) => {
                return item.id;
              }) +
              "," +
              itemsArray.map((item) => {
                return item.id;
              })
        }
      />
      {isLoading === null && <Loading imgStyle={{ marginTop: "150px" }} />}
      {isLoading && folderArray.length === 0 && itemsArray.length === 0 && (
        <Empty img={nothing} title="Nothing to see here" />
      )}
      <div className="advanced-search_body">
        {folderArray.length !== 0 && (
          <div className="business-component">
            <h2 className="header-text">Business</h2>
            <div className={"quantity-grid_display"}>
              {!folderArray
                ? ""
                : folderArray.map((item, idx) => {
                    return (
                      <div
                        key={idx}
                        id={item.id}
                        className={
                          folderId.includes(item.id)
                            ? "quantity-grid_item quantity-grid_item-focus"
                            : "quantity-grid_item"
                        }
                      >
                        <div className="quantity-grid_item-top">
                          <div className="folder-icon_wrap">
                            <FolderOpen style={{ fontSize: "32px" }} />
                          </div>
                          <h6>{item.totalItems} Items</h6>
                          {isChecked ? (
                            <Dcheckbox
                              checkId={item.id}
                              checkboxChange={handleCheckbox}
                              checked={isChecked ? true : ""}
                            />
                          ) : (
                            <Dcheckbox
                              checkId={item.id}
                              checkboxChange={handleCheckbox}
                              checked={folderId.includes(item.id) ? true : ""}
                            />
                          )}
                        </div>
                        <div className="quantity-grid_item-middle">
                          {/* <Link
                          to="/business/view"
                          style={{ textDecoration: "none" }}
                        > */}
                          <h3>{item.name}</h3>
                          {/* </Link> */}
                        </div>
                        <div className="quantity-grid_item-bottom">
                          <h5>Business value:</h5>
                          <h4>
                            ₦ {Number(item.totalValue).toLocaleString()}.00
                          </h4>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        )}

        {itemsArray.length !== 0 && (
          <div className="item-component">
            <h2 className="header-text">Items</h2>
            <div className="item-grid_display">
              {itemsArray &&
                itemsArray.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      id={item.id}
                      className={
                        itemId.includes(item.id)
                          ? "item-grid_item quantity-block_item-focus"
                          : "item-grid_item"
                      }
                    >
                      <div className="item-image_header">
                        {!item.picture ? (
                          <img src={itemImg} alt="item" />
                        ) : (
                          <img src={item.picture} alt="item" />
                        )}
                        <div className="item-image_header-span">
                          {item.itemType === "Stand Alone" ? (
                            <p style={{ background: "transparent" }}></p>
                          ) : (
                            <p>{item.itemType}</p>
                          )}
                          {isChecked ? (
                            <Dcheckbox
                              checkboxChange={handleItemCheck}
                              checkId={item.id}
                              containerBg="white"
                              checked={isChecked ? true : ""}
                            />
                          ) : (
                            <Dcheckbox
                              checkboxChange={handleItemCheck}
                              checkId={item.id}
                              containerBg="white"
                              checked={itemId.includes(item.id) ? true : ""}
                            />
                          )}
                        </div>
                      </div>
                      <div className="item-grid_middle">
                        <div className="item-grid_middle-top">
                          {/* <Link
                            to={
                              item.itemType === "Stand Alone"
                                ? "/items/stand-alone"
                                : item.itemType === "Grouped"
                                ? "/items/eded-items"
                                : item.itemType === "Composite"
                                ? "/items/composite-items"
                                : ""
                            }
                            style={{ textDecoration: "none" }}
                          > */}
                          <h3>{item.name}</h3>
                          {/* </Link> */}
                          {/* <More
                                // onClick={() => openMore(item)}
                                style={{
                                  rotate: "90deg",
                                  color: "#111111",
                                  cursor: "pointer",
                                }}
                              /> */}

                          {/* <div
                            className={
                              displayMore && selectedItemId === item.id
                                ? "items-more_list"
                                : "display-none"
                            }
                          >
                            <p>
                              <Clock /> History
                            </p>
                            <p>
                              <Copy /> Clone Item
                            </p>
                            <p>
                              <Trash /> Delete
                            </p>
                          </div> */}
                        </div>
                        {!item.stockStatus ? (
                          <span
                            style={{
                              background: "transparent",
                              color: "transparent",
                              width: "100px",
                            }}
                          >
                            stock
                          </span>
                        ) : item.stockStatus === "In Stock" ? (
                          <span
                            style={{
                              background: "#EDF7EE",
                              color: "#4CAF50",
                              width: "70px",
                            }}
                          >
                            {item.stockStatus}
                          </span>
                        ) : item.stockStatus === "Out of Stock" ? (
                          <span
                            style={{
                              background: "#FFE6E6",
                              color: "#FF0000",
                              width: "100px",
                            }}
                          >
                            {item.stockStatus}
                          </span>
                        ) : item.stockStatus === "Low Stock" ? (
                          <span
                            style={{
                              background: "#FFF9E6",
                              color: "#FFC107",
                              width: "81px",
                            }}
                          >
                            {item.stockStatus}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="item-grid_bottom">
                        <h5>{item.quantity} units</h5>
                        <h6>₦ {Number(item.totalValue).toLocaleString()}.00</h6>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default TrashPage;
