import { useState } from "react";
import Dashboardbtn from "../../dashboard-buttons/Dashboardbtn";
import EmailPreference from "./email-preference.component";

import "./general.styles.css";
import DeleteAccountModal from "../../add-items-popup/DeleteAccountModal";
// import LinkedAccounts from "./linked-accounts.component";

const General = () => {
    
  const [display, setDisplay] = useState(false);

    const clickDelete = () =>{
        setDisplay(true)
    }

    return (
        <section className="general-container">
            <EmailPreference />
            {/* <LinkedAccounts /> */}
            <div style={{padding: "15px 0"}}>
            <Dashboardbtn btnClass={"btn"} btnBgColor={"red"} btnText={"Delete Account"} submitForm={clickDelete} />
            </div>
            <DeleteAccountModal display={display} setDisplay={setDisplay} />
        </section>
    );
}
 
export default General;