import { ExportCurve } from "iconsax-react";
import Dashboardheader from "../../Components/dashboard-header/Dashboardheader";
import "./customers.css";

import React from "react";
import Empty from "../../Components/empty/empty.component";
import emptyImg from "../../assets/dashboard/empty-notifications.png";
import { useEffect } from "react";
import { AxiosGet } from "../../axios/axios";
import { useState } from "react";
import Pagination from "../../Components/pagination/pagination.component";
import ExportComponent from "../../Components/notification/ExportComponent";
import useSelect from "../../hooks/useSelect";

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [pagData, setPagData] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [isLoading, setIsLoading] = useState(null);
  const { select, setSelect } = useSelect();
  const url = `customers/?page=${pageNum}&pageSize=10`;
  const exportUrl = `customers/?export=true&exportType=${select.toUpperCase()}`;
  const [display, setDisplay] = useState(false);
  const openExport = () => {
    setDisplay(true);
  };

  function downloadExcelFromBase64(base64String, fileName) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/vnd.ms-excel" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  const handlePdfDownload = (base64String, fileName) => {
    // Convert Base64 to Blob
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    // Create URL for the Blob
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // Cleanup
    URL.revokeObjectURL(url);
  };

  const exportCustomers = () => {
    if (select === "excel") {
      setIsLoading(true);
      AxiosGet(exportUrl)
        .then((res) => {
          // console.log(res);
          downloadExcelFromBase64(
            res.data.download.file,
            res.data.download.fileName
          );
          setIsLoading(false);
          setDisplay(false);
          setSelect("");
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
    }
    if (select === "pdf") {
      setIsLoading(true);
      AxiosGet(exportUrl)
        .then((res) => {
          // console.log(res);
          handlePdfDownload(res.data.download.file, res.data.download.fileName);
          setIsLoading(false);
          setDisplay(false);
          setSelect("");
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    AxiosGet(url)
      .then((res) => {
        // console.log(res);
        setPagData(res);
        setCustomers(res.data.customerData);
      })
      .catch((err) => {
        // console.log(err.response);
      });
    // eslint-disable-next-line
  }, [pageNum]);

  const handleUpdate = (item) => {
    setPageNum(item);
  };

  const customerDetails = !customers ? [] : customers;
  return (
    <section>
      <Dashboardheader
        headerText="Customers"
        addStyle="flex"
        addBtnText="export"
        addBtnIcon={<ExportCurve />}
        addSubmitForm={openExport}
        btnWidth={"120px"}
      />
      <ExportComponent
        display={display}
        setDisplay={setDisplay}
        submitForm={exportCustomers}
        isLoading={isLoading}
      />
      {customerDetails && (
        <section className="customer-table">
          <section className="customer-table-cont">
            <section className="customer-table_head">
              <p> Customer Name </p>
              <p> Customer Phone Number </p>
            </section>
            {customerDetails.map((item, idx) => {
              return (
                <React.Fragment key={idx}>
                  <section className="customer-table_body">
                    <p> {item.name} </p>
                    <p> {item.phoneNumber} </p>
                  </section>
                  <div
                    style={{
                      border: "1px solid #D1D1D1",
                    }}
                  ></div>
                </React.Fragment>
              );
            })}
          </section>
        </section>
      )}
      {!customerDetails?.length && (
        <Empty img={emptyImg} title={"You don't have any Customer"} />
      )}
      {customerDetails?.length > 0 && (
        <Pagination
          currentPage={pagData?.pagination?.currentPage}
          numberOfPages={pagData?.pagination?.numberOfPages}
          handleUpdate={handleUpdate}
        />
      )}
    </section>
  );
};

export default Customers;
