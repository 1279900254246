import React from 'react';
import { Link, NavLink, useLocation } from "react-router-dom";

import logo from "../../assets/dashboard/app-logo.png";
import useActive from "../../hooks/useActive";

import "./sidenav.styles.css";
import { useEffect, useState } from "react";
import useActivityHistoryData from "../../hooks/useActivityHistoryData";
import { CloseSquare, LogoutCurve, Menu, MessageAdd, MoneyRecive, Profile2User, Speedometer,  } from "iconsax-react";

const AdminSidenav = () => {

  const [isMenu, setIsMenu] = useState(false);

  const routesInfo = [
    { name: "Admin-overview", img: <Speedometer />, title: "Overview" },
    { name: "Admin-users", img: <Profile2User />, title: "Users" },
    { name: "Admin-subscription", img: <MoneyRecive />, title: "Subscriptions" },
    { name: "Admin-message", img: <MessageAdd />, title: "Messages" },
  ] 

  const otherRoutesInfo = [
    { name: "", img: <LogoutCurve />, title: "Logout" },
  ];

  const routes = [
    "Admin-overview",
    "Admin-users",
    "Admin-subscription",
    "Admin-message"
  ];
  
  const { pathname } = useLocation();
  const { active, setActive } = useActive(routes[0]);
  const { setActivityHistoryData } = useActivityHistoryData();

  const handleActive = (route) => {
    setActive(route);
    setActivityHistoryData({
      orderBy: "Latest",
      dateValue: "Today",
      startDate: "",
      endDate: "",
    });
  };
  const handleMobActive = (route) => {
    setIsMenu(false);
    setActive(route);
    setActivityHistoryData({
      orderBy: "Latest",
      dateValue: "Today",
      startDate: "",
      endDate: "",
    });
  };
  useEffect(() => {
    handleActive();
    // eslint-disable-next-line
  }, [active]);

  useEffect(() => {
    const pathComponents = pathname.split("/");
    const firstPathComponent = pathComponents[1];
    setActive(firstPathComponent);
    // eslint-disable-next-line
  }, [pathname]);

  const openMenu = () => {
    setIsMenu(!isMenu);
  }

  const logout = () => {
    localStorage.clear();
  }

  return (<>
    <aside className="sidenav-container desktop-menu">
      <Link to={"/admin-overview"} className="logo-img_cont">
        <img className="logo-img" src={logo} alt="stock-keeper-logo" />
      </Link>
      <nav className="sidenav">
        <ul>
          {routesInfo.map((routeInfo, id) => (
            <li key={id} className="nav-list">
              <NavLink
                to={`/${routeInfo.name
                  .trim()
                  .replace(/\s+/g, "")
                  .toLowerCase()}`}
                className={"nav-link"}
                onClick={() =>
                  handleActive(
                    routeInfo.name.trim().replace(/\s+/g, "").toLowerCase()
                  )
                }
              >
                {active !== routeInfo.name.trim().replace(/\s+/g, "").toLowerCase() && <span> {routeInfo.img} </span>}
                <span className={active === routeInfo.name.trim().replace(/\s+/g, "").toLowerCase() ? "selected-nav" : "nav-text"}> {routeInfo.title} </span>
              </NavLink>
            </li>
          ))}

          <div
            style={{
              backgroundColor: "#CCCCCC",
              width: "100%",
              margin: "20px auto",
              border: "1px solid #CCCCCC",
            }}
          ></div>

          {otherRoutesInfo.map((routeInfo, id) => (
            <li key={id} className="nav-list log-red" >
              <NavLink
                to={`/${routeInfo.name
                  .trim()
                  .replace(/\s+/g, "")
                  .toLowerCase()}`}
                className={"nav-link"}
                onClick={logout}
              >
                {active !== routeInfo.name.trim().replace(/\s+/g, "").toLowerCase() && <span style={{color: "#F08227"}}> {routeInfo.img} </span>}
                <span className={active === routeInfo.name.trim().replace(/\s+/g, "").toLowerCase() ? "selected-nav" : "nav-text"} style={{color: "#F08227"}}> {routeInfo.title} </span>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </aside>


    <div className="mobile-menu">
      {!isMenu && <div className="menu-bar"><Menu onClick={openMenu} cursor={"pointer"} /></div>}
    {isMenu && <aside className="sidenav-container" style={{display: isMenu ? "block" : "none"}}>
      {isMenu && <div className="menu-bar" style={{paddingBottom: "20px"}}><CloseSquare onClick={openMenu} cursor={"pointer"} color="white"  /></div>}
      <Link to={"/admin-overview"} className="logo-img_cont">
        <img className="logo-img" src={logo} alt="stock-keeper-logo" />
      </Link>
      <nav className="sidenav">
        <ul>
          {routesInfo.map((routeInfo, id) => (
            <li key={id} className="nav-list">
              <NavLink
                to={`/${routeInfo.name
                  .trim()
                  .replace(/\s+/g, "")
                  .toLowerCase()}`}
                className={"nav-link"}
                onClick={() =>
                  handleMobActive(
                    routeInfo.name.trim().replace(/\s+/g, "").toLowerCase()
                  )
                }
              >
                {active !== routeInfo.name.trim().replace(/\s+/g, "").toLowerCase() && <span> {routeInfo.img} </span>}
                <span className={active === routeInfo.name.trim().replace(/\s+/g, "").toLowerCase() ? "selected-nav" : "nav-text"}> {routeInfo.title} </span>
              </NavLink>
            </li>
          ))}

          <div
            style={{
              backgroundColor: "#CCCCCC",
              width: "100%",
              margin: "20px auto",
              border: "1px solid #CCCCCC",
            }}
          ></div>

          {otherRoutesInfo.map((routeInfo, id) => (
            <li key={id} className="nav-list">
              <NavLink
                to={`/${routeInfo.name
                  .trim()
                  .replace(/\s+/g, "")
                  .toLowerCase()}`}
                className={"nav-link"}
                onClick={logout}
              >
                {active !== routeInfo.name.trim().replace(/\s+/g, "").toLowerCase() && <span style={{color: "#F08227"}}> {routeInfo.img} </span>}
                <span className={active === routeInfo.name.trim().replace(/\s+/g, "").toLowerCase() ? "selected-nav" : "nav-text"} style={{color: "#F08227"}}> {routeInfo.title} </span>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </aside>}
    </div>
    </>
  )
}

export default AdminSidenav