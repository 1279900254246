import React from "react";
import "./text-area.css";

const TextAreaInput = ({
  textAreaId,
  textAreaLabel,
  dataChange,
  textAreaPlaceholder,
  formValue,
  errorMessage,
  isReadonly
}) => {
  const handleDataChange = (e) => {
    if (dataChange) {
      dataChange(e);
    }
  };
  return (
    <div className="textarea-input">
      <label>{textAreaLabel}</label>
      <textarea
        id={textAreaId}
        value={formValue}
        onChange={handleDataChange}
        placeholder={textAreaPlaceholder}
        cols="30"
        rows="10"
        readOnly={isReadonly}
      ></textarea>
      {errorMessage && <p style={{color: "red"}}>{errorMessage}</p>}
    </div>
  );
};

export default TextAreaInput;
