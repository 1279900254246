import React, { useState, useRef } from "react";
import "./AuthHead.css";
import authLogo from "../../assets/landingpage/authLogo.png";
import { Link } from "react-router-dom";
import { ArrowLeft, Eye, EyeSlash } from "iconsax-react";
import spinner from "../../assets/landingpage/spinner.gif";
import { Form } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";

export const AuthHead = ({ headerText, pText }) => {
  const [displayTip, setDisplayTip] = useState(false);
  return (
    <div>
      <div className="authhead-top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <div className="logo-home">
            <img src={authLogo} alt="logo" onMouseEnter={()=> setDisplayTip(true)} onMouseLeave={()=> setDisplayTip(false)} />
            <p style={{display: !displayTip ? "none" : "flex" }}><ArrowLeft /> Go back home</p>
          </div>
        </Link>
        <h2>{headerText}</h2>
        <h3>{pText}</h3>
      </div>
    </div>
  );
};

export const InputForm = ({
  formValue,
  formLabel,
  placeholder,
  dataChange,
  formId,
  errorMessage,
  inputStyle,
  labelStyle,
  isReadonly,
  inputType
}) => {
  const inputRef = useRef(null);
  const [isCursorOnInput, setIsCursorOnInput] = useState(false);
  const handleFocus = () => {
    setIsCursorOnInput(true);
  };
  const handleBlur = () => {
    setIsCursorOnInput(false);
  };
  const handleDataChange = (e) => {
    setIsCursorOnInput(document.activeElement === inputRef.current);
    if (dataChange) {
      dataChange(e);
    }
  };
  return (
    <div>
      <div className={`login-form_input`}>
        <label
          className={`${
            !formValue
              ? ""
              : `${
                  !isCursorOnInput
                    ? "focus-label"
                    : formValue
                    ? "active-label"
                    : "neutral-label"
                }`
          } ${errorMessage ? "error-label" : ""}`}
          style={labelStyle}
        >
          {formLabel}
        </label>
        <input
          className={`${
            !formValue
              ? ""
              : `${
                  !isCursorOnInput
                    ? "focus-input"
                    : formValue
                    ? "active-input"
                    : "neutral-input"
                }`
          } ${errorMessage ? "error-input" : ""}`}
          type={inputType ? inputType : "text"}
          onChange={(e) => handleDataChange(e)}
          id={formId}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={inputRef}
          value={formValue}
          placeholder={placeholder}
          style={inputStyle}
          readOnly={isReadonly}
        />
        <p style={{ color: "red" }}>{errorMessage}</p>
      </div>
    </div>
  );
};

export const PasswordInput = ({
  dataChange,
  formValue,
  formLabel,
  placeholder,
  formId,
  errorMessage,
  autoComplete
}) => {
  const inputPRef = useRef(null);
  const [isPCursorOnInput, setIsPCursorOnInput] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleDataChange = (e) => {
    setIsPCursorOnInput(document.activeElement === inputPRef.current);
    if (dataChange) {
      dataChange(e);
    }
  };
  const handleFocus = () => {
    setIsPCursorOnInput(true);
  };

  const handleBlur = () => {
    setIsPCursorOnInput(false);
  };
  const handleShowPassword = () => {
    if (!showPassword) {
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  };
  return (
    <div>
      <div className="login-form_input">
        <label
          className={`${
            !formValue
              ? ""
              : `${
                  !isPCursorOnInput
                    ? "focus-label"
                    : formValue
                    ? "active-label"
                    : "neutral-label"
                }`
          } ${errorMessage ? "error-label" : ""}`}
        >
          {formLabel}
        </label>
        <div
          className={`${
            !formValue
              ? "password-input"
              : `password-input ${
                  !isPCursorOnInput
                    ? "focus-input"
                    : formValue
                    ? "active-input"
                    : "neutral-input"
                }`
          } ${errorMessage ? "error-input" : ""} `}
        >
          <input
            type={!showPassword ? "password" : "text"}
            onChange={(e) => handleDataChange(e)}
            id={formId}
            value={formValue}
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={inputPRef}
            placeholder={placeholder}
            autoComplete={autoComplete}
          />
          <div className="show-password" onClick={handleShowPassword}>
            {!showPassword ? (
              <EyeSlash style={{ color: "#A3A3A3", cursor: "pointer" }} />
            ) : (
              <Eye style={{ color: "#A3A3A3", cursor: "pointer" }} />
            )}
          </div>
        </div>
        <p style={{ color: "#FF0000" }}>{errorMessage}</p>
      </div>
    </div>
  );
};

export const SelectInput = ({
  style,
  formValue,
  dataChange,
  formLabel,
  optionsArray,
  optionLabel,
  labelStyle,
  formId,
  errorMessage,
}) => {
  const inputPRef = useRef(null);
  const [isPCursorOnInput, setIsPCursorOnInput] = useState(false);
  const handleDataChange = (e) => {
    setIsPCursorOnInput(document.activeElement === inputPRef.current);
    if (dataChange) {
      dataChange(e);
    }
  };
  const handleFocus = () => {
    setIsPCursorOnInput(true);
  };

  const handleBlur = () => {
    setIsPCursorOnInput(false);
  };
  return (
    <div className="login-form_input">
      <label
        className={
          !formValue
            ? ""
            : `${
                !isPCursorOnInput
                  ? "focus-label"
                  : formValue
                  ? "active-label"
                  : "neutral-label"
              } ${errorMessage ? "error-label" : ""}`
        }
        style={labelStyle}
      >
        {formLabel}
      </label>
      <Form.Select
        onChange={(e) => handleDataChange(e)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        id={formId}
        style={style}
        className={
          !formValue
            ? "form-select"
            : `${
                !isPCursorOnInput
                  ? "focus-input"
                  : formValue
                  ? "active-input"
                  : "neutral-input"
              } ${errorMessage ? "error-input" : ""}`
        }
      >
        <option>{optionLabel}</option>
        {!optionsArray
          ? ""
          : optionsArray.map((item, idx) => {
              return (
                <option key={idx} id={item.id} value={item.id} name={item.name}>
                  {item.name}
                </option>
              );
            })}
      </Form.Select>
      <p style={{ color: "#FF0000" }}>{errorMessage}</p>
    </div>
  );
};

export const FormButton = ({
  submitForm,
  isLoading,
  dataValues,
  buttonText,
  btnStyle
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    if (submitForm) {
      submitForm();
    }
  };
  return (
    <div>
      <div className="login-form_button">
        <button
          onClick={handleSubmit}
          className={dataValues ? `btn high-opacity` : `btn low-opacity`}
          style={btnStyle}
        >
          {!isLoading ? buttonText : <img src={spinner} alt="Loading..." />}
        </button>
      </div>
    </div>
  );
};

export const CheckBox = ({ checkboxChange, id, checkValue, checked }) => {
  const handleCheckboxChange = (e) => {
    if (checkboxChange) {
      checkboxChange(e);
    }
  };
  return (
    <div>
      <label className="container">
        <input
          type="checkbox"
          id={id}
          value={checkValue}
          checked={checked}
          onChange={handleCheckboxChange}
        />
        <span className="checkmark"></span>
      </label>
    </div>
  );
};

export const MultiSelectInput = ({
  selectOptions,
  selected,
  setSelected,
  formLabel,
  errorMessage,
  selectWidth,
}) => {
  // const [selected, setSelected] = useState([]);
  const options = selectOptions.map((option) => {
    return {
      label: option.name,
      value: option.id,
    };
  });
  return (
    <div className="multi-select" style={{ width: selectWidth }}>
      <h3>{formLabel}</h3>
      <MultiSelect
        options={options}
        value={selected}
        onChange={setSelected}
        labelledBy="Select"
      />
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
    </div>
  );
};


export function getFirstSentence(text) {
  const match = text.match(/.*?[.!?](\s|$)/);
  
  return match ? match[0].trim() : text;
}

