import { createSlice } from "@reduxjs/toolkit";


export const profileSlice = createSlice({
    name: "profileDetails",
    initialState: {},
    reducers: {
        profile: (state,action) => {
            const singleProfile = action.payload
            return (singleProfile);
        }
    }
})

export const {profile} = profileSlice.actions;

export default profileSlice.reducer;