import { useState } from "react";

import { createColumnHelper } from "@tanstack/react-table";

import Table from "../../table/table.component";
import Empty from "../../empty/empty.component";


import edit_icon from "../../../assets/dashboard/edit-icon.svg";
import empty_icon from "../../../assets/dashboard/empty-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import OnlyDate from "../../excerpts/OnlyDate";
import OnlyTime from "../../excerpts/OnlyTime";
import { getDrafts, setSalesModal, setSingleDraft, setSingleDraftId } from "../../../states/sales/sales";
import Pagination from "../../pagination/pagination.component";
import useActivityHistoryData from "../../../hooks/useActivityHistoryData";
import Loading from "../../Loading";
import { AxiosGet } from "../../../axios/axios";
import AddSaleModal from "../sales-component/AddSaleModal";

const Drafts = () => {
    const dispatch = useDispatch();    
    const { drafts } = useSelector((state) => state.sales);
    const [display, setDisplay] = useState(false);

    // eslint-disable-next-line
    const [data, setData] = useState((drafts?.result && drafts.result?.draftList?.length > 0 ) ? drafts?.result.draftList.map(sale => ({ ...sale, checked: "" })) : [] );
    const { activityHistoryData } = useActivityHistoryData();
    const [itemDict, setItemDict] = useState([]);
    


    const handleEdit = (id) => {
        dispatch(setSingleDraft(id));
        localStorage.setItem("draft", true)
        AxiosGet(`drafts/${id}/`).then(res => {
            // console.log(res);
            dispatch(setSingleDraftId(res.data));
            setDisplay(true);
        }).catch(err => {
            // console.log(err.response);
        })
    }


    const columnHelper = createColumnHelper();
    const columns = [
    columnHelper.accessor('name', {
        cell: (info) => (
                <p className=""> {info.getValue()} </p>
            ),
        header: () => <span>Item</span>,
    }),
    columnHelper.accessor('unitPrice', {
        cell: (info) => (
                <p className=""> {info.getValue()} </p>
        ),
        header: () => <span> Unit Price(N) </span>,
    }),
    columnHelper.accessor('quantity', {
        cell: (info) => (
            <p> {info.getValue()} </p>
        ),
        header: () => <span> QTY </span>,
    }),
    columnHelper.accessor('subTotal', {
        cell: (info) => (
            <p> {info.getValue()} </p>
        ),
        header: () => <span> Sub Total(N) </span>,
    }),
    columnHelper.accessor('total', {
        cell: (info) => (
            <p> {info.getValue()} </p>
        ),
        header: () => <span> Total Amount(N) </span>,
    }),
    columnHelper.accessor('customerName', {
        cell: (info) => (
            <p> {info.getValue()} </p>
        ),
        header: () => <span>Name</span>,
    }),
    columnHelper.accessor('customerPhoneNumber', {
        cell: (info) => (
            <p> {info.getValue()} </p>
        ),
        header: () => <span>Phone</span>,
    }),
    columnHelper.accessor('createdAt', {
        cell: (info) => (
            <>
                <p className=""> { <OnlyDate date={info.getValue()} /> } </p>
                <p className="time"> { <OnlyTime date={info.getValue()} /> } </p>
            </>
        ),
        header: () => <span> Date/Time </span>,
    }),
    columnHelper.accessor(row => `${row.id}`, {
        id: 'edit-trash',
        cell: (info) => (
            <div className="edit-trash-container" style={{ display: 'flex', gap: '16px' }}>
                <img 
                    src={edit_icon} 
                    onClick={() =>  handleEdit(info.getValue())} 
                    alt="edit-icon" 
                />
            </div>
    ),
        header: () => <span> </span>,
    }),
];

    const handleAddSaleModal = () => dispatch(setSalesModal(true));
    
    const handleUpdate = (item) => {
        dispatch(getDrafts(`?dateFilter=${activityHistoryData.dateValue}&startDate=${activityHistoryData.startDate}&endDate=${activityHistoryData.endDate}&orderBy=${activityHistoryData.orderBy}&page=${item}&pageSize=10`))
    };

    return (
        <>
            { drafts.status === 'loading' 
                ? 
                <Loading />
                :
                <>
                    { (data && data?.length > 0) && <Table minWidth={"100%"} data={data} columns={columns}/> }
                    { ( data && data?.length === 0) && 
                        <Empty submitClick={handleAddSaleModal} title={"You don't have any draft"} img={empty_icon} /> 
                    }
                </>
            }
            { (data && data.length > 0) && 
                <Pagination
                    currentPage={drafts?.result?.pagination?.currentPage} 
                    numberOfPages={drafts?.result?.pagination?.numberOfPages} 
                    handleUpdate={handleUpdate} />
            }
            <AddSaleModal display={display} setDisplay={setDisplay} itemDict={itemDict} setItemDict={setItemDict} />
        </>
    );
}
 
export default Drafts;