import HeadingThree from "../expenses/heading-three.component";

import edit_icon from "../../assets/dashboard/edit-icon.svg";
import trash_icon2 from "../../assets/dashboard/trash-icon2.svg";

import "./manage-team.styles.css";
import { useEffect, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "../table/table.component";
import AddTeamMember from "./modals/add-team-member.component";
import { AxiosDelete, AxiosGet } from "../../axios/axios";
import Empty from "../empty/empty.component";
import emptyImg from "../../assets/dashboard/empty-member.png";
import Loading from "../Loading";
import { useDispatch, useSelector } from "react-redux";
import { setFailureModal } from "../../states/general/general";
import FailedMsg from "../alert/failed-msg.component";
import DeletePopup from "../filter-popups/DeletePopup";
import SuccessPopup from "../filter-popups/SuccessPopup";

const Members = ({ modal, setModal }) => {
  const teamUrl = "team-members/";
  const memberId = localStorage.getItem("memberId");
  // const [memId, setMemId] = useState("");
  const singleMemberUrl = `team-members/${memberId}/`;
  const [teamData, setTeamData] = useState();
  // const [memberData, setMemberData] = useState();
  const [isLoading, setIsLoading] = useState(null);
  const [isDLoading, setIsDLoading] = useState(null);
  const userPermissions = useSelector((state) => state.userPermissions);
  const dispatch = useDispatch();
  const [displayDelete, setDisplayDelete] = useState(false);
  const [memberName, setMemberName] = useState("");
  const [displaySuccess, setDisplaySuccess] = useState(false);
  useEffect(() => {
    AxiosGet(teamUrl)
      .then((res) => {
        // console.log(res);
        setTeamData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err.response);
      });
    // eslint-disable-next-line
  }, [modal, displaySuccess]);
  const clickEdit = (info) => {
    if (userPermissions?.addTeamMember) {
      localStorage.setItem("memberId", info.row.original.id);
      localStorage.setItem("editMem", true);
      setModal(true);
    } else {
      dispatch(setFailureModal(true));
    }
  };
  const clickOpen = (info) => {
    if (userPermissions?.addTeamMember) {
      localStorage.setItem("memberId", info.row.original.id);
      localStorage.setItem("openMem", true);
      setModal(true);
    } else {
      dispatch(setFailureModal(true));
    }
  };
  const clickDelete = (info) => {
    setDisplayDelete(!displayDelete);
    localStorage.setItem("memberId", info.row.original.id);
    // console.log(info);
    setMemberName(info.row.original.name);
    // setMemId(info.row.original.id);
  };
  const deleteItem = () => {
    setIsDLoading(true);
    AxiosDelete(singleMemberUrl).then((res) => {
      // console.log(res);
      setDisplaySuccess(true);
      setDisplayDelete(false);
      setIsDLoading(false);
    }).catch((err) => {
      // console.log(err.response);
      setIsDLoading(false);
    })
  };
  const cancelDelete = () => {
    setDisplayDelete(!displayDelete);
    localStorage.removeItem("memberId");
  };
  const data = !teamData ? [] : teamData.teamList;

  const headingArray = [{ text: "Team members", amount: `${data.length}` }];

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => (
        <p
          onClick={() => clickOpen(info)}
          className=""
          style={{ textTransform: "capitalize" }}
        >
          {" "}
          {info.getValue()}{" "}
        </p>
      ),
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor("role", {
      cell: (info) => (
        <p
          onClick={() => clickOpen(info)}
          className=""
          style={{ textTransform: "capitalize" }}
        >
          {" "}
          {info.getValue()}{" "}
        </p>
      ),
      header: () => <span>Role</span>,
    }),
    columnHelper.accessor("createdAt", {
      cell: (info) => (
        <p onClick={() => clickOpen(info)}>
          {" "}
          {new Date(info.getValue()).toLocaleDateString("en-GB")}{" "}
        </p>
      ),
      header: () => <span> Date Created </span>,
    }),
    columnHelper.accessor("edit_trash", {
      cell: (info) => (
        <div style={{ display: "flex", gap: "16px" }}>
          <img
            style={{ cursor: "pointer" }}
            src={edit_icon}
            onClick={() => clickEdit(info)}
            alt="edit-icon"
          />
          <img
            style={{ cursor: "pointer" }}
            src={trash_icon2}
            onClick={() => clickDelete(info)}
            alt="trash-icon"
          />
        </div>
      ),
      header: () => <span> </span>,
    }),
  ];
  return isLoading === null ? (
    <Loading imgStyle={{ marginTop: "150px" }} />
  ) : (
    <section className="members-container">
      <>
        <HeadingThree array={headingArray} headingDisplay="flex" />
        <FailedMsg
          msg={"You do not have permissions to perform this action!!!"}
        />
        <SuccessPopup
          display={displaySuccess}
          setDisplay={setDisplaySuccess}
          popupMessage={"You have successfully deleted " + memberName}
        />
        <DeletePopup
          display={displayDelete}
          deleteItem={deleteItem}
          popupName={memberName}
          isLoading={isDLoading}
          cancelDelete={cancelDelete}
          setDispay={setDisplayDelete}
        />
        {data.length === 0 ? "" : <Table data={data} columns={columns} minWidth={"100%"} />}
        <AddTeamMember modal={modal} setModal={setModal} />
      </>
      {data.length === 0 ? (
        <Empty
          img={emptyImg}
          title="Create a team member"
          btnText={"Add Team Member"}
          submitClick={() => setModal(true)}
        />
      ) : (
        ""
      )}
    </section>
  );
};

export default Members;
