const SubHeading = ({ text }) => {
    return (
        <h2 style={{
            fontWeight: '600',
            fontSize: '1.5rem',
            lineHeight: '1.875rem',
            color: '#000000',
        }}> 
            {text} 
        </h2>
    );
}
 
export default SubHeading;