import { useState } from "react";

import ToggleBar from "../../toggle-component/ToggleBar";
import Tooltip from "../../tooltip/Tooltip";


import info from "../../../assets/dashboard/info.png";


const Discount = ({ hasDiscount, setHasDiscount, setDiscountPercent }) => {
    const [showVariantTip, setShowVariantTip] = useState(false);
    const discounts = ['2', '5', '10'];
    
    const [ activeDiscount, setActiveDiscount ] = useState();

    
    const clickPopup = () => {
      setShowVariantTip(!showVariantTip);
    };

    const handleActiveDiscount = (discount) => {
        setActiveDiscount(discount);
        setDiscountPercent(discount);
    }


    return (
        <section className="discount-container">
            <section className="discount-selector">
                <ToggleBar
                toggleWidth="40px"
                toggleHeight="20px"
                toggleBallHeight="16px"
                toggleBallWidth="17px"
                toggleState={hasDiscount}
                setToggleState={setHasDiscount}
                />
                <h4 className="discount-text"> Discount </h4>
                <img src={info} className="info" alt="info" width={12} height={12} onClick={clickPopup} />
                <Tooltip
                tooltipText="This item has discount"
                tipWidth="150px"
                showTip={!showVariantTip ? "" : "flex"}
                showTipRight="180px"
                showTipBottom="-315px"
                />
            </section>
           { hasDiscount &&
                <section className="discount-options">
                    { discounts.map((discount, index) => (
                        <div  
                            key={index} 
                            className={`option ${activeDiscount === discount ? 'discount-active' : ''}`}
                            onClick={() => handleActiveDiscount(discount)}
                        >
                            <span> {discount}{"%"} </span>
                        </div>
                    ))}
                </section>
            }
        </section>
    );
}
 
export default Discount;