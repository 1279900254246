import { useEffect, useState } from "react";

import * as yup from "yup";

import useFormValidation from "../../../hooks/useFormValidation";
import CustomInput from "../../inputs/custom-input/custom-input.component";
import CustomTextarea from "../../inputs/custom-textarea/custom-textarea..component";
import SubHeading from "../sub-heading";

import SuccessMsg from "../../alert/success-msg.component";

import { AxiosAuthPost } from "../../../axios/axios";

import "./send-message.styles.css";
import { useDispatch } from "react-redux";
import { setSuccessModal } from "../../../states/general/general";


import spin from "../../../assets/landingpage/spinner.gif";
import convertArrayToObject from "../../excerpts/ConvertArrayToObject";


const initialValues = {
    name: '',
    message: '',
};

const validationSchema = yup.object().shape({
    name: yup.string().required('field may not be blank'),
    message: yup.string().required('field may not be blank'),
  });

const SendMessage= () => {
    const dispatch = useDispatch();
    const [errorMessages, setErrorMessages]  = useState({});

    const onSubmit = (values) => {
        const payload = {
            name: values.name,
            message: values.message
        };
        AxiosAuthPost('help-support/', payload)
            .then((res) => {
                dispatch(setSuccessModal(true));
            })
            .catch((err) => {
                const errors  = err.response.data.errors;
                const newObjects = convertArrayToObject(errors);
                setErrorMessages(newObjects);
            })
        };
        
        const { values, errors, isSubmitting, handleChange, handleSubmit, setErrors } = useFormValidation(initialValues, validationSchema, onSubmit);

    useEffect(() => {
        setErrors(errorMessages);
        // eslint-disable-next-line
    }, [errorMessages])
    

    
    return (
        <section className="msg-container">
            <section className="msg-sub-container">
                <SubHeading text={"Send Us a Message"} />
                <CustomInput 
                    label={"Full Name"}
                    name={"name"}
                    value={values['name']} 
                    errors={errors}
                    onChange={(e) => handleChange(e)}
                />
                <CustomTextarea 
                    label={"Reach Us"} 
                    name={"message"}
                    placeholder={"what can we help you with"}
                    value={values['message']} 
                    errors={errors}
                    onChange={(e) => handleChange(e)} 
                />
                <section className="msg-btn-container">
                    <button 
                        onClick={handleSubmit} 
                        className="msg-btn"
                    > 
                         { isSubmitting ? <img style={{ width: "20px", height: "20px" }} src={spin} alt="" /> : "Send Message" }  
                    </button>
                </section>
                <SuccessMsg msg={"Message sent successfully"} />
            </section>
        </section>
    );
}
 
export default SendMessage