import React from 'react';
import "./landing-page.css";

const TermsConditions = () => {

  const useOfApp = [
    {
      text: "Eligibility:",
      span: "By using the App, you affirm that you are at least 18 years old and have the legal capacity to enter into a binding agreement. If you are using the App on behalf of an organization, you represent and warrant that you have the necessary authority to bind that organization to these Terms.",
    },
    {
      text: "User Account:",
      span: "In order to access certain features of the App, you may need to create a user account. You are responsible for providing accurate and complete information during the registration process and for keeping your account information up-to-date. You are also responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.",
    },
    {
      text: "User Content:",
      span: `The App allows you to upload, submit, store, and manage inventory-related data ("User Content"). You retain ownership of any intellectual property rights in your User Content. By uploading or submitting User Content, you grant StockKeeper a non-exclusive, worldwide, royalty-free, and transferable license to use, reproduce, modify, adapt, publish, distribute, and display your User Content solely for the purpose of operating and improving the App.`,
    },
  ];
  const intellectProp = [
    {
      text: "Ownership:",
      span: "All rights, title, and interest in and to the App, including all content, features, and functionality, are and will remain the exclusive property of StockKeeper and its licensors. The App is protected by copyright, trademark, and other laws of both domestic and foreign jurisdictions.",
    },
    {
      text: "License:",
      span: "Subject to your compliance with these Terms, StockKeeper grants you a limited, non-exclusive, non-transferable, and revocable license to access and use the App for its intended purposes. This license does not grant you any rights to use StockKeeper's name, logo, or trademarks without our prior written consent.",
    },
  ];
  const termination = [
    "Upon receipt of instructions from you to suspend or terminate your access to the Platform;",
    "Upon receipt of notification from you that your Device has been lost or stolen;",
    "If we suspect the Platform is being used for fraudulent or other illegal activities;",
    "If we are of the opinion that you have breached a provision contained in this Terms of Use; If we are of the opinion that continued access to the Platform will be detrimental to our business; or",
    "If you provide any information that is untrue, inaccurate, not current or incomplete, or if we have reasonable grounds to suspect that such information is untrue, inaccurate, not current, or incomplete.",
  ];

  return (
    <section className="privacy-policy">
      <div className="privacy-policy_cont">
        <div className="privacy-policy_head">
          <h2>
          Terms & <span>Conditions</span>
          </h2>
          <p>
          These Terms and Conditions govern your access to and use of the StockKeeper web application and mobile application (collectively referred to as the "App"), provided by Stock Keeper ("we," "us," or "our"). By accessing or using the App, you ("user," "you," or "your") agree to these Terms in their entirety. Please read these Terms carefully before using the App. If you do not agree with any part of these Terms, please refrain from using the App.
          </p>
        </div>

        <div className="privacy-policy_body">
          <div className="when-collect">
            <h3>1. About Us </h3>
            <p>
              Stock Keeper (“We”, “Us” or “Our”) is a team of solution-providing, forward-thinking, and ambitious individuals who believe in enabling easier ways to aid business operations, which includes stock-keeping and inventory management. We achieve this by building our easy-to-use solutions for customers, one business after the other. <br /> <br />We combine the best technology to help businesses grow efficiently with less manual labour and with digitization. Digital inclusion in businesses is needed and that is the solution we are providing with the App.
            </p>
          </div>
          <div className="info-collect">
            <h3>2. Use of The App</h3>
            {useOfApp &&
              useOfApp.map((item, idx) => {
                return (
                  <p key={idx}>
                    {item.text} <span>{item.span}</span>
                  </p>
                );
              })}
          </div>
          <div className="info-collect">
            <h3>3. Intellectual Property</h3>
            {intellectProp &&
              intellectProp.map((item, idx) => {
                return (
                  <p key={idx}>
                    {item.text} <span>{item.span}</span>
                  </p>
                );
              })}
          </div>
          <div className="when-collect">
            <h3>4. Representation & Warranties </h3>
            <p>
            You represent and warrant to Stock Keeper that you are duly organised, authorised, and in good standing under the laws of the Federal Republic of Nigeria or any state, region or country of your organisation and are duly authorised to do business in all other states, regions, or countries in which your business operates.
            </p>
          </div>
          <div className="when-collect">
            <h3>5. Changes To Terms </h3>
            <p>
            StockKeeper may revise and update these Terms from time to time at its sole discretion. All changes are effective immediately upon being posted on the App. Your continued use of the App following the posting of revised Terms constitutes your acceptance and agreement to the changes.
            </p>
          </div>
          <div className="when-collect">
            <h3>6. Our Fees & Pricing Schedule </h3>
            <p>
            You agree to pay us for the services we render for automating your business operations. Our Fees are as demonstrated on the Pricing page on our website. The Fees on our Pricing page are integral to and form part of this Agreement, and are based on the ease we aim to provide. <br /> We reserve the right to revise our Fees. In the event that we revise our fees, we will notify you within 30 days of such change.
            </p>
          </div>
          <div className="data-security">
            <h3>7. Termination</h3>
            <p>
            StockKeeper reserves the right, in its sole discretion, to suspend, modify, or terminate your access to the App at any time for any reason, without notice. Some of the other reasons can include:
            </p>
            <ul>
              {termination &&
                termination.map((item, idx) => {
                  return <li key={idx}>{item}</li>;
                })}
            </ul>
          </div>
          <div className="when-collect">
            <h3>8. Disclaimer </h3>
            <p>
            We try to keep Stock Keeper available at all times, bug-free and safe, however, you use it at your own risk. <br /> <br /> Our Website and Services are provided “as is” without any express, implied and/or statutory warranties (including, but not limited to, any implied or statutory warranties of merchantability, fitness for a particular use or purpose, title, and non-infringement of intellectual property rights). Without limiting the generality of the foregoing, Stock Keeper makes no warranty that our Website and Services will meet your requirements or that our Website will be uninterrupted, timely, secure, or error free. No advice or information, whether oral or written, obtained by you through our Website or from Stock Keeper, its parents, subsidiaries, or other affiliated companies, or its or their suppliers (or the respective officers, directors, employees, or agents of any such entities) (collectively, "Thepeer parties") shall create any warranty.
            </p>
          </div>
          <div className="when-collect">
            <h3>9. Limitation of Liability </h3>
            <p>
            To the maximum extent permitted by applicable law, StockKeeper and its affiliates, officers, directors, employees, agents, and licensors shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from (a) your access to or use of or inability to access or use the App; (b) any conduct or content of any third party on the App; (c) any unauthorized access, use, or alteration of your transmissions or content.
            </p>
          </div>
          <div className="when-collect">
            <h3>10. Indemnification </h3>
            <p>
            You agree to indemnify Stock Keeper, and its subsidiaries, affiliates, officers, agents and employees for any loss, damages, liabilities, sanction, claims, suits, fines, charges, expenses due to or arising out of any transaction you process through the Platform. <br /><br /> You agree to indemnify Stock Keeper and its subsidiaries, affiliates, officers, agents and employees for any loss, damages, liabilities, sanction, claims, suits, fines, charges, expenses arising from your violation of this Terms of Use.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TermsConditions