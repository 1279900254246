import React from "react";
import Back from "../../Components/Back";
import appleLogo from "../../assets/landingpage/appleLogo.png";
import linkedinLogo from "../../assets/landingpage/linkedinLogo.png";
import googleLogo from "../../assets/landingpage/googleLogo.png";
import { Link } from "react-router-dom";
import {AuthHead} from "../../Components/AuthHead/AuthHead";

const Signup = () => {
  return (
    <div >
        <Back />
        <AuthHead headerText="Tack Control Of your Inventory Today" pText="Keep your inventory in check. Sign up to streamline your inventory
            processes." />
        <div className="signup-cards_cont">
          <Link to="/signupemail" style={{ textDecoration: "none" }}>
            <div className="signup-card" style={{ background: "#080B24" }}>
              <p>Sign up with Phone Number</p>
            </div>
          </Link>
          <div className="signup-card">
            <img src={googleLogo} alt="google logo" />
            <p style={{ color: "#1D1C2B" }}>Sign up with Google</p>
          </div>
          <div className="signup-card" style={{ background: "#000000" }}>
            <img src={appleLogo} alt="apple logo" />
            <p>Sign up with Apple</p>
          </div>
          <div className="signup-card" style={{ background: "#0A66C2" }}>
            <img src={linkedinLogo} alt="linkedin logo" />
            <p>Sign up with LinkedIn</p>
          </div>
        </div>
      </div>
  );
};

export default Signup;
