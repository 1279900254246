import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { CloseCircle} from "iconsax-react";

import { setFailureModal } from "../../states/general/general";

import "./alert.styles.css";

const FailedMsg = ({ msg }) => {
    const dispatch = useDispatch();
    const { failureModal } = useSelector((state) => state.general);


    
    useEffect(() => {
        if (failureModal) {
          const timeout = setTimeout(() => {
            dispatch(setFailureModal(false));
          }, 3000 );
    
          return () => clearTimeout(timeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [failureModal]);

    return (
        <section className={`failed-msg-container ${failureModal ? 'slide-in' : 'slide-out'}`}>
            <div 
                style={{
                    display: 'flex',
                    gap: '24px',
                    alignItems: 'center',
                }}
            >
                <CloseCircle
                    color="red"
                    size="32"
                    style={{ cursor: "pointer" }}
                />
                <span> {msg} </span>
            </div>
        </section>
    );
}
 
export default FailedMsg;