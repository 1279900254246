import React, { useState } from "react";
import Back from "../../Components/Back";
// import apple from "../../assets/landingpage/AppleButton.png";
// import google from "../../assets/landingpage/GoogleButton.png";
// import linkedin from "../../assets/landingpage/LinkedInButton.png";
import {
  AuthHead,
  CheckBox,
  FormButton,
  InputForm,
  PasswordInput,
} from "../../Components/AuthHead/AuthHead";
import { Link, useNavigate } from "react-router-dom";
import { AxiosPost } from "../../axios/axios";
import useAuth from "../../hooks/useAuth";

const Login = () => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setAuth } = useAuth();
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const handleDataChange = (e) => {
    setErrorMessage("")
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    // console.log(newData);
  };
  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked);
  };
  const url = "auth/normal-login/";
  const dataObject = {
    credential: data.email,
    password: data.password
  };
  const navigate = useNavigate();
  // const windowWidth = window.innerWidth;
  const handleSubmit = () => {
    if (data.email && data.password) {
      setIsLoading(true);
      AxiosPost(url,dataObject)
      .then(res => {
        // console.log(res);
        setIsLoading(false);
        if(res.data.accountType === "Admin"){
          localStorage.clear();
          localStorage.setItem("stk-apk", JSON.stringify(res.data));
          navigate("/admin-overview");
          // setTimeout(function() {
            // window.location.reload();
          // }, 100);
        }
        if (!res.data.accountSetupCompleted && res.data.accountType !== "Admin"){
          localStorage.setItem("stk-apk", JSON.stringify(res.data));
          navigate("/signuponboarding")
        } 
        if(res.data.accountType !== "Admin"){
          // if (windowWidth < 1000  ){
          //   navigate("/mobile-redirect");
          // }
          // if (windowWidth > 1000  ){
            setAuth(res.data);
            localStorage.setItem("stk-apk", JSON.stringify(res.data));
            navigate("/overview");
            // setTimeout(function() {
              // window.location.reload();
            // }, 100);
          // }
        }
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err);
        if (err.message !== "Request failed with status code 500" && err.message !== "Network Error") {
        setErrorMessage(err.response.data.message);
        }
      })
    }
  };
  return (
    <div>
      <Back />
      <AuthHead
        headerText="Welcome Back"
        pText="Stay organized and stay on top of your inventory. Sign in to your
            account."
      />
      <div className="loginpage-form">
        <form method="post">
          <InputForm
            formLabel="Email Address/Phone Number"
            placeholder="Enter your email address/phone number"
            dataChange={handleDataChange}
            formId="email"
            formValue={data.email}
            errorMessage={errorMessage ? " " : ""}
          />
          <PasswordInput
            formLabel="Password"
            placeholder="Enter your password"
            dataChange={handleDataChange}
            formId="password"
            formValue={data.password}
            errorMessage={errorMessage}
          />
          {errorMessage ? <Link to="/forgot-password" style={{textDecoration: "none"}}><p style={{color: "#F08227"}}>Forgot Password?</p></Link> : ""}
          <div
            className={
              data.email && data.password
                ? `remember-me display-flex`
                : `display-none`
            }
          >
            <CheckBox checkboxChange={handleCheckboxChange} checked={isCheckboxChecked} />
            <h6 className={isCheckboxChecked ? "focus-label" : ""}>
              Remember me
            </h6>
          </div>
          <FormButton
            submitForm={handleSubmit}
            isLoading={isLoading}
            dataValues={data.email && data.password}
            buttonText="login"
          />
        </form>
      </div>
      {/* <div className="login-continue">
        <p>Or continue with</p>
      </div>
      <div className="login-page_external-links">
        <img src={google} alt="google" />
        <img src={apple} alt="apple" />
        <img src={linkedin} alt="linkedin" />
      </div> */}
      <div className="login-signup">
        <p>Don’t have an account? <Link to="/signupemail" style={{textDecoration: "none"}}><span>Sign up</span> </Link></p>
      </div>
    </div>
  );
};

export default Login;
