import { ArrowUp } from "iconsax-react";
import Dashboardbtn from "../../dashboard-buttons/Dashboardbtn";
import SubHeading from "../../help-support/sub-heading";
import { useState } from "react";
import PriceCard from "./PriceCard";

const Plan = ({ currentPlan, lastSub }) => {
    
    const [display, setDisplay] = useState(false);

    const upgradePlan = () => {
        setDisplay(true);
    }


  return (
    <section className="billing-container">
        <PriceCard display={display} setDisplay={setDisplay} currentPlan={currentPlan} lastSub={lastSub} />
      <section className="billing-plan">
        <section className="plan-type">
          <SubHeading text={currentPlan?.type} />
          <span className="duration"> {currentPlan?.period} </span>
        </section>

        <section>
          <section className="billing-duration-amount">
            <span className="amount"> ₦{currentPlan?.amount} </span>
            <span className="duration">
              {" "}
              {currentPlan?.period === "Monthly"
                ? "Per Month"
                : "Per Year"}{" "}
            </span>
          </section>
          <section className="btn-hold">
            <Dashboardbtn
              btnClass={currentPlan?.type === "Free" ? "dashboard-btn" : "btn-green"}
              btnText={currentPlan?.type === "Free" ? "Upgrade Plan" : "Subscribe"}
              rightBtnIcon={currentPlan?.type === "Free" && <ArrowUp color="#FFFFFF" className="arrow-left" />}
              submitForm={upgradePlan}
            />
          </section>
        </section>
      </section>
    </section>
  );
};

export default Plan;
