import { useEffect, useState } from "react";

import { Outlet, useLocation, useNavigate } from "react-router-dom";

import SideNav from "../sidenav/sidenav.component";
import TopBar from "../top-bar/top-bar.component";

import "./protected.styles.css";
import { AxiosGet } from "../../axios/axios";
import { useDispatch} from "react-redux";
import { profile } from "../../states/profile/profileSlice";
import Loading from "../Loading";
import { bDropdown } from "../../states/business/businessDropdown";
import { itemDd } from "../../states/items/itemDropdown";
import { newUser } from "../../states/user/user";

const Protected = () => {
  const { pathname } = useLocation();
  const profileUrl = "profile/";
  const businessUrl = "business/";
  const allItemsUrl = "items-all/?itemType=Stand Alone";
  const dispatch = useDispatch();
  const userDetails = JSON.parse(localStorage.getItem("stk-apk"));

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(null);

  // setAuth(userToken)
  const checkUserToken = () => {
    const userToken = JSON.parse(localStorage.getItem("stk-apk"));
    const targetDateTime = userToken?.authTokenExp;
    const currentDateTime = new Date().toISOString();
    // console.log(userToken);
    if (!userToken || userToken === "undefined") {
      setIsLoggedIn(false);
      return navigate("/login");
    }
    if (targetDateTime < currentDateTime) {
      setIsLoggedIn(false);
      localStorage.removeItem("stk-apk");
      return navigate("/login");
    }
    if (!userToken?.accountType || userToken?.accountType !== "Admin"){
      setIsLoggedIn(true);
    }
    if (userToken?.accountType === "Admin"){
      navigate("/admin-overview");
    }
  };
  useEffect(() => {
    checkUserToken();
    // eslint-disable-next-line 
  }, [isLoggedIn]);

  useEffect(() => {
    AxiosGet(profileUrl)
      .then((res) => {
        // console.log(res);
        dispatch(profile(res.data));
        setIsLoading(true);
      })
      .catch((err) => {
        // console.log(err.response.status);
        if (err.response.status === 403){
          navigate("/login");
          // localStorage.clear();
        }
      });
      // eslint-disable-next-line
  },[isLoggedIn]);
  
  useEffect(() => {
    AxiosGet(businessUrl)
      .then((res) => {
        // console.log(res.data);
        dispatch(bDropdown(res.data.businessList));
      })
      .catch((err) => {
        // console.log(err.response);
      });
      dispatch(newUser(userDetails.permissions));
      // console.log(userDetails.permissions);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    AxiosGet(allItemsUrl)
      .then((res) => {
        // console.log(res);
        dispatch(itemDd(res.data));
      })
      .catch((err) => {
        // console.log(err.response);
      });
    // eslint-disable-next-line
  }, []);

  return isLoggedIn ? (
    <section className="layout-container">
      {isLoading === null && <Loading /> }
      {isLoading && <>
          <SideNav />
          <section className="content">
            <TopBar />
            <Outlet />
          </section>
        </>}
        
    </section>
  ) : null;
};

export default Protected;
