import Profile from "../profile/profile.component";
import SearchBar from "../search-bar/search-bar.component";

import "./top-bar.styles.css";

const TopBar = () => {
    return (
        <section className="top-bar">
            <SearchBar />
            <Profile />
        </section>
    );
}
 
export default TopBar;