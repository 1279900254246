import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { CloseCircle } from "iconsax-react";

import OnlyDate from "../excerpts/OnlyDate";
import OnlyTime from "../excerpts/OnlyTime";

import ConnectToPrinter from "./connect-to-printer.component";

import "./receipt.styles.css";
import { useRef } from "react";
import {
  clearSingleReceiptId,
  clearSingleSaleId,
} from "../../states/sales/sales";

const Receipt = ({ showReceipt, setShowReceipt, singleSaleId }) => {
  const { singleReceiptId } = useSelector((state) => state.sales);
  const dispatch = useDispatch();
  const myProfile = useSelector((state) => state.profileDetails);
  const [showModal, setShowModal] = useState(false);
  const receiptRef = useRef(null);

  const hanldeReceiptSlideOut = () => {
    setShowReceipt(false);
    dispatch(clearSingleReceiptId());
    dispatch(clearSingleSaleId());
  };

  const handleConnectToPrinterModalShow = () => {
    setShowReceipt(false);
    setShowModal(true);
  };

  return (
    <>
      <div className={`overlay ${showReceipt ? "w-full" : "w-0"}`}></div>
      <aside
        className={`receipt-container ${
          showReceipt ? "slide-in" : "slide-out"
        }`}
      >
        <section className="heading">
          <h4>Receipt</h4>
          <CloseCircle
            size="32"
            className="close-receipt"
            color="#111111"
            onClick={hanldeReceiptSlideOut}
          />
        </section>
        <section ref={receiptRef} className="receipt-info-container">
          <h5 style={{textTransform: "capitalize"}}>
            {!myProfile.organizationDetails.name
              ? myProfile.firstName + " " + myProfile.lastName
              : myProfile.organizationDetails.name}
          </h5>
          <div className="company-info">
            <h3>{myProfile.organizationDetails.industry}</h3>
            <p>{myProfile.organizationDetails.address}</p>
          </div>
          <div
            style={{
              border: "1px dashed #A3A3A3",
              marginTop: "30px",
            }}
          ></div>

          <section className="items-container">
            <div className="item">
              <span className="title">Customer Name:</span>
              <span className="info" style={{ textTransform: "capitalize" }}>
                {" "}
                {singleReceiptId && singleReceiptId.customerName}{" "}
                {singleSaleId && singleSaleId.customerName}{" "}
              </span>
            </div>
            <div className="item">
              <span className="title">Salesperson Name:</span>
              <span className="info">
                {" "}
                {singleReceiptId && singleReceiptId.sellerName}
                {singleSaleId && singleSaleId.sellerName}{" "}
              </span>
            </div>
            <div className="item">
              <span className="title">Date:</span>
              <span className="info">
                {" "}
                <OnlyDate
                  date={
                    singleReceiptId
                      ? singleReceiptId.createdAt
                      : singleSaleId.createdAt
                  }
                />{" "}
              </span>
            </div>
            <div className="item">
              <span className="title">Time:</span>
              <span className="info">
                {" "}
                <OnlyTime
                  date={
                    singleReceiptId
                      ? singleReceiptId.createdAt
                      : singleSaleId.createdAt
                  }
                />{" "}
              </span>
            </div>
            <div className="item">
              <span className="title"> Sales I.D: </span>
              <span className="info">
                {" "}
                {singleReceiptId && singleReceiptId?.salesNumber}
                {singleSaleId && singleSaleId?.salesNumber}{" "}
              </span>
            </div>
            <div className="item">
              <span className="title"> Receipt Number: </span>
              <span className="info">
                {" "}
                {singleReceiptId && singleReceiptId?.receiptNumber}
                {singleSaleId && singleSaleId?.receiptNumber}{" "}
              </span>
            </div>
          </section>

          <div
            style={{
              border: "1px dashed #A3A3A3",
              marginTop: "30px",
            }}
          ></div>

          <section className="receipt-item-qty-amount-container">
            {singleReceiptId?.itemsDict?.length > 0 &&
              singleReceiptId?.itemsDict?.map((item, idx) => {
                return (
                  <section key={idx} className="receipt-item-qty-amount">
                    <p style={{ textTransform: "capitalize" }}> {item.name} </p>
                    <section className="qty-amount">
                      <div className="qty">
                        <span className="first"> Quantity: </span>
                        <span className="second"> {item.quantity} </span>
                      </div>

                      <div className="amount">
                        <span className="first"> Total Amount: </span>
                        <span className="second"> ₦ {item.totalAmount} </span>
                      </div>
                    </section>
                  </section>
                );
              })}
            {singleSaleId?.itemsDict?.length > 0 &&
              singleSaleId?.itemsDict?.map((item, idx) => {
                return (
                  <section key={idx} className="receipt-item-qty-amount">
                    <p style={{ textTransform: "capitalize" }}> {item.name} </p>
                    <section className="qty-amount">
                      <div className="qty">
                        <span className="first"> Quantity: </span>
                        <span className="second"> {item.quantity} </span>
                      </div>

                      <div className="amount">
                        <span className="first"> Total Amount: </span>
                        <span className="second"> ₦ {item.totalAmount} </span>
                      </div>
                    </section>
                  </section>
                );
              })}
          </section>

          <div
            style={{
              border: "1px dashed #A3A3A3",
              marginTop: "30px",
            }}
          ></div>

          <section className="total-amount-container">
            <p id="receipt-total-amount"> Total Amount </p>
            {singleReceiptId && (
              <span>
                {" "}
                {!singleReceiptId.total ? "" : "₦"} {singleReceiptId.total}{" "}
              </span>
            )}
            {singleSaleId && (
              <span>
                {" "}
                {!singleSaleId.total ? "" : "₦"} {singleSaleId.total}{" "}
              </span>
            )}
          </section>
        </section>

        <button
          onClick={handleConnectToPrinterModalShow}
          className="print-receipt"
        >
          Print Receipt
        </button>
      </aside>
      <ConnectToPrinter
        ref={receiptRef}
        showModal={showModal}
        setShowModal={setShowModal}
        setShowReceipt={setShowReceipt}
      />
    </>
  );
};

export default Receipt;
