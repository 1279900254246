import { useEffect, useState } from "react";

import * as yup from "yup";

import { CloseCircle } from "iconsax-react";

import { AxiosAuthPost } from "../../axios/axios";

import useFormValidation from "../../hooks/useFormValidation";

import { useDispatch, useSelector } from "react-redux";

import { clearSingleExpense, getExpenses, setExpenseModal, updateExpense } from "../../states/expenses/expenses";
import { setFailureModal, setSuccessModal } from "../../states/general/general";

import CustomInput from "../inputs/custom-input/custom-input.component";
import AmountInput from "../amount-input/AmountInput";
import CustomSelect from "../inputs/custom-select/custom-select.component";

import { IsEmptyObject } from "../excerpts/IsEmptyObject";

import SuccessMsg from "../alert/success-msg.component";

import spin from "../../assets/landingpage/spinner.gif";
import convertArrayToObject from "../excerpts/ConvertArrayToObject";
import FailedMsg from "../alert/failed-msg.component";


const validationSchema = yup.object().shape({
    description: yup.string().required('description is required'),
    amount: yup.number().typeError("amount must be a number").required('amount is required'),
});

const initialValues = {
    description: '',
    amount: '',  
};

const options = [
    {value: "salary" , label: "Salary"},
    {value: "utility" , label: "Utility"},
    {value: "maintenance" , label: "Maintenance"},
    {value: "others" , label: "Others"},
];


const ExpenseModal = () => {
    const dispatch = useDispatch();
    const [failedMsg, setFailedMsg] = useState("");
    const { expenseModal, singleExpense } = useSelector((state) => state.expenses);

    const [selectedOption, setSelectedOption] = useState(options[0]);
    const [submitType, setSubmitType] = useState("add");

    const [errorMessages, setErrorMessages]  = useState({});
    
    


    const onSubmit = (values) => {
        const data = {
            type: selectedOption.label,
            description: values.description,
            amount: parseFloat(values.amount)
        }
        if (submitType === "add") {
            AxiosAuthPost("expenses/", data)
                .then((res) => {
                    dispatch(getExpenses("?orderBy=Latest&dateFilter=Today"));
                    dispatch(setExpenseModal(false));
                    dispatch(setSuccessModal(true));
                }).catch((err) => {
                    const errors  = err.response.data.errors;
                    if (err.response.status === 403){
                      setFailedMsg(err.response.data.message);
                      dispatch(setFailureModal(true));
                    } else {
                        const newObjects = convertArrayToObject(errors);
                        setErrorMessages(newObjects);
                    }
                });
        } else if (submitType === "edit") {
            const payload = {
                id: singleExpense.id,
                data,
            }
            dispatch(updateExpense(payload))
                .unwrap()
                .then((res) => {
                    dispatch(getExpenses("?orderBy=Latest&dateFilter=Today"));
                    dispatch(setExpenseModal(false));
                    dispatch(clearSingleExpense());
                    dispatch(setSuccessModal(true));
                })
                .catch((err) => {
                    const errors  = err.response.data.errors;
                    if (err.response.status === 403){
                      setFailedMsg(err.response.data.message);
                      dispatch(setFailureModal(true));
                    } else {
                        const newObjects = convertArrayToObject(errors);
                        setErrorMessages(newObjects);
                    }
                })
        }
        
       
    };

    const { values, 
        errors, 
        setValues,
        handleChange, 
        handleSubmit,
        isSubmitting,
        setErrors
    } = useFormValidation(initialValues, validationSchema, onSubmit);


    const handleModalClose = () =>{ 
        setValues(initialValues);
        setSelectedOption(options[0]);
        dispatch(setExpenseModal(false));
        dispatch(clearSingleExpense());
    };

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };

    useEffect(() => {
        setErrors(errorMessages);
        // eslint-disable-next-line
    }, [errorMessages])

    useEffect(() => {
        if (!IsEmptyObject(singleExpense)) {
            setValues((prev) => ({...prev, description: singleExpense.description, amount: singleExpense.amount }));
            setSelectedOption(options.find(option => option.label.toLowerCase() === singleExpense.type.toLowerCase()));
            setSubmitType("edit");
        } else {
            setSubmitType("add");
        }
        // eslint-disable-next-line
    }, [singleExpense]);

    return (
        <>
            <div className={`overlay ${expenseModal ? 'w-full' : 'w-0'}`}></div>
            {expenseModal && <FailedMsg msg={failedMsg} />}
            <section className={`expense-modal ${expenseModal ? 'opacity-1' : 'opacity-0'}`}>
                <section className="expense-modal-heading">
                    <h3> Add Expense </h3>
                    <CloseCircle size="32" className="close" color=" #111111" onClick={handleModalClose} />
                </section>
                <CustomSelect 
                        label={"Expense Type"} 
                        options={options} 
                        name={"type"}
                        value={selectedOption}
                        onChange={handleSelectChange}
                />
                <CustomInput 
                    name={"description"} 
                    label={"Description"} 
                    placeholder={"Enter Description"}
                    value={values['description']} 
                    errors={errors}
                    onChange={(e) => handleChange(e)}
                />
                <section className="pt">
                    <AmountInput 
                        name={'amount'} 
                        amountLabel={"Amount"} 
                        amountPlaceholder={"0.00"}
                        amountValue={values['amount']} 
                        errorMessage={errors['amount']}
                        dataChange={handleChange}
                    />
                </section>
                <section className="btn-hold">
                    <button className="add-expense" onClick={handleSubmit}>  
                        { isSubmitting ? <img style={{ width: "20px", height: "20px" }} src={spin} alt="" /> : "Add Expense" }  
                    </button>
                </section>
            </section>
            <SuccessMsg msg={"You have successfully added a new expense"} />
        </>
    );
}
 
export default ExpenseModal;