// import { Add } from "iconsax-react";
import { useState } from "react";
import Loading from "../../Components/Loading";
import Dashboardheader from "../../Components/dashboard-header/Dashboardheader";
import FreeTrial from "../../Components/overview/FreeTrial";
import OverviewComponent from "../../Components/overview/overview.component";
import { AxiosGet } from "../../axios/axios";
import useActivityHistoryData from "../../hooks/useActivityHistoryData";
import { useEffect } from "react";

const Overview = () => {

  const [isLoading, setIsLoading] = useState(null);
  const [overviewInventory, setOverviewInventory] = useState();
  const { activityHistoryData } = useActivityHistoryData();
  const url = `overview/inventory-summary/?dateFilter=${activityHistoryData.dateValue}&startDate=${activityHistoryData.startDate}&endDate=${activityHistoryData.endDate}`;
  useEffect(() => {
    AxiosGet(url)
      .then((res) => {
        // console.log(res);
        setOverviewInventory(res.data);
        setIsLoading(false);
      })
      .catch((err) => {});
    // eslint-disable-next-line
  }, [activityHistoryData]);

  return isLoading === null ? (
    <Loading imgStyle={{ marginTop: "150px" }} />
  ) :  (
    <section>
      <FreeTrial subscription={overviewInventory?.subscriptionDaysRemaining} subPlan={overviewInventory?.subscriptionPlanType}/>
      <Dashboardheader
        headerText="overview"
        // addStyle="flex"
        // addBtnText="add"
        // addBtnIcon={<Add />}
      />
      <OverviewComponent overviewInventory={overviewInventory} />
    </section>
  );
};

export default Overview;
