import React, { Fragment, useState } from "react";

import { useSelector } from "react-redux";

import increase_icon from "../../../assets/dashboard/increase-icon.svg";
// import decrease_icon from "../../../assets/dashboard/decrease-icon.svg";

import Notification from "../../notification/Notification";
import DropdownSort from "../../dropdown-sort/dropdown-sort.component";
import SalesHistoryTable from "./sales-history-table.component";

import "./sales.styles.css";
import Loading from "../../Loading";

const SalesComponent = () => {
    const { sales } = useSelector((state) => state.sales);
    const items = (sales?.result && sales?.result?.topSellingItems !== undefined ) && sales?.result?.topSellingItems.map(item => item.name);
    const [ activeItem, setActiveItem ] = useState(items[0]);
    const info = [
        { heading: "TOTAL QTY SOLD", number: sales?.result?.totalQuantitySold, img: increase_icon, text: "10% increase compared to Last Month" }, 
        { heading: "TOTAL AMOUNT SOLD", number: "₦" + Number(sales?.result?.totalAmountSold).toLocaleString() + ".00" , img: increase_icon, text: "10% increase compared to Last Month" }, 
        { heading: "SALES PROFIT", number: "₦" + Number(sales?.result?.salesProfit).toLocaleString() + ".00", img: increase_icon, text: "10% decrease compared to Last Month" }, 
    ];

    const handleActiveList = (item) => {
        setActiveItem(item);
    }

    if (sales.status === "loading") {
        return <Loading />
    }

    return (
        <section className="sales-component-container">
            <section className="info-container">
                
                <section className="qty-amt-profit">
                    { info.map(({heading, number, img, text}, id) => (
                        <React.Fragment key={id}>
                            <section className="qty">
                                <h5> {heading} </h5>
                                <p className="numbers"> {number} </p>
                                <span className="comparison"> <img src={img} alt={'arrow-icon'} /> {text}  </span>
                            </section>
                            { heading !== "SALES PROFIT" &&
                                <div style={{
                                    border: '1px solid #D1D1D1',
                                }} className="line-bd"></div>
                            }
                        </React.Fragment>
                    ))
                    }
                </section>

                <section className="top-selling">
                    <h5> Top Selling Item </h5>
                    <section className="flex">
                        <ol>
                            { (sales?.result && sales.result?.topSellingItems?.length > 0) && sales?.result?.topSellingItems.map(({id, name}) => (
                                <Fragment key={id}>
                                    <li 
                                    onClick={() => handleActiveList(name)}
                                    className={`${activeItem === name ? 'active-list' : ''}`}
                                    > 
                                        {name} 
                                    </li>
                                </Fragment>

                            ))}
                        </ol>
                        <section>
                            <div>
                                <h6>QTY SOLD</h6>
                                <span> { (sales?.result && sales.result?.topSellingItems?.length > 0) && sales?.result?.topSellingItems.find((item) => item.name.trim() === activeItem.trim()).quantity } </span>
                            </div>
                            <div className="total">
                                <h6>TOTAL AMOUNT</h6>
                                <span> { (sales?.result && sales.result?.topSellingItems?.length > 0) && sales?.result?.topSellingItems.find((item) => item.name.trim() === activeItem.trim()).totalAmount } </span>
                            </div>
                        </section>
                    </section>
                </section>

            </section>

            <section className="sales-history-header">
                <h4> Sales History </h4>
                <section className="flex sales-flex">
                    <Notification notificationStyle={"flex"} />
                    <DropdownSort />
                </section>
            </section>

            <section className="sales-history-table-container">
                <SalesHistoryTable />
            </section>

        </section>
    );
}
 
export default SalesComponent;