import React, { useState } from "react";
import Dashboardheader from "../../Components/dashboard-header/Dashboardheader";
import HeadingThree from "../../Components/expenses/heading-three.component";

import "./advanced-search.css";
import Dcheckbox from "../../Components/dashboard-checkbox/Dcheckbox";
import { FolderOpen } from "iconsax-react";
import { Link, useNavigate } from "react-router-dom";
import itemImg from "../../assets/dashboard/single-item.svg";
import memberImg from "../../assets/dashboard/profile_img.png";
import emptySearch from "../../assets/dashboard/empty-search.svg";
import nothing from "../../assets/dashboard/nothing.png";
import SelectDelete from "../../Components/select-delete-bar/SelectDelete";
import AdvancedFilter from "../../Components/filter-popups/AdvancedFilter";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../Components/Loading";
import Empty from "../../Components/empty/empty.component";
import useSearchParam from "../../hooks/useSearchParam";
import { AxiosGet } from "../../axios/axios";
import { search } from "../../states/search/searchSlice";
import DeletePopup from "../../Components/filter-popups/DeletePopup";

const AdvancedSearch = () => {
  const searchResult = useSelector((state) => state.searchDetails);
  // console.log(searchResult);
  const { searchParam } = useSearchParam();
  const dispatch = useDispatch();
  const [stockLevel, setStockLevel] = useState([]);
  const [displayDelete, setDisplayDelete] = useState(false);
  const [sortby, setSortby] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    minPrice: "",
    maxPrice: "",
  });
  const handleDataChange = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    // console.log(newData);
  };
  const handleStock = (e) => {
    if (e.target.checked === true) {
      setStockLevel([...stockLevel, e.target.id]);
    }
    if (e.target.checked === false) {
      setStockLevel(stockLevel.filter((item) => item !== e.target.id));
    }
  };
  const handleSortby = (e) => {
    setSortby(e.target.id);
  };
  const url = `advance-search/?searchQuery=${searchParam}&stockLevel=${stockLevel.join(
    ","
  )}&sortBy=${sortby}&itemPriceMin=${data.minPrice}&itemPriceMax=${
    data.maxPrice
  }`;
  const navigate = useNavigate();
  const submitFilter = () => {
    setIsLoading(true);
    AxiosGet(url)
      .then((res) => {
        // console.log(res);
        setIsLoading(false);
        dispatch(search(res.data));
        setDisplay(false);
      })
      .catch((err) => {
        // console.log(err.response);
        setIsLoading(false);
      });
  };
  const headingArray = !searchResult
    ? ""
    : [
        {
          text: "Sections",
          amount: Number(
            searchResult?.totalDetails?.businessCount
          ).toLocaleString(),
        },
        {
          text: "Items",
          amount: Number(searchResult?.totalDetails?.itemCount).toLocaleString(),
        },
        {
          text: "Team Members",
          amount: Number(
            searchResult?.totalDetails?.teamMembersCount
          ).toLocaleString(),
        },
        {
          text: "Quantity",
          amount: Number(searchResult?.totalDetails?.quantity).toLocaleString(),
        },
      ];
  const folderArray = !searchResult
    ? []
    : searchResult?.searchResult?.businessData?.businessList;
  const membersArray = !searchResult
    ? []
    : searchResult?.searchResult?.membersData?.memberList;
  const itemsArray = !searchResult ? [] : searchResult?.searchResult?.itemsData?.itemsList;
  const [folderId, setFolderId] = useState([]);
  const [generalId, setGeneralId] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [itemId, setItemId] = useState([]);
  // const [displayMore, setDisplayMore] = useState(false);
  const [display, setDisplay] = useState(false);
  // const [selectedItemId, setSelectedItemId] = useState(null);
  const handleCheckbox = (e) => {
    if (folderArray) {
      if (e.target.checked === true) {
        setFolderId([...folderId, e.target.id]);
        setGeneralId([...generalId, e.target.id]);
      }
      if (e.target.checked === false) {
        setFolderId(folderId.filter((item) => item !== e.target.id));
        setGeneralId(generalId.filter((item) => item !== e.target.id));
        setIsChecked(false);
      }
    }
  };
  const handleItemCheck = (e) => {
    if (itemsArray) {
      if (e.target.checked === true) {
        setItemId([...itemId, e.target.id]);
        setGeneralId([...generalId, e.target.id]);
      }
      if (e.target.checked === false) {
        setItemId(itemId.filter((item) => item !== e.target.id));
        setGeneralId(generalId.filter((item) => item !== e.target.id));
        setIsChecked(false);
      }
    }
  };
  const handleGeneralCheck = (e) => {
    if (e.target.checked === true) {
      setIsChecked(true);
      const newArray = e.target.id;
      // setGeneralId(newArray.split(","));
      const filteredArray = newArray.split(",").filter((item) => item !== "");
      setGeneralId(filteredArray);
    }
    if (e.target.checked === false) {
      setIsChecked(false);
      setGeneralId([]);
      setFolderId([]);
      setItemId([]);
    }
  };
  const openFilter = () => {
    setDisplay(!display);
  };
  const resetFilter = () => {
    setStockLevel([]);
    setSortby("");
    setData({
      minPrice: "",
      maxPrice: "",
    });
  };
  const openItem = (item) => {
    localStorage.setItem("itemId", item.id);
    navigate("/items/stand-alone");
  };
  const openBusiness = (item) => {
    localStorage.setItem("businessId", item.id);
    navigate("/business/view");
  };
  const popDelete = () => {
    setDisplayDelete(!displayDelete);
  };
  const deleteCancel = () => {
    setDisplayDelete(false);
  };
  // const openMore = (item) => {
  //   setSelectedItemId(item.id);
  //   setDisplayMore(!displayMore);
  // };
  return (
    <section>
      <Dashboardheader
        headerText="Advanced Search"
        filterStyle="flex"
        filterSubmitForm={openFilter}
      />
      {!searchResult ? (
        <Loading />
      ) : (
        <>
          <AdvancedFilter
            display={display}
            setDisplay={setDisplay}
            handleStock={handleStock}
            handleSortby={handleSortby}
            dataChange={handleDataChange}
            minPrice={data.minPrice}
            maxPrice={data.maxPrice}
            submitForm={submitFilter}
            isLoading={isLoading}
            reset={resetFilter}
          />
          <HeadingThree
            array={headingArray}
            headingDisplay={
              folderId.length === 0 && itemId.length === 0 ? "flex" : "none"
            }
          />
          <DeletePopup
            display={displayDelete}
            setDispay={setDisplayDelete}
            // deleteItem={deleteItem}
            popupName={generalId.length + " selected"}
            isLoading={isLoading}
            cancelDelete={deleteCancel}
          />
          <SelectDelete
            deleteDisplay={
              folderId.length !== 0 || itemId.length !== 0 ? "flex" : "none"
            }
            deleteItem={popDelete}
            editDisplay="none"
            checkboxChange={handleGeneralCheck}
            numberSelect={generalId.length}
            checkId={
              !folderArray && !itemsArray
                ? ""
                : folderArray.map((item) => {
                    return item.id;
                  }) +
                  "," +
                  itemsArray.map((item) => {
                    return item.id;
                  })
            }
          />

          <div className="advanced-search_body">
            {
            searchResult.totalDetails.businessCount === 0 &&
              searchResult.totalDetails.teamMembersCount === 0 &&
              searchResult.totalDetails.itemCount === 0 ? (
              <Empty title="Nothing to show here" img={nothing} />
            ) : folderArray?.length === 0 &&
            membersArray?.length === 0 &&
            itemsArray?.length === 0 ? (
              <Empty
                title="Search for Sections, Items, Team members and more"
                img={emptySearch}
              />
            ) : (
              <>
                {folderArray?.length !== 0 &&
                  <div className="business-component">
                    <h2 className="header-text">Sections</h2>
                    <div className={"quantity-grid_display"}>
                      {folderArray.map((item, idx) => {
                        return (
                          <div
                            key={idx}
                            id={item.id}
                            className={
                              folderId.includes(item.id)
                                ? "quantity-grid_item quantity-grid_item-focus"
                                : "quantity-grid_item"
                            }
                          >
                            <div className="quantity-grid_item-top">
                              <div className="folder-icon_wrap">
                                <FolderOpen style={{ fontSize: "32px" }} />
                              </div>
                              <h6>{item.totalItems} Items</h6>
                              {isChecked ? (
                                <Dcheckbox
                                  checkId={item.id}
                                  checkboxChange={handleCheckbox}
                                  checked={isChecked ? true : ""}
                                />
                              ) : (
                                <Dcheckbox
                                  checkId={item.id}
                                  checkboxChange={handleCheckbox}
                                  checked={
                                    folderId.includes(item.id) ? true : ""
                                  }
                                />
                              )}
                            </div>
                            <div className="quantity-grid_item-middle">
                              <h3 onClick={() => openBusiness(item)}>
                                {item.name}
                              </h3>
                            </div>
                            <div className="quantity-grid_item-bottom">
                              <h5>Business value:</h5>
                              <h4>
                                ₦ {Number(item.totalValue).toLocaleString()}.00
                              </h4>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                }

                {itemsArray?.length !== 0 &&
                  <div className="item-component">
                    <h2 className="header-text">Items</h2>
                    <div className="item-grid_display">
                      {itemsArray.map((item, idx) => {
                        return (
                          <div
                            key={idx}
                            id={item.id}
                            className={
                              itemId.includes(item.id)
                                ? "item-grid_item quantity-block_item-focus"
                                : "item-grid_item"
                            }
                          >
                            <div className="item-image_header">
                              {!item.picture ? (
                                <img src={itemImg} alt="item" />
                              ) : (
                                <img src={item.picture} alt="item" />
                              )}

                              <div className="item-image_header-span">
                                <p style={{ background: "transparent" }}></p>
                                {/* {item.itemType === "Standalone" ? (
                                ) : (
                                  <p>{item.itemType}</p>
                                )} */}
                                {isChecked ? (
                                  <Dcheckbox
                                    checkboxChange={handleItemCheck}
                                    checkId={item.id}
                                    containerBg="white"
                                    checked={isChecked ? true : ""}
                                  />
                                ) : (
                                  <Dcheckbox
                                    checkboxChange={handleItemCheck}
                                    checkId={item.id}
                                    containerBg="white"
                                    checked={
                                      itemId.includes(item.id) ? true : ""
                                    }
                                  />
                                )}
                              </div>
                            </div>
                            <div className="item-grid_middle">
                              <div className="item-grid_middle-top">
                                <h3 onClick={() => openItem(item)}>
                                  {item.name}
                                </h3>
                                {/* <More
                                  // onClick={() => openMore(item)}
                                  style={{
                                    rotate: "90deg",
                                    color: "#111111",
                                    cursor: "pointer",
                                  }}
                                /> */}

                                {/* <div
                                  className={
                                    displayMore && selectedItemId === item.id
                                      ? "items-more_list"
                                      : "display-none"
                                  }
                                >
                                  <p>
                                    <Clock /> History
                                  </p>
                                  <p>
                                    <Copy /> Clone Item
                                  </p>
                                  <p>
                                    <Trash /> Delete
                                  </p>
                                </div> */}
                              </div>
                              {item.stockStatus === "In Stock" ? (
                                <span
                                  style={{
                                    background: "#EDF7EE",
                                    color: "#4CAF50",
                                    width: "75px",
                                  }}
                                >
                                  {item.stockStatus}
                                </span>
                              ) : item.stockStatus === "Out of Stock" ? (
                                <span
                                  style={{
                                    background: "#FFE6E6",
                                    color: "#FF0000",
                                    width: "95px",
                                  }}
                                >
                                  {item.stockStatus}
                                </span>
                              ) : item.stockStatus === "Low Stock" ? (
                                <span
                                  style={{
                                    background: "#FFF9E6",
                                    color: "#FFC107",
                                    width: "85px",
                                  }}
                                >
                                  {item.stockStatus}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="item-grid_bottom">
                              <h5>
                                {Number(item.quantity).toLocaleString()} units
                              </h5>
                              <h6>
                                ₦ {Number(item.totalValue).toLocaleString()}.00
                              </h6>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                }

                {membersArray?.length !== 0 &&
                  <div className="member-component">
                    <h2 className="header-text">Team Members</h2>
                    <div className="members-display">
                      {membersArray.map((item, idx) => {
                        return (
                          <div key={idx} className="members-item">
                            {!item.picture ? (
                              <img src={memberImg} alt="member" />
                            ) : (
                              <img src={item.image} alt="member" />
                            )}
                            <Link
                              to="/manageteam"
                              style={{ textDecoration: "none" }}
                            >
                              <h3>{item.name}</h3>
                            </Link>
                            <p>{item.roleName}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                }
              </>
            )}
          </div>
        </>
      )}
    </section>
  );
};

export default AdvancedSearch;
