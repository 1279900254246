import Notification from "../notification/Notification";

import DropdownSort from "../dropdown-sort/dropdown-sort.component";

import "./table-heading.styles.css"

const TableHeading = ({title, dropdown, activityDrop}) => {

    return (
        <section className="table-heading-container-component">
            { title && <h4> {title} </h4> }
            <section className="input-container-component">
                <Notification notificationStyle="flex" />
                { dropdown && <DropdownSort activityDrop={true} /> }
            </section>
        </section>
    );
}
 
export default TableHeading;