import React, { useState } from "react";
import "./upload-img.css";
import { GalleryAdd } from "iconsax-react";
import useCoverPicture from "../../hooks/useCoverPicture";
import useCoverImage from "../../hooks/useCoverImage";
import userImage from "../../assets/dashboard/userImage.svg";

const UploadImg = ({ style, iconSize, userPic, emptyPic, picDisplay, pic }) => {
  const { setCoverPicture } = useCoverPicture();
  const {coverImage, setCoverImage} = useCoverImage();
  const [errorMsg, setErrorMsg] = useState("");
  function handleCoverImage(e) {
    const selectedFile = e.target.files;
    const selectFile = e.target.files[0];
    const reader = new FileReader();
    const fileSizeInBytes = selectFile.size;
    const maxSizeInBytes = 1024 * 1024; 
    if(fileSizeInBytes > maxSizeInBytes){
      setCoverImage();
      setErrorMsg("Image too large, Pls upload a smaller image, less than 1MB");
    } else{
      setErrorMsg("");
      reader.onload = (event) => {
        const base64String = event.target.result;
        // Use the base64 string as needed
        // console.log('Base64 String:', base64String);
        setCoverPicture(base64String);
      };
  
      reader.readAsDataURL(selectFile);
  
      const selectedFilesArray = Array.from(selectedFile);
  
      const imagesArray = selectedFilesArray.map((file) =>  URL.createObjectURL(file));
  
      setCoverImage(imagesArray);
    }

    // setCoverPicture(selectFile);
    // console.log(selectFile);
    // console.log(selectedFile);
  }
  return (
    <label>
      <input
        type="file"
        style={{ display: "none" }}
        // value={coverPicture}
        onChange={(e) => handleCoverImage(e)}
      />
      {coverImage ? (
        coverImage.map((item, idx) => {
          return (
            <img
              key={idx}
              src={item}
              alt="pic"
              style={{ width: "100%", height: "250px" }}
            />
          );
        })
      ) : (
        <>
          <div className="item-img_upload" style={style}>
            <p>
              <GalleryAdd size={iconSize} /> ( Add photo, maximum of 1 MB )
            </p>
          </div>

          <div className="settings-picture_upload display-none" style={{display: userPic}}>
            <img src={userImage} alt="user" style={{cursor: "pointer", display: emptyPic}} />
            <img className="pic-display" src={pic} alt="user" style={{cursor: "pointer", display: picDisplay}} />
            <div className="upload-btn dashboard-variant">Upload New Image</div>
          </div>
        </>
      )}
      {errorMsg && <p style={{color: "red"}}>{errorMsg}</p>}
    </label>
  );
};

export default UploadImg;
