import { useEffect, useState } from "react";

import { createColumnHelper } from "@tanstack/react-table";

import Table from "../../table/table.component";
import Empty from "../../empty/empty.component";

import empty_icon from "../../../assets/dashboard/empty-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import OnlyDate from "../../excerpts/OnlyDate";
import OnlyTime from "../../excerpts/OnlyTime";
import { getDebts, getDrafts, getReceipts, getRecovery, getSales, setSalesModal } from "../../../states/sales/sales";
import Pagination from "../../pagination/pagination.component";
import useActivityHistoryData from "../../../hooks/useActivityHistoryData";
import Loading from "../../Loading";
import DeletePopup from "../../filter-popups/DeletePopup";
import { AxiosDelete } from "../../../axios/axios";
import SuccessMsg from "../../alert/success-msg.component";



const DebtTable = () => {
    const dispatch = useDispatch(); 
    const { debts } = useSelector((state) => state.sales); 
    const [success, setSuccess] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [debtInfo, setDebtInfo] = useState();
    const url = `debts/${debtInfo?.id}/`;
    
    // eslint-disable-next-line
    const [data, setData] = useState( (debts?.result && debts?.result.debtList !== undefined) ? debts?.result?.debtList.map(sale => ({ ...sale, checked: "" })) : [] );
    const { activityHistoryData } = useActivityHistoryData();

    const clickDebt = (info) => {
        setSuccess(true);
        setDebtInfo( info.row.original);
        // console.log(info.row.original);
    }
    const cancelDelete = () => {
        setSuccess(false);
    }

    const payDebt = () => {
        setIsLoading(true)
        AxiosDelete(url).then((res) => {
            // console.log(res);
            setSuccessMsg(res.message);
            setSuccess(false);
            setIsLoading(false);
        }).catch((err) => {
            // console.log(err.response);
            setIsLoading(false);
        })
    }


    const columnHelper = createColumnHelper();
    const columns = [
    columnHelper.accessor('name', {
        cell: (info) => (
                <p className=""> {info.getValue()} </p>
            ),
        header: () => <span>Item</span>,
    }),
    columnHelper.accessor('unitPrice', {
        cell: (info) => (
                <p className=""> {info.getValue()} </p>
        ),
        header: () => <span> Unit Price(N) </span>,
    }),
    columnHelper.accessor('quantity', {
        cell: (info) => (
            <p> {info.getValue()} </p>
        ),
        header: () => <span> QTY </span>,
    }),
    columnHelper.accessor('subTotal', {
        cell: (info) => (
            <p> {info.getValue()} </p>
        ),
        header: () => <span> Sub Total(N) </span>,
    }),
    columnHelper.accessor('total', {
        cell: (info) => (
            <p> {info.getValue()} </p>
        ),
        header: () => <span> Total Amount(N) </span>,
    }),
    columnHelper.accessor('customerName', {
        cell: (info) => (
            <p> {info.getValue()} </p>
        ),
        header: () => <span> Name </span>,
    }),
    columnHelper.accessor('customerPhoneNumber', {
        cell: (info) => (
            <p> {info.getValue()} </p>
        ),
        header: () => <span> Phone </span>,
    }),
    columnHelper.accessor('createdAt', {
        cell: (info) => (
            <>
                <p className=""> { <OnlyDate date={info.getValue()} /> } </p>
                <p className="time"> { <OnlyTime date={info.getValue()} /> } </p>
            </>
        ),
        header: () => <span> Date/Time </span>,
    }),
    columnHelper.accessor('debtBtn', {
        cell: (info) => (
            <>
                <div className="btn" onClick={() => clickDebt(info)} >
                    <p style={{color: "white"}}>Pay</p>
                </div>
            </>
        ),
        header: () => <span></span>,
    }),
];

    const handleAddSaleModal = () => dispatch(setSalesModal(true));

    const handleUpdate = (item) => {
        dispatch(getDebts(`?dateFilter=${activityHistoryData.dateValue}&startDate=${activityHistoryData.startDate}&endDate=${activityHistoryData.endDate}&orderBy=${activityHistoryData.orderBy}&page=${item}&pageSize=10`))
    };

    useEffect(() => {
        if (successMsg !== ""){
            dispatch(getDebts("?dateFilter=Today"));
            dispatch(getSales("?orderBy=Latest&dateFilter=Today"));
            dispatch(getRecovery("?dateFilter=Today"));
            dispatch(getDrafts("?dateFilter=Today"));
            dispatch(getReceipts("?dateFilter=Today"));
        }
        // eslint-disable-next-line
    }, [successMsg])

    return (
        <>
            { debts.status === 'loading'
                ?
                <Loading />
                :
                <>
                <SuccessMsg msg={successMsg} />
                <DeletePopup display={success} setDispay={setSuccess} restore={"Pay"} cancelDelete={cancelDelete} isLoading={isLoading} deleteItem={payDebt} popupName={`${debtInfo?.customerName}'s ₦${Number(debtInfo?.total).toLocaleString()}.00 debt`} />
                    { (data && data.length > 0) && <Table minWidth={"100%"} data={data} columns={columns} /> }
                    { ( data && data.length === 0) && 
                        <Empty submitClick={handleAddSaleModal} title={"You don't have any sales"} img={empty_icon} /> 
                    }
                </>
            } 
            { (data && data.length > 0) && 
                <Pagination
                    currentPage={debts?.result?.pagination?.currentPage} 
                    numberOfPages={debts?.result?.pagination?.numberOfPages} 
                    handleUpdate={handleUpdate} />
            }
        </>
    );
}
 
export default DebtTable;