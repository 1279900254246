// import { useState } from "react";
import "./billing.styles.css";
import PaymentHistory from "./payment-history.component";
// import PaymentMethod from "./payment-method.component";
import Plan from "./plan.component";
import { AxiosGet } from "../../../axios/axios";
import { useEffect, useState } from "react";
import Loading from "../../Loading";

const Billing = () => {
    const url = `billing/`;
    const [isLoading, setIsLoading] = useState(null);
    const [billData, setBillData] = useState([]);
    useEffect(() => {
      AxiosGet(url)
        .then((res) => {
          // console.log(res);
          setBillData(res.data);
          setIsLoading(true);
        })
        .catch((err) => {
          // console.log(err.response);
        });
      // eslint-disable-next-line
    }, []);

    return ( !isLoading ? <Loading imgStyle={{marginTop: "120px"}} /> :
        <section>
            <Plan currentPlan={billData?.currentSubscription} lastSub={billData?.latestExpiredSubscription} />
            {/* <PaymentMethod /> */}
            <PaymentHistory paymentHistoryDetail={billData?.paymentHistory} />
        </section>
    );
}
 
export default Billing;