import { useEffect, useState } from "react";

import HeadingThree from "../expenses/heading-three.component";

import edit_icon from "../../assets/dashboard/edit-icon.svg";
import trash_icon2 from "../../assets/dashboard/trash-icon2.svg";

import { createColumnHelper } from "@tanstack/react-table";

import "./manage-team.styles.css";
import Table from "../table/table.component";
import AddRole from "./modals/add-role.component";
import { AxiosDelete, AxiosGet } from "../../axios/axios";
import SuccessPopup from "../filter-popups/SuccessPopup";
import DeletePopup from "../filter-popups/DeletePopup";
import emptyImg from "../../assets/dashboard/empty-role.png";
import Empty from "../empty/empty.component";
import Loading from "../Loading";
import { useDispatch, useSelector } from "react-redux";
import { setFailureModal } from "../../states/general/general";
import FailedMsg from "../alert/failed-msg.component";

const Roles = ({ modal, setModal }) => {
  const url = "roles/";
  const roleId = localStorage.getItem("roleId");
  const singleRoleUrl = `roles/${roleId}/`;
  const [roleData, setRoleData] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const [isDLoading, setIsDLoading] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [displayDelete, setDisplayDelete] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const userPermissions = useSelector((state) => state.userPermissions);
  const dispatch = useDispatch();
  useEffect(() => {
    AxiosGet(url)
      .then((res) => {
        // console.log(res);
        setRoleData(res.data.rolesList);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err.response);
      });
    // eslint-disable-next-line
  }, [modal, displaySuccess]);

  const clickEdit = (info) => {
    if (userPermissions.createRole) {
      localStorage.setItem("roleId", info.row.original.id);
      localStorage.setItem("isEdit", true);
      setModal(true);
    } else {
      dispatch(setFailureModal(true));
    }
  };
  const clickOpen = (info) => {
    if (userPermissions.createRole) {
      localStorage.setItem("roleId", info.row.original.id);
      localStorage.setItem("openRole", true);
      setModal(true);
    } else {
      dispatch(setFailureModal(true));
    }
  };
  const clickDelete = (info) => {
    if (userPermissions.createRole) {
      setDisplayDelete(!displayDelete);
      localStorage.setItem("roleId", info.row.original.id);
      setRoleName(info.row.original.name);
    } else {
      dispatch(setFailureModal(true));
    }
  };
  const cancelDelete = () => {
    setDisplayDelete(!displayDelete);
    localStorage.removeItem("roleId");
  };
  const deleteItem = () => {
    // setDisplayDelete(!displayDelete);
    setIsDLoading(true);
    AxiosDelete(singleRoleUrl)
      .then((res) => {
        // console.log(res);
        setIsDLoading(false);
        if (res.success === true) {
          setDisplaySuccess(true);
          localStorage.removeItem("roleId");
          setDisplayDelete(false);
        }
      })
      .catch((err) => {
        setIsDLoading(false);
        // console.log(err.response);
      });
  };

  const data = roleData.length === 0 ? [] : roleData;

  const headingArray = [{ text: "Roles", amount: `${data.length}` }];

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => (
        <p
          onClick={() => clickOpen(info)}
          className=""
          style={{ textTransform: "capitalize" }}
        >
          {" "}
          {info.getValue()}{" "}
        </p>
      ),
      header: () => <span>Role Name</span>,
    }),
    columnHelper.accessor("description", {
      cell: (info) => (
        <p
          onClick={() => clickOpen(info)}
          className=""
          style={{ textTransform: "capitalize" }}
        >
          {" "}
          {info.getValue()}{" "}
        </p>
      ),
      header: () => <span>Description</span>,
    }),
    columnHelper.accessor("createdAt", {
      cell: (info) => (
        <p onClick={() => clickOpen(info)}>
          {" "}
          {new Date(info.getValue()).toLocaleDateString("en-GB")}{" "}
        </p>
      ),
      header: () => <span> Date Created </span>,
    }),
    columnHelper.accessor("edit_trash", {
      cell: (info) => (
        <div style={{ display: "flex", gap: "16px" }}>
          <img
            style={{ cursor: "pointer" }}
            onClick={() => clickEdit(info)}
            src={edit_icon}
            alt="edit-icon"
          />
          <img
            style={{ cursor: "pointer" }}
            onClick={() => clickDelete(info)}
            src={trash_icon2}
            alt="trash-icon"
          />
        </div>
      ),
      header: () => <span> </span>,
    }),
  ];

  return isLoading === null ? (
    <Loading imgStyle={{ marginTop: "150px" }} />
  ) : (
    <section className="roles-container">
      <SuccessPopup
        display={displaySuccess}
        setDisplay={setDisplaySuccess}
        popupMessage={"You have successfully deleted role " + roleName}
      />
      <FailedMsg
        msg={"You do not have permissions to perform this action!!!"}
      />
      <DeletePopup
        display={displayDelete}
        deleteItem={deleteItem}
        popupName={roleName}
        isLoading={isDLoading}
        cancelDelete={cancelDelete}
        setDispay={setDisplayDelete}
      />
      <HeadingThree array={headingArray} headingDisplay="flex" />
      {roleData.length === 0 ? "" : <Table data={data} columns={columns} minWidth={"100%"} />}
      <AddRole modal={modal} setModal={setModal} />
      {roleData.length === 0 ? (
        <Empty
          img={emptyImg}
          title="Create a role"
          btnText={"Add a New Role"}
          submitClick={() => setModal(true)}
        />
      ) : (
        ""
      )}
    </section>
  );
};

export default Roles;
