import React, { useState, useEffect } from "react";
import Faq from "../../Components/faq/Faq";
import {
  Additem,
  Chart,
  CloseCircle,
  Edit2,
  Element4,
  FolderAdd,
  ImportSquare,
  InfoCircle,
  Login,
  Mobile,
  Notification,
  Printer,
  Refresh,
  RefreshCircle,
  Sms,
  TransactionMinus,
} from "iconsax-react";

const Services = () => {
  const [activeLink, setActiveLink] = useState("");
  const activeStyle =
    activeLink === "Organization"
      ? {
          color: "#FFFFFF",
          background: "#080B24",
          padding: "10px 20px",
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
        }
      : activeLink === "Mobile"
      ? {
          color: "#FFFFFF",
          background: "#080B24",
          padding: "10px 20px",
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
        }
      : {
          color: "#FFFFFF",
          background: "#080B24",
          padding: "10px 20px",
        };
  const clickLink = (e) => {
    setActiveLink(e.target.textContent);
    // console.log(e.target.textContent);
  };
  useEffect(() => {
    setActiveLink("Organization");
  }, []);

  const organization = [
    {
      logo: <Additem />,
      header: "Add Items",
      paragraph:
        "Easily input and include new inventory items into the system.",
    },
    {
      logo: <Edit2 />,
      header: "Customizable Experience and Access",
      paragraph:
        "Tailor user permissions and access levels to control system functionality and data visibility.",
    },
    {
      logo: <TransactionMinus />,
      header: "Inventory List",
      paragraph:
        "View and manage the complete list of available inventory items",
    },
    {
      logo: <Element4 />,
      header: "Custom Fields",
      paragraph:
        "Create and include additional fields to capture specific information related to your inventory.",
    },
    {
      logo: <FolderAdd />,
      header: "Custom Folders",
      paragraph:
        "Organize and categorize inventory items into customized folders for easier navigation and management.",
    },
    {
      logo: <ImportSquare />,
      header: "Bulk Import",
      paragraph:
        " Efficiently import multiple inventory items into the system at once, saving time and effort.",
    },
    {
      logo: <Printer />,
      header: "Print Instant Reciepts",
      paragraph:
        "Get thermal printers to automatically generate printed copy of receipts.",
    },
  ];

  const management = [
    {
      logo: <Notification />,
      header: "Quantity Based Alerts",
      paragraph:
        "Receive notifications or alerts based on preset quantity thresholds for efficient inventory management.",
    },
    {
      logo: <Mobile />,
      header: "In App Alerts",
      paragraph:
        "Receive real-time notifications and alerts within the inventory management application to stay informed about important events or updates.",
    },
    {
      logo: <Sms />,
      header: "Email Alerts",
      paragraph:
        "Get notified via email about critical inventory-related events or updates to ensure timely actions and decision-making.",
    },
  ];

  const reports = [
    {
      logo: <Refresh />,
      header: "Activity History Report",
      paragraph:
        "Access a comprehensive report that provides a detailed record of all activities and changes made within the inventory management system.",
    },
    {
      logo: <Chart />,
      header: "Total Inventory Report",
      paragraph:
        "Generate a comprehensive report that provides an overview of the entire inventory, including quantities, values, and other relevant details.",
    },
    {
      logo: <InfoCircle />,
      header: "Low Level or Out of Stock Report",
      paragraph:
        "Generate a report that highlights inventory items with low quantities or are completely out of stock, ensuring proactive replenishment or restocking actions.",
    },
  ];

  const mobile = [
    {
      logo: <RefreshCircle />,
      header: "Automatic Sync",
      paragraph:
        "Enable seamless synchronization between the web and mobile app versions, ensuring that inventory data is always up to date across all devices.",
    },
    {
      logo: <CloseCircle />,
      header: "Offline Mode",
      paragraph:
        "Allow users to access and perform limited inventory management tasks on the mobile app even without an internet connection, ensuring productivity in remote or offline environments.",
    },
    {
      logo: <Login />,
      header: "SSO (Single Sign on)",
      paragraph:
        " Provide users with the convenience of logging into the mobile app using their existing credentials from the web app, eliminating the need for separate login information.",
    },
  ];

  return (
    <div>
      <div className="services-page">
        <div className="service-page_top">
          <h2>
            Our Inventory <span> Management Services</span>
          </h2>
          <p>
            With our web app, you can easily track stock levels, monitor
            inventory movements, and gain real-time insights into your inventory
            performance. Whether you're a small business or a large enterprise,
            our solution is designed to meet your specific needs.
          </p>
        </div>
        <div className="service-page_links">
          <p
            onClick={(e) => clickLink(e)}
            style={activeLink === "Organization" ? activeStyle : undefined}
          >
            Organization
          </p>
          <p
            onClick={(e) => clickLink(e)}
            style={activeLink === "Management" ? activeStyle : undefined}
          >
            Management
          </p>
          <p
            onClick={(e) => clickLink(e)}
            style={activeLink === "Reports" ? activeStyle : undefined}
          >
            Reports
          </p>
          <p
            onClick={(e) => clickLink(e)}
            style={activeLink === "Mobile" ? activeStyle : undefined}
          >
            Mobile
          </p>
        </div>
        <div className="service-page_links-card_cont">
          {activeLink === "Organization"
            ? organization.map((item, idx) => {
                return (
                  <div className="service-page_links-card">
                    <div className="service-card_logo">{item.logo}</div>
                    <h4>{item.header}</h4>
                    <p>{item.paragraph}</p>
                  </div>
                );
              })
            : activeLink === "Management"
            ? management.map((item, idx) => {
                return (
                  <div className="service-page_links-card">
                    <div className="service-card_logo">{item.logo}</div>
                    <h4>{item.header}</h4>
                    <p>{item.paragraph}</p>
                  </div>
                );
              })
            : activeLink === "Reports"
            ? reports.map((item, idx) => {
                return (
                  <div className="service-page_links-card">
                    <div className="service-card_logo">{item.logo}</div>
                    <h4>{item.header}</h4>
                    <p>{item.paragraph}</p>
                  </div>
                );
              })
            : activeLink === "Mobile"
            ? mobile.map((item, idx) => {
                return (
                  <div className="service-page_links-card">
                    <div className="service-card_logo">{item.logo}</div>
                    <h4>{item.header}</h4>
                    <p>{item.paragraph}</p>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
      <Faq />
    </div>
  );
};

export default Services;
