import React, { useState } from "react";
import DatePicker from "react-datepicker";
import calender from "../../assets/dashboard/calendar.svg";
import "./amount-input.css";

const DateInput = ({ dateValue,setDateValue, dateLabel, newDate }) => {
  const [endDate, setEndDate] = useState(new Date());
  const handleDateChange = (date) => {
    setEndDate(date);
    const inputDate = date;
    const day = inputDate.getDate();
    const month = inputDate.getMonth() + 1;
    const year = inputDate.getFullYear();
    const formattedDate = `${month.toString().padStart(2, "0")}/${day
      .toString()
      .padStart(2, "0")}/${year}`;
    if (setDateValue) {
      setDateValue(formattedDate);
    }
  };
  
  const newDateChange = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();
  
    setDateValue(`${year}-${month}-${day}`);
  }

  return (
    <div className="date-picker_cont">
      <h4>{dateLabel}</h4>
      <div className="date-picker">
        <img src={calender} alt="date" />
        <DatePicker
          selected={endDate}
          value={dateValue}
          id="end_date"
          onChange={newDate ? newDateChange : handleDateChange}
        />
      </div>
    </div>
  );
};

export default DateInput;
