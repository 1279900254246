import React, { useEffect } from "react";
import "./filter-popup.css";
import { TickCircle } from "iconsax-react";
// import { CloseCircle, TickCircle } from "iconsax-react";

const SuccessPopup = ({ display, popupMessage, setDisplay, cancelClick }) => {
  useEffect(()=>{
    setTimeout(() => {
      setDisplay(false);
    }, 3000);
     // eslint-disable-next-line 
  },[display])
  return (
    <div>
      {/* <div
        className={`overlay ${display ? "w-full" : "w-0"}`}
        onClick={() => setDisplay(false)}
      >
        {" "}
      </div> */}
      <div className={!display ? "display-none" : "success-message"}>
        <TickCircle
          color="green"
          size="32"
          onClick={() => setDisplay(false)}
          style={{ cursor: "pointer" }}
        />
        <h3>{popupMessage}</h3>
        {/* <CloseCircle
          color="red"
          size="32"
          onClick={() => {
            // setTimeout(() => {
            //   setDisplay(false);
            // }, 1000);
            if (cancelClick) {
              cancelClick();
            }
          }}
          style={{ cursor: "pointer" }}
        /> */}
      </div>
    </div>
  );
};

export default SuccessPopup;
