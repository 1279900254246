import React, { useEffect } from "react";
import { ArrowRight } from "iconsax-react";
import heroPic from "../../assets/landingpage/heroPic.png";
import inventory from "../../assets/landingpage/inventory.png";
import alert from "../../assets/landingpage/alert.png";
import stock from "../../assets/landingpage/stock.png";
// import appStore from "../../assets/landingpage/appStore.png";
import playStore from "../../assets/landingpage/googlePlay.png";
import iPhone from "../../assets/landingpage/iPhone.png";
import { Link, useNavigate } from "react-router-dom";
import Pricingplan from "../../Components/Pricingplan/Pricingplan";
import Faq from "../../Components/faq/Faq";

const LandingPage = () => {
  const advancedFeatures = ["All Basic Features", "Priority Support"];
  const basicFeatures = [
    "Inventory Tracking",
    "Stock Optimization",
    "Alerts & Notifications and more...",
  ];
  const soloFeatures = [
    "Inventory Tracking",
    "Stock Optimization",
    "Alerts & Notifications and more...",
  ];
  const navigate = useNavigate();
  const checkUserToken = () => {
    const userToken = JSON.parse(localStorage.getItem("stk-apk"));
    if(userToken){
      navigate("/overview");
    }
  };
  useEffect(() => {
    checkUserToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <div className="landing-page_hero-section">
        <h1>
          Inventory & Stock Management Made <span>Easy</span>{" "}
        </h1>
        <p>
          Take control of your inventory management process with our powerful
          web application. Streamline, track, and optimize your inventory like
          never before. Say goodbye to manual spreadsheets and outdated systems
          today.
        </p>
        <div className="landing-page_hero-section_buttons">
          <Link to="/signupemail" style={{ textDecoration: "none" }}>
            <button className="btn">get started</button>
          </Link>
          <Link to="/pricing" style={{ textDecoration: "none" }}>
            <button className="btn-trans" style={{ color: "#F08227" }}>
              view plans <ArrowRight />
            </button>
          </Link>
        </div>
        <div className="landing-page_hero-pic">
          <img src={heroPic} alt="Payments Placeholder" />
        </div>
      </div>

      <div className="landing-page_services-section">
        <h2>
          Our Inventory <span>Management Services</span>{" "}
        </h2>
        <p>
          With our web app, you can easily track stock levels, monitor inventory
          movements, and gain real-time insights into your inventory
          performance. Whether you're a small business or a large enterprise,
          our solution is designed to meet your specific needs.
        </p>
        <div className="landing-page_services-section_cards">
          <div className="landing-page_services-section_cards-left">
            <h3>Inventory Tracking</h3>
            <h4>
              Effortlessly monitor and track your inventory in real-time with
              our inventory tracking service. Our web app provides a centralized
              dashboard where you can easily view stock levels, track product
              movements, and receive low stock notifications. This enables you
              to have a comprehensive overview of your inventory, ensuring that
              you never run out of stock or miss an order again. With accurate
              and up-to-date information at your fingertips, you can make
              informed decisions about purchasing, restocking, and inventory
              replenishment.
            </h4>
            <Link to="/signupemail" style={{ textDecoration: "none" }}>
              <button className="btn-variant">Get Started</button>
            </Link>
          </div>
          <div className="landing-page_services-section_cards-right">
            <img src={inventory} alt="inventory placeholder" />
          </div>
        </div>
        <div
          className="landing-page_services-section_cards"
          style={{ flexDirection: "row-reverse" }}
        >
          <div className="landing-page_services-section_cards-left left-card_flex">
            <h3>In App Alerts on Stock Levels</h3>
            <h4>
              Our new In-App Stock Alert Levels service, designed to
              revolutionize your inventory management experience. With this
              powerful feature, you can set customized stock alert thresholds
              for your products, ensuring you never run out of stock or
              overstock again. Receive real-time notifications directly within
              our platform, empowering you to make informed decisions and take
              immediate action to maintain optimal inventory levels. Stay in
              control of your supply chain like never before with our In-App
              Stock Alert Levels service.
            </h4>
            <Link to="/signupemail" style={{ textDecoration: "none" }}>
              <button className="btn-variant">Get Started</button>
            </Link>
          </div>
          <div className="landing-page_services-section_cards-right">
            <img src={alert} alt="alerts placeholder" />
          </div>
        </div>
        <div className="landing-page_services-section_cards">
          <div className="landing-page_services-section_cards-left">
            <h3>Stock Optimization</h3>
            <h4>
              Our web app offers smart inventory organization features that
              allow you to efficiently categorize items, define storage
              locations, and implement efficient picking and packing processes.
              This helps reduce unnecessary movement within the warehouse, save
              time in locating items, and improve overall productivity.
            </h4>
            <Link to="/signupemail" style={{ textDecoration: "none" }}>
              <button className="btn-variant">Get Started</button>
            </Link>
          </div>
          <div className="landing-page_services-section_cards-right">
            <img src={stock} alt="stock placeholder" />
          </div>
        </div>
        <div className="landing-page_bottom-services">
          <h6>See More Services</h6>
          <Link to="/services" style={{ textDecoration: "none" }}>
            <button className="btn-variant">
              learn more <ArrowRight style={{ color: "#F08227" }} />
            </button>
          </Link>
        </div>
      </div>

      <div className="download-mobile_app">
        <div className="download-mobile_app-left">
          <h2>Initiate Sales Easily From the Comfort of our New Mobile App</h2>
          <p>
            We are thrilled to announce the launch of our new mobile app
            designed exclusively for the users of our web app. Now, you can add
            a sale whenever and wherever you are, with just a few taps on your
            smartphone.
          </p>
          <div className="download-mobile_app-left_button">
            {/* <h3>Coming soon...</h3> */}
            {/* <img src={appStore} alt="Apple Store" /> */}
            <a href="https://play.google.com/store/apps/details?id=ng.stockkeeper" target="_blank" rel="noopener noreferrer"><img src={playStore} alt="Google Store" /></a>
          </div>
        </div>
        <div className="download-mobile_app-right">
          <img src={iPhone} alt="iPhone" />
        </div>
      </div>

      <Pricingplan
        advancedFeatures={advancedFeatures}
        basicFeatures={basicFeatures}
        freeFeatures={basicFeatures}
        soloFeatures={soloFeatures}
        displayPriority={{ display: "none" }}
      />
      <Faq />
    </div>
  );
};

export default LandingPage;
