import React, {useEffect} from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const Layout = () => {
  
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [pathname]);

  return (
    <div className="layout">
      <Navbar />
      <div className="layout-body">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
