import React, { useState } from "react";
import "./Dashboardheader.css";
import Dashboardbtn from "../dashboard-buttons/Dashboardbtn";
import {
  ArrowDown2,
  ArrowUp2,
  Clock,
  FilterSearch,
  FolderAdd,
  More,
  Refresh,
  Trash,
} from "iconsax-react";

import "react-datepicker/dist/react-datepicker.css";
import Tooltip from "../tooltip/Tooltip";
import Notification from "../notification/Notification";

const Dashboardheader = ({
  headerText,
  refreshStyle,
  addStyle,
  addBtnIcon,
  addBtnText,
  refreshLink,
  addLink,
  refreshSubmitForm,
  addSubmitForm,
  filterStyle,
  filterLink,
  filterSubmitForm,
  notificationStyle,
  headerVariation,
  headerTextVariationStyle,
  standaloneStyle,
  handleClick,
  inputDisplay,
  formId,
  formValue,
  placeholder,
  dataChange,
  errorMessage,
  deleteItem,
  headerVText,
  setHeaderVText,
  isLoading,
  btnWidth,
  // clickCloneItem,
  clickHistoryItem,
  isReadOnly
}) => {

  const headerList = [
    "All items",
    // "Composite items",
    "Grouped items",
    "Standalone items",
  ];

  const [display, setDisplay] = useState(false);
  const [displayMore, setDisplayMore] = useState(false);
  const [showTip, setShowTip] = useState(false);
  const clickNot = () => {
    setDisplay(!display);
  };
  const clickNotOptions = (e) => {
    if (e.target.id === "header-variation") {
      setHeaderVText(e.target.textContent);
      if (headerVariation) {
        headerVariation(e);
      }
      setDisplay(false);
    }
  };
  const handleShowTip = () => {};
  const handleDisplayMore = () => {
    setDisplayMore(!displayMore);
  };
  const clickMove = () => {
    if (handleClick) {
      handleClick();
    }
  };
  const clickDeleteItem = () => {
    if (deleteItem) {
      setDisplayMore(false);
      deleteItem();
    }
  };

  return (
    <section className="dashboard-header">
      <div className="dashboard-headerleft">
          <input
            className={
              !inputDisplay ? "display-none" : "new-item_input dashboard-input"
            }
            type="text"
            placeholder={placeholder}
            id={formId}
            onChange={dataChange}
            value={formValue}
            readOnly={isReadOnly}
          />
          {errorMessage ? <p style={{ color: "red" }}>{errorMessage}</p> : ""}

        <h1>{headerText}</h1>
        <div
          className="display-none header-variation_cont"
          style={{ display: headerTextVariationStyle }}
        >
          <div className="header-variation">
            <h1 onClick={clickNot}>
              {headerVText} {!display ? <ArrowDown2 /> : <ArrowUp2 />}
            </h1>
          </div>
          <div
            className={
              display ? "display-flex header-variation-menu " : "display-none"
            }
          >
            {headerList.map((item) => {
              return (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={clickNotOptions}
                  key={item}
                >
                  <p
                    id="header-variation"
                    style={
                      item === headerVText
                        ? { background: "#F08227", color: "white" }
                        : undefined
                    }
                  >
                    {item}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="dashboard-headerright">
        <div className="display-none" style={{ display: refreshStyle }}>
          <Dashboardbtn
            btnClass="dashboard-btntrans"
            btnText="refresh"
            btnIcon={<Refresh style={{ color: "#F08227" }} />}
            btnLink={refreshLink}
            submitForm={refreshSubmitForm}
          />
        </div>
        <div className="display-none" style={{ display: filterStyle }}>
          <Dashboardbtn
            btnClass="dashboard-btntrans"
            btnText="filter"
            btnIcon={<FilterSearch style={{ color: "#F08227" }} />}
            btnLink={filterLink}
            submitForm={filterSubmitForm}
          />
        </div>
        <div className="display-none" style={{ display: addStyle }}>
          <Dashboardbtn
            btnClass="dashboard-btn"
            btnText={addBtnText}
            btnIcon={addBtnIcon}
            btnLink={addLink}
            submitForm={addSubmitForm}
            isLoading={isLoading}
            btnWidth={btnWidth}
          />
        </div>
        <Notification notificationStyle={notificationStyle} />
        <div
          className="display-none standalone-style"
          style={{ display: standaloneStyle }}
          onClick={handleShowTip}
          onMouseEnter={() => setShowTip(true)}
          onMouseLeave={() => setShowTip(false)}
        >
          <FolderAdd onClick={clickMove} />
          <Tooltip
            tooltipText="Move to business"
            tipWidth="130px"
            showTip={!showTip ? "" : "flex"}
          />
        </div>
        <div
          className="display-none standalone-style"
          style={{ display: standaloneStyle }}
        >
          <More onClick={handleDisplayMore} />
          <div className={displayMore ? "items-more_list more-list" : "display-none"}>
            <p onClick={()=>{clickHistoryItem(); setDisplayMore(false)}}>
              <Clock /> History
            </p>
            {/* <p onClick={()=>{clickCloneItem(); setDisplayMore(false)}}>
              <Copy /> Clone Item
            </p> */}
            <p onClick={clickDeleteItem}>
              <Trash /> Delete
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboardheader;
