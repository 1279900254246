import { CloseCircle } from "iconsax-react";
import React, { useState } from "react";
import "./add-items-popup.css";
import { PasswordInput } from "../AuthHead/AuthHead";
import Dashboardbtn from "../dashboard-buttons/Dashboardbtn";
import { AxiosAuthPost } from "../../axios/axios";
import { useNavigate } from "react-router-dom";


const DeleteAccountModal = ({display, setDisplay}) => {
  const url = "delete-account/";
  
  const closeModal = () => {
    setDisplay(false);
  }

  const clickCancel = () => {
    setDisplay(false);
  }

  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleDataChange = (e) => {
    setPasswordErr("");
    setPassword(e.target.value)
  }

  const deleteAcc = () => {
    setIsLoading(true);
    const dataObject = {
      password: password,
    }
    AxiosAuthPost(url, dataObject).then((res) => {
      // console.log(res);
      setIsLoading(false);
      localStorage.clear();
      navigate("/");
    }).catch((err) => {
      // console.log(err.response);
      setPasswordErr(err?.response?.data?.errors[0]?.error);
      setIsLoading(false);
    })
  }

  return (
    <div>
      <div
        className={`overlay ${display ? "w-full" : "w-0"}`}
        onClick={closeModal}
      >
        {" "}
      </div>
      <div className={!display ? "display-none" : "add-items_popup delete-modal"}>
        <div className="add-items_popup-head" style={{justifyContent: "flex-end"}}>
          <CloseCircle style={{ cursor: "pointer" }} onClick={closeModal} />
        </div>
        <div className="delete-head">
          <h3>Delete Account</h3>
          <p>Note that this action will permanently remove your account from our system.</p>
        </div>
          <PasswordInput
            placeholder="Enter your password"
            dataChange={handleDataChange}
            formValue={password}
            errorMessage={passwordErr}
          />
          <div className="delete-btn">
            <Dashboardbtn btnClass={"btn-variant2"} btnText={"Go Back"} btnWidth={"192px"} submitForm={clickCancel} />
            <Dashboardbtn btnClass={"btn"} btnBgColor={"red"} btnText={"Delete Account"} btnWidth={"192px"} submitForm={deleteAcc} isLoading={isLoading} />
          </div>
      </div>
    </div>
  );
};

export default DeleteAccountModal;
