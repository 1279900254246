import React from "react";
import { Outlet } from "react-router-dom";
import gradient from "../../assets/landingpage/gradient.png";

const AuthLayout = () => {
  return (
    <div className="auth-layout">
      <div className="auth-layout_gradient">
        <img src={gradient} alt="" />
      </div>
      <div className="authpage-cont">
        <div className="authpage">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
