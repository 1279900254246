import React, {useState} from 'react';
import "./composite-items.css";
import { Add } from 'iconsax-react';
import Dashboardheader from '../../Components/dashboard-header/Dashboardheader';
import Goback from '../../Components/Dashboard-goback/Goback';
import Quantitybar from '../../Components/quantity-bar/Quantitybar';
import itemImg from "../../assets/dashboard/itemPlaceholder.png";
import AddItemsPopup from '../../Components/add-items-popup/AddItemsPopup';

const CompositeItems = () => {
  const [display, setDisplay] = useState(false);
  const showItemAdd = () => {
    if (!display) {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  };
    const quantityArray = [
        {
          value: "Quantity",
          quantity: "1500",
        },
        {
          value: "Total Value",
          quantity: "₦200,000.00",
        },
      ];
      const itemsArray = [
        {
          id: "1",
          image: itemImg,
          variant: "Standalone",
          name: "Garri Flakes",
          stock_alert: "In stock",
          quantity: "500 units",
          price: "₦2,000.00",
        },
        {
          id: "2",
          image: itemImg,
          variant: "Standalone",
          name: "Garri Flakes",
          stock_alert: "Out of stock",
          quantity: "500 units",
          price: "₦2,000.00",
        },
        {
          id: "3",
          image: itemImg,
          variant: "Standalone",
          name: "Garri Flakes",
          stock_alert: "In stock",
          quantity: "500 units",
          price: "₦2,000.00",
        },
        {
          id: "4",
          image: itemImg,
          variant: "Standalone",
          name: "Garri Flakes",
          stock_alert: "Low stock",
          quantity: "500 units",
          price: "₦2,000.00",
        },
      ];
  return (
    <div>
        <Goback />
        <div className="composite-items_header">
        <Dashboardheader
          headerText="Composite items name"
          filterStyle="flex"
          addStyle="flex"
          addBtnText="add"
          addBtnIcon={<Add />}
          addSubmitForm={showItemAdd}
        />
        <AddItemsPopup display={display} setDisplay={setDisplay}  />
        </div>
        <Quantitybar
          quantityArray={quantityArray}
          editDisplay=""
          itemsArray={itemsArray}
          itemDisplay="block"
          quantityDisplay="flex"
          quantityBarDisplay="block"
        />
    </div>
  )
}

export default CompositeItems