import React from "react";
import "./mobile-redirect.css";
import { AuthHead } from "../../Components/AuthHead/AuthHead";
import googlePlay from "../../assets/dashboard/Google Play.svg";
// import appStore from "../../assets/dashboard/App Store.svg";

const MobileRedirect = () => {
  return (
    <div>
      <AuthHead
        headerText="Stay Connected On-the-Go"
        pText="Experience the power of our platform in the palm of your hand. Download our mobile app for a seamless and user-friendly mobile experience"
      />
      <div
        className="mobile-link_btn"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          padding: "20px 0",
          alignItems: "center",
        }}
      >
        <a href="https://play.google.com/store/apps/details?id=ng.stockkeeper" target="_blank" rel="noopener noreferrer"><img
          style={{ width: "166px", height: "48px" }}
          src={googlePlay}
          alt="google-play"
        /></a>
        {/* <img
          style={{ width: "166px", height: "48px" }}
          src={appStore}
          alt="app-store"
        /> */}
      </div>
    </div>
  );
};

export default MobileRedirect;
