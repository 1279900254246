import emptyNotification from "../../assets/dashboard/empty-notifications.svg"

const NotificationsEmpty = () => {
    return (
        <section className="empty-notification-container">
            <img src={emptyNotification} width={200} height={200} alt="empty-notification" />
            <p>  You don't have any notifications </p>
        </section>
    );
}
 
export default NotificationsEmpty;