import React, { useState } from "react";
import Back from "../../Components/Back";
import { AuthHead, FormButton, InputForm } from "../../Components/AuthHead/AuthHead";
import { AxiosPost } from "../../axios/axios";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
    const url = "password-reset/generate/";
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const navigate = useNavigate();
    const handleDataChange = (e) =>{
        if(e.target.id === "email"){
            setEmailErr("");
            setEmail(e.target.value);
        }
        // console.log(email);
    }
    const dataObject = {
        credential: email,
    }
    const handleSubmit = () =>{
        // setIsLoading(true);
        AxiosPost(url,dataObject).then(res=>{
            // console.log(res);
            setIsLoading(false);
            if (res.success === true){
                navigate("/forgot-password/verify");
            }
        }).catch(err =>{
            // console.log(err.response);
            setIsLoading(false);
            if (err.message !== "Request failed with status code 500" && err.message !== "Network Error") {
            for (let i = 0; i < err.response.data.errors.length; i++) {
              if (err.response.data.errors[i].fieldName === "email" || err.response.data.errors[i].fieldName === "credential" ) {
                setEmailErr(err.response.data.errors[i].error);
              }
            }
          }
        })
    }

  return (
    <div>
      <Back />
      <AuthHead
        headerText="Forgot Password?"
        pText="No worries! It happens to the best of us. But don't worry, we're here to help you get back on track."
      />
      <InputForm
        formLabel="Email Address/Phone Number"
        placeholder="Enter your email address/phone number"
        dataChange={handleDataChange}
        formId="email"
        formValue={email}
        errorMessage={emailErr}
      />
      <FormButton
        submitForm={handleSubmit}
        isLoading={isLoading}
        dataValues={email}
        buttonText={"Reset Password"}
      />
    </div>
  );
};

export default ForgotPassword;
