import { useState } from "react";

import GeneralComponent from "../../Components/notifications-component/general-component.component";
import InventoryComponent from "../../Components/notifications-component/inventory-component.component";


import Dashboardheader from "../../Components/dashboard-header/Dashboardheader";
import Tab from "../../Components/settings/tab/tab.component";
// import ActivityHistory from "../activity-history/activity-history.page";


const Notifications = () => {
  
  const [ activeTab, setActiveTab ] = useState("general");
  const tabs = ["general", "inventory"];

    return (
        <section>
          <Dashboardheader
            headerText="Notifications"
            notificationStyle={activeTab === "inventory" && "flex"}
          />
          <Tab activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
          { activeTab === tabs[0] && 
          // <ActivityHistory nots={true} />
          <GeneralComponent />
           }
          { activeTab === tabs[1] && <InventoryComponent /> }
        </section>
    );
}
 
export default Notifications;