const convertArrayToObject = (arr) => {
    const result = {};

    for (let i = 0; i < arr.length; i++) {
      const fieldName = arr[i].fieldName;
      const message = arr[i].error;

      result[fieldName] = message;
    }

    return result;
};

export default convertArrayToObject;