import Select from 'react-select';
import "./custom-select.styles.css";

const CustomSelect = ({ label, options, ...rest }) => {
    
    const customStyles = {
      control: (base, state) => ({
        ...base,
        border: state.isFocused ? '1px solid #E6E6E6' : '1px solid #E6E6E6',
        borderRadius: '8px',
        boxShadow: state.isFocused ? 0 : 0,
        fontFamily: 'Raleway',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        '&:hover': {
          border: state.isFocused ? '1px solid #E6E6E6' : '1px solid #E6E6E6'
        },
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isFocused ? "#F08227" : null,
          color: isFocused ? "#FFFFFF" : "#111111",
          fontFamily: 'Raleway',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
        };
      }
    };


    return (
      <>
        <section className='select-container'>
          <label className=""> { label } </label>
          <Select
              className='select_options'
              options={options}
              styles={customStyles}
              components={{
                IndicatorSeparator: () => null
              }}
              {...rest}
          />
        </section>
      </>
    );
}
 
export default CustomSelect;