import React from "react";
import "./filter-popup.css";
import Dashboardbtn from "../dashboard-buttons/Dashboardbtn";

const DeletePopup = ({
  display,
  cancelDelete,
  popupName,
  deleteItem,
  isLoading,
  setDispay,
  restore,
}) => {
  return (
    <div>
      <div
        className={`overlay ${display ? "w-full" : "w-0"}`}
      >
        {" "}
      </div>
      <div className={!display ? "display-none" : "delete-popup"}>
        <h3>{restore ? restore : "Delete"}</h3>
        <div className="middle-popup_text">
          <h4>Do you want to {restore ? restore : "delete"} : </h4>
          <p>{popupName} ?</p>
        </div>

        <div className="delete-popup_btn">
          <Dashboardbtn
            btnClass="dashboard-variant  delete-bt"
            btnText="cancel"
            submitForm={cancelDelete}
          />
          <Dashboardbtn
            btnClass="dashboard-btn delete-bt"
            btnText={restore ? restore : "delete"}
            btnBgColor={restore ? "#367C39" : "#B50000"}
            submitForm={deleteItem}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default DeletePopup;
