import "./tab.styles.css";

const Tab = ({ activeTab, setActiveTab, tabs }) => {

    const handleActiveTab = (tab) => {
        setActiveTab(tab);
    }

    return (
        <section className="tabs-container">
            { tabs.map((tab, id) => (
                <span
                key={id}
                className={`${activeTab === tab ? 'active' : ""}`}
                onClick={() => handleActiveTab(tab)}
                > 
                    {tab} 
                </span>
            ))}
        </section>
    );
}
 
export default Tab;