import { Add, CloseCircle } from "iconsax-react";
import Dashboardheader from "../../Components/dashboard-header/Dashboardheader";
import Quantitybar from "../../Components/quantity-bar/Quantitybar";

import "./folder-page.css";
import { InputForm } from "../../Components/AuthHead/AuthHead";
import { useState } from "react";
import AddNew from "../../Components/add&new/Add&New";
import FolderFilter from "../../Components/filter-popups/FolderFilter";
import { useSelector, useDispatch } from "react-redux";
import { itemDd } from "../../states/items/itemDropdown";
import {
  AxiosAuthPost,
  AxiosAuthPut,
  AxiosDelete,
  AxiosGet,
} from "../../axios/axios";
import { useEffect } from "react";
import useAddContent from "../../hooks/useAddContent";
import { bDropdown } from "../../states/business/businessDropdown";
import Empty from "../../Components/empty/empty.component";
import emptyIcon from "../../assets/dashboard/empty-icon.svg";
import MultipleSelect from "../../Components/AuthHead/MultipleSelect";
import DeletePopup from "../../Components/filter-popups/DeletePopup";
import SuccessPopup from "../../Components/filter-popups/SuccessPopup";
import Loading from "../../Components/Loading";
import { setFailureModal } from "../../states/general/general";
import FailedMsg from "../../Components/alert/failed-msg.component";

const Folder = () => {
  const [selectedSort, setSelectedSort] = useState("");
  const [selectedOrder, setSelectedOrder] = useState("");
  const url = `business/?sortBy=${selectedSort}&orderBy=${selectedOrder}`;
  const deleteMultipleUrl = "business/multiple-delete";
  const [selected, setSelected] = useState([]);
  const folderId = localStorage.getItem("folderId");
  const deleteUrl = `business/${folderId}/`;
  const [businessName, setBusinessName] = useState("");
  const multiDelete = localStorage.getItem("multiDelete");
  const [isDLoading, setIsDLoading] = useState(false);
  const [applyFilter, setApplyFilter] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [businessData, setBusinessData] = useState("");
  const [success, setSuccess] = useState("");
  const [displayDelete, setDisplayDelete] = useState(false);
  const { addContent } = useAddContent();
  const [nameError, setNameError] = useState("");
  const [itemError, setItemError] = useState("");
  const [display, setDisplay] = useState(false);
  const [displayFilter, setDisplayFilter] = useState(false);
  // const [subErr, setSubErr] = useState(false);
  const [subErrMsg, setSubErrMsg] = useState("");
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(null);
  const itemDropdown = useSelector((state) => state.itemsDrop);
  const myItemsId = useSelector((state) => state.itemId);
  const allItemsUrl = "items-all/?itemType=Stand Alone";
  const userPermissions = useSelector((state) => state.userPermissions);
  const dispatch = useDispatch();
  const selectedValue = selected.map((item) => {
    return item.id;
  });
  const dataObject = {
    name: businessName,
    itemIds: selectedValue,
  };
  const deleteObject = {
    businessIds: myItemsId,
  };
  useEffect(() => {
    AxiosGet(url)
      .then((res) => {
        // console.log(res.data);
        setBusinessData(res.data);
        dispatch(bDropdown(res.data.businessList));
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err.response);
      });
    // eslint-disable-next-line
  }, [display, displayDelete, displaySuccess, applyFilter]);
  useEffect(() => {
    AxiosGet(allItemsUrl)
      .then((res) => {
        // console.log(res);
        // setItemDropdown(res.data);
        dispatch(itemDd(res.data));
      })
      .catch((err) => {
        // console.log(err.response);
      });
    // eslint-disable-next-line
  }, [display]);
  const submitAdd = () => {
    setIsLoading(true);
    // console.log(dataObject);
    AxiosAuthPost(url, dataObject)
      .then((res) => {
        setIsLoading(false);
        // console.log(res);
        if (addContent === "Add") {
          setDisplay(false);
          setSelected([]);
          setBusinessName("");
        }
        if (addContent === "Add & New") {
          setDisplaySuccess(true);
          setSuccess(
            "You have successfully added a business, you can now add another"
          );
          setSelected([]);
          setBusinessName("");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        // console.log(err.response);
        if(err?.response?.status === 403){
          dispatch(setFailureModal(true));
          setSubErrMsg(err?.response?.data?.message);
        }
        for (let i = 0; i < err?.response?.data?.errors?.length; i++) {
          if (err.response?.data?.errors[i]?.fieldName === "name") {
            setNameError(err?.response?.data?.errors[i]?.error);
          }
          if (err?.response?.data?.errors[i]?.fieldName === "itemsIds") {
            setItemError(err?.response?.data?.errors[i]?.error);
          }
        }
      });
  };
  const popDelete = () => {
    if (userPermissions.deleteItem) {
      setDisplayDelete(!displayDelete);
    } else {
      dispatch(setFailureModal(true));
      setSubErrMsg("You do not have permissions to perform this action!!!");
    }
  };
  const popEdit = () => {
    if (userPermissions.editItem) {
      setDisplay(!display);
      setIsEdit(true);
    } else {
      dispatch(setFailureModal(true));
      setSubErrMsg("You do not have permissions to perform this action!!!");
    }
  };
  const deleteCancel = () => {
    setDisplayDelete(false);
    localStorage.removeItem("multiDelete");
  };
  const deleteFolder = () => {
    setIsDLoading(true);
    if (!multiDelete) {
      AxiosDelete(deleteUrl)
        .then((res) => {
          // console.log(res);
          setIsDLoading(false);
          if (res.success === true) {
            setDisplaySuccess(true);
            setSuccess("You have successfully deleted selected entities");
            setDisplayDelete(false);
          }
        })
        .catch((err) => {
          setIsDLoading(false);
          // console.log(err.response);
        });
    }
    if (multiDelete) {
      AxiosAuthPost(deleteMultipleUrl, deleteObject)
        .then((res) => {
          // console.log(res);
          setIsDLoading(false);
          if (res.success === true) {
            localStorage.removeItem("multiDelete");
            setDisplaySuccess(true);
            setSuccess("You have successfully deleted selected entities");
            setDisplayDelete(false);
          }
        })
        .catch((err) => {
          // console.log(err.response);
          setIsDLoading(false);
        });
    }
  };
  const editFolder = () => {
    setIsDLoading(true);
    // console.log("true");
    AxiosAuthPut(deleteUrl, dataObject)
      .then((res) => {
        // console.log(res);
        setIsDLoading(false);
        if (addContent === "Add") {
          setDisplay(false);
          setSelected([]);
          setBusinessName("");
        }
        if (addContent === "Add & New") {
          setSelected([]);
          setBusinessName("");
        }
      })
      .catch((err) => {
        setIsDLoading(false);
        // console.log(err.response);
        for (let i = 0; i < err.response.data.errors.length; i++) {
          if (err.response.data.errors[i].fieldName === "name") {
            setNameError(err.response.data.errors[i].error);
          }
          if (err.response.data.errors[i].fieldName === "itemsIds") {
            setItemError(err.response.data.errors[i].error);
          }
        }
      });
  };

  const quantityArray = !businessData
    ? []
    : [
        {
          value: "Items",
          quantity: Number(
            businessData.totalDetails.totalItems
          ).toLocaleString(),
        },
        {
          value: "Quantity",
          quantity: Number(
            businessData.totalDetails.totalQuantity
          ).toLocaleString(),
        },
        {
          value: "Total Value",
          quantity:
            "₦" +
            Number(businessData.totalDetails.totalValue).toLocaleString() +
            ".00",
        },
      ];
  const folderArray = !businessData ? [] : businessData.businessList;
  // eslint-disable-next-line
  const folderName = folderArray.map((item) => {
    if (item.id === folderId) {
      return item.name;
    }
  });
  const filterSubmit = () => {
    setApplyFilter(!applyFilter);
    setDisplayFilter(false);
  };
  const handleDataChange = (e) => {
    setNameError("");
    setBusinessName(e.target.value);
    // console.log(businessName);
  };
  const handleAdd = () => {
    if (userPermissions.addItem) {
      setDisplay(!display);
    } else {
      dispatch(setFailureModal(true));
      setSubErrMsg("You do not have permissions to perform this action!!!");
    }
  };
  const openFilter = () => {
    setDisplayFilter(!display);
  };

  return isLoading === null ? (
    <Loading />
  ) : (
    <section>
      <SuccessPopup
        display={displaySuccess}
        setDisplay={setDisplaySuccess}
        popupMessage={success}
      />
      <FailedMsg
        msg={subErrMsg}
      />
      <div className="folder-dashboard_header">
        <Dashboardheader
          headerText="section"
          filterStyle="flex"
          addStyle="flex"
          addBtnText="add"
          addBtnIcon={<Add />}
          addSubmitForm={handleAdd}
          filterSubmitForm={openFilter}
        />
        <FolderFilter
          display={displayFilter}
          setDisplay={setDisplayFilter}
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          selectedSort={selectedSort}
          setSelectedSort={setSelectedSort}
          applyFilter={filterSubmit}
        />
        <div
          className={`overlay ${display ? "w-full" : "w-0"}`}
          onClick={() => {
            setDisplay(false);
            setIsEdit(false);
          }}
        >
          {" "}
        </div>
        <div className={display ? `add-folder` : `display-none`}>
          <div className="add-folder_head">
            <h2>Add New Section</h2>
            <CloseCircle
              onClick={() => {
                setDisplay(false);
                setIsEdit(false);
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="add-folder_body">
            <InputForm
              formLabel="Name"
              placeholder="Enter Section Name"
              dataChange={handleDataChange}
              errorMessage={nameError}
              formValue={businessName}
            />
            <div className="add-folder-select">
              <MultipleSelect
                formLabel="Add Items"
                optionsArray={itemDropdown}
                selected={selected}
                setSelected={setSelected}
                errorMessage={itemError}
                setErrorMessage={setItemError}
                selectPlaceholder="Select options..."
                dropHeight="180px"
              />
              <AddNew
                addBtnStyle={{ paddingBottom: "0" }}
                submitForm={!isEdit ? submitAdd : editFolder}
                isLoading={isLoading}
                btnWidth="120px"
              />
            </div>
          </div>
        </div>
      </div>
      {businessData.businessList.length === 0 ? (
        <Empty
          img={emptyIcon}
          title="You do not have any section"
          btnText="Add New Section"
          submitClick={handleAdd}
        />
      ) : (
        <Quantitybar
          quantityDisplay="flex"
          quantityArray={quantityArray}
          folderArray={folderArray}
          editDisplay=""
          folderDisplay="block"
          quantityBarDisplay="block"
          deleteItem={popDelete}
          editItem={popEdit}
        />
      )}
      <DeletePopup
        display={displayDelete}
        setDispay={setDisplayDelete}
        deleteItem={deleteFolder}
        popupName={!multiDelete ? folderName : myItemsId.length + " Selected"}
        isLoading={isDLoading}
        cancelDelete={deleteCancel}
      />
    </section>
  );
};

export default Folder;
