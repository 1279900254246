import CompanyInformation from "./company-information.component";
import PersonalInformation from "./personal-information.component";
import "./profile.styles.css";

const Profile = () => {
    return (
        <section className="profile-container">
            <PersonalInformation />
            <CompanyInformation />
        </section>
    );
}
 
export default Profile;