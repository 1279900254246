import { ArrowRight } from "iconsax-react";
import Dashboardheader from "../../Components/dashboard-header/Dashboardheader";
import "./reports.css";
import { useState } from "react";
import ReportsModal from "../../Components/reports/ReportsModal";
import { useDispatch, useSelector } from "react-redux";
import FailedMsg from "../../Components/alert/failed-msg.component";
import { setFailureModal } from "../../states/general/general";

const ReportAnalysis = () => {
  const [display, setDisplay] = useState(false);
  const [reportId, setReportId] = useState(false);
  const dispatch = useDispatch();
  const userPermissions = useSelector((state) => state.userPermissions);
  const openModal = (item) => {
    if (userPermissions.viewReport) {
      setDisplay(!display);
      setReportId(item.id);
    } else {
      dispatch(setFailureModal(true));
    }
  };
  const reportsArray = [
    {
      id: "total",
      title: "Total Inventory Report",
      text: "This report offers a concise overview of the current state of inventory within the specified timeframe.",
    },
    {
      id: "low-level",
      title: "Low Level Out of Stock Report",
      text: "This report highlights inventory items that have reached or fallen below the defined low stock threshold.",
    },
    {
      id: "expired-items",
      title: "Expired Items Report",
      text: "This shows a list of expired Items in the system.",
    },
    {
      id: "stock-balance",
      title: "Stock Balance Report",
      text: "This report provides real-time visibility into your inventory, displaying accurate quantities, and essential product details.",
    },
    {
      id: "staff",
      title: "Staff Report",
      text: "This report provides real-time visibility into your staff.",
    },
  ];
  return (
    <section>
      <Dashboardheader headerText="Reports & Analytics" />
      <FailedMsg
        msg={"You do not have permissions to perform this action!!!"}
      />
      <ReportsModal
        display={display}
        setDisplay={setDisplay}
        headerText={
          reportId === "total"
            ? "Total Inventory Report"
            : reportId === "low-level" ? "Low Level Out of Stock Report" : reportId === "stock-balance" ? "Stock Balance Report" : reportId === "staff" ? "Staff Report" : reportId === "expired-items" ? "Expired Items" :  ""
        }
        totalInventoryDisplay={reportId === "total" ? "block" : ""}
        lowInventoryDisplay={reportId === "low-level" ? "block" : ""}
        stockBalanceDisplay={reportId === "stock-balance" ? "block" : ""}
        expiredItemsDisplay={reportId === "expired-items" ? "block" : ""}
        staffDisplay={reportId === "staff" ? "block" : ""}
      />
      {reportsArray.map((item, idx) => {
        return (
          <div
            onClick={() => openModal(item)}
            key={idx}
            id={item.id}
            className="reports-analysis_card"
          >
            <div className="reports-analysis_card-left">
              <h3>{item.title}</h3>
              <p>{item.text}</p>
            </div>
            <div className="reports-analysis_card-right">
              <ArrowRight />
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default ReportAnalysis;
