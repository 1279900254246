import { useState } from "react";
import Dashboardheader from "../../Components/dashboard-header/Dashboardheader";
import Tab from "../../Components/settings/tab/tab.component";
import General from "../../Components/settings/general/general.component";
import Profile from "../../Components/settings/profile/profile.component";
import ChangePassword from "../../Components/settings/change-password/change-password.components";
import Billing from "../../Components/settings/billing/billing.component";

const Settings = () => {
  const [ activeTab, setActiveTab ] = useState("general");
  const tabs = ["general", "profile", "password", "billing" ];

    return (
        <section>
          <Dashboardheader
            headerText="settings"
          />
          <Tab activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />  
          { activeTab === tabs[0] && <General /> }
          { activeTab === tabs[1] && <Profile /> }
          { activeTab === tabs[2] && <ChangePassword /> }
          { activeTab === tabs[3] && <Billing /> }
        </section>
    );
}
 
export default Settings;