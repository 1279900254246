import React from 'react'
import Faq from "../../Components/faq/Faq";

const FaqPage = () => {
  return (
    <div>
      <Faq />
    </div>
  )
}

export default FaqPage