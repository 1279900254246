import React, { useEffect, useState } from "react";
import Goback from "../../Components/Dashboard-goback/Goback";
import Dashboardheader from "../../Components/dashboard-header/Dashboardheader";
import Quantitybar from "../../Components/quantity-bar/Quantitybar";
import { InputForm } from "../../Components/AuthHead/AuthHead";
import "./stand-alone.css";
import AmountInput from "../../Components/amount-input/AmountInput";
import TextAreaInput from "../../Components/textarea/TextAreaInput";
import { CloseCircle, Edit2, Trash } from "iconsax-react";
import Dashboardbtn from "../../Components/dashboard-buttons/Dashboardbtn";
import ToolTipInput from "../../Components/tooltip/ToolTipInput";
import CustomFields from "../../Components/custom-fields/CustomFields";
import {
  // AxiosAuthPost,
  AxiosAuthPut,
  AxiosDelete,
  AxiosFormDataPut,
  AxiosGet,
} from "../../axios/axios";
import UploadImg from "../../Components/upload-img/UploadImg";
import useCoverPicture from "../../hooks/useCoverPicture";
import useData from "../../hooks/useData";
import { useNavigate } from "react-router-dom";
import DeletePopup from "../../Components/filter-popups/DeletePopup";
import DiscardChanges from "../../Components/discard-changes/DiscardChanges";
import Loading from "../../Components/Loading";
import { useDispatch, useSelector } from "react-redux";
import SuccessPopup from "../../Components/filter-popups/SuccessPopup";
import useActivityDetails from "../../hooks/useActivityDetails";
import FailedMsg from "../../Components/alert/failed-msg.component";
import { setFailureModal } from "../../states/general/general";
import DateInput from "../../Components/amount-input/DateInput";

const Standalone = () => {
  const itId = localStorage.getItem("itemId");
  const url = `items/${itId}/`;
  const moveUrl = `items/`;
  // const cloneUrl = `items/${itId}/clone`;
  const historyUrl = `items/${itId}/history`;
  const dispatch = useDispatch();
  const [singleItemData, setSingleItemData] = useState();
  const { data, setData } = useData();
  const {setActivityDetails} = useActivityDetails();
  const [dateValue, setDateValue] = useState();
  const businessDropdown = useSelector((state) => state.businessDrop);
  const userPermissions = useSelector((state) => state.userPermissions);
  useEffect(() => {
    AxiosGet(url)
      .then((res) => {
        // console.log(res.data);
        setSingleItemData(res.data);
        setData({...data, expiringDate: res.data.expiringDate})
      })
      .catch((err) => {
        // console.log(err.response);
      });
    // eslint-disable-next-line
  }, []);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isDLoading, setIsDLoading] = useState(false);
  const [displayDelete, setDisplayDelete] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [failedMsg, setFailedMsg] = useState("");
  const [editPic, setEditPic] = useState(false);
  const [displayDiscard, setDisplayDiscard] = useState(false);
  const { coverPicture, setCoverPicture } = useCoverPicture();
  const [nameError, setNameError] = useState("");
  const [
    showToggle,
    // setShowToggle
  ] = useState(false);
  const [quantityError, setQuantityError] = useState("");
  const [cpError, setCpError] = useState("");
  const [spError, setSpError] = useState("");
  const [alertError, setAlertError] = useState("");
  const [businessId, setBusinessId] = useState("");
  const [businessName, setBusinessName] = useState("");
  const dataObject = !singleItemData
    ? ""
    : {
        name: data.name ? data.name : singleItemData.name,
        cost_price: data.cost_price
          ? data.cost_price
          : singleItemData.costPrice,
        selling_price: data.selling_price
          ? data.selling_price
          : singleItemData.sellingPrice,
        quantity: data.quantity ? data.quantity : singleItemData.quantity,
        picture: coverPicture ? coverPicture : "",
        alert_threshold: data.alert_threshold
          ? data.alert_threshold
          : singleItemData.alertThreshold,
        notes: data.notes ? data.notes : singleItemData.notes,
        has_variants: showToggle
          ? data.hasVariants
          : singleItemData.hasVariants,
        variant_attributes: data.variant_attributes
          ? data.variant_attributes
          : singleItemData.variant_attributes,
        variant_options: data.variant_options
          ? data.variant_options
          : singleItemData.variant_options,
        expiringDate: dateValue
          ? dateValue
          : singleItemData.expiringDate,
        business_id: data.business_id
          ? data.business_id
          : singleItemData.business_id,
      };
  const addItem = () => {
    // console.log("hi");
    // console.log(data);
    // console.log(dataObject);
    setIsLoading(true);
    AxiosFormDataPut(url, dataObject)
      .then((res) => {
        // console.log(res);
        setSingleItemData(res.data);
        navigate("/items");
        setData({
          name: "",
          cost_price: "",
          selling_price: "",
          quantity: "",
          picture: "",
          alert_threshold: "",
          notes: "",
          has_variants: "",
          variant_attributes: "",
          expiringDate: "",
          variant_options: "",
          business_id: "",
        });
        setCoverPicture("");
        setDateValue("");
        setIsLoading(false);
        // console.log(res);
      })
      .catch((err) => {
        // console.log(err.response.data.errors);
        if (err.response.status === 403){
          setFailedMsg(err.response.data.message);
          dispatch(setFailureModal(true));
        } else
        for (let i = 0; i < err.response.data.errors.length; i++) {
          if (err.response.data.errors[i].fieldName === "name") {
            setNameError(err.response.data.errors[i].error);
          }
          if (err.response.data.errors[i].fieldName === "quantity") {
            setQuantityError(err.response.data.errors[i].error);
          }
          if (err.response.data.errors[i].fieldName === "costPrice") {
            setCpError(err.response.data.errors[i].error);
          }
          if (err.response.data.errors[i].fieldName === "sellingPrice") {
            setSpError(err.response.data.errors[i].error);
          }
          if (err.response.data.errors[i].fieldName === "alertThreshold") {
            setAlertError(err.response.data.errors[i].error);
          }
        }
        setIsLoading(false);
      });
  };

  const handleDataChange = (e) => {
    if (e.target.id === "name") {
      setNameError("");
    }
    if (e.target.id === "quantity") {
      setQuantityError("");
    }
    if (e.target.id === "cost_price") {
      setCpError("");
    }
    if (e.target.id === "selling_price") {
      setSpError("");
    }
    if (e.target.id === "alert_threshold") {
      setAlertError("");
    }
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    // console.log(newData);
  };
  // const cloneItem = () => {
  //   AxiosAuthPost(cloneUrl)
  //     .then((res) => {
  //       // console.log(res);
  //       if (res.success === true) {
  //         setDisplaySuccess(true);
  //         setSuccessMessage(res.message);
  //       }
  //     })
  //     .catch((err) => {
  //       // console.log(err.response);
  //     });
  // };
  const itemHistory = () => {
    AxiosGet(historyUrl)
      .then((res) => {
        // console.log(res);
        navigate("/activityhistory");
        setActivityDetails(res.data);
      })
      .catch((err) => {
        // console.log(err.response);
      });
  };
  const popDelete = () => {
    if (userPermissions.deleteItem){
      setDisplayDelete(!displayDelete);
    } else{
      dispatch(setFailureModal(true));
    }
  };
  const deleteItem = () => {
    setIsDLoading(true);
    AxiosDelete(url)
      .then((res) => {
        // console.log(res);
        if (res.success === true) {
          setIsDLoading(false);
          setDisplayDelete(false);
          navigate("/items");
        }
      })
      .catch((err) => {
        // console.log(err.response);
        if (err.response.status === 403){
          setFailedMsg(err.response.data.message);
          dispatch(setFailureModal(true));
        }
        setIsDLoading(false);
      });
  };
  const moveObject = {
    itemIds: [itId],
    businessId: businessId,
  };
  const [moveFolder, setMoveFolder] = useState(false);
  const selectBusiness = (item) => {
    setBusinessId(item.id);
    setBusinessName(item.name);
  };
  const moveToBusiness = () => {
    setIsLoading(true);
    AxiosAuthPut(moveUrl, moveObject)
      .then((res) => {
        // console.log(res);
        setIsLoading(false);
        if (res.success === true) {
          setMoveFolder(false);
          setDisplaySuccess(true);
          setSuccessMessage(res.message + " " + businessName);
          // navigate("/items");
        }
      })
      .catch((err) => {
        // console.log(err.response);
        setIsLoading(false);
      });
  };
  const lastEditedAt = !singleItemData
    ? ""
    : new Date(singleItemData.lastEditedAt);
  const quantityArray = !singleItemData
    ? ""
    : [
        {
          value: "Quantity",
          quantity: Number(singleItemData.quantity).toLocaleString(),
        },
        {
          value: "Total Value",
          quantity:
            "₦" + Number(singleItemData.totalValue).toLocaleString() + ".00",
        },
        {
          value: "Last Updated at",
          quantity: lastEditedAt.toLocaleDateString("en-GB"),
        },
      ];

  const handleMove = () => {
    if (userPermissions.addItem){
      setMoveFolder(!moveFolder);;
    } else{
      dispatch(setFailureModal(true));
    }
  };
  const clickDiscard = () => {
    if (userPermissions.editItem){
      setDisplayDiscard(!displayDiscard);
    } else{
      dispatch(setFailureModal(true));
    }
  };
  return !singleItemData ? (
    <Loading />
  ) : (
    <section>
      <Goback />
      <FailedMsg msg={failedMsg ? failedMsg : "You do not have permissions to perform this action!!!"} />
      <SuccessPopup
        display={displaySuccess}
        setDisplay={setDisplaySuccess}
        popupMessage={successMessage}
        cancelClick={() => navigate("/business")}
      />
      <div className="standalone-header">
        <Dashboardheader
          inputDisplay={true}
          placeholder={singleItemData.name}
          formValue={data.name}
          formId="name"
          dataChange={handleDataChange}
          errorMessage={nameError}
          standaloneStyle="flex"
          handleClick={handleMove}
          deleteItem={popDelete}
          // clickCloneItem={cloneItem}
          clickHistoryItem={itemHistory}
          isReadOnly={userPermissions.editItem ? "" : true}
        />
        <div className={`overlay ${moveFolder ? "w-full" : "w-0"}`}> </div>
        <div className={!moveFolder ? "display-none" : "move-to_folder"}>
          <div className="move-to_folder-header">
            <h3>Move to Business</h3>
            <CloseCircle
              onClick={() => {
                setMoveFolder(false);
                setBusinessId("");
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
          {businessDropdown.map((item, idx) => {
            return (
              <p
                onClick={() => selectBusiness(item)}
                key={idx}
                id={item.id}
                className={item.id === businessId ? "selected" : ""}
              >
                {item.name}
              </p>
            );
          })}

          <div className="move-to_folder-btn">
            <Dashboardbtn
              btnClass="dashboard-btn"
              btnText="Select"
              btnWidth="180px"
              submitForm={moveToBusiness}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>

      <Quantitybar quantityArray={quantityArray} />
      <div className="standalone-form">
        <div className="standalone-form_left">
          <div className="standalone-form_left-inputs">
            <div className="quantity-form">
              <InputForm
                formLabel="Quantity"
                placeholder={Number(singleItemData.quantity).toLocaleString()}
                formId="quantity"
                dataChange={handleDataChange}
                errorMessage={quantityError}
                formValue={data.quantity}
                isReadonly={userPermissions.editItem ? "" : true}
              />
            </div>
            <AmountInput
              amountLabel="Cost Price"
              amountPlaceholder={Number(
                singleItemData.costPrice
              ).toLocaleString()}
              amountId="cost_price"
              dataChange={handleDataChange}
              errorMessage={cpError}
              amountValue={data.cost_price}
              readOnly={userPermissions.editItem ? "" : true}
            />
            <AmountInput
              amountLabel="Selling Price"
              amountPlaceholder={Number(
                singleItemData.sellingPrice
              ).toLocaleString()}
              amountId="selling_price"
              dataChange={handleDataChange}
              errorMessage={spError}
              amountValue={data.selling_price}
              readOnly={userPermissions.editItem ? "" : true}
            />
            <AmountInput
              amountLabel="Total Value"
              amountPlaceholder={
                Number(singleItemData.totalValue).toLocaleString() + ".00"
              }
              readOnly={"readOnly"}
            />
            <ToolTipInput
              formLabel="Alert Threshold"
              placeholder={Number(
                singleItemData.alertThreshold
              ).toLocaleString()}
              tooltipText="Set alert threshold to determine the level at which you will be notified about low stock. When the threshold is reached, an alert will be triggered to ensure you stay informed and can take appropriate action."
              tipWidth="320px"
              formId="alert_threshold"
              dataChange={handleDataChange}
              errorMessage={alertError}
              formValue={data.alert_threshold}
              isReadonly={userPermissions.editItem ? "" : true}
            />
          </div>
            {singleItemData.expiringDate && 
            <DateInput
              dateLabel="Expiry Date"
              dateValue={dateValue}
              setDateValue={setDateValue}
              newDate={true}
            />}
          <TextAreaInput
            textAreaLabel="Notes"
            textAreaPlaceholder={
              !singleItemData.notes ? "Add Notes" : singleItemData.notes
            }
            textAreaId="notes"
            dataChange={handleDataChange}
            formValue={data.notes}
            isReadonly={userPermissions.editItem ? "" : true}
          />
        </div>
        <div className="standalone-form_right">
          {!singleItemData.picture ? (
            <UploadImg
              style={{ height: "326px", width: "100%" }}
              iconSize="44"
            />
          ) : editPic ? (
            <UploadImg
              style={{ height: "326px", width: "100%" }}
              iconSize="44"
            />
          ) : (
            <img src={singleItemData.picture} alt="item" />
          )}

          <div className="standalone-form_right-icon_cont">
            <div className="standalone-form_right-edit_icon">
              <Edit2
                style={{ cursor: "pointer" }}
                onClick={() => userPermissions.editItem ? setEditPic(!editPic) : dispatch(setFailureModal(true))}
              />
            </div>
            <div className="standalone-form_right-delete_icon">
              <Trash style={{ cursor: "pointer" }} onClick={popDelete} />
            </div>
          </div>
          <DeletePopup
            display={displayDelete}
            deleteItem={deleteItem}
            setDisplay={setDisplayDelete}
            popupName={singleItemData.name}
            isLoading={isDLoading}
            cancelDelete={() => setDisplayDelete(false)}
          />
        </div>
      </div>
      <CustomFields />
      <div className="standalone-buttons">
        <Dashboardbtn
          btnClass="dashboard-variant special-bt"
          btnText="Discard"
          btnWidth="200px"
          submitForm={()=> userPermissions.editItem ? clickDiscard() : dispatch(setFailureModal(true))}
        />
        <Dashboardbtn
          isLoading={isLoading}
          submitForm={()=> userPermissions.editItem ? addItem() : dispatch(setFailureModal(true))}
          btnClass="dashboard-btn special-bt"
          btnText="Save Changes"
          btnWidth="200px"
        />
      </div>
      <DiscardChanges
        displayCancel={displayDiscard}
        cancelForm={() => setDisplayDiscard(false)}
        discardForm={() => navigate("/items")}
      />
    </section>
  );
};

export default Standalone;
