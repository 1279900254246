import ExpensesTable from "./expenses-table.component";
import HeadingThree from "./heading-three.component";
import TableHeading from "../table-heading/table-heading.component";
import { useSelector } from "react-redux";

import "./expenses.styles.css";
import Loading from "../Loading";

const ExpensesComponent = () => {   
    const { expenses } = useSelector((state) => state.expenses);
    const headingArray = [ {text: "Total Expense", amount: "₦" + Number(expenses?.result?.totalExpense).toLocaleString() + ".00"} ];

    if (expenses.status === "loading") {
        return <Loading imgStyle={{ marginTop: "150px" }} />
    }

    return (
        <section className="expense-container">
            <HeadingThree array={headingArray} headingDisplay="flex" />
            <TableHeading title={"Expense History"} dropdown={true} />
            <ExpensesTable />
        </section>
    );
}
 
export default ExpensesComponent;