import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeft } from "iconsax-react";
import "./Goback.css";

const Goback = () => {
    const navigate = useNavigate();
  
    const goBack = () => {
      navigate(-1);
    };
  return (
    <div>
    <Link style={{ textDecoration: "none" }} >
      <button onClick={goBack} className="dashboard-back">
        <ArrowLeft />
      </button>
    </Link>
    </div>
  )
}

export default Goback