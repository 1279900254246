import React, { useState } from "react";
import "./custom-fields.css";
import { InputForm } from "../AuthHead/AuthHead";
import {
  Add,
  ArrowRight,
  Calendar1,
  CloseCircle,
  Edit,
  Link1,
  Text,
  Trash,
} from "iconsax-react";
import Dashboardbtn from "../dashboard-buttons/Dashboardbtn";
import SingleCustomFields from "./SingleCustomFields";
import DateInput from "../amount-input/DateInput";

const CustomFields = () => {
  const suggestedField = [
    {
      id: "1",
      icon: <Text />,
      text: "Serial Number",
    },
    {
      id: "2",
      icon: <Calendar1 />,
      text: "Expiry Date",
    },
    {
      id: "3",
      icon: <Text />,
      text: "Model/Part Number",
    },
    {
      id: "4",
      icon: <Text />,
      text: "Size",
    },
    {
      id: "5",
      icon: <Calendar1 />,
      text: "Purchase Date",
    },
    {
      id: "6",
      icon: <Link1 />,
      text: "Product Link",
    },
  ];
  const [displayField, setDisplayField] = useState(false);
  const [displayCustomField, setDisplayCustomField] = useState(false);
  const [displaySingleField, setDisplaySingleField] = useState(false);
  const [showSerial, setShowSerial] = useState(false);
  const [showExpiry, setShowExpiry] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [showSize, setShowSize] = useState(false);
  const [showProduct, setShowProduct] = useState(false);
  const [showPurchase, setShowPurchase] = useState(false);
  const [clickedField, setClickedField] = useState(false);
  const [clickedId, setClickedId] = useState("");
  const [fieldName, setFieldName] = useState("");
  const showSingleField = () => {
    if (fieldName) {
      setDisplaySingleField(!displaySingleField);
    }
  };
  const showField = () => {
    setDisplayField(!displayField);
  };
  const selectField = (item) => {
    setClickedId(item.id);
    setFieldName(item.text);
    setClickedField(true);
  };
  const saveField = () => {
    if (fieldName){
      setDisplayCustomField(true);
      if (fieldName === "Serial Number"){
        setShowSerial(true);
      }
      if (fieldName === "Expiry Date"){
        setShowExpiry(true);
      }
      if (fieldName === "Model/Part Number"){
        setShowModel(true);
      }
      if (fieldName === "Size"){
        setShowSize(true);
      }
      if (fieldName === "Purchase Date"){
        setShowPurchase(true);
      }
      if (fieldName === "Product Link"){
        setShowProduct(true);
      }
    }
    setDisplaySingleField(false);
    setDisplayField(false);
  };
  return (
    <div className="custom-fields" style={{display: "none"}}>
      <div className={!displayCustomField ? "display-none" : "display-block"}>
        <h3>Custom Fields</h3>
        <div className="custom-fields_form">
          <div
            className={
              !showSerial ? "display-none" : "custom-fields_single-input"
            }
          >
            <InputForm
              formLabel="Serial Number"
              placeholder="Add Serial Number"
              inputStyle={{ width: "320px" }}
            />
            <div className="custom-fields_single-input_icons">
              <Edit />
              <Trash />
            </div>
          </div>

          <div
            className={
              !showExpiry ? "display-none" : "custom-fields_single-input"
            }
          > 
            <DateInput dateLabel="Expiry Date" />
            <div className="custom-fields_single-input_icons date-icons">
              <Edit />
              <Trash />
            </div>
          </div>

          <div
            className={
              !showModel ? "display-none" : "custom-fields_single-input"
            }
          >
            <InputForm
              formLabel="Model/Part Number"
              placeholder="Add Model/Part Number"
              inputStyle={{ width: "320px" }}
            />
            <div className="custom-fields_single-input_icons">
              <Edit />
              <Trash />
            </div>
          </div>

          <div
            className={
              !showSize ? "display-none" : "custom-fields_single-input"
            }
          >
            <InputForm
              formLabel="Size"
              placeholder="Add Size"
              inputStyle={{ width: "320px" }}
            />
            <div className="custom-fields_single-input_icons">
              <Edit />
              <Trash />
            </div>
          </div>

          <div
            className={
              !showPurchase ? "display-none" : "custom-fields_single-input"
            }
          >
            <DateInput dateLabel="Purchase Date" />
            <div className="custom-fields_single-input_icons date-icons">
              <Edit />
              <Trash />
            </div>
          </div>

          <div
            className={
              !showProduct ? "display-none" : "custom-fields_single-input"
            }
          >
            <InputForm
              formLabel="Product Link"
              placeholder="Add Product Link"
              inputStyle={{ width: "320px" }}
            />
            <div className="custom-fields_single-input_icons">
              <Edit />
              <Trash />
            </div>
          </div>

        </div>
      </div>
      <p style={{ cursor: "pointer" }} onClick={showField}>
        <Add /> Add new field
      </p>
      <div className={`overlay ${displayField ? "w-full" : "w-0"}`}> </div>
      <div className={displayField ? "suggested-fields" : "display-none"}>
        <div className="suggested-field_head">
          <h4>Suggested Fields</h4>
          <CloseCircle
            style={{ cursor: "pointer" }}
            onClick={() => {
              setDisplayField(false);
              setClickedField(false);
            }}
          />
        </div>
        <div className="suggested-field_cont">
          {suggestedField.map((item, idx) => {
            return (
              <div
                onClick={() => selectField(item)}
                key={idx}
                id={item.id}
                className={
                  clickedField && item.id === clickedId
                    ? "suggested-content content-active"
                    : "suggested-content"
                }
              >
                {item.icon}{" "}
                <p
                  className={
                    clickedField && item.id === clickedId ? "contentp" : ""
                  }
                >
                  {item.text}
                </p>
              </div>
            );
          })}
        </div>
        <div className="suggested-field_btn">
          <Dashboardbtn
            btnClass="dashboard-btn"
            btnWidth="128px"
            rightBtnIcon={<ArrowRight />}
            btnText="next"
            submitForm={showSingleField}
          />
        </div>
      </div>
      <SingleCustomFields
        displaySingleField={displaySingleField}
        setDisplaySingleField={setDisplaySingleField}
        fieldName={fieldName}
        setFieldName={setFieldName}
        setClickedId={setClickedId}
        submitSaveForm={saveField}
      />
    </div>
  );
};

export default CustomFields;
