import { useEffect, useState } from "react";
import notificationsCircle from "../../assets/dashboard/notifications-circle.svg";
import "./notifications-component.styles.css";
import { AxiosGet } from "../../axios/axios";
import Loading from "../Loading";
import NotificationsEmpty from "./empty-notifications.component";
import useActivityHistoryData from "../../hooks/useActivityHistoryData";
import { getFirstSentence } from "../AuthHead/AuthHead";

const GeneralComponent = () => {
  const { activityHistoryData } = useActivityHistoryData();
  const url = `notifications/general/?dateFilter=${activityHistoryData.dateValue}&startDate=${activityHistoryData.startDate}&endDate=${activityHistoryData.endDate}`;
  const [isLoading, setIsLoading] = useState(null);
  const [isRead, setIsRead] = useState(false);
  const [clickReadId, setClickReadId] = useState();
  const [notsData, setNotsData] = useState([]);
  useEffect(() => {
    AxiosGet(url)
      .then((res) => {
        // console.log(res.data.map((item) =>{ return item.body.length}));
        setNotsData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err.response);
      });
    // eslint-disable-next-line
  }, []);

  const clickRead = (idx) => {
    setIsRead(true)
    setClickReadId(idx);
    // console.log(idx);
  }

  return isLoading === null ? (
    <Loading imgStyle={{ marginTop: "150px" }} />
  ) : (
    <>
      {notsData.length === 0 && <NotificationsEmpty />}
      {notsData &&
        notsData.map((item, idx) => {
          return (
            <section key={idx} className="notifications-component">
              <section className="notifications-circle-hold">
                <img src={notificationsCircle} alt="notifications-circle" />
              </section>
              <section key={idx}>
                <h3> {item.title}</h3>
                {item.body.length > getFirstSentence(item.body).length ? (
                  <p>
                    {!isRead &&  getFirstSentence(item.body)}<span onClick={() => clickRead(idx)} style={{cursor: "pointer", fontWeight: "700"}}>{ !isRead  && "...Read More"}</span>
                    {isRead && clickReadId === idx ? item.body : isRead && clickReadId !== idx ? getFirstSentence(item.body) : ""}
                    <span onClick={() => clickRead(idx)} style={{cursor: "pointer", fontWeight: "700"}}>{ isRead && clickReadId !== idx  ? "...Read More" : ""}</span>
                  </p>
                ) : (
                  <p>{getFirstSentence(item.body)}</p>
                )}
              </section>
            </section>
          );
        })}
    </>
  );
};

export default GeneralComponent;
