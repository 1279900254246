const HeadingThree = ({ array, headingDisplay }) => {
  return (
    <div className="display-none head-tre" style={{display: headingDisplay}}>
      <section
        style={{
          display: "flex",
          gap: "30px",
          flexWrap: "wrap",
        }}
      >
        {array &&
          array.map(({ text, amount }, index) => (
            <h3
              key={index}
              style={{
                fontFamily: "Nunito",
                fontWeight: 600,
                fontSize: "24px",
                lineHeight: "30px",
                color: "#616161",
              }}
            >
              {text}: <span style={{ marginLeft: "10px" }}> {amount} </span>
            </h3>
          ))}
      </section>
    </div>
  );
};

export default HeadingThree;
