import stock_icon_one from "../../assets/dashboard/stock-icon-1.svg";
import stock_icon_two from "../../assets/dashboard/stock-icon-2.svg";
import stock_icon_three from "../../assets/dashboard/stock-icon-3.svg";

import HeadingThree from "../expenses/heading-three.component";
import TableHeading from "../table-heading/table-heading.component";
import StockAtHand from "./stock-at-hand.component";

import LineChart from "./line-chart.component";

import "./overview.styles.css";
import RecentActivities from "./recent-activities.component";
import TopSellers from "./top-sellers.component";
import BarChart from "./bar-chart.component";
import PieChart from "./pie-chart.component";

const OverviewComponent = ({overviewInventory}) => {
  const headingArray = [
          { text: "Sections", amount: overviewInventory?.businessCount },
          { text: "Items", amount: overviewInventory?.itemsCount },
        ];

  const stocks = [
        {
          color: "#040614",
          icon: stock_icon_one,
          headerText: "Total Expenses",
          stockQuantity: "₦" + Number(overviewInventory?.summary?.totalExpenses).toLocaleString() + ".00",
        },
        {
          color: "#F08227",
          icon: stock_icon_two,
          headerText: "Total Stock Value",
          stockQuantity:
            "₦" +
            Number(overviewInventory?.summary?.totalStockValue).toLocaleString() +
            ".00",
        },
        {
          color: "#133861",
          icon: stock_icon_three,
          headerText: "Total Sales",
          stockQuantity: "₦" +
          Number(overviewInventory?.summary?.totalStockSales).toLocaleString() +
          ".00",
        },
        {
          color: "#6B2D00",
          icon: stock_icon_three,
          headerText: "Total Cost Price",
          stockQuantity: "₦" +
          Number(overviewInventory?.summary?.totalCostPrice).toLocaleString() +
          ".00",
        },
      ];

  return (
    <section>
      <HeadingThree array={headingArray} headingDisplay={"flex"} />
      <TableHeading title={"Inventory Summary"} />
      <section className="stock-at-hand-container">
        {stocks.map(({ color, icon, stockQuantity, headerText }, index) => (
          <StockAtHand
            key={index}
            color={color}
            icon={icon}
            stockQuantity={stockQuantity}
            headerText={headerText}
          />
        ))}
      </section>
      <LineChart />
      <div className="overview-grid">
        <RecentActivities />
        <TopSellers />
        <BarChart />
        <PieChart />
      </div>
    </section>
  );
};

export default OverviewComponent;
