import React, { useState, useEffect } from "react";
import "./notification.css";
import { ArrowDown2, ArrowUp2, Calendar1 } from "iconsax-react";
import Dashboardbtn from "../dashboard-buttons/Dashboardbtn";
import DatePicker from "react-datepicker";
import calender from "../../assets/dashboard/calendar.svg";
import useActivityHistoryData from "../../hooks/useActivityHistoryData";

const Notification = ({ notificationStyle, notsFunction }) => {
  const [notDate, setNotDate] = useState("Today");
  const [display, setDisplay] = useState(false);
  const { activityHistoryData, setActivityHistoryData } =
    useActivityHistoryData();
  const selectMenuList = [
    "Today",
    "Yesterday",
    "This Week",
    "Last Week",
    "This Month",
    "Last Month",
    "Last 3 Months",
  ];
  const clickNot = () => {
    if (!display) {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  };
  const clickNotOptions = (e) => {
    if (e.target.id === "nots-list") {
      setNotDate(e.target.textContent);
      if (notsFunction) {
        notsFunction(e);
      }
      setEndDateValue();
      setStartDateValue();
    }
  };
  const [placeholderDate, setPlaceholderDate] = useState("");
  //   Get today's date
  const today = new Date();
  //   get yesterdays date
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  // Get the start date of the current week
  const currentWeekStart = new Date(today);
  currentWeekStart.setDate(today.getDate() - today.getDay());
  // Get the start date of the previous week
  const previousWeekStart = new Date(currentWeekStart);
  previousWeekStart.setDate(currentWeekStart.getDate() - 7);
  // Get the start date of the current month
  const currentMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
  // Get the date from the previous month
  const lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  // Get the date from three months ago
  const threeMonthsAgo = new Date(
    today.getFullYear(),
    today.getMonth() - 3,
    today.getDate()
  );

  useEffect(() => {
    if (notDate === "Today") {
      const inputDate = today;
      const day = inputDate.getDate();
      const month = inputDate.getMonth() + 1;
      const year = inputDate.getFullYear();
      const formattedDate = `${month.toString().padStart(2, "0")}/${day
        .toString()
        .padStart(2, "0")}/${year}`;
      setPlaceholderDate(formattedDate);
    }
    if (notDate === "Yesterday") {
      const inputDate = yesterday;
      const day = inputDate.getDate();
      const month = inputDate.getMonth() + 1;
      const year = inputDate.getFullYear();
      const formattedDate = `${month.toString().padStart(2, "0")}/${day
        .toString()
        .padStart(2, "0")}/${year}`;
      setPlaceholderDate(formattedDate);
    }
    if (notDate === "This Week") {
      const inputDate = currentWeekStart;
      const day = inputDate.getDate();
      const month = inputDate.getMonth() + 1;
      const year = inputDate.getFullYear();
      const formattedDate = `${month.toString().padStart(2, "0")}/${day
        .toString()
        .padStart(2, "0")}/${year}`;
      setPlaceholderDate(formattedDate);
    }
    if (notDate === "Last Week") {
      const inputDate = previousWeekStart;
      const day = inputDate.getDate();
      const month = inputDate.getMonth() + 1;
      const year = inputDate.getFullYear();
      const formattedDate = `${month.toString().padStart(2, "0")}/${day
        .toString()
        .padStart(2, "0")}/${year}`;
      setPlaceholderDate(formattedDate);
    }
    if (notDate === "This Month") {
      const inputDate = currentMonthStart;
      const day = inputDate.getDate();
      const month = inputDate.getMonth() + 1;
      const year = inputDate.getFullYear();
      const formattedDate = `${month.toString().padStart(2, "0")}/${day
        .toString()
        .padStart(2, "0")}/${year}`;
      setPlaceholderDate(formattedDate);
    }
    if (notDate === "Last Month") {
      const inputDate = lastMonth;
      const day = inputDate.getDate();
      const month = inputDate.getMonth() + 1;
      const year = inputDate.getFullYear();
      const formattedDate = `${month.toString().padStart(2, "0")}/${day
        .toString()
        .padStart(2, "0")}/${year}`;
      setPlaceholderDate(formattedDate);
    }
    if (notDate === "Last 3 Months") {
      const inputDate = threeMonthsAgo;
      const day = inputDate.getDate();
      const month = inputDate.getMonth() + 1;
      const year = inputDate.getFullYear();
      const formattedDate = `${month.toString().padStart(2, "0")}/${day
        .toString()
        .padStart(2, "0")}/${year}`;
      setPlaceholderDate(formattedDate);
    }
    // eslint-disable-next-line
  }, [notDate]);


  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [startDateValue, setStartDateValue] = useState();
  const [endDateValue, setEndDateValue] = useState();
  const handleEndDateChange = (date) => {
    setEndDate(date);
    const inputDate = date;
    const day = inputDate.getDate();
    const month = inputDate.getMonth() + 1;
    const year = inputDate.getFullYear();
    const formattedDate = `${month.toString().padStart(2, "0")}/${day
      .toString()
      .padStart(2, "0")}/${year}`;
    setEndDateValue(formattedDate);
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
    const inputDate = date;
    const day = inputDate.getDate();
    const month = inputDate.getMonth() + 1;
    const year = inputDate.getFullYear();
    const formattedDate = `${month.toString().padStart(2, "0")}/${day
      .toString()
      .padStart(2, "0")}/${year}`;
    setStartDateValue(formattedDate);
  };
  const submitDate = () => {
    const sDate = startDateValue ? startDateValue : [];
    const parts2 = startDateValue ? sDate.split("/") : "";
    const startValue = startDateValue ? `${parts2[2]}-${parts2[0]}-${parts2[1]}` : "";
    const eDate = endDateValue ? endDateValue : [];
    const parts3 = endDateValue ? eDate.split("/") : "";
    const endValue = endDateValue ? `${parts3[2]}-${parts3[0]}-${parts3[1]}` : "";
    setDisplay(false);
    setActivityHistoryData({
      ...activityHistoryData,
      dateValue: startDate && endDate ? "" : notDate,
      startDate: startValue,
      endDate: endValue,
    });
  };
  
  
  
  return (
    <div className="display-none" style={{ display: notificationStyle }}>
      <div className="notification-select">
        <div onClick={clickNot} className="notification-select_block">
          <Calendar1 />
          {startDateValue && endDateValue ? (
            <p>
              {startDateValue} <span> - </span> {endDateValue}
            </p>
          ) : (
            <p>
              {activityHistoryData.dateValue} <span> {placeholderDate}</span>
            </p> 
          )}

          {!display ? <ArrowDown2 /> : <ArrowUp2 />}
        </div>
        <div
          className={
            !display ? "display-none" : "display-flex notification-select_menu"
          }
        >
          {selectMenuList.map((item) => {
            return (
              <div
                style={{ cursor: "pointer" }}
                onClick={clickNotOptions}
                key={item}
                className={
                  notDate === item ? "notsActive notsItem" : "notsItem"
                }
              >
                <p id="nots-list">{item}</p>
              </div>
            );
          })}
          <div className="date-picker_component">
            <p>From</p>
          </div>
          <div className="date-picker">
            <img src={calender} alt="date" />
            <DatePicker selected={startDate} onChange={handleStartDateChange} />
          </div>
          <div className="date-picker_component">
            <p>To</p>
          </div>
          <div className="date-picker">
            <img src={calender} alt="date" />
            <DatePicker
              id="end_date"
              selected={endDate}
              onChange={handleEndDateChange}
            />
          </div>
          <div className="nots-btn">
            <Dashboardbtn
              btnText="cancel"
              btnClass="dashboard-variant"
              submitForm={()=> setDisplay(false)}
            />
            <Dashboardbtn
              btnText="apply"
              btnClass="dashboard-btn"
              submitForm={submitDate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
