import { Link, NavLink, useLocation } from "react-router-dom";

import logo from "../../assets/dashboard/app-logo.png";
import useActive from "../../hooks/useActive";

import "./sidenav.styles.css";
import { useEffect, useState } from "react";
import useActivityHistoryData from "../../hooks/useActivityHistoryData";
import { useSelector } from "react-redux";
import { Activity, Additem, Android, Chart, CloseSquare, Folder2, Menu, MessageQuestion, MoneyRecive, MoneySend, NotificationBing, Profile2User, SearchNormal, Setting2, Speedometer, Trash, UserTag } from "iconsax-react";

const SideNav = () => {

  const userPermissions = useSelector((state) => state.userPermissions);
  const [isMenu, setIsMenu] = useState(false);

  const routesInfo = !userPermissions.viewSales ? [
    { name: "Overview", img: <Speedometer /> },
    { name: "Advanced Search", img: <SearchNormal /> },
    { name: "Section", img: <Folder2 /> },
    { name: "Items", img: <Additem /> },
    { name: "Expenses", img: <MoneyRecive /> },
    { name: "Customers", img: <UserTag /> },
    { name: "Activity History", img: <Activity /> },
    { name: "Reports & Analysis", img: <Chart /> },
    { name: "Referral", img: <Android /> },
    { name: "Manage Team", img: <Profile2User /> },
    { name: "Notifications", img: <NotificationBing /> },
    { name: "Trash", img: <Trash /> },
  ] : [
    { name: "Overview", img: <Speedometer /> },
    { name: "Advanced Search", img: <SearchNormal /> },
    { name: "Section", img: <Folder2 /> },
    { name: "Items", img: <Additem /> },
    { name: "Sales", img: <MoneySend /> },
    { name: "Expenses", img: <MoneyRecive /> },
    { name: "Customers", img: <UserTag /> },
    { name: "Activity History", img: <Activity /> },
    { name: "Reports & Analysis", img: <Chart /> },
    { name: "Referral", img: <Android /> },
    { name: "Manage Team", img: <Profile2User /> },
    { name: "Notifications", img: <NotificationBing /> },
    { name: "Trash", img: <Trash /> },
  ];

  const otherRoutesInfo = [
    { name: "Settings", img: <Setting2 /> },
    { name: "Help & Support", img: <MessageQuestion /> },
  ];

  const routes = [
    "Overview",
    "Advanced Search",
    "Section",
    "Items",
    "Sales",
    "Expenses",
    "Customers",
    "Activity History",
    "Reports & Analysis",
    "Referral",
    "Manage Team",
    "Notifications",
    "Trash",
    "Settings",
    "Help & Support",
  ];
  
  const { pathname } = useLocation();
  const { active, setActive } = useActive(routes[0]);
  const { setActivityHistoryData } = useActivityHistoryData();

  const handleActive = (route) => {
    setActive(route);
    setActivityHistoryData({
      orderBy: "Latest",
      dateValue: "Today",
      startDate: "",
      endDate: "",
    });
  };
  const handleMobActive = (route) => {
    setIsMenu(false);
    setActive(route);
    setActivityHistoryData({
      orderBy: "Latest",
      dateValue: "Today",
      startDate: "",
      endDate: "",
    });
  };
  useEffect(() => {
    handleActive();
    // eslint-disable-next-line
  }, [active]);

  useEffect(() => {
    const pathComponents = pathname.split("/");
    const firstPathComponent = pathComponents[1];
    setActive(firstPathComponent);
    // eslint-disable-next-line
  }, [pathname]);

  const openMenu = () => {
    setIsMenu(!isMenu);
  }

  return (
    <>
    <aside className="sidenav-container desktop-menu">
      <Link to={"/overview"} className="logo-img_cont">
        <img className="logo-img" src={logo} alt="stock-keeper-logo" />
      </Link>
      <nav className="sidenav">
        <ul>
          {routesInfo.map((routeInfo, id) => (
            <li key={id} className="nav-list">
              <NavLink
                to={`/${routeInfo.name
                  .trim()
                  .replace(/\s+/g, "")
                  .toLowerCase()}`}
                className={"nav-link"}
                onClick={() =>
                  handleActive(
                    routeInfo.name.trim().replace(/\s+/g, "").toLowerCase()
                  )
                }
              >
                {active !== routeInfo.name.trim().replace(/\s+/g, "").toLowerCase() && <span> {routeInfo.img} </span>}
                <span className={active === routeInfo.name.trim().replace(/\s+/g, "").toLowerCase() ? "selected-nav" : "nav-text"}> {routeInfo.name} </span>
              </NavLink>
            </li>
          ))}

          <div
            style={{
              backgroundColor: "#CCCCCC",
              width: "100%",
              margin: "20px auto",
              border: "1px solid #CCCCCC",
            }}
          ></div>

          {otherRoutesInfo.map((routeInfo, id) => (
            <li key={id} className="nav-list">
              <NavLink
                to={`/${routeInfo.name
                  .trim()
                  .replace(/\s+/g, "")
                  .toLowerCase()}`}
                className={"nav-link"}
                onClick={() =>
                  handleActive(
                    routeInfo.name.trim().replace(/\s+/g, "").toLowerCase()
                  )
                }
              >
                {active !== routeInfo.name.trim().replace(/\s+/g, "").toLowerCase() && <span> {routeInfo.img} </span>}
                <span className={active === routeInfo.name.trim().replace(/\s+/g, "").toLowerCase() ? "selected-nav" : "nav-text"}> {routeInfo.name} </span>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </aside>


    <div className="mobile-menu">
      {!isMenu && <div className="menu-bar"><Menu onClick={openMenu} cursor={"pointer"} /></div>}
    {isMenu && <aside className="sidenav-container" style={{display: isMenu ? "block" : "none"}}>
      {isMenu && <div className="menu-bar" style={{paddingBottom: "20px"}}><CloseSquare onClick={openMenu} cursor={"pointer"} color="white"  /></div>}
      <Link to={"/overview"} className="logo-img_cont">
        <img className="logo-img" src={logo} alt="stock-keeper-logo" />
      </Link>
      <nav className="sidenav">
        <ul>
          {routesInfo.map((routeInfo, id) => (
            <li key={id} className="nav-list">
              <NavLink
                to={`/${routeInfo.name
                  .trim()
                  .replace(/\s+/g, "")
                  .toLowerCase()}`}
                className={"nav-link"}
                onClick={() =>
                  handleMobActive(
                    routeInfo.name.trim().replace(/\s+/g, "").toLowerCase()
                  )
                }
              >
                {active !== routeInfo.name.trim().replace(/\s+/g, "").toLowerCase() && <span> {routeInfo.img} </span>}
                <span className={active === routeInfo.name.trim().replace(/\s+/g, "").toLowerCase() ? "selected-nav" : "nav-text"}> {routeInfo.name} </span>
              </NavLink>
            </li>
          ))}

          <div
            style={{
              backgroundColor: "#CCCCCC",
              width: "100%",
              margin: "20px auto",
              border: "1px solid #CCCCCC",
            }}
          ></div>

          {otherRoutesInfo.map((routeInfo, id) => (
            <li key={id} className="nav-list">
              <NavLink
                to={`/${routeInfo.name
                  .trim()
                  .replace(/\s+/g, "")
                  .toLowerCase()}`}
                className={"nav-link"}
                onClick={() =>
                  handleMobActive(
                    routeInfo.name.trim().replace(/\s+/g, "").toLowerCase()
                  )
                }
              >
                {active !== routeInfo.name.trim().replace(/\s+/g, "").toLowerCase() && <span> {routeInfo.img} </span>}
                <span className={active === routeInfo.name.trim().replace(/\s+/g, "").toLowerCase() ? "selected-nav" : "nav-text"}> {routeInfo.name} </span>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </aside>}
    </div>
    </>

  );
};

export default SideNav;
