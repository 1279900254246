import { configureStore } from "@reduxjs/toolkit";
import itemReducer from "../states/items/itemSlice";
import profileReducer from "../states/profile/profileSlice";
import searchReducer from "../states/search/searchSlice";
import itemIdReducer from "../states/items/itemIdSlice";
import itemDropdownReducer from "../states/items/itemDropdown"
import businessDropdownReducer from "../states/business/businessDropdown";
import salesReducer from "../states/sales/sales";
import expensesReducer from "../states/expenses/expenses";
import generalReducer from "../states/general/general";
import notificationsReducer from "../states/notifications/notifications";
import userReducer from "../states/user/user";

export default configureStore({
    reducer: {
        itemsArray: itemReducer,
        itemId: itemIdReducer,
        itemsDrop: itemDropdownReducer,
        businessDrop: businessDropdownReducer,
        sales: salesReducer,
        expenses: expensesReducer,
        profileDetails: profileReducer,
        searchDetails: searchReducer,
        general: generalReducer,
        notifications: notificationsReducer,
        userPermissions: userReducer
    },
});