import { Add, CloseCircle } from "iconsax-react";
import React, { useEffect, useState } from "react";
import CustomSelect from "../../inputs/custom-select/custom-select.component";
import SearchSelect from "../../AuthHead/SearchSelect";
import { useDispatch, useSelector } from "react-redux";
import AmountInput from "../../amount-input/AmountInput";
import { InputForm } from "../../AuthHead/AuthHead";
import Item from "./item.component";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Discount from "./discount.component";
import Dashboardbtn from "../../dashboard-buttons/Dashboardbtn";
import { AxiosAuthPost } from "../../../axios/axios";
import SuccessMsg from "../../alert/success-msg.component";
import { setFailureModal, setSuccessModal } from "../../../states/general/general";
import {
  clearSingleDraft,
  clearSingleDraftId,
  getDebts,
  getDrafts,
  getReceipts,
  getRecovery,
  getSales,
} from "../../../states/sales/sales";
import { IsEmptyObject } from "../../excerpts/IsEmptyObject";
import FailedMsg from "../../alert/failed-msg.component";

const AddSaleModal = ({ display, setDisplay, itemDict, setItemDict }) => {
  const options = [
    { value: "Sales", label: "Sales" },
    { value: "Debt", label: "Debt" },
    // { value: "Sales Recovery", label: "Sales Returns" },
  ];
  const url = "sales/";
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const { allItems, singleDraftId, singleDraft, singleSaleId } = useSelector(
    (state) => state.sales
  );
  const recoveryUrl = `sales/${singleSaleId.id}/recovery/`
  const [failedMsg, setFailedMsg] = useState("");
  const [itemSearch, setItemSearch] = useState(false);
  const [itemsArray, setItemsArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDLoading, setIsDLoading] = useState(false);
  const [itemName, setItemName] = useState("");
  const [hasDiscount, setHasDiscount] = useState(false);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [inputQuantity, setInputQuantity] = useState();
  const [itemSingle, setItemSingle] = useState();
  const [editItemsObj, setEditItemsObj] = useState();
  const [customerNameErr, setCustomerNameErr] = useState("");
  const [customerNumberErr, setCustomerNumberErr] = useState("");
  const [quantityErr, setQuantityErr] = useState("");
  const [addItemErr, setAddItemErr] = useState("");
  const [subtotalErr, setSubtotalErr] = useState("");
  const dispatch = useDispatch();
  const [data, setData] = useState({
    customerName: "",
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  const handleCodeChange = (value) => {
    setCustomerNumberErr("");
    setPhoneNumber(value);
  };

  const isDraft = localStorage.getItem("draft");
  const isRecover = localStorage.getItem("recovery");

  useEffect(() => {
    localStorage.removeItem("recovery");
  }, [])
  

  const handleModalClose = () => {
    setDisplay(false);
    setItemSingle();
    setInputQuantity("");
    setItemDict([]);
    setItemName("");
    setEditItemsObj();
    setCustomerNameErr("");
    setSubtotalErr("");
    setCustomerNumberErr("");
    setData({
      customerName: "",
    });
    setPhoneNumber("");
    setHasDiscount(false);
    setDiscountPercent(0);
    setSelectedOption(options[0]);
    dispatch(clearSingleDraft());
    dispatch(clearSingleDraftId());
    setQuantityErr("");
    localStorage.removeItem("draft");
    localStorage.removeItem("buttonClicked");
    localStorage.removeItem("recovery");
  };
  const optionClick = (item) => {
    setItemSingle(item);
  };

  const handleSubmit = () => {
    if (itemSingle){
    const isItemExist = itemDict.some((item) => item.id === itemSingle.id);
    const items = { ...itemSingle };
    items.quantity = inputQuantity;
    items.price = items.sellingPrice;
    delete items.sellingPrice;
    items.totalAmount = itemSingle?.sellingPrice * inputQuantity;
    if (isItemExist) {
      setItemDict((prevItems) =>
        prevItems.map((item) => (item.id === itemSingle.id ? items : item))
      );
    } else {
      setItemDict((item) => [...item, items]);
    }
    setAddItemErr("")
    }

    if (editItemsObj){
    const isItemExist = itemDict.some((item) => item.id === editItemsObj.id);
    const items = { ...editItemsObj };
    items.quantity = inputQuantity;
    items.price = items.sellingPrice || items.price;
    delete items.sellingPrice;
    items.totalAmount = editItemsObj?.sellingPrice * inputQuantity || editItemsObj?.price * inputQuantity;
    if (isItemExist) {
      setItemDict((prevItems) =>
        prevItems.map((item) => (item.id === editItemsObj.id ? items : item))
      );
    } else {
      setItemDict((item) => [...item, items]);
    }
    setAddItemErr("")
    }
    setItemSingle("");
    setInputQuantity("");
    setItemName("");
  };

  const sum = !itemDict?.length
    ? 0
    : itemDict.reduce(
        (accumulator, item) => accumulator + item.totalAmount || item.total,
        0
      );

  const discount = hasDiscount
    ? parseFloat(sum * parseFloat(parseInt(discountPercent) * 0.01))
    : 0;

  const handleDataChange = (e) => {
    setCustomerNameErr("");
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    // console.log(newData);
  };

  const handleItemSearch = (e) => {
    setItemName(e.target.value);
    if (e.target.value) {
      setItemSearch(true);
      setItemsArray(
        allItems.filter((item) =>
          item.name.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    } else {
      setItemSearch(false);
    }
    if (e.target.value !== itemSingle?.name) {
      setItemSingle();
    }
  };

  const handleItemDelete = (id) => {
    // if (!isDraft){
      setQuantityErr("");
      setItemDict((prev) => prev.filter((item) => item.id !== id));
    // }
  };

  const handleItemEdit = (items) => {
    // console.log(items);
    // if (!isDraft){
      setQuantityErr("");
      setEditItemsObj(items);
      setItemName(items.name);
      setInputQuantity(items.quantity);
      setItemDict((prev) => prev.filter((item) => item.id !== items.id));
    // }
  };

  const submitSale = (e) => {
    // console.log(itemDict);
    if (localStorage.getItem("buttonClicked") === "Add") {
      setIsLoading(true);
    }
    if (localStorage.getItem("buttonClicked") === "Draft") {
      setIsDLoading(true);
    }
    const payLoad = {
      salesType:
        localStorage.getItem("buttonClicked") === "Add"
          ? selectedOption.value
          : localStorage.getItem("buttonClicked") === "Draft"
          ? "Draft"
          : "",
      itemDict: itemDict,
      customerName: data.customerName
        ? data.customerName
        : singleDraftId?.customerName,
      customerPhoneNumber: phoneNumber
        ? phoneNumber
        : singleDraftId?.customerPhoneNumber,
      hasDiscount: hasDiscount,
      discountPercent: discountPercent,
      subTotal: sum,
      total: sum - discount,
    };
      AxiosAuthPost(isDraft ? `drafts/${singleDraft.id}/` : url, payLoad)
      .then((res) => {
        // console.log(res);
        setIsLoading(false);
        setIsDLoading(false);
        dispatch(setSuccessModal(true));
        localStorage.removeItem("draft");
        localStorage.removeItem("buttonClicked");
        setItemSingle();
        setInputQuantity("");
        setItemDict([]);
        setItemName("");
        setCustomerNameErr("");
        setCustomerNumberErr("");
        setData({
          customerName: "",
        });
        setPhoneNumber("");
        setHasDiscount(false);
        setSelectedOption(options[0]);
        setDiscountPercent(0);
        setQuantityErr("");
        dispatch(getDrafts("?dateFilter=Today&page=1&pageSize=10"));
        dispatch(getDebts("?dateFilter=Today&page=1&pageSize=10"));
        dispatch(getReceipts("?dateFilter=Today&page=1&pageSize=10"));
        dispatch(getRecovery("?dateFilter=Today&page=1&pageSize=10"));
        dispatch(getSales("?orderBy=Latest&dateFilter=Today"));
        dispatch(clearSingleDraft());
        dispatch(clearSingleDraftId());
        setDisplay(false);
      })
      .catch((err) => {
        // console.log(err.response);
        if (err.response.status === 403){
          setFailedMsg(err.response.data.message);
          dispatch(setFailureModal(true));
        } else
        for (let i = 0; i < err.response.data.errors.length; i++) {
          if (err.response.data.errors[i].fieldName === "customerName") {
            setCustomerNameErr(err.response.data.errors[i].error);
          }
          if (err.response.data.errors[i].fieldName === "customerPhoneNumber") {
            setCustomerNumberErr(err.response.data.errors[i].error);
          }
          if (err.response.data.errors[i].fieldName === "itemDict") {
            setQuantityErr(err.response.data.errors[i].error);
          }
          if (err.response.data.errors[i].fieldName === "subTotal") {
            setAddItemErr("select an item and click Add Item to add the selected item to your item cart before selling");
          }
        }
        setIsLoading(false);
        setIsDLoading(false);
        localStorage.removeItem("buttonClicked");
      });
    
  };

  useEffect(() => {
    if (singleDraft && !IsEmptyObject(singleDraft)) {
      setItemDict(singleDraftId.itemsDict);
    }
    // console.log(singleDraftId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleDraft, singleDraftId]);
  // console.log(singleSaleId);

  const recoveryPayLoad = {
    itemDict: itemDict,
    customerName: !singleSaleId?.customerName
      ? ""
      : singleSaleId?.customerName,
    customerPhoneNumber: !singleSaleId?.customerPhoneNumber
      ? ""
      : singleSaleId?.customerPhoneNumber,
    hasDiscount: hasDiscount,
    discountPercent: discountPercent,
    subTotal: !sum ? 0 : sum,
    total: !sum ? 0 : sum - discount,
  };

  const submitRecovery = () => {
    setIsLoading(true);
    AxiosAuthPost(recoveryUrl, recoveryPayLoad).then(res => {
      // console.log(res);
      setIsLoading(false);
      dispatch(setSuccessModal(true));
      localStorage.removeItem("recovery");
      setSubtotalErr("")
      dispatch(getRecovery("?dateFilter=Today&page=1&pageSize=10"));
      dispatch(getReceipts("?dateFilter=Today&page=1&pageSize=10"));
      dispatch(getSales("?orderBy=Latest&dateFilter=Today"));
    }).catch(err => {
      // console.log(err.response);
      for (let i = 0; i < err.response.data.errors.length; i++) {
        if (err.response.data.errors[i].fieldName === "customerName") {
          setCustomerNameErr(err.response.data.errors[i].error);
        }
        if (err.response.data.errors[i].fieldName === "customerPhoneNumber") {
          setCustomerNumberErr(err.response.data.errors[i].error);
        }
        if (err.response.data.errors[i].fieldName === "itemDict") {
          setQuantityErr(err.response.data.errors[i].error);
        }
        if (err.response.data.errors[i].fieldName === "subTotal") {
          setSubtotalErr(err.response.data.errors[i].error);
        }
      }
      setIsLoading(false);
    })
  }

  return (
    <>
      <div className={`overlay ${display ? "w-full" : "w-0"}`}></div>
      {display && <FailedMsg msg={failedMsg} />}
      <section
        className={`add-sale-modal ${display ? "opacity-1" : "opacity-0"}`}
      >
        <section>
          <section className="add-sale-modal-heading">
            <h3> Add a Sale </h3>
            <CloseCircle
              size="32"
              className="close"
              color="#111111"
              onClick={handleModalClose}
            />
          </section>
          <CustomSelect
            label={"Type"}
            options={options}
            name={"type"}
            value={selectedOption}
            onChange={handleSelectChange}
          />
          {itemDict?.length > 0 &&
            itemDict.map((item) => (
              <Item
                key={item.id}
                item={item}
                handleEdit={handleItemEdit}
                handleDelete={handleItemDelete}
              />
            ))}
          {quantityErr && <p style={{ color: "red" }}>{quantityErr}</p>}
          <SearchSelect
            formLabel={"Item Name"}
            placeholder={"Enter Item Name"}
            dataChange={handleItemSearch}
            searching={itemSearch}
            optionsArray={itemsArray}
            setSearching={setItemSearch}
            optionClick={optionClick}
            formValue={itemSingle ? itemSingle?.name : itemName}
          />
          <InputForm
            formLabel={"Quantity"}
            placeholder={"Quantity"}
            dataChange={(e) => setInputQuantity(e.target.value)}
            formValue={inputQuantity}
            errorMessage={
              (!isRecover && itemSingle?.quantity < inputQuantity)
                ? `quantity is more than what you have in stock... You have ${itemSingle?.quantity} of this item(s) in stock`
                : ""
            }
            inputType={"text"}
          />
          <AmountInput
            amountLabel={"Unit Price"}
            amountPlaceholder={"0"}
            amountValue={
              inputQuantity && itemSingle
                ? itemSingle?.sellingPrice * inputQuantity
                : editItemsObj?.sellingPrice * inputQuantity || editItemsObj?.price * inputQuantity || 0
            }
            // dataChange={handleChange}
            // errorMessage={errors["subTotal"]}
            // name={"subTotal"}
            readOnly
          />
          <section className="add-new-item-container">
            <p onClick={handleSubmit}>
              {" "}
              <Add color="#080b24" /> Add Item{" "}
            </p>
          </section>
            {addItemErr && <p style={{color: "red", textAlign: "center"}}>{addItemErr}</p>}
          <InputForm
            formLabel={"Customer Name"}
            placeholder={"Enter Customer Name"}
            dataChange={handleDataChange}
            formId={"customerName"}
            formValue={
              data.customerName
                ? data.customerName
                : singleDraftId?.customerName
            }
            errorMessage={customerNameErr}
          />
          <p className="phone-p" style={{ color: customerNumberErr && "red" }}>
            Customer Phone Number
          </p>
          <div
            className="customer-number"
            style={{
              border: !customerNumberErr
                ? "1px solid #E6E6E6"
                : "1px solid red",
              height: "3.25rem",
              padding: "0.5rem 1rem",
              paddingTop: "0.85rem",
              borderRadius: "12px",
              margin: "10px 0",
            }}
          >
            <PhoneInput
              id="phoneNumber"
              placeholder="Enter phone number"
              defaultCountry="NG"
              onChange={handleCodeChange}
              value={
                phoneNumber ? phoneNumber : singleDraftId?.customerPhoneNumber
              }
            />
          </div>
          {customerNumberErr && (
            <p style={{ color: "red" }}>{customerNumberErr}</p>
          )}

          <Discount
            hasDiscount={hasDiscount}
            setHasDiscount={setHasDiscount}
            setDiscountPercent={setDiscountPercent}
          />
          <section className="total-subtotal-container">
            <div
              style={{
                borderTop: "1px solid #D1D1D1",
                width: "100%",
                margin: "5px 0px",
              }}
            ></div>
            <section className="subtotal">
              <span className="text">Sub Total</span>
              <span className="amount"> N {!sum ? 0 : sum} </span>
            </section>

            <section className="total">
              <span className="text"> Total </span>
              <span className="amount"> N {!sum ? 0 : sum - discount} </span>
            </section>

            <div
              style={{
                borderTop: "1px solid #D1D1D1",
                width: "100%",
                margin: "5px 0px",
              }}
            ></div>
          </section>
          {subtotalErr && <p style={{ color: "red" }}>{subtotalErr}</p>}
          <div
            className="sales-btns"
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingTop: "20px",
            }}
          >
            <Dashboardbtn
              btnClass={"dashboard-variant"}
              btnText={"Save to Drafts"}
              btnWidth={"170px"}
              submitForm={() => {
                if (!isRecover){
                  localStorage.setItem("buttonClicked", "Draft");
                  submitSale();
                }
              }}
              isLoading={isDLoading}
            />
            <Dashboardbtn
              btnClass={"dashboard-btn"}
              btnText={"Sell"}
              btnWidth={"100px"}
              submitForm={() => {
                if(isRecover){
                  submitRecovery();
                } else {
                  localStorage.setItem("buttonClicked", "Add");
                  submitSale();
                }
              }}
              isLoading={isLoading}
            />
          </div>
        </section>
      </section>
      <SuccessMsg msg={"Successful"} />
    </>
  );
};

export default AddSaleModal;
