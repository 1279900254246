import { useEffect, useState } from "react";
import "./custom-input.styles.css";
import { Eye, EyeSlash } from "iconsax-react";
import ErrorMsg from "../../alert/error-msg.component";

const CustomInput = ({ label, name, errors, innerRef, ...rest }) => {
    const [isPassword, setIsPassword] = useState(false);
  
    useEffect(() => {
      if (rest.type === 'password') {
        setIsPassword(true);
      }
    }, [rest.type]);

    return (
        <>
            <section className="input-container">
                <label className={`${errors[name] ? 'error-label': ''}`} htmlFor=""> {label} </label>
                    <input
                        ref={innerRef}
                        name={name}
                        className={`${isPassword ? 'pr' : ''} ${errors[name] ? 'error-input': ''} `}
                        type={isPassword ? 'password' : 'text'}
                        {...rest}
                    />
                    { rest.type === 'password' && (
                        <section className="eye-container" onClick={() => setIsPassword(!isPassword)}>
                        <span> {!isPassword ? <Eye color="#292D32"/> : <EyeSlash color="#292D32"/> }</span>
                        </section>
                    )}
            </section>
            { errors[name] && <ErrorMsg msg={errors[name]} /> }
        </>
    );
}
 
export default CustomInput;